<template>
  <div class="live-map-device-filter">
    <SelectFiltersDropdown
      v-if="categoryOptions?.length > 0"
      :key="categoryOptions"
      ref="filterCategories"
      v-model:filters="filters.categories"
      :filter-name="$t('category')"
      :filter-list="categoryOptions"
      :ls-storage-key="StorageKey.CATEGORIES"
    />
    <SelectFiltersDropdown
      v-if="routesFilterOptions?.length > 0"
      :key="routesFilterOptions"
      ref="filterRoutes"
      v-model:filters="filters.routes"
      :filter-name="$t('route')"
      :filter-list="routesFilterOptions"
      :ls-storage-key="StorageKey.ROUTES"
    />
    <SelectFiltersDropdown
      v-if="teamsFilterOptions?.length > 0"
      :key="teamsFilterOptions"
      ref="filterTeams"
      v-model:filters="filters.teams"
      :filter-name="$t('team')"
      :filter-list="teamsFilterOptions"
      :ls-storage-key="StorageKey.TEAMS"
    />
    <div v-if="hasFilters" class="live-map-device-filter__reset">
      <div class="live-map-device-filter__reset__separation" />
      <Btn class="live-map-device-filter__reset__btn" type="tertiary" @click="deleteFilter()">
        <span>{{ $t('resetFilters') }}</span>
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import SelectFiltersDropdown from '@/components/common/SelectFiltersDropdown.vue';
import { AlternativeState, DelayState, OFF_ITINERARY } from '@/store/devices';

/** @enum {string} */
const StorageKey = {
  CATEGORIES: 'settings.op.livemap.categoriesFilter',
  ROUTES: 'settings.op.livemap.routesFilter',
  TEAMS: 'settings.op.livemap.teamsFilter',
};

/**
 * Check if any filters are selected, return true if any type has at least 1 element selected
 * @param {FilterEvent} filters
 * @return {boolean}
 */
export const checkIfAnyFilters = filters => {
  return filters.routes?.length > 0 || filters.categories?.length > 0 || filters.teams?.length > 0;
};

export default {
  name: 'LiveMapDeviceFilter',

  components: {
    Btn,
    SelectFiltersDropdown,
  },

  props: {
    /** @type {import('vue').Prop<Array<import('@/components/common/SelectFiltersDropdown.vue').DropdownOption>>} */
    teamsFilterOptions: {
      type: Array,
      required: true,
    },
    /** @type {import('vue').Prop<Array<import('@/components/common/SelectFiltersDropdown.vue').DropdownOption>>} */
    routesFilterOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['updateFilters'],
  data: () => ({
    StorageKey,
    /** @type {boolean} */
    modalFilterShown: false,

    /** @type {FilterEvent} */
    filters: {
      routes: [],
      teams: [],
      categories: [],
    },
    /** @type {Array<import('@/components/common/SelectFiltersDropdown.vue').DropdownOption>} */
    categoryOptions: [],
  }),
  computed: {
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },

    /** @return {boolean} */
    hasFilters() {
      return checkIfAnyFilters(this.filterChecker);
    },

    /** @return {FilterEvent} */
    filterChecker() {
      const filterCheck = {
        routes: [],
        teams: [],
        categories: [],
      };
      if (this.filters.routes.length !== this.routesFilterOptions.length)
        filterCheck.routes = this.filters.routes;

      if (
        this.filters.categories.length !==
        this.categoryOptions.filter(option => option.isSeparationline !== true).length
      )
        filterCheck.categories = this.filters.categories;

      if (this.filters.teams.length !== this.teamsFilterOptions.length)
        filterCheck.teams = this.filters.teams;
      return filterCheck;
    },
  },
  watch: {
    filterChecker: {
      deep: true,
      handler() {
        this.$emit('updateFilters', this.filterChecker);
      },
    },
  },

  created() {
    this.createCategoryOptions();
  },

  methods: {
    deleteFilter() {
      this.$refs.filterCategories.toggleAll(false, true);
      this.$refs.filterRoutes.toggleAll(false, true);
      this.$refs.filterTeams.toggleAll(false, true);
    },

    createCategoryOptions() {
      this.categoryOptions = Object.values(DelayState).map(k => ({
        id: k,
        label: this.$t(`delayState.${k}`),
      }));
      this.categoryOptions.push({ id: 'none', isSeparationline: true });

      Object.values(AlternativeState).forEach(k => {
        this.categoryOptions.push({
          id: k,
          label: this.$t(`alternativeState.${k}`),
        });
      });
      this.categoryOptions.push({ id: 'none2', isSeparationline: true });

      this.categoryOptions.push({ id: OFF_ITINERARY, label: this.$t('offItineraryState') });
    },
  },
};

/**
 * @typedef {Object} FilterEvent
 * @property {Array<string>} categories
 * @property {Array<string>} routes
 * @property {Array<string>} teams
 */
</script>

<style lang="scss">
.live-map-device-filter {
  display: flex;
  gap: 10px;
  padding: 12px 12px 12px 0;

  &__reset {
    display: flex;

    &__separation {
      width: 1px;
      height: 100%;
      margin: 0 5px;
      background: $border;
    }
  }

  .select-filter__button {
    height: 36px;
  }
}
</style>

<i18n locale="fr">
  {
    "category": "Catégorie",
    "route": "Ligne",
    "team": "Équipe",
    "delayState": {
      "tooEarly": "En avance",
      "tooLate": "En retard",
      "onTime": "À l'heure"
    },
    "alternativeState":{
      "deadRunning": "Haut-le-pied",
      "routing": "Acheminement",
    },
    "offItineraryState": "Hors itinéraire",
    "resetFilters": "Supprimer les filtres"

  }
  </i18n>

<i18n locale="en">
  {
    "filter": "Filter",
    "category": "Category",
    "route": "Route",
    "team": "Team",
    "delayState": {
      "tooEarly": "Early",
      "tooLate": "Late",
      "onTime": "On time"
    },
    "alternativeState":{
      "deadRunning": "Dead running",
      "routing": "Routing",
    },
    "offItineraryState": "Off itinerary",
    "resetFilters": "Reset filters"
  }
  </i18n>
