<template>
  <div class="passengers-app">
    <!-- Header -->
    <div class="passengers-app__header">
      <div>
        <Btn type="primary" :route="{ name: GroupRoute.REPORTING_PASSENGERS_APP }">
          {{ $t('passengerAppReport') }}
        </Btn>
      </div>

      <div class="up-right-container">
        <Btn type="secondary" :route="{ name: GroupRoute.PASSENGERS_MESSAGE }">
          {{ $t('passengersMessage') }}
        </Btn>
        <Btn type="secondary" :route="{ name: GroupRoute.GTFS_RT }">
          {{ $t('gtfsRt') }}
        </Btn>
      </div>
    </div>

    <div class="passengers-app__main">
      <!-- Left side -->
      <div class="passengers-app__left-side">
        <!-- Activate/Deactivate -->
        <div class="form-group">
          <ToggleSwitch
            id="display-group"
            :checked="values.pub"
            class="settings-info__toggle-switch"
            @change="onToggleChange"
          />
          <span class="form-group__label switch-label">{{ $t('activateApp') }}</span>
        </div>

        <div class="passengers-app__display-info" :class="{ published: values.pub }">
          {{ values.pub ? $t('displayInfo') : $t('nonDisplayInfo') }}
          <div>{{ $t('available') }}</div>
        </div>

        <!-- Logo -->
        <GroupLogoInput
          v-model:logo-url="values.logo_url"
          v-model:trigger-save-logo="saveLogo"
          @isLogoValid="checkLogoValidity"
          @logoHasChanged="checkLogoHasChanged"
        ></GroupLogoInput>

        <!-- Color selection -->
        <div class="form-group">
          <label class="form-group__label" for="label">
            {{ $t('color') }}
          </label>
          <input
            id="color"
            v-model="values.color"
            class="passengers-app__color-selector"
            name="color"
            type="color"
            @mousedown.prevent
            @input="onInputChange"
          />
        </div>

        <!-- Email -->
        <div class="form-group">
          <label class="form-group__label" for="contact-email">{{ $t('contactEmail') }}</label>
          <input
            id="contact-email"
            v-model="values.contact.email"
            class="form-group__input"
            name="contact-email"
            type="text"
            required
            @input="onInputChange"
          />
        </div>

        <!-- Phone number -->
        <div class="form-group">
          <label class="form-group__label" for="contact-phone_number">{{ $t('contactPhoneNumber') }}</label>
          <input
            id="contact-phone_number"
            v-model="values.contact.phone_number"
            class="form-group__input"
            name="contact-phone_number"
            type="text"
            required
            @input="onInputChange"
          />
        </div>

        <Btn type="primary" :disabled="(!hasChanged && !logoHasChanged) || !isValid" @click="saveInfo">
          <font-awesome-icon icon="fa-floppy-disk" />
          <span>{{ $t('save') }}</span>
        </Btn>
      </div>

      <!-- Right side -->
      <div class="passengers-app__right-side">
        <div class="passengers-app__catch-phrase">
          {{ $t('catchPhrase1') }}
          <span class="lighter">{{ $t('catchPhrase2') }}</span>
          {{ $t('catchPhrase3') }}
        </div>
        <img
          alt="illustration"
          class="passengers-app__illustration"
          src="@/assets/img/illustration_passenger_app.svg?url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GroupRoute } from '@/libs/routing';

import GroupLogoInput from '@/components/common/GroupLogoInput.vue';
import Btn from '@/components/ui/Btn.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

import SettingsMixin, { ConfigTarget } from '@/pages/SettingsPage/SettingsMixin';

export default {
  name: 'PassengersApp',

  components: {
    Btn,
    ToggleSwitch,
    GroupLogoInput,
  },

  mixins: [SettingsMixin],

  data: () => ({
    GroupRoute,
    logoHasChanged: false,
    islogoValid: true,
    saveLogo: false,
    hasChanged: false,
    values: {
      pub: false,
      color: '#00B871', //$primary-light
      contact: {
        email: '',
        other_informations: '',
        phone_number: '',
      },
      logo_url: '',
    },
  }),

  computed: {
    /** @return {boolean} */
    isValid() {
      return (
        this.values.contact?.email?.length > 0 &&
        this.values.contact?.phone_number?.length > 0 &&
        this.islogoValid
      );
    },
  },

  methods: {
    async saveInfo() {
      if (this.logoHasChanged) this.saveLogo = true;
      this.save(ConfigTarget.INFO_APP);
      this.hasChanged = false;
      this.logoHasChanged = false;
    },
    checkLogoValidity(value) {
      this.islogoValid = value;
    },
    checkLogoHasChanged(value) {
      this.logoHasChanged = value;
      this.hasChanged = true;
    },
    /**
     * Handle toggle change and save
     * @param {boolean} checked
     */
    onToggleChange(checked) {
      this.values.pub = checked;
      this.hasChanged = true;
      this.saveInfo();
    },
    onInputChange() {
      this.hasChanged = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.passengers-app {
  padding: $view-standard-padding;

  &__catch-phrase {
    padding: 30px;
    color: $secondary;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;

    .lighter {
      color: $primary-light;
    }
  }

  &__color-selector {
    display: block;
    height: 34px;
    padding: 0;
    border: none;

    &--disabled {
      opacity: 0.2;
    }
  }

  &__display-info {
    width: fit-content;
    margin: 10px 0 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: $transparent-text-secondary;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    line-height: 1.6em;

    &.published {
      background-color: $transparent-primary;
      color: $primary-light;
    }
  }

  &__illustration {
    width: 87%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .up-right-container {
    display: flex;
  }

  &__left-side {
    width: 50%;
  }

  &__main {
    display: flex;
  }

  &__right-side {
    width: 50%;
    text-align: center;
  }

  .switch-label {
    margin-left: 10px;
  }

  .form-group {
    &__input {
      height: 30px;
      min-height: 30px;
    }
  }
}
</style>

<!-- eslint-disable no-irregular-whitespace -->
<i18n locale="fr">
{
  "activateApp": "Activer l'application voyageurs",
  "available": "Disponible sur Android et iOS.",
  "catchPhrase1": "Améliorez ",
  "catchPhrase2": "l'expérience de vos voyageurs ",
  "catchPhrase3": "grâce à l'information en temps réel !",
  "chooseFile": "Choisir un fichier",
  "color": "Couleur du réseau",
  "contactEmail": "Adresse email du réseau pour les voyageurs",
  "contactPhoneNumber": "Numéro de téléphone du réseau pour les voyageurs",
  "displayInfo": "Votre groupe est affiché sur l'application voyageurs Pysae.",
  "gtfsRt": "GTFS-RT",
  "nonDisplayInfo": "Activez l'application voyageurs pour afficher votre groupe sur l'application voyageurs Pysae.",
  "passengerApp": "Application voyageurs",
  "passengersMessage": "Messages voyageurs",
  "passengerAppReport": "Rapport application voyageurs"
}
</i18n>

<i18n locale="en">
{
  "activateApp": "Activate the passenger app",
  "available": "Available on Android and iOS.",
  "catchPhrase1": "Improve your ",
  "catchPhrase2": "passengers experience ",
  "catchPhrase3": "with real-time information!",
  "chooseFile": "Choose an image",
  "color": "Group color",
  "contactEmail": "Contact email address for passengers",
  "contactPhoneNumber": "Contact phone number for passengers",
  "displayInfo": "Your group is displayed on the Pysae passenger app.",
  "gtfsRt": "GTFS-RT",
  "nonDisplayInfo": "Activate the passenger app to display your group on the Pysae app.",
  "passengerApp": "Passengers app",
  "passengersMessage": "Passengers message",
  "passengerAppReport": "Passengers app reporting"
}
</i18n>
