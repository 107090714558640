<template>
  <div class="settings-route-management">
    <DataGridVuetify
      ref="dataGrid"
      :title="$t('routes', { count: routesList.length })"
      :data="routesList"
      :datagrid="datagrid"
      :build-cell-injectors="buildCellInjectors"
      height="75vh"
    />
    <Btn type="primary" :disabled="!hasChanged" @click="saveRoutesConfig">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
/**
 * This view does not use the group SettingsMixin
 * because it calls PUT /api/v3/groups/{group_id}/config/routes
 * instead of PUT /api/v2/groups/{group_id}
 */

import api from '@/api';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';

import { ColumnKey, getDatagrid } from './RouteManagement.conf.js';

export default {
  name: 'RouteManagement',
  components: { DataGridVuetify, Btn },

  data: () => ({
    datagrid: getDatagrid(),
    /** @type {Array<import('@/store/gtfs').Route>} */
    routesList: [],
    /** @type {import('@/api').RoutesConfig} */
    routesConfig: {
      deactivated_routes: [],
      private_routes: [],
    },
  }),

  computed: {
    /** @return {import('@/api').RoutesConfig} */
    apiRoutesConfig() {
      return {
        deactivated_routes: this.group.deactivated_routes || [],
        private_routes: this.group.private_routes || [],
      };
    },

    /** @return {{[key in ColumnKey]: (data: {apiData: import('@/store/gtfs').Route}) => Object}} */
    buildCellInjectors() {
      const setDeactivatedRoutes = apiDataRow => () => {
        const routeId = apiDataRow.route_id;
        if (!this.routesConfig.deactivated_routes.includes(routeId)) {
          this.routesConfig.deactivated_routes.push(routeId);
          this.routesList.find(route => route.route_id === routeId).localIsDeactivated = true;

          // If a route is deactivated, it should be added also to the private routes :
          if (!this.routesConfig.private_routes.includes(routeId)) {
            this.routesConfig.private_routes.push(routeId);
            this.routesList.find(route => route.route_id === routeId).localIsPrivate = true;
          }
        } else {
          this.routesConfig.deactivated_routes = this.routesConfig.deactivated_routes.filter(
            id => id !== routeId,
          );
          this.routesList.find(route => route.route_id === routeId).localIsDeactivated = false;
        }
      };
      const setPrivateRoutes = apiDataRow => () => {
        const routeId = apiDataRow.route_id;
        if (!this.routesConfig.private_routes.includes(routeId)) {
          this.routesConfig.private_routes.push(routeId);
          this.routesList.find(route => route.route_id === routeId).localIsPrivate = true;
        } else {
          this.routesConfig.private_routes = this.routesConfig.private_routes.filter(id => id !== routeId);
          this.routesList.find(route => route.route_id === routeId).localIsPrivate = false;
        }
      };
      return {
        [ColumnKey.STATUS]: ({ apiData }) => ({ valueChanged: setDeactivatedRoutes(apiData) }),
        [ColumnKey.PASSENGERS_INFO_VISIBILITY]: ({ apiData }) => ({ click: setPrivateRoutes(apiData) }),
      };
    },

    /** @return {boolean} */
    hasChanged() {
      return JSON.stringify(this.apiRoutesConfig) !== JSON.stringify(this.routesConfig);
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },
  },

  watch: {
    group: {
      handler() {
        // Reset routesConfig to the group's config
        // spread operator is needed to avoid modifying the original group object
        this.routesConfig.deactivated_routes = [...this.group.deactivated_routes] || [];
        this.routesConfig.private_routes = [...this.group.private_routes] || [];
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    this.getRoutes();
  },

  methods: {
    async getRoutes() {
      const routesGtfs = await this.$store.dispatch('gtfs/getRoutesMap', { gtfsId: this.gtfsId });
      this.routesList = Object.values(routesGtfs).map(route => ({
        ...route,
        is_deactivated: this.group.deactivated_routes?.includes(route.route_id),
        is_private: this.group.private_routes?.includes(route.route_id),
        // Handle reactivity before saving :
        localIsDeactivated: this.group.deactivated_routes?.includes(route.route_id),
        localIsPrivate: this.group.private_routes?.includes(route.route_id),
      }));
    },

    async saveRoutesConfig() {
      await api.config.putRoutesDisplay(this.group._id, this.routesConfig);
      await this.$store.dispatch('groupUpdate');
      await this.getRoutes();
    },
  },
};
</script>

<style lang="scss">
.settings-route-management {
  .datagrid-vuetify {
    margin-bottom: 30px;
  }
}
</style>
