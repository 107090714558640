import i18n from '@/i18n';

/** @enum {string} */
export const SeriesCorrespondance = {
  0: 'on_time',
  1: 'too_late',
  2: 'too_early',
};

export const PunctualityReportsHelper = {
  /**
   * @param {import('@/api').PunctualityByRoute | import('@/api').PunctualityByDay} entry
   * @param {SeriesCorrespondance} key
   * @param {Boolean} [isRouteChartType]
   * @returns {String}
   */
  createTooltip(entry, key, isRouteChartType) {
    const { t } = i18n.global;
    return `
    <ul class="apexcharts-custom-tooltip">
      <li class="apexcharts-custom-tooltip__title ${key}">${t(`dashboard.${key}`)}</li>
      <li>${t('dashboard.stopsRecorded', [entry[key]])}</li>
      <li>${t('dashboard.punctualityPercent', [parseFloat(entry[`${[key]}_percentage`].toFixed(2))])}</li>
          ${
            isRouteChartType
              ? `<li class="apexcharts-custom-tooltip__subtitle">${entry.route_long_name}</li>`
              : ``
          }
    </ul>
    `;
  },

  /**
   * Data series for ApexChart
   * @param {Array<import('@/api').PunctualityByRoute | import('@/api').PunctualityByDay>} data
   * @return {Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>}
   */
  getSeries(data) {
    return [
      {
        data: data.map(iter => (iter ? parseFloat(iter.on_time_percentage.toFixed(2)) : 0)),
        color: () => '#00B871', // $primary-light
      },
      {
        data: data.map(iter => (iter ? parseFloat(iter.too_late_percentage.toFixed(2)) : 0)),
        color: () => '#f99c49', // $warn
      },
      {
        data: data.map(iter => (iter ? parseFloat(iter.too_early_percentage.toFixed(2)) : 0)),
        color: () => '#eb5757', // $danger
      },
    ];
  },
};
