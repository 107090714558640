<script setup>
import Language from '@/components/layout/NavBarTop/Language.vue';
import { AuthRoute } from '@/libs/routing';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<template>
  <div class="login-page">
    <div class="login-page__header">
      <Language />
      <router-link
        v-if="route.name === AuthRoute.PASSWORD_RESET"
        :to="{ name: AuthRoute.LOGIN_FORM }"
        class="login-page__link"
      >
        {{ $t('login.backToLogin') }}
      </router-link>
    </div>
    <div class="login-page__main">
      <div class="logo" />
      <router-view class="login-page__content" />
    </div>
  </div>
</template>

<style lang="scss">
.login-page {
  height: 100vh;
  padding: $view-standard-padding;
  background-color: $canvas;

  &__content {
    width: 500px;
  }

  &__header {
    position: absolute;
  }

  &__link {
    padding: $view-standard-padding;
    padding-top: 0;
    color: $text-dark-variant;
    font-weight: 500;
    font-size: 14px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
