<template>
  <div class="reports">
    <AnimatedDots v-if="isLoading" class="reports__loading reports__loading-dots" :type-v2="true" />
    <NoReportData v-else-if="!hasData" />

    <template v-else>
      <div class="reports__abstract">
        <NumberDisplay :title="$t('numberOfPassengers')" :number="totalPassengers" />
        <NumberDisplay :title="$t('averagePassengers')" :number="averagePassengers" />
      </div>
      <div class="reports__barcharts">
        <BarChart3
          :height="chartHeight"
          :options="dayChart.options"
          :series="dayChart.series"
          :categories="dayChart.categories"
          :title="$t('chartDayTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
        <BarChart3
          :height="chartHeight"
          :options="routeChart.options"
          :series="routeChart.series"
          :categories="routeChart.categories"
          :title="$t('chartRouteTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { stats as ApiStats } from '@/api';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import BarChart3 from '@/components/ui/BarChart3.vue';
import NumberDisplay from '@/components/ui/NumberDisplay.vue';
import NoReportData from '@/components/ui/NoReportData.vue';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { GroupBy } from '@/libs/reports';

import { PassengerCountReportsHelper } from './PassengerCountReportsHelper';

export default {
  name: 'ReportsPassengersCounts',

  components: {
    AnimatedDots,
    BarChart3,
    NumberDisplay,
    NoReportData,
  },

  props: {
    /** @type {Object} */
    chartHeight: {
      type: Number,
      default: 620,
    },
    /** @type {Object} */
    chartWidth: {
      type: Number,
      default: 1200,
    },
    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },
  },
  emits: ['hasData'],

  data: () => ({
    /** @type {Array<import('@/api').PassengerCounts>} */
    data: [],

    dayChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },

    routeChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },

    isLoading: false,
  }),

  computed: {
    /** @return {number} */
    averagePassengers() {
      if (!this.dayChart.data.length) return 0;
      return Math.round(
        this.dayChart.data.reduce(
          (acc, passengerCountObject) => acc + passengerCountObject.total_boarding,
          0,
        ) / this.dayChart.data.length,
      );
    },

    /** @return {number} */
    totalPassengers() {
      return this.dayChart.data.reduce(
        (acc, passengerCountObject) => acc + passengerCountObject.total_boarding,
        0,
      );
    },

    hasData() {
      const result = this.dayChart.data.length !== 0 && this.routeChart.data.length !== 0;
      this.$emit('hasData', result);
      return result;
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.loadData();
        }
      },
    },
  },

  methods: {
    async loadData() {
      this.isLoading = true;

      const startDate = dateObjToGtfsFormat(new Date(this.dateInterval.start * 1000));
      const endDate = dateObjToGtfsFormat(new Date(this.dateInterval.end * 1000));

      const [responseDay, responseRoute] = await Promise.all([
        ApiStats.getPassengerCounts(this.$store.getters.group._id, 'day', startDate, endDate),
        ApiStats.getPassengerCounts(this.$store.getters.group._id, 'route', startDate, endDate),
      ]);

      // Day chart
      this.dayChart.data = responseDay.map(item =>
        PassengerCountReportsHelper.getGroupedInfos(item, GroupBy.DAY),
      );
      this.dayChart.categories = this.dayChart.data.map(el =>
        this.$d(dateGtfsFormatToObj(el.date), 'dayMonth').replace(/\./g, ''),
      );
      this.dayChart.series = PassengerCountReportsHelper.getSeries(this.dayChart.data);
      this.dayChart.options = this.getOptions(this.dayChart.data, 'day');

      // Route chart
      this.routeChart.data = responseRoute
        .map(item => PassengerCountReportsHelper.getGroupedInfos(item, GroupBy.ROUTE))
        .sort(this.sortRoutesAlphaNum);
      this.routeChart.categories = this.routeChart.data.map(el => el.route_short_name);
      this.routeChart.series = PassengerCountReportsHelper.getSeries(this.routeChart.data);
      this.routeChart.options = this.getOptions(this.routeChart.data, 'route');

      this.isLoading = false;
    },

    /**
     * @param {Array<import('@/api').PassengerCounts>} data
     * @param {'day'|'route'} chartType
     * @return {Object}
     */
    getOptions(data, chartType) {
      const chartOptions = {
        xaxis:
          chartType === 'route'
            ? {
                labels: {
                  hideOverlappingLabels: false,
                  showDuplicates: true,
                  maxHeight: 200,
                  style: {
                    fontWeight: 600,
                  },
                },
              }
            : {},

        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const focusedData = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
            const customCategories = PassengerCountReportsHelper.getCustomCategories(data);

            return PassengerCountReportsHelper.createTooltip(focusedData, customCategories, seriesIndex);
          },
        },
      };
      return chartOptions;
    },
  },
};
</script>

<style scoped lang="scss">
.reports {
  background-color: $canvas;
}
</style>

<i18n locale="fr">
{
  "noData": {
    "head": "Aucune donnée disponible.",
    "tip": "Veuillez modifier l'indicateur ou la période."
  },
  "tooltip": {
    "alighting": "Descentes : {0}",
    "boarding": "Montées : {0}",
  },
  "averagePassengers": "Nombre moyen de voyageurs par jour",
  "boardings": "embarquement",
  "chartDayTitle": "Nombre de voyageurs par jour",
  "chartRouteTitle": "Nombre de voyageurs par ligne",
  "days": "Jours",
  "numberOfPassengers": "Total des voyageurs",
  "yaxisTitle": "Nombre de voyageurs"
}
</i18n>

<i18n locale="en">
{
  "tooltip": {
    "alighting": "Alightings: {0}",
    "boarding": "Boardings: {0}",
  },
  "averagePassengers": "Average of passengers counts per day",
  "boardings": "boardings",
  "chartDayTitle": "Passengers counts per day",
  "chartRouteTitle": "Passengers counts per route",
  "days": "Days",
  "numberOfPassengers": "Total of passengers counts",
  "yaxisTitle": "Passengers counts"
}
</i18n>
