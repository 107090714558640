/* eslint-disable no-use-before-define */
/** @module */
import LinkHeader from 'http-link-header';
import { maintenance } from '@/libs/maintenanceChecker';
import auth0, { untilAuthenticated } from '@/auth0';
import { TravelsTimesError } from './errors';
import { PysaeApiError, PysaeApiErrorContent, request } from '@/libs/api/client';
import i18n from '@/i18n';

const { t } = i18n.global;

const appSuffix = window.location.hostname !== 'localhost' ? 'op2' : '';
const appPort = window.location.port ? `:${window.location.port}` : '';

export const baseAppUrl = `${window.location.protocol}//${window.location.hostname}${appPort}`;
export const AppUrl = `${baseAppUrl}/${appSuffix}`;

let errorHandler;

/**
 * Set global API error handler.
 *
 * @param {function(Error): (boolean)} apiErrorHandler
 */
export function setApiErrorHandler(apiErrorHandler) {
  errorHandler = apiErrorHandler;
}

/**
 * Extract filename from Content-Disposition header
 * @param {Response} res
 * @returns {string} filename from HTTP response*/
function getFilename(res) {
  const header = res.headers.get('Content-Disposition');
  const part = header.split(';');
  return part[1].split('=')[1].replaceAll('"', '');
}

/**
 * Download file from API using retriedFetch and SSO auth
 * @param {Response} res
 * @param {string=} filename
 */
async function downloadFile(res, filename) {
  const name = filename ?? getFilename(res);
  const blob = await res.blob();
  if (!blob) {
    throw new Error('Empty response from export endpoint');
  }
  const objectURL = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = objectURL;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    a.remove();
    window.URL.revokeObjectURL(objectURL);
  }, 0);
}

/**
 * Error handler based on toast that automatically generate an translated message based on `code` field
 * received in the error response.
 *
 * Add apiErrors.codes.<code> inside translation files for this handler to support an error code coming
 * from API
 *
 * @param toast
 * @return {function(Error): (boolean)}
 */
export function toastApiErrorHandlerFactory(toast) {
  /**
   * @param {Error} error
   */
  return function toastApiErrorHandler(error) {
    let key;
    if (error instanceof PysaeApiError && error.data instanceof PysaeApiErrorContent) {
      key = `apiErrors.codes.${error.data.code}`;
    }

    if (key === undefined) {
      return;
    }

    const translatedError = t(key);
    if (translatedError === key || translatedError === '') {
      return;
    }

    const toastId = toast.error(translatedError, { position: 'bottom-right' });
    setTimeout(() => toast.dismiss(toastId), 5000);
  };
}

/**
 * @param {number} ms
 * @return {Promise<void>}
 */
function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Automatically retry HTTP request multiple times and optionally handle error if errorHandler is set.
 *
 * @param {string} url
 * @param {RetriedFetchOptions & RequestInit} [options]
 * @return {Promise<Response>} Promise is resolved with HTTP Response or rejected with error informations.
 */
// eslint-disable-next-line consistent-return
async function retriedFetch(url, options = {}) {
  if (localStorage.getItem('byAuth0')) {
    if (!options.headers) {
      options.headers = {};
    }
    await untilAuthenticated(400);
    options.headers.Authorization = `Bearer ${await auth0.getAccessTokenSilently()}`;
  } else {
    options.credentials = 'include';
  }
  const maxRetries = options.maxRetries || 1;
  const retryInterval = options.retryInterval || 1000;
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await request(url, options);
    } catch (e) {
      await sleep(retryInterval);

      if (maintenance.check(e.response)) {
        maintenance.goTo();
      } else {
        errorHandler?.(e);
      }
      throw e;
    }
  }
}

export const qrCodes = {
  /**
   * Download QRCodes
   * @param {QRCodeParam[]} qrCodes
   */
  async downloadMany(qrCodes) {
    const res = await retriedFetch('/api/v3/qrcodes', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(qrCodes),
    });
    await downloadFile(res);
  },

  /**
   * Get a QRCode for display
   * @param {QRCodeParam}
   * @returns {Response}
   */
  async get({ content, filename, label }) {
    const q = new URLSearchParams({ content, filename });
    if (label) {
      q.append('label', label);
    }
    return await retriedFetch(`/api/v3/qrcodes?${q.toString()}`);
  },

  /**
   * Download a QRCode
   * @param {QRCodeParam}
   */
  async download({ content, filename, label }) {
    const q = new URLSearchParams({ content, filename });
    if (label) {
      q.append('label', label);
    }
    const res = await retriedFetch(`/api/v3/qrcodes?${q.toString()}`);
    await downloadFile(res, filename);
  },
};

/**
 * @typedef {Object} RetriedFetchOptions
 * @property {number} [retryInterval=1000]
 * @property {number} [maxRetries=-1]
 */

export const alerts = {
  async download(groupId) {
    const res = await retriedFetch(`/api/v3/groups/${groupId}/export/alerts`);
    await downloadFile(res, 'alerts.csv');
  },

  /**
   * Delete an alert.
   * @param {string} groupId
   * @param {string} alertId
   * @throws {InvalidResponseError}
   */
  async delete(groupId, alertId) {
    await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alertId}`, {
      method: 'DELETE',
    });
  },

  /**
   * Get an alert.
   * @param {string} groupId
   * @param {string} alertId
   * @return {Promise<import('@/store/alerts').Alert>}
   * @throws {Response}
   */
  async get(groupId, alertId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alertId}`);
    return response.json();
  },

  /**
   * Get alerts.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/alerts').Alert>>}
   * @throws {Response}
   */
  async getList(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/alerts`);
    return response.json();
  },

  /**
   * Create a new alert.
   * @param {string} groupId
   * @param {import('@/store/alerts').Alert} alert - Alert object without id.
   * @return {Promise<string>} Id of the new alert.
   * @throws {Response}
   */
  async post(groupId, alert) {
    // fix post a duplicate alert since API does not allow extra fields
    if (alert.id) {
      delete alert.id;
    }
    const response = /** @type {import('@/store/alerts').Alert} */ (
      await retriedFetch(`/api/v2/groups/${groupId}/alerts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(alert),
      }).then(r => r.json())
    );

    return response._id;
  },

  /**
   * Change an existing alert.
   * @param {string} groupId
   * @param {import('@/store/alerts').Alert} alert
   * @throws {Response}
   */
  async put(groupId, alert) {
    await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alert._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(alert),
    });
  },

  /**
   * @param {string} groupId
   * @param {string} alertId
   * @return {Promise<{last_push: number}>}
   */
  async sendPushAlert(groupId, alertId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alertId}/push`, {
      method: 'POST',
    });
    return response.json();
  },
};

export const config = {
  /**
   * Get versions
   * @return {Promise<Array<Config>>}
   * @throws {Response}
   */
  async getVersion() {
    const response = await retriedFetch(`/api/v3/config/driver/versions`);
    return response.json();
  },

  /**
   * Put routes display
   * @param {Object} payload
   * @param {string[]} payload.private_routes
   * @param {string[]} payload.deactivated_routes
   * @return {Promise<RoutesConfig>}
   * @throws {Response}
   */
  async putRoutesDisplay(groupId, payload) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/config/routes`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  },

  /**
   * Put versions
   * @param {Object} payload
   * @param {string[]} payload.deprecated
   * @param {string[]} payload.to_update
   * @return {Promise<Array<Config>>}
   * @throws {Response}
   */
  async putVersions(payload) {
    const response = await retriedFetch(`/api/v3/config/driver/versions`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  },
};

export const deviations = {
  async download(groupId, date) {
    const res = await retriedFetch(`/api/v3/groups/${groupId}/export/deviation-list?date=${date}`);
    await downloadFile(res, 'deviation-list.csv');
  },
};

export const group = {
  /**
   *
   * @param {string} groupId
   * @param {ConfigTarget} target
   * @param {GroupConfigRoot | GroupConfigDriverApp | GroupConfigReports | GroupConfigDistanceThresholds | GroupConfigTrips |GroupConfigInfoApp} updates
   * @returns
   */
  async patchGroup(groupId, target, updates) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/config/${target}`, {
      method: 'PATCH',
      body: JSON.stringify(updates),
    });
    return response.json();
  },
};

export const drivers = {
  async download(groupId) {
    const res = await retriedFetch(`/api/v3/groups/${groupId}/export/drivers`);
    await downloadFile(res, 'drivers.csv');
  },

  /**
   * Add driver in a group.
   * @param {string} groupId
   * @param {import('@/store/drivers').Driver} driverInfos
   * @return {Promise<{}>}
   * @throws {Response}
   */
  async post(groupId, driverInfos) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/drivers`, {
      method: 'POST',
      body: JSON.stringify(driverInfos),
      headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
  },

  /**
   * Edit driver in a group.
   * @param {string} groupId
   * @param {string} driverId
   * @param {import('@/store/drivers').Driver} driverInfos
   * @return {Promise<void>}
   * @throws {Response}
   */
  async put(groupId, driverId, driverInfos) {
    await retriedFetch(`/api/v3/groups/${groupId}/drivers/${driverId}`, {
      method: 'PUT',
      body: JSON.stringify(driverInfos),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  /**
   * Delete driver in a group.
   * @param {string} groupId
   * @param {string} driverId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async delete(groupId, driverId) {
    await retriedFetch(`/api/v3/groups/${groupId}/drivers/${driverId}`, {
      method: 'PATCH',
      body: JSON.stringify({ archived: true }),
      headers: { 'Content-Type': 'application/json' },
    });
  },
};

export const gtfs = {
  /**
   * Add a gtfs
   * @param {string} groupId
   * @param {string} gtfsName
   * @return {Promise<number>}
   * @throws {Response}
   */
  async addGtfs(groupId, gtfsName) {
    const qParams = new URLSearchParams([['file_name', gtfsName]]);

    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs?${qParams}`, {
      method: 'POST',
    });
    return response.json();
  },

  /**
   * Delete gtfs in a group
   * @param {string} groupId
   * @param {string} fileId
   * @return {Promise<number>}
   * @throws {Response}
   */
  async deleteGtfs(groupId, fileId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${fileId}`, {
      method: 'DELETE',
    });
    return response.status;
  },

  /**
   * Copy a gtfs from another gtfs
   * @param {string} groupId
   * @param {string} gtfsName
   * @param {string} gtfsSourceId
   * @return {Promise<number>}
   * @throws {Response}
   */
  async duplicateGtfs(groupId, gtfsName, gtfsSourceId) {
    const qParams = new URLSearchParams([
      ['file_name', gtfsName],
      ['source', gtfsSourceId],
    ]);

    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs?${qParams}`, {
      method: 'POST',
    });
    return response.status;
  },

  /**
   * Get calendar of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Service>>}
   * @throws {Response}
   */
  async getCalendar(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/calendar`);
    return response.json();
  },

  /**
   * Get calendar exceptions of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Service>>}
   * @throws {Response}
   */
  async getCalendarDates(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/calendar_dates`);
    return response.json();
  },

  /**
   * Get list of all gtfs
   * @param {string} groupId
   * @return {Promise<Array<GtfsSchedule>>}
   * @throws {Response}
   */
  async getGtfs(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs`);
    if (!response) {
      return [];
    }

    return response.json();
  },

  /**
   * @param {string} groupId
   * @throws {Response}
   */
  async getGtfsPlainText(groupId) {
    const qParams = new URLSearchParams([['format', 'plaintext']]);

    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs-rt?${qParams}`);
    if (!response) {
      return '';
    }

    return response.json();
  },

  /**
   * Get list of gtfs publications.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/gtfs').Publication>>}
   * @throws {Response}
   */
  async getGtfsPublications(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/gtfs`);
    const publications = await response.json();
    publications.map(e => {
      e.ts = e._id;
      return e;
    });

    return publications;
  },

  /**
   * Get routes list of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Route>>}
   * @throws {Response}
   */
  async getRoutes(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/routes`);
    return response.json();
  },

  /**
   * Get stops list of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Stop>>}
   * @throws {Response}
   */
  async getStops(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/stops`);
    return response.json();
  },

  /**
   * Get stop of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {string} stopId
   * @return {Promise<import('@/store/gtfs').Stop>}
   * @throws {Response}
   */
  async getStop(groupId, gtfsId, stopId) {
    const response = await retriedFetch(`api/v3/groups/${groupId}/gtfs/${gtfsId}/stops/${stopId}`);
    return response.json();
  },

  /**
   * Get trips list of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Trip>>}
   * @throws {Response}
   */
  async getTrips(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/trips`);
    return response.json();
  },

  /**
   * Get shapes list.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Shape>>}
   * @throws {Response}
   */
  async getShapes(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/shapes`);
    return response.json();
  },

  /**
   * Get gtfs validation status.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<GtfsValidateResponse>}
   * @throws {Response}
   */
  async getValidationStatus(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/validate`, {
      method: 'GET',
    });
    return response.json();
  },

  /**
   * Publish a gtfs
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async publishGtfs(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/publish`, {
      method: 'POST',
    });
  },

  /**
   * Schedule a gtfs publication
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {Date} scheduleTime
   * @return {Promise<void>}
   * @throws {Response}
   */
  async scheduleGtfs(groupId, gtfsId, scheduleTime) {
    const qParams = new URLSearchParams([['schedule', scheduleTime.toISOString()]]);

    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/schedule?${qParams}`, {
      method: 'PUT',
    });
  },

  /**
   * Cancel a scheduled gtfs publication
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async cancelScheduledGtfsPublication(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/schedule`, {
      method: 'PUT',
    });
  },

  /**
   * Archive a gtfs
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async archiveGtfs(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/archive`, {
      method: 'POST',
    });
  },

  /**
   * Restore a gtfs
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async restoreGtfs(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/restore`, {
      method: 'POST',
    });
  },
};

export const logo = {
  /** @param {string} groupId */
  async delete(groupId) {
    await retriedFetch(`/api/v2/groups/${groupId}/logo`, { method: 'DELETE' });
  },

  /**
   * @param {string} groupId
   * @param {FormData} form - Form containing an entry `logo` with an image file.
   */
  async put(groupId, form) {
    await retriedFetch(`/api/v2/groups/${groupId}/logo`, {
      method: 'PUT',
      body: form,
    });
  },
};

export const planning = {
  /**
   * @param {string} groupId
   * @param {string} date - Gtfs date
   * @return {Promise<Array<PlanningEntry>>}
   */
  async get(groupId, date) {
    const qParams = new URLSearchParams([['date', date]]);

    const response = await retriedFetch(`/api/v4/groups/${groupId}/planning?${qParams}`);
    return response.ok ? response.json() : [];
  },

  /**
   * Import a file to replace planning data.
   * @param {string} groupId
   * @param {string | File} file
   * @param {PlanningImportFormat} format
   * @return {Promise<Response>}
   */
  async post(groupId, file, format = PlanningImportFormat.JSON) {
    let content;
    if (format === PlanningImportFormat.GESCAR) {
      const formData = new FormData();
      formData.append('upload_file', file);
      content = {
        body: formData,
      };
    } else {
      content = {
        body: file,
        headers: { 'Content-Type': 'application/json' },
      };
    }
    const qParams = new URLSearchParams([['format', format]]);

    return retriedFetch(`/api/v4/groups/${groupId}/planning?${qParams}`, {
      method: 'PUT',
      ...content,
    });
  },
};

export const admin = {
  users: {
    async get() {
      const response = await retriedFetch('/api/v3/users');
      return response.json();
    },

    async create(user) {
      delete user._id; // api does not allow to send an id
      const response = await retriedFetch('/api/v3/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      // if response ok, add _id because api does not return any
      // TODO, fix api to return complete object on creation
      const userResponse = await response.json();
      userResponse._id = user.email;
      return userResponse;
    },

    async update(user) {
      const response = await retriedFetch(`/api/v3/users/${encodeURIComponent(user._id)}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      return response.json();
    },

    async del({ _id }) {
      const response = await retriedFetch(`/api/v3/users/${encodeURIComponent(_id)}`, { method: 'DELETE' });
      return response.json();
    },

    async requestPasswordResetToken({ email }) {
      const res = await retriedFetch(`/api/v3/users/${encodeURIComponent(email)}/password/token`, {
        method: 'POST',
      });
      return res.json();
    },
  },

  groups: {
    async get() {
      const response = await retriedFetch('/api/v3/groups');
      return response.json();
    },

    async create(group) {
      group._id = group.group_id;
      const response = await retriedFetch(`/api/v3/groups`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(group),
      });
      return response.json();
    },

    async update(group) {
      const response = await retriedFetch(`/api/v3/groups/${group._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(group),
      });
      return response.json();
    },

    async del({ _id }) {
      const response = await retriedFetch(`/api/v3/groups/${_id}`, {
        method: 'DELETE',
      });
      return response.status;
    },
  },
};

export const messages = {
  /**
   * Archive a message.
   * @param {string} groupId
   * @param {string} messageId - ID Message to archive.
   * @return {Promise<boolean>} result on archive message process.
   * @throws {Response}
   */
  async archive(groupId, messageId) {
    await retriedFetch(`/api/v2/groups/${groupId}/messages/${messageId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        archived: true,
      }),
    });
    return true;
  },

  /**
   * Get Hot Inbox  info.
   * @param {string} groupId - Group Id.
   * @return {Promise<Array<import('@/store/messages').ApiMessage>>}
   * @throws {Response}
   */
  async getHotInBoxMessages(groupId) {
    const qParams = new URLSearchParams([['to', 'op']]);

    const response = await retriedFetch(`/api/v2/groups/${groupId}/messages?${qParams}`);
    return response.json();
  },

  /**
   * Get messages info.
   * @param {string} groupId - Group Id.
   * @return {Promise<Array<import('@/store/messages').ApiMessage>>}
   * @throws {Response}
   */
  async getMessages(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/messages`);
    return response.json();
  },

  /**
   * Patch a message.
   * @param {string} groupId
   * @param {Object} $0 - Message to patch.
   * @param {string} $0.messageId
   * @param {Partial<import('@/store/messages').ApiMessage>} $0.message
   * @return {Promise<object>} result on patched message process.
   * @throws {Response}
   */
  async patch(groupId, { messageId, message }) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/messages/${messageId}/recipients/op`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
    return response;
  },

  /**
   * Create a new message.
   * @param {string} groupId
   * @param {import('@/store/messages').ApiMessage} message
   * @throws {Response}
   */
  async post(groupId, message) {
    await retriedFetch(`/api/v2/groups/${groupId}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
  },
};

/**
 * Fetch helper for stats
 * @param {object} args
 * @param {string} args.baseUrl - Base URL
 * @param {string} args.groupBy - Group by value
 * @param {string} args.startDate - Date in format YYYYMMDD
 * @param {string} args.endDate - Date in format YYYYMMDD
 * @return {Promise}
 */
const statsFetch = async ({ baseUrl, groupBy, startDate, endDate }) => {
  const qParams = new URLSearchParams([['group_by', groupBy]]);
  if (startDate !== undefined) qParams.append('start_date', startDate);
  if (endDate !== undefined) qParams.append('end_date', endDate);

  const response = await retriedFetch(`${baseUrl}?${qParams}`);
  return response.json();
};

export const stats = {
  /**
   * Get stats of devices.
   * @param {string} groupId
   * @param {string} from
   * @param {string} to
   * @return {Promise<Array<DailyDevices>>}
   */
  async getDailyDevices(groupId, from, to) {
    const qParams = new URLSearchParams([
      ['from', from],
      ['to', to],
    ]);

    const response = await retriedFetch(`/api/v2/groups/${groupId}/stats/daily_devices?${qParams}`);
    return response.json();
  },

  /**
   * Get stats of punctuality by day or route.
   * @param {string} groupId
   * @param {number} from - Start date in timestamp seconds (included in the interval)
   * @param {number} to - Limit date in timestamp seconds (excluded from the interval)
   * @param {string} groupBy - "day" or "route"
   * @return {Promise<Array<PunctualityByDay | PunctualityByRoute>>}
   */
  async getPunctualityStats(groupId, from, to, groupBy) {
    const qParams = new URLSearchParams([
      ['from', from.toString()],
      ['to', to.toString()],
      ['group_by', groupBy],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/stats/punctuality?${qParams}`);
    return response.json();
  },

  /**
   * Get stats of VK.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDate] - Date in format YYYYMMDD
   * @param {string} [endDate] - Date in format YYYYMMDD
   * @return {Promise<Array<TripKM>>}
   */
  getTripKM(groupId, groupBy, startDate, endDate) {
    const baseUrl = `/api/v3/groups/${groupId}/stats/trip-km`;
    return statsFetch({
      baseUrl,
      groupBy,
      startDate,
      endDate,
    });
  },

  /**
   * Get stats of passenger counts.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDate] - Date in format YYYYMMDD
   * @param {string} [endDate] - Date in format YYYYMMDD
   * @return {Promise<Array<PassengerCounts>>}
   */
  getPassengerCounts(groupId, groupBy, startDate, endDate) {
    const baseUrl = `/api/v3/groups/${groupId}/stats/passenger-counts`;
    return statsFetch({
      baseUrl,
      groupBy,
      startDate,
      endDate,
    });
  },

  /**
   * Get stats of trip tracking.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDate] - Date in format YYYYMMDD
   * @param {string} [endDate] - Date in format YYYYMMDD
   * @return {Promise<Array<TripTracking>>}
   */
  getTripTracking(groupId, groupBy, startDate, endDate) {
    const baseUrl = `/api/v3/groups/${groupId}/stats/trip-tracking`;
    return statsFetch({
      baseUrl,
      groupBy,
      startDate,
      endDate,
    });
  },

  /**
   * Export report
   * @param {string} groupId
   * @param {string} metric
   * @param {string} startDate - Date in format YYYYMMDD
   * @param {string} endDate - Date in format YYYYMMDD
   * @return {Promise<Response>}
   */
  async exportReport(groupId, metric, startDate, endDate) {
    const qParams = new URLSearchParams([
      ['start_date', startDate],
      ['end_date', endDate],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/export/${metric}?${qParams}`);
    return response;
  },
};

export const trips = {
  /**
   * Get stop times history.
   * @param {string} groupId
   * @param {string|number} dateGtfsOrFrom - Start date or from interval.
   * @param {number} [to] - To interval.
   * @param {string} [stopId] - stop id filter.
   * @return {Promise<Array<StopTimeHistory>>}
   * @throws {Response}
   */
  async getHistoryStopTimes(groupId, dateGtfsOrFrom, to, stopId = null) {
    const qParams = new URLSearchParams([
      ['event', 'departure'],
      ['include_last_stop_arrival', 'true'],
    ]);
    if (to == null) {
      qParams.append('start_date', dateGtfsOrFrom.toString());
    } else {
      qParams.append('from', dateGtfsOrFrom.toString());
      qParams.append('to', to.toString());
    }
    if (stopId) qParams.append('stop_id', stopId);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/stop_times?${qParams}`);

    return response.json();
  },

  /**
   * Get a trip from a gtfs.
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {string} tripId
   * @returns {Promise<import('./@types/gtfs').Trip>}
   */
  async getTripFromGtfs(groupId, gtfsId, tripId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/trips/${tripId}`);
    return response.json();
  },

  /**
   * Get a trip's event feed
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {string} tripId
   * @param {string} startDate
   */
  async getTripEventFeed(groupId, gtfsId, tripId, startDate) {
    const qParams = new URLSearchParams([
      ['trip_id', tripId],
      ['start_date', startDate],
      ['gtfs_id', gtfsId],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/trip-details-feed?${qParams}`);
    return response.json();
  },

  /**
   * Get trip list.
   * @param {Object} groupId
   * @param {string} date - Date (Gtfs format).
   * @param {Object} [options = {}] - Options.
   * @return {Promise<Array<TripListItem>>}
   * @throws {Response}
   */
  async getTripList(groupId, date, options = {}) {
    const qParams = new URLSearchParams([['date', date]]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/trip-list?${qParams}`, options);
    return response.json();
  },

  /**
   * Get trip list.
   * @param {Object} groupId
   * @param {string} date - Date (Gtfs format).
   * @param {Object} [options = {}] - Options.
   * @return {Promise<Array<TripListItemV4>>}
   * @throws {Response}
   */
  async getTripListV4(groupId, date, options = {}) {
    const qParams = new URLSearchParams([['date', date]]);

    const response = await retriedFetch(`/api/v4/groups/${groupId}/trips?${qParams}`, options);
    return response.json();
  },

  async downloadExport(groupId, date) {
    const res = await retriedFetch(`/api/v4/groups/${groupId}/export/trips?date=${date}`);
    await downloadFile(res, 'trips.csv');
  },

  /**
   * Get one trip (same data format as get Trips V4)
   * @param {Object} groupId
   * @param {string} date - Date (Gtfs format).
   * @param {string} tripId
   * @param {Object} [options = {}] - Options.
   * @param {boolean} [merge = true] - Merge
   * @return {Promise<TripListItemV4>}
   * @throws {Response}
   */
  async getTripFromTripList(groupId, date, tripId, merge = true, options = {}) {
    const qParams = new URLSearchParams([
      ['date', date],
      ['merge', merge.toString()],
    ]);

    const response = await retriedFetch(`/api/v4/groups/${groupId}/trips/${tripId}?${qParams}`, options);
    return response.json();
  },

  /**
   * Get trip vkHistory.
   * @param {string} groupId
   * @param {string} startDateGtfs - Date Gtfs.
   * @param {string} [endDateGtfs] - Date Gtfs. Will use `startDateGtfs` when omitted.
   * @return {Promise<Array<import('@/store/trips').VkHistory>>}
   * @throws {Response}
   */
  async getVkHistory(groupId, startDateGtfs, endDateGtfs) {
    const qParams = new URLSearchParams([
      ['from', startDateGtfs],
      ['to', endDateGtfs || startDateGtfs],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/vk?${qParams}`);
    return response.json();
  },

  /**
   * Get trip-km stats.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDateGtfs] - Date Gtfs
   * @param {string} [endDateGtfs] - Date Gtfs

   * @return {Promise<Array<KmStatsGroupByDay | KmStatsGroupByRoute >>}
   * @throws {Response}
   */
  async getTripKMStats(groupId, groupBy, startDateGtfs, endDateGtfs) {
    const qParams = new URLSearchParams([
      ['start_date', startDateGtfs],
      ['end_date', endDateGtfs],
      ['group_by', groupBy],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/stats/trip-km?${qParams}`);
    return response.json();
  },

  /**
   * Change an existing trip.
   * @param {string} groupId
   * @param {Object} query
   * @param {Object} body
   * @param {Boolean} many
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async updateTrip(groupId, query, body, many) {
    const qParams = new URLSearchParams([
      ['trip_id', query.trip_id],
      ['gtfs_id', query.gtfs_id],
    ]);
    let caseMany = '';
    if (many) {
      qParams.append('start_date', query.start_date);
      if (query.end_date) {
        qParams.append('end_date', query.end_date);
      }
      caseMany = '/many';
    } else {
      qParams.append('date', query.start_date);
    }

    return retriedFetch(`/api/v4/groups/${groupId}/trip-updates${caseMany}?${qParams}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  },
};

export const vehicles = {
  async download(groupId) {
    const res = await retriedFetch(`/api/v3/groups/${groupId}/export/vehicles`);
    await downloadFile(res, 'vehicles.csv');
  },

  /**
   * Add vehicle in a group.
   * @param {string} groupId
   * @param {import('@/store-pinia/vehicles').Vehicle} vehicleInfos
   * @return {Promise<string>}
   * @throws {Response}
   */
  async post(groupId, vehicleInfos) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/vehicles`, {
      method: 'POST',
      body: JSON.stringify(vehicleInfos),
      headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
  },

  /**
   * Edit vehicle in a group.
   * @param {string} groupId
   * @param {string} vehicleId
   * @param {import('@/store-pinia/vehicles').Vehicle} vehicleInfos
   * @return {Promise<void>}
   * @throws {Response}
   */
  async put(groupId, vehicleId, vehicleInfos) {
    await retriedFetch(`/api/v3/groups/${groupId}/vehicles/${vehicleId}`, {
      method: 'PUT',
      body: JSON.stringify(vehicleInfos),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  /**
   * Delete vehicle in a group.
   * @param {string} groupId
   * @param {string} vehicleId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async delete(groupId, vehicleId) {
    await retriedFetch(`/api/v3/groups/${groupId}/vehicles/${vehicleId}`, {
      method: 'PATCH',
      body: JSON.stringify({ archived: true }),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  async get(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/vehicles`);
    return response.json();
  },
};

export const devices = {
  /**
   * Add device in a group.
   * @param {string} groupId
   * @param {Partial<import('@/store/devices').Device>} deviceInfos
   * @return {Promise<void>}
   * @throws {Response}
   */
  async post(groupId, deviceInfos) {
    await retriedFetch(`/api/v3/groups/${groupId}/devices`, {
      method: 'POST',
      body: JSON.stringify(deviceInfos),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  /**
   * Delete a device.
   * @param {string} groupId
   * @param {string} deviceId
   * @throws {Response}
   */
  async deleteDevice(groupId, deviceId) {
    await retriedFetch(`/api/v2/groups/${groupId}/devices/${deviceId}`, {
      method: 'DELETE',
    });
  },

  /**
   * Get device info.
   * @param {string} groupId - Group Id.
   * @param {string} deviceId - Device Id.
   * @return {Promise<import('@/store/devices').Device>}
   * @throws {Response}
   */
  async getDevice(groupId, deviceId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/devices/${deviceId}`);
    return response.json();
  },

  /**
   * Get versions of device for each group.
   * @return {Promise<import('@/store/devices').Device>}
   * @throws {Response}
   */
  async getAllDeviceVersions() {
    const response = await retriedFetch(`/api/v3/devices/versions`);
    return response.json();
  },

  /**
   * Get devices list
   * @param {string} groupId - Group Id.
   * @return {Promise<Array<import('@/store/devices').Device>>}
   * @throws {Response}
   */
  async getDevices(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/devices`);
    return response.json();
  },

  /**
   * Get events for a specific device starting at a specific timestamp from server.
   * @param {string} groupId Group Id
   * @param {Object} [filter] Filters
   * @param {string} [filter.deviceId] Device Id
   * @param {number} [filter.fromTs] Timestamp at lower limit
   * @param {number} [filter.toTs] Timestamp at upper limit
   * @param {string} [filter.tripId] Trip id
   * @param {string} [filter.afterId] Event id to retrieve from
   * @param {number} [filter.page] Page
   * @param {number} [filter.pageSize] Page size
   * @return {Promise<Array<import('@/store/devices').Event>>}
   * @throws {Response}
   */
  async getEvents(groupId, filter) {
    const qParams = new URLSearchParams();

    if (filter?.deviceId !== undefined) qParams.append('device_id', filter.deviceId);
    if (filter?.fromTs !== undefined) qParams.append('from', String(Math.floor(filter.fromTs)));
    if (filter?.toTs) qParams.append('to', String(Math.ceil(filter.toTs)));
    if (filter?.tripId) qParams.append('trip_id', filter.tripId);
    if (filter?.afterId) qParams.append('after_id', String(filter.afterId));
    if (filter?.page) qParams.append('page', String(filter.page));
    if (filter?.pageSize) qParams.append('page_size', String(filter.pageSize));

    const queryString = qParams.size > 0 ? `?${qParams}` : '';

    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/events${queryString}`);
    return response.json();
  },

  /**
   * Change an existing device.
   * @param {string} groupId
   * @param {import('@/store/devices').Device} device
   * @return {Promise<import('@/store/devices').Device>}
   * @throws {Response}
   */
  async put(groupId, device) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/devices/${device.device_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(device),
    });

    return response.json();
  },

  /**
   * Archive an existing device
   * @param {string} groupId
   * @param {string} deviceId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async archive(groupId, deviceId) {
    await retriedFetch(`/api/v3/groups/${groupId}/devices/${deviceId}/archive`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Unarchive an existing device
   * @param {string} groupId
   * @param {string} deviceId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async unarchive(groupId, deviceId) {
    await retriedFetch(`/api/v3/groups/${groupId}/devices/${deviceId}/unarchive`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Get device registration code and expiration time
   * @param {string} groupId
   * @return {Promise<RegistrationCode>}
   */
  async getDeviceRegistrationCode(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/code`);
    if (response.status === 404) return null;
    return response.json();
  },

  /**
   * Request new device registration code
   * @param {string} groupId
   * @return {Promise<RegistrationCode>}
   */
  async generateDeviceRegistrationCode(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/code/generate`, {
      method: 'POST',
    });
    return response.json();
  },
};

export const integrations = {
  /**
   * Get integrations.
   *
   * @param {string} groupId - Group Id.
   * @return {Promise<Record<string, *>>}
   */
  async getAllGroupIntegrations(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/integrations`);
    return response.json();
  },

  /**
   * Create or replace group integration.
   * @param {string} groupId
   * @param {string} integrationId
   * @param {*} configuration
   * @returns {Promise<Record<string, *>>}
   */
  async replaceOrCreateGroupIntegration(groupId, integrationId, configuration) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/integrations/${integrationId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(configuration),
    });
    return response.json();
  },

  /**
   * Delete group integration.
   *
   * @param {string} groupId
   * @param {string} integrationId
   * @returns {Promise<void>}
   */
  async deleteGroupIntegration(groupId, integrationId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/integrations/${integrationId}`, {
      method: 'DELETE',
    });
    return response.json();
  },
};

export const importResource = {
  /**
   * Import or compare uploaded csv file with existing resources for a group.
   * @param {string} groupId
   * @param {File} file
   * @param {boolean} [compareOnly=false]
   * @param {string} resourceType
   * @return {Promise<CompareSchema | ImportResultSchema>}
   * @throws {Response}
   */
  async uploadCsv(groupId, file, resourceType, compareOnly = false) {
    const qParams = compareOnly ? `?${new URLSearchParams([['compare_only', 'true']])}` : '';

    const data = new FormData();
    data.append('file', file);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/import/${resourceType}${qParams}`, {
      method: 'POST',
      body: data,
    });

    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  },
};

export const urgencies = {
  /**
   * Acknowledge an urgency
   * @param {string} groupId
   * @param {string} urgencyId
   * @return {Promise<void>}
   * @throws {Response}
   * */
  async acknowledge(groupId, urgencyId) {
    await retriedFetch(`/api/v3/groups/${groupId}/urgency/acknowledge/${urgencyId}`, {
      method: 'POST',
    });
  },

  /**
   * Get all created urgencies
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/urgencies').Urgency>>}
   * @throws {Response}
   */
  async getArchivedUrgencies(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/urgency`);
    return response.json();
  },

  /**
   * Resolve an urgency
   * @param {string} groupId
   * @param {string} urgencyId
   * @param {boolean} is_false_alert
   * @param {string} comment
   * @return {Promise<void>}
   * @throws {Response}
   * */
  async resolve(groupId, urgencyId, is_false_alert = false, comment = '') {
    await retriedFetch(`/api/v3/groups/${groupId}/urgency/resolve/${urgencyId}`, {
      method: 'POST',
      body: JSON.stringify({ is_false_alert, comment }),
      headers: { 'Content-Type': 'application/json' },
    });
  },
};

export const users = {
  /**
   * change a password
   * @param {string} email
   * @param {string} password
   * @param {object} payload
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async updatePassword(email, password, { token, old }) {
    return await retriedFetch(`/api/v3/users/${encodeURIComponent(email)}/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ new: password, token, old }),
    });
  },

  /**
   * request email to reset a password
   * @param {string} email
   * @param {string} captcha
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async requestPasswordResetToken(email, captcha) {
    return await retriedFetch(`/api/v3/users/${encodeURIComponent(email)}/password/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ g_recaptcha_response: captcha }),
    });
  },

  /**
   * Check authentication validity.
   * @return {Promise<import('@/store').User>} Authenticated user's data.
   * @throws {Error} URL of login page.
   */
  async getCurrent() {
    const response = await retriedFetch('/api/v3/users/me');
    if (!response.ok) {
      return null;
    }
    return response.json();
  },

  /** @param {import('@/store').User} user */
  async update(user) {
    await retriedFetch(`/api/v3/users/${encodeURIComponent(user._id)}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
  },
};

export default {
  alerts,
  config,
  devices,
  drivers,
  group,
  gtfs,
  importResource,
  integrations,
  logo,
  messages,
  planning,
  stats,
  trips,
  urgencies,
  users,
  vehicles,

  /**
   * Get activity log entries for a date.
   * @param {string} groupId
   * @param {string} date - in GTFS format.
   * @return {Promise<Array<import('@/store/activity-log').ActivityLogEntry>>}
   */
  async getActivityLog(groupId, date) {
    const qParams = new URLSearchParams([['start_date', date]]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/duties?${qParams}`);
    return response.json();
  },

  /**
   * Get activity log entries for a date.
   * @param {string} groupId
   * @param {string} entryId
   * @return {Promise<import('@/store/activity-log').ActivityLogEntry>}
   */
  async getActivityLogEntry(groupId, entryId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/duties/${entryId}`);
    return response.json();
  },

  /**
   * @param {string} groupId
   * @param {Number} fromTs
   * @param {Number} toTs
   * @return {Promise<Array>}
   * @throws {Response}
   */
  async getAppEvents(groupId, fromTs, toTs) {
    const qParams = new URLSearchParams([
      ['start_date', Math.floor(fromTs).toString()],
      ['end_date', Math.floor(toTs).toString()],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/info-session-counter?${qParams}`);
    return response.json();
  },

  /**
   * Get drivers' list of a group.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/drivers').Driver>>}
   * @throws {Response}
   */
  async getDriverList(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/drivers`);
    return response.json();
  },

  /**
   * Get group info.
   * @param {string} groupId
   * @return {Promise<import('@/store').Group>}
   * @throws {Response}
   */
  async getGroup(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}`);
    return response.json();
  },

  /**
   * Get groups list.
   * @return {Promise<Array<import('@/store').GroupMinimal>>}
   * @throws {Promise<Response>}
   */
  async getGroups() {
    const response = /** @type {Array<import('@/store').Group>} */ (
      await retriedFetch('/api/v3/groups').then(r => r.json())
    );
    if (!response) {
      return [];
    }

    return response.map(g => ({
      _id: g._id,
      name: g.name,
      color: g.color ?? '#00B871', //$primary-light
    }));
  },

  /**
   * Get oldest duty data available
   * @param {string} groupId
   * @returns {Promise<Array<import('@/store/activity-log').ActivityLogEntry>>}
   */
  async getOldestDutyData(groupId) {
    const qParams = new URLSearchParams([
      ['anonymized', 'false'],
      ['sort', 'check_in:asc'],
      ['limit', '1'],
    ]);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/duties?${qParams}`);
    return response.json();
  },

  /**
   * Get events for an area.
   * @param {string} groupId
   * @param {number} fromTs
   * @param {number} toTs
   * @param {number} minLat
   * @param {number} maxLat
   * @param {number} minLng
   * @param {number} maxLng
   * @return {Promise<Array<import('@/store/devices').Event>>}
   */
  async getPointHistory(groupId, fromTs, toTs, minLat, maxLat, minLng, maxLng) {
    const qParams = new URLSearchParams([
      ['from', fromTs.toString()],
      ['to', toTs.toString()],
      ['min_lat', minLat.toString()],
      ['max_lat', maxLat.toString()],
      ['min_lng', minLng.toString()],
      ['max_lng', maxLng.toString()],
    ]);
    const countEndpoint = `/api/v3/groups/${groupId}/history/events/count?${qParams}`;
    const countResponse = await retriedFetch(countEndpoint);
    const countResult = await countResponse.json();
    if (countResult.count > 150000) {
      throw new Error(TravelsTimesError.LIMITE_EXCEEDED);
    }
    const pages = [];

    let next = `/api/v3/groups/${groupId}/history/events?${qParams}`;
    let idx = 0;
    while (next && idx < 3) {
      const response = await retriedFetch(next);
      pages.push(response.json());
      next = null;

      const headerLink = response.headers.get('link');
      if (headerLink) {
        const links = LinkHeader.parse(headerLink);
        const headerNext = links.rel('next');

        if (headerNext.length > 0) {
          next = headerNext[0].uri;
        }
      }

      idx += 1;
    }

    const result = Promise.all(pages).then(results => results.shift().concat(...results));
    return result;
  },

  /**
   * @param {String} groupId
   * @return {Promise<Array<UserRole>>}
   * @throws {Response}
   */
  async getRoles(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/roles`);
    return response.json();
  },

  /**
   * @param {String} groupId
   * @return {Promise<UserRole>}
   * @throws {Response}
   */
  async updateRole(groupId, role) {
    const updates = { role: role.role, teams: role.teams };
    const response = await retriedFetch(`/api/v3/groups/${groupId}/roles/${role.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });
    return response.json();
  },

  /**
   * Delete an role.
   * @param {string} groupId
   * @param {string} roleId
   * @throws {Response}
   */
  async deleteRole(groupId, roleId) {
    await retriedFetch(`/api/v2/groups/${groupId}/roles/${roleId}`, {
      method: 'DELETE',
    });
  },

  /**
   * @param {String} groupId
   * @return {Promise<Array<import('@/store').Team>>}
   * @throws {Response}
   */
  async getTeams(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/teams`);
    return response.json();
  },

  /**
   * Get vehicles' list of a group.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store-pinia/vehicles').Vehicle>>}
   * @throws {Response}
   */
  async getVehicleList(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/vehicle_list`);
    return response.json();
  },

  /**
   * Log out current user
   * @throws {Response}
   */
  async logout() {
    await retriedFetch('/api/v3/logout');
  },

  /**
   * Log a user
   * @param {string} email
   * @param {string} password
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async sendAuth(email, password) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    const response = await retriedFetch('/api/v2/login', {
      method: 'POST',
      body: formData,
    });
    return response;
  },

  /**
   * @param {import('@/store').Group} group
   * @return {Promise<import('@/store').Group>}
   */
  async putGroup(group) {
    const response = await retriedFetch(`/api/v2/groups/${group._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(group),
    });
    return response.json();
  },

  /**
   * @param {string} groupId
   * @param {Object} role
   * @return {Promise<Response>}
   * */
  async addRoles(groupId, role) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/roles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(role),
    });
    return response.json();
  },

  /**
   * @param {string} groupId
   * @param {Object} role
   * */
  async putRoles(groupId, role) {
    await retriedFetch(`/api/v2/groups/${groupId}/roles/${role.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(role),
    });
  },

  /**
   * @param {string} groupId
   * @param {import('@/store').Team} team
   * */
  async createNewTeam(groupId, team) {
    await retriedFetch(`/api/v3/groups/${groupId}/teams`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(team),
    });
  },

  /**
   * @param {string} groupId
   * @param {import('@/store').Team} team
   * */
  async modifyTeam(groupId, team) {
    await retriedFetch(`/api/v3/groups/${groupId}/teams/${team.team_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(team),
    });
  },
};

/**
 * History event poller
 */
export class HistoryEventsPoller {
  /**
   * @param {string} groupId
   * @param {HistoryEventsCallback} onData
   */
  constructor(groupId, onData) {
    this.groupId = groupId;
    this.onData = onData;
    this.pollingTimer = undefined;
    this.pollingInterval = 5000;
    this.minPollingInterval = this.pollingInterval / 5;
  }

  async open() {
    this.close();

    let currentId;

    const lastEvents = await devices.getEvents(this.groupId, { pageSize: 1 });
    const lastEvent = lastEvents[0];
    if (lastEvent !== undefined) {
      currentId = lastEvent.id;
      this.onData(lastEvents);
    }

    const handler = async () => {
      const ts = Date.now();

      if (currentId === undefined) {
        const lastEvents = await devices.getEvents(this.groupId, { pageSize: 1 });
        const lastEvent = lastEvents[0];
        if (lastEvent !== undefined) {
          currentId = lastEvent.id;
        }
      }

      if (currentId !== undefined) {
        const events = await devices.getEvents(this.groupId, { afterId: currentId });

        const lastEvent = events[0];
        if (lastEvent) {
          currentId = lastEvent.id;
        }

        if (events !== undefined && events.length > 0) {
          events.reverse();
          this.onData(events);
        }
      }

      const tsOffset = Date.now() - ts;

      if (this.pollingTimer) {
        const interval = Math.max(this.minPollingInterval, this.pollingInterval - tsOffset);
        this.pollingTimer = setTimeout(handler, interval);
      }
    };

    this.pollingTimer = setTimeout(handler, this.pollingInterval);
  }

  close() {
    if (this.pollingTimer) {
      clearTimeout(this.pollingTimer);
      this.pollingTimer = undefined;
    }
  }
}

/**
 * WebSocket updates.
 * @class
 * @param {string} groupId
 * @param {UrgencyCallback} onData
 */
export class EventsWebSocket {
  constructor(groupId, onData) {
    this.groupId = groupId;
    this.onData = onData;
    this.ws = null;
    this.currentWSTimeout = null;
    this.wsProto = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
    this.url = `${this.wsProto}//${window.location.host}/api/v3/groups/${this.groupId}/events/op/subscribe`;
    this.openWs();
    this.close = function close() {
      this.ws.onclose = function onClose() {
        console.log('WebSocket closed');
      };
      this.clearWsTimeout();

      this.ws.close();
    };
  }

  openWs() {
    this.ws = new WebSocket(this.url);
    this.ws.onerror = error => {
      console.warn('WebSocket closed, reopening in 5s', error);
      setTimeout(() => {
        this.openWs();
      }, 5000);
      this.ws.onclose = null;
    };
    this.ws.onopen = () => {
      console.log('WebSocket opened to: ', this.url);
    };
    if (this.onData) {
      this.ws.onmessage = e => {
        this.onData(JSON.parse(e.data));
      };
    }
  }

  clearWsTimeout() {
    if (this.currentWSTimeout) {
      clearTimeout(this.currentWSTimeout);
    }
  }
}

/**
 * @typedef {Object} DailyDevices
 * @property {string} date - In GTFS format
 * @property {number} devices - Number of devices.
 */

/**
 * @typedef {Object} DailyPunctuality
 * @property {string} date - In GTFS format
 * @property {{[upperBound: string]: number}} delays - Number of passage by delay category.
 */

/**
 * @typedef {Object} Punctuality
 * @property {number} on_time
 * @property {number} too_early
 * @property {number} too_late
 * @property {number} on_time_percentage
 * @property {number} too_early_percentage
 * @property {number} too_late_percentage
 * @property {number} total_count
 */

/**
 * @typedef {Object} PunctualityByDay
 * @extends Punctuality
 * @property {string} start_date
 */

/**
 * @typedef {Object} PunctualityByRoute
 * @extends Punctuality
 * @property {string} route_id
 * @property {string} route_long_name
 * @property {string} route_short_name
 */

/**
 * @typedef {Object} GtfsInfo
 * @property {string} _id
 * @property {string} [group_id]
 * @property {string} name
 * @property {string} mod_user
 * @property {string} mod_time Date in ISO format
 */

/**
 * @typedef {Object} GtfsSchedule
 * @extends GtfsInfo
 * @property {number} archived
 * @property {number} published
 * @property {number} scheduled
 * @property {boolean} [blocked] // deprecated
 */

/**
 * @typedef {Object} GtfsValidateResponse
 * @see https://github.com/etalab/transport-validator?tab=readme-ov-file
 * @extends GtfsInfo
 * @property {ValidationStatus} validation_status
 * @property {GtfsValidation} validation
 */

/**
 * @typedef {Object} GtfsValidation
 * @property {Object} metadata
 * @property {Object} validations
 */

/**
 * @typedef {Object} TripKM
 * @property {string} date - In GTFS format
 * @property {string} [gtfs_id] - GTFS Id
 * @property {string} [gtfs_name] - GTFS name
 * @property {number} percent_of_theoretical_km_recorded - Percentage of theoretical kilometers recorded
 * @property {number} recorded_commercial_km - Recorded kilometers
 * @property {?number} reliable_km - Reliable kilometers
 * @property {string} [route_id] - Route Id
 * @property {string} [route_long_name] - Route long name
 * @property {string} [route_short_name] - Route short name
 * @property {number} theoretical_commercial_km - Theoretical kilometers
 */

/**
 * @typedef {Object} PassengerCounts
 * @property {number} alighting - Alighting (Total alighting)
 * @property {number} boarding - Default boarding count
 * @property {{[name: string]: number}} custom_boarding - Custom boarding counts
 * @property {number} total_boarding - Total boarding
 * @property {string} [date] - Date
 * @property {string} [route_id] - Route Id
 * @property {string} [route_long_name] - Route long name
 * @property {string} [route_short_name] - Route short name
 */

/**
 * @typedef {Object} PlanningEntry
 * @property {string} trip_id
 * @property {string} date - GTFS date
 * @property {string} [driver_id]
 * @property {string} [vehicle_id]
 */

/** @enum {string} */
export const PlanningImportFormat = {
  GESCAR: 'gescar',
  JSON: 'json',
};

/**
 * @typedef {Object} StopTimeHistory
 * @property {string} _id
 * @property {number} delay
 * @property {string} device_id
 * @property {"arrival"|"departure"} event
 * @property {string} gtfs_id
 * @property {string} start_date
 * @property {string} stop_id
 * @property {number} stop_sequence
 * @property {string} trip_id
 * @property {number} ts
 * @property {boolean} [last_stop]
 */

/**
 * @typedef {Object} TripTracking
 * @property {string} [start_date] - start_date
 * @property {number} scheduled - scheduled
 * @property {number} tracked - tracked
 * @property {string} [gtfs_id] - gtfs_id
 * @property {string} [gtfs_name] - gtfs_name
 * @property {string} [route_id] - route_id
 * @property {string} [route_short_name] - route_short_name
 * @property {string} [route_long_name] - route_long_name
 * @property {number} [percent_tracked] - percent_tracked
 */

/**
 * @typedef {Object} CompareSchema
 * @property {number} to_create
 * @property {number} to_update
 * @property {number} to_delete
 */

/**
 * @typedef {Object} ImportResultSchema
 * @property {number} created
 * @property {number} modified
 * @property {number} ignored
 */

/**
 * Callback when data is for history events endpoint received.
 * @callback HistoryEventsCallback
 * @param {Array<import('@/store/devices').Device>} data
 */

/**
 * Callback when data is for urgency endpoint received.
 * @callback UrgencyCallback
 * @param {Array<import('@/components/common/ModalUrgency.vue').Urgency>} data
 */

/** @enum {string} */
export const TemporalityType = {
  UNDERWAY: 'current',
  COMPLETED: 'passed',
  SCHEDULED: 'future',
};

/** @enum {string} */
export const TripStatusType = {
  OK: 'ok',
  TRACKED: 'tracked',
  ROUTING: 'routing',
  UNTRACKED: 'untracked',
  PROBLEM: 'problem',
  NO_DATA: 'no data',
  SCHEDULED: 'scheduled',
};

/** @enum {string} */
export const UpdateType = {
  COMMENT: 'comment',
  DO_NOT_SERVE: 'skipped_stop_time_seqs',
  DELAY: 'delay',
  TRIP_CANCELED: 'is_canceled',
  STOP_INFO: 'stop_infos',
};

/** @enum {string} */
export const ValidationStatus = {
  TO_VALIDATE: 'to_validate',
  TO_FIX: 'to_fix',
  VALIDATED: 'validated',
};

/**
 * @typedef {Object} RegistrationCode
 * @property {string} code
 * @property {number} expire
 */

/**
 * @typedef {Object} RoutesConfig
 * @property {Array<string>} private_routes
 * @property {Array<string>} deactivated_routes
 */

/**
 * @typedef {Object} Service
 * @property {number} recorded_stops
 * @property {number} planned_stops
 */

/**
 * @typedef {Object} Team
 * @property {string} id
 * @property {string} color
 */

/**
 * @typedef {Object} TripListDevice
 * @property {string} id
 * @property {string} name
 * @property {string} [team_id]
 * @property {string} [team_color]
 */

/**
 * @typedef {Object} TripListDeviceV4
 * @property {string} id
 * @property {string} name
 * @property {Team} team
 * @property {?boolean} status
 */

/**
 * @typedef {Object} TripListGtfs
 * @property {string} id
 * @property {string} name
 * @property {string} publication_date - ISO format
 */

/**
 * @typedef {Object} TripListItem
 * @property {string} arrival_stored
 * @property {string} arrival_time
 * @property {string} block_id
 * @property {?number | Array<?number>} delay
 * @property {string} departure_stored
 * @property {string} departure_time
 * @property {Array<TripListDevice>} device
 * @property {TripListStop} first_stop
 * @property {Array<TripListGtfs>} gtfs
 * @property {TripListStop} last_stop
 * @property {TripListPassengerCount} passenger_count
 * @property {number} percent_km
 * @property {TripListProblems} problems
 * @property {?number} reliable_km
 * @property {TripListRoute} route
 * @property {Service} service
 * @property {string} service_date - GTFS date
 * @property {TemporalityType} temporality
 * @property {number} tk
 * @property {string} trip_formatted_name
 * @property {string} trip_id
 * @property {TripStatusType} trip_status
 * @property {string} trip_team_id
 * @property {string} trip_team_color
 * @property {Array<TripListItem>} [trips]
 * @property {number} unreliable_km
 * @property {Array<Update>} [updates]
 * @property {number} vk
 * @property {number} vk_no_status
 * @property {Array<import('@/store/drivers').Driver>} drivers
 * @property {Array<import('@/store-pinia/vehicles').Vehicle>} vehicles
 *
 */

/**
 * @typedef {Object} TripListItemV4
 * @property {number} arrival_stored
 * @property {number} arrival_time
 * @property {string} block_id
 * @property {?number | Array<?number>} delay
 * @property {number} departure_stored
 * @property {number} departure_time
 * @property {Array<TripListDeviceV4>} devices
 * @property {TripListStop} first_stop
 * @property {Array<TripListGtfs>} gtfs
 * @property {TripListStop} last_stop
 * @property {TripListPassengerCount} passenger_count
 * @property {number} percent_km
 * @property {TripListProblems} problems
 * @property {?number} reliable_km
 * @property {TripListRoute} route
 * @property {Service} service
 * @property {string} service_date - GTFS date
 * @property {TemporalityType} temporality
 * @property {number} tk
 * @property {string} formatted_name
 * @property {string} id
 * @property {TripStatusType} status
 * @property {Team} team
 * @property {Array<TripListItemV4>} [trips]
 * @property {number} unreliable_km
 * @property {TripUpdates} updates
 * @property {number} vk
 * @property {string} headsign
 * @property {Array<import('@/store/drivers').Driver>} drivers
 * @property {Array<import('@/store-pinia/vehicles').Vehicle>} vehicles
 */

/**
 * @typedef {Object} TripListPassengerCount
 * @property {number} [alightings]
 * @property {number} [boardings]
 * @property {number} [loading]
 */

/**
 * @typedef {Object} TripListProblems
 * @property {boolean} [distance]
 * @property {boolean} [kilometers]
 * @property {boolean} [time]
 */

/**
 * @typedef {Object} TripListRoute
 * @property {string} color
 * @property {string} id
 * @property {string} [long_name]
 * @property {string} [short_name]
 * @property {string} [text_color]
 */

/**
 * @typedef {Object} TripListStop
 * @property {string} code
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} KmStatsGroupByDay
 * @property {string} date - In GTFS format
 * @property {number} recorded_commercial_km
 * @property {number} theoretical_commercial_km
 * @property {number} percent_of_theoretical_km_recorded
 */

/**
 * @typedef {Object} KmStatsGroupByRoute
 * @property {string} gtfs_id
 * @property {string} [gtfs_name]
 * @property {string} route_id
 * @property {string} [route_short_name]
 * @property {string} [route_long_name]
 * @property {number} recorded_commercial_km
 * @property {number} theoretical_commercial_km
 * @property {number} percent_of_theoretical_km_recorded
 * /

/**
 * @typedef {Object} StopTimeUpdate
 * @property {string} stop_id
 * @property {string} stop_name
 * @property {number} stop_sequence
 * @property {string} schedule_relationship
 */

/**
 * @typedef {Object} Update
 * @property {Date} date
 * @property {string} source
 * @property {string} trip_id
 * @property {string} group_id
 * @property {string} gtfs_id
 * @property {string} start_date
 * @property {UpdateType} info_type
 * @property {Array<StopTimeUpdate> | Number | String | import('@/store/trips').ScheduleRelationship | Array<StopInfo> } content
 */

/**
 * @typedef {Object} UserRole
 * @property {Role} role
 * @property {Array<string>} [teams]
 * @property {string} user_id
 * @property {string} _id
 */

/**
 * @typedef {Object} TripUpdates
 * @property {string} comment
 * @property {number} delay
 * @property {boolean} is_canceled
 * @property {Array<number>} skipped_stop_time_seqs
 * @property {Array<StopInfo>} stop_infos
 */

/**
 * @typedef {Object} StopInfo
 * @property {number} stop_sequence
 * @property {string} information
 */

/**
 * @typedef {Object} Config
 * @property {Semver} [semver]
 */

/**
 * Semver object coming from API.
 * @typedef {{deprecated: string[], to_update: string[]}} Semver
 */

/**
 * @typedef {Object} GroupConfigRoot
 * @property {string} name
 * @property {string} [driver_trip_format]
 * @property {Array<number>} [driver_ontime_interval]
 * @property {number} [delay_device_online]
 * @property {number} [delay_device_offline_visible]
 * @property {boolean} [notifications]
 * @property {number} [retention_period]
 */

/**
 * @typedef {Object} GroupConfigDriverApp
 * @property {string} [driver_call_number]
 * @property {Array<import('@/store').DriverMessage>} [driver_message_values]
 * @property {boolean} [driver_option_messages_block_send]
 * @property {{login:string, password:string}} [router]
 * @property {string} [pis_dead_mileage_code]
 * @property {Array<string>} [driver_passenger_count_custom_boarding]
 */

/**
 * @typedef {Object} GroupConfigReports
 * @property {{bounds: {down: number, up: number}, cats: Array<number>}} [categories]
 */

/**
 * @typedef {Object} GroupConfigDistanceThresholds
 * @property {number} [stop_distance_threshold]
 * @property {number} [incoming_distance_threshold]
 * @property {number} [shape_distance_threshold]
 */

/**
 * @typedef {Object} GroupConfigTrips
 * @property {import('@/store').TripListConfiguration} [trip_list_configuration]
 * @property {import('@/store').TripValidationRules} [trip_validation_rules]
 */

/**
 * @typedef {Object} GroupConfigInfoApp
 * @property {boolean} [pub]
 * @property {string} [color]
 * @property {{email: string, phone_number:string}} [contact]
 */

/** @typedef {Object} QRCodeParam
 * @property {string} content
 * @property {string} filename
 * @property {string=} label
 */
