import type { Device, DeviceEvent } from '@/@types/device';

/**
 * Convert an array to a fake map object.
 */
function arrToFakeMap<T>(arr: Array<T>, prop: string): Record<string, T> {
  return arr.reduce((acc: { [key: string]: T }, val) => {
    const index = val[prop as keyof T] as string;
    acc[index] = val;
    return acc;
  }, {});
}

/**
 * Convert an array to an object.
 */
function arrToObj<T>(arr: Array<T>, prop?: string): Record<string, T> {
  return arr.reduce((acc: { [key: string]: any }, val) => {
    const key = (prop ? val[prop as keyof T] : val) as string;
    acc[key] = true;

    return acc;
  }, {});
}

/**
 * Compare 2 object and return wether they have the same keys or not
 */
function compareObjectKeys(firstObject: Object, secondObject: Object): boolean {
  const firstKeys = Object.keys(firstObject).sort();
  const secondKeys = Object.keys(secondObject).sort();
  return JSON.stringify(firstKeys) === JSON.stringify(secondKeys);
}

/**
 * Check if a value is a plain object (e.g. object literal)
 */
function isPlainObject(val: any): boolean {
  return val instanceof Object && Object.getPrototypeOf(val) === Object.prototype;
}

/**
 * Perform a deep comparison of two values.
 * Can only compare equal strings, numbers, arrays and objects, other types are
 * considered not equal (notably Date and Position).
 */
function isDeepEqual(a: any, b: any): boolean {
  if (a === b) return true;
  if ((Array.isArray(a) && Array.isArray(b)) || (isPlainObject(a) && isPlainObject(b))) {
    for (const k in a) {
      if (!isDeepEqual(a[k], b[k])) return false;
    }
    for (const k in b) {
      if (!isDeepEqual(a[k], b[k])) return false;
    }
    return true;
  }
  return false;
}

function clearEmptyValues<T>(value: T): T | Partial<T> {
  if (!(value instanceof Array) && typeof value === 'object' && value != null) {
    return Object.entries(value).reduce((acc: T | Partial<T>, [k, v]) => {
      const key = k as keyof T;
      acc[key] = clearEmptyValues(v);

      if (
        acc[key] == null ||
        acc[key] === '' ||
        (typeof acc[key] === 'object' && Object.keys(acc[key]).length === 0)
      ) {
        delete acc[key];
      }

      return acc;
    }, {});
  }

  return value;
}

/**
 * Filter a list to remove duplicates values based on an object key.
 * Keeps the initial order of the array
 */
function filterSameValueInList(list: Array<any>, key: string): Array<any> {
  return list.filter((v, i, a) => a.findIndex(v2 => v2[key] === v[key]) === i);
}

/**
 * Convert a stream of state mutations into a stream of full state.
 * It also merge events having the same timestamp and trip.
 */
function eventStreamToStateStream(eventStream: Array<DeviceEvent>, initState: Device): Array<DeviceEvent> {
  /** @type {import("@/store/devices").Event} */
  const state = initState || { device_id: null, ts: 0 };

  /** @type {Array<import("@/store/devices").Event>} */
  const stateStream = [];

  for (const event of eventStream) {
    Object.assign(state, event);

    const lastState = stateStream[stateStream.length - 1];

    if (
      lastState !== undefined &&
      lastState.ts === state.ts &&
      lastState.trip?.trip_id === state.trip?.trip_id &&
      lastState.trip?.start_date === state.trip?.start_date
    ) {
      Object.assign(lastState, state);
    } else {
      stateStream.push({ ...state });
    }
  }

  return stateStream;
}

export {
  arrToFakeMap,
  arrToObj,
  compareObjectKeys,
  eventStreamToStateStream,
  filterSameValueInList,
  isDeepEqual,
  clearEmptyValues,
};
