import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

import { NEW_LINE_ID } from './index.vue';
import { Role, Permission } from '@/auth';
import store from '@/store';

const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const SelectorCell = defineAsyncComponent(() => import('@/components/Table/cells/SelectorCell.vue'));
const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const MultipleTeamSelector = defineAsyncComponent(() => import('@/components/ui/MultipleTeamSelector.vue'));

export const USERS_LS_COLUMNS = 'usersPage/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  USER_NAME: 'user_id',
  ROLE: 'role',
  TEAMS: 'teams',
  CREATION_DATE: 'creationDate',
  ACTION: 'action',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(USERS_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'userListDatagrid',
    searchFields: ['user_id'],
    defaultSortBy: [{ key: ColumnKey.USER_NAME, order: SortOrder.ASC }],
    rowIdKey: '_id',
    columnSelectionLocalStorageKey: USERS_LS_COLUMNS,
    hasActions: true,
    columns: [
      // USER NAME
      new DataGridColumn({
        key: ColumnKey.USER_NAME,
        title: 'column.email',
        defaultSelected: defaultSelected(ColumnKey.USER_NAME),
        selectable: false,
        cellBuilder([value, object], { valueChanged }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              editMode: object._id === NEW_LINE_ID,
              value,
              type: 'user_id',
            },
          };
        },
      }),

      // ROLE
      new DataGridColumn({
        key: ColumnKey.ROLE,
        title: 'column.role',
        defaultSelected: defaultSelected(ColumnKey.ROLE),
        filterable: true,
        selectable: false,
        i18nPrefix: 'roles',
        cellBuilder([value, object], { valueChanged }) {
          return {
            component: SelectorCell,
            events: { valueChanged },
            props: {
              editMode: store.getters.hasPermission(Permission.ADMIN_USERS),
              value,
              type: 'role',
              options: Object.values(Role).filter(r => r !== Role.SUPERUSER),
              i18nPrefix: 'roles',
            },
          };
        },
      }),

      // TEAMS
      new DataGridColumn({
        key: ColumnKey.TEAMS,
        title: 'column.teams',
        defaultSelected: defaultSelected(ColumnKey.TEAMS),
        filterable: value => ({ value, label: store.getters.getTeamNameById(value) }),
        sortable: false,
        cellBuilder([value, object], { valueChanged }) {
          /** @type {import('@/store').User} */
          const user = store.state.user;

          return {
            component: MultipleTeamSelector,
            events: { valueChanged },
            props: {
              editMode: store.getters.hasPermission(Permission.ADMIN_USERS),
              multipleSelection: true,
              selectedTeams: value,
              showAll: user.id === object.user_id,
            },
          };
        },
      }),

      // CREATION DATE
      new DataGridColumn({
        key: ColumnKey.CREATION_DATE,
        title: 'column.creationDate',
        defaultSelected: defaultSelected(ColumnKey.CREATION_DATE),
        cellBuilder([value, object]) {
          return {
            component: DateCell,
            props: {
              date: value.toLocaleString(),
            },
          };
        },
      }),
    ],
  });
};
