<template>
  <div class="no-report-data">
    <p>{{ content.head }}</p>
    <p>{{ content.tip }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoReportData',

  props: {
    /** @type {import('vue').Prop<NoDataContent>} */
    customContent: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    /** @return {NoDataContent} */
    content() {
      return {
        head: this.customContent?.head || this.$t('noData.head'),
        tip: this.customContent?.tip || this.$t('noData.tip'),
      };
    },
  },
};

/**
 * @typedef {Object} NoDataContent
 * @property {string} [head]
 * @property {string} [tip]
 */
</script>

<style lang="scss">
.no-report-data {
  height: 70vh;
  margin: 25px 25px 25px 0;
  padding-top: 30vh;
  border-radius: 9px;
  background: $background;
  text-align: center;

  p {
    margin: 0;

    &:first-child {
      font-weight: $font-weight-semi-bold;
    }
  }
}
</style>

<i18n locale="fr">
{
  "noData": {
    "head": "Aucune donnée disponible.",
    "tip": "Veuillez modifier l'indicateur ou la période."
  },
}
</i18n>

<i18n locale="en">
{
  "noData": {
    "head": "No data available.",
    "tip": "Please change the indicator or the period."
  },
}
</i18n>
