<template>
  <div class="connector-field-gtfs-field">
    <div class="form-group">
      <label class="form-group__label" :for="name">
        <slot name="label">
          {{ $t('label') }}
        </slot>
      </label>
      <Selector :id="name" v-model:value="value" :options="gtfsFields" mode="single" />
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed } from 'vue';

import Selector from '@/components/ui/Selector.vue';
import { GTFS_FIELDS } from '@/store/integrations';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'gtfs_field',
  },
});

const gtfsFields = GTFS_FIELDS;

const { value, errorMessage } = useField(computed(() => props.name));
</script>

<i18n locale="fr">
{
  "label": "Champs GTFS",
  "choose_code": "Sélectionnez un code"
}

</i18n>

<i18n locale="en">
{
  "label": "GTFS Field",
  "choose_code": "Choose a code"
}
</i18n>
