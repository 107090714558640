<template>
  <div class="reports">
    <AnimatedDots v-if="isLoading" class="reports__loading reports__loading-dots" :type-v2="true" />
    <NoReportData v-else-if="!hasData" />

    <template v-else>
      <div class="reports__abstract">
        <NumberDisplay :title="$t('totalRecorded')" :number="totalKm" :unit="$t('distanceUnit')" />
        <RadialBarChart :title="$t('averagePercent')" :value="averageKm" />
      </div>
      <div class="reports__barcharts">
        <BarChart3
          :height="chartHeight"
          :options="dayChart.options"
          :categories="dayChart.categories"
          :series="dayChart.series"
          :title="$t('chartDayTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
        <BarChart3
          :height="chartHeight"
          :options="routeChart.options"
          :categories="routeChart.categories"
          :series="routeChart.series"
          :title="$t('chartRouteTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import deepmerge from 'deepmerge';

import { trips as ApiTrips } from '@/api';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import BarChart3 from '@/components/ui/BarChart3.vue';
import NumberDisplay from '@/components/ui/NumberDisplay.vue';
import RadialBarChart from '@/components/ui/RadialBarChart.vue';
import NoReportData from '@/components/ui/NoReportData.vue';

import { TripKmReportsHelper } from '@/pages/ReportsPage/ReportsTripKm/TripKmReportsHelper.js';

import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';

export default {
  name: 'ReportsTripKm',

  components: {
    AnimatedDots,
    BarChart3,
    NumberDisplay,
    RadialBarChart,
    NoReportData,
  },

  props: {
    /** @type {Object} */
    chartHeight: {
      type: Number,
      default: 620,
    },
    /** @type {Object} */
    chartWidth: {
      type: Number,
      default: 1200,
    },
    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },
  },
  emits: ['hasData'],
  data: () => ({
    /** @type {Boolean} */
    isLoading: true,

    // index.vue : ChartData
    routeChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },
    dayChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },
  }),

  computed: {
    /** @return {number} */
    totalKm() {
      return this.dayChart.data.reduce((acc, tripKmObject) => acc + tripKmObject.recordedKm, 0);
    },
    // TODO : reovir les calculs de average, car faussé par les journées sans courses
    /** @return {number} */
    averageKm() {
      if (!this.dayChart.data.length) return 0;
      return Math.round(
        this.dayChart.data.reduce((acc, tripKmObject) => acc + tripKmObject.percent, 0) /
          this.dayChart.data.length,
      );
    },
    hasData() {
      const result = this.totalKm > 0;
      this.$emit('hasData', result);
      return result;
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.isLoading = true;
          Promise.all([this.loadData('day'), this.loadData('route')]).then(() => {
            this.isLoading = false;
          });
        }
      },
    },
  },

  methods: {
    /** @param {'day'|'route'} groupBy */
    async loadData(groupBy) {
      const response = await ApiTrips.getTripKMStats(
        this.$store.getters.group._id,
        groupBy,
        dateObjToGtfsFormat(new Date(this.dateInterval.start * 1000)),
        dateObjToGtfsFormat(new Date(this.dateInterval.end * 1000)),
      );

      if (groupBy === 'day') {
        this.dayChart.data = response
          .map(item => TripKmReportsHelper.getGroupByColumnsInfos(item, 'day'))
          .sort((a, b) => a.date - b.date);

        this.dayChart.series = TripKmReportsHelper.getSeries(this.dayChart.data);
        this.dayChart.categories = this.dayChart.data.map(el =>
          this.$d(dateGtfsFormatToObj(el.date), 'dayMonth').replace(/\./g, ''),
        );
        this.dayChart.options = this.getOptions(this.dayChart.data, 'day');
      } else if (groupBy === 'route') {
        this.routeChart.data = response
          .map(item => TripKmReportsHelper.getGroupByColumnsInfos(item, 'route'))
          .sort(this.sortRoutesAlphaNum);

        this.routeChart.series = TripKmReportsHelper.getSeries(this.routeChart.data);
        this.routeChart.categories = this.routeChart.data.map(el => el.routeShortName || el.routeId);
        this.routeChart.options = this.getOptions(this.routeChart.data, 'route');
      }
    },

    /**
     * @param {Array<import('./TripKmReportsHelper.js').TripKMObject>} data
     * @param {'day'|'route'} chartType
     * @return {Object}
     */
    getOptions(data, chartType) {
      const genericOptions = {
        dataLabels: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
            if (!entry) return null; // prevent console error on screen nav
            const { theoricalKm, percent, recordedKm } = entry;
            // switch labels of bottom and top bars when recordedKm exceeds theoricalKm :
            if (recordedKm < theoricalKm) {
              return seriesIndex === 0 ? `${percent} %` : '';
            }
            return seriesIndex === 0 ? '' : `${percent} %`;
          },
        },
      };
      if (chartType === 'day') {
        const dayChartOptions = {
          tooltip: {
            custom: ({ dataPointIndex, w }) => {
              const { theoricalKm, recordedKm, percent, unreliableKm } =
                data[dataPointIndex + w.config.page * w.config.barsPerScreen];
              return TripKmReportsHelper.createTooltip(theoricalKm, recordedKm, percent, unreliableKm);
            },
          },
        };
        return deepmerge(genericOptions, dayChartOptions);
      }
      if (chartType === 'route') {
        const routeChartOptions = {
          xaxis: {
            labels: {
              hideOverlappingLabels: false,
              showDuplicates: true,
              maxHeight: 200,
              style: {
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            custom: ({ dataPointIndex, w }) => {
              const { gtfsId, gtfsName, theoricalKm, recordedKm, percent, unreliableKm } =
                data[dataPointIndex + w.config.page * w.config.barsPerScreen];
              return TripKmReportsHelper.createTooltip(
                theoricalKm,
                recordedKm,
                percent,
                unreliableKm,
                gtfsName || gtfsId,
              );
            },
          },
        };
        return deepmerge(genericOptions, routeChartOptions);
      }
      return null;
    },

    /**
     * @param {import('./TripKmReportsHelper.js').TripKMObject} a
     * @param {import('./TripKmReportsHelper.js').TripKMObject} b
     * @return {number}
     */
    sortRoutesAlphaNum(a, b) {
      return a.routeId.localeCompare(b.routeId, this.$i18n.locale, { numeric: true });
    },
  },
};
</script>
<style scoped lang="scss">
.reports {
  background-color: $canvas;
}
</style>
<i18n locale="fr">
{
  "noData": {
    "head": "Aucune donnée disponible.",
    "tip": "Veuillez modifier l'indicateur ou la période."
  },
  "averagePercent": "Pourcentage moyen des kilomètres enregistrés",
  "chartDayTitle": "Kilomètres enregistrés par jour",
  "chartRouteTitle": "Kilomètres enregistrés par ligne",
  "distanceUnit": "km",
  "recorded": "Km enregistrés : {0} km",
  "percent": "Pourcentage : {0} %",
  "planned": "Km théoriques : {0} km",
  "totalRecorded": "Total des kilomètres enregistrés",
  "unreliableKm": "Km peu fiables : {0} km",
  "yaxisTitle" : "Km théoriques"
}
</i18n>

<i18n locale="en">
{
  "averagePercent": "Average percentage of recorded kilometers",
  "chartDayTitle": "Recorded km per day",
  "chartRouteTitle": "Recorded km per line",
  "distanceUnit": "km",
  "recorded": "Recorded km: {0} km",
  "percent": "Percent: {0} %",
  "planned": "Planned km: {0} km",
  "totalRecorded": "Total recorded kilometers",
  "unreliableKm": "Unreliable km: {0} km",
  "yaxisTitle" : "Planned km"
}
</i18n>
