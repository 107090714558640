/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module Messages */
import { useToast } from 'vue-toastification';
import { messages as messagesAPI } from '../api';
import MessageToast from '@/components/ui/MessageToast.vue';
import { ToastOptions } from '@/libs/toast';

const toast = useToast();

/** @enum {string} */
export const BoxDirection = {
  INBOX: 'inbox',
  OUTBOX: 'outbox',
};

/** @typedef {'high' | 'normal'} Priority */
// /** @enum {string} */
export const Priority = {
  HIGH: 'high',
  NORMAL: 'normal',
};

/** @enum {string} */
export const StatusEnum = {
  NOT_RECEIVED: 'notReceived',
  READ: 'read',
  RECEIVED: 'received',
};

/** @enum {string} */
export const ReadStatus = {
  READ: 'read',
  UNREAD: 'unread',
};

export const MESSAGE_REFRESH_INTERVAL = 60000;

/**
 * Get message status.
 * @param {Array<import('@/@types/api/message.d.ts').ApiRecipient>} recipients
 * @return {StatusEnum} message Status
 */
function getMessageStatus(recipients) {
  if (recipients.length === 0) return;

  let readTotal = 0;
  let receivedTotal = 0;
  let notReceivedTotal = 0;

  recipients.forEach(recipient => {
    if (!Object.prototype.hasOwnProperty.call(recipient, 'delivered')) return;
    if (!Object.prototype.hasOwnProperty.call(recipient, 'read')) return;

    // Read message.
    if (recipient.read !== false) {
      readTotal += 1;
    }

    // Received message.
    if (recipient.delivered !== false) {
      receivedTotal += 1;
    }

    // NotReceived message.
    if (recipient.read === false && recipient.delivered === false) {
      notReceivedTotal += 1;
    }
  });

  // Return a summarize status for all messages.
  if (notReceivedTotal > 0 && receivedTotal === 0 && readTotal === 0) {
    return StatusEnum.NOT_RECEIVED;
  }

  if (readTotal > 0 && readTotal >= receivedTotal && notReceivedTotal === 0) {
    return StatusEnum.READ;
  }

  return StatusEnum.RECEIVED;
}

/**
 * Temporary method to merge subject and content, until it is done on the API server.
 * @param {import('@/@types/api/message.d.ts').ApiMessage} message
 * @return {string}
 */
export function getFormattedDetails(message) {
  const text = [];

  if (message.subject && message.subject !== '') {
    text.push(message.subject);
  }

  if (message.content && message.content !== '') {
    text.push(message.content);
  }

  return text.join(' - ');
}

/**
 * Retrieve send date of a message.
 * @param {import('@/@types/api/message.d.ts').ApiMessage} message
 * @return {number} Timestamp of the send date.
 */
export function getSendDate(message) {
  return parseInt(message._id.substring(0, 8), 16) * 1000;
}

/**
 * Construct formatted status string.
 * @param {import('@/@types/api/message.d.ts').ApiMessage} message
 * @return {?StatusEnum} Status string.
 */
export function getFormattedStatus(message) {
  if (!message) return;
  if (!Object.prototype.hasOwnProperty.call(message, 'recipients')) return;
  if (message.recipients.length === 0) return;

  return getMessageStatus(message.recipients);
}

/** @typedef {typeof state} State */
const state = {
  /** @type {Array<import('@/@types/api/message.d.ts').ApiMessage>} */
  hotInbox: [],

  /** @type {Array<import('@/@types/api/message.d.ts').ApiMessage>} */
  list: [],

  /** @type {boolean} */
  watchUpdates: false,
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  getters: {
    /** @return { number } */
    nbUnreadMessages(state) {
      return state.hotInbox.filter(message => message.status === StatusEnum.RECEIVED && !message.archived)
        .length;
    },

    /** @return { Set.<string> } */
    hotInboxIds(state) {
      return new Set(state.hotInbox.map(({ _id }) => _id));
    },
  },

  mutations: {
    /**
     * Clear list state.
     * @param state
     */
    clearList(state) {
      state.list = [];
    },

    /**
     * Set a message array in hotInbox.
     * @param state
     * @param {Array<import('@/@types/api/message.d.ts').ApiMessage>} hotInbox
     */
    setHotInbox(state, hotInbox) {
      state.hotInbox = hotInbox;
    },

    resetHotInbox(state) {
      state.hotInbox = [];
    },

    /**
     * Set a message array in the list.
     * @param state
     * @param {Array<import('@/@types/api/message.d.ts').ApiMessage>} messages
     */
    setList(state, messages) {
      state.list = messages;
    },

    setMessageStatusToRead(state, messageId) {
      if (state.hotInbox.find(message => message._id === messageId)?.status)
        state.hotInbox.find(message => message._id === messageId).status = StatusEnum.READ;
    },

    watchUpdates(state, value) {
      state.watchUpdates = !!value;
    },
  },

  actions: {
    /**
     * Clear messages list.
     * @param context
     */
    clear({ commit }) {
      commit('clearList');
    },

    resetNotifications({ commit }) {
      commit('resetHotInbox');
      commit('watchUpdates', false);
    },

    /**
     * Archive a message.
     * @param context
     * @param {string} messageId - Message Id to archive.
     */
    async archive({ dispatch, commit }, messageId) {
      const group = await dispatch('getGroup', null, { root: true });
      await messagesAPI.archive(group._id, messageId);

      // Change message status to read so that it disappear from nbUnreadMessages
      commit('setMessageStatusToRead', messageId);
    },

    /**
     * Mark messages as Received
     */
    changeMessagesStatus({ dispatch, state }) {
      const message = {
        delivered: Date.now() / 1000,
      };
      state.hotInbox
        .filter(record => record.status === StatusEnum.NOT_RECEIVED)
        .forEach(record => {
          dispatch('patch', {
            messageId: record._id,
            message,
          });
          // Update status
          record.status = StatusEnum.RECEIVED;
        });
    },

    /**
     * Retrieve all messages for the current group and from server.
     * @param context
     * @return {Promise<messages>}
     */
    async getMessages({ state, commit, dispatch }) {
      const group = await dispatch('getGroup', null, { root: true });
      const messages = (await messagesAPI.getMessages(group._id)).filter(m => !m.archived);

      // Save messages.
      commit('setList', messages);
      return state.list;
    },

    /**
     * Get all informations on a recipient.
     * @param context
     * @param {{recipient: import('@/@types/api/message.d.ts').ApiRecipient, recipientTotal: number}} values
     * @return {Promise<Object>}
     */
    async getRecipientInfos({ rootState, rootGetters }, values) {
      if (!Object.prototype.hasOwnProperty.call(values, 'recipient')) return;
      if (!Object.prototype.hasOwnProperty.call(values, 'recipientTotal')) return;

      let address = null;
      const recipientInfo = {};

      if (Object.prototype.hasOwnProperty.call(values.recipient, 'address')) {
        address = values.recipient.address;
      }

      // 'Inbox'
      if (address === 'op') {
        recipientInfo.senderName = `<${rootState.user.email}>`;
        recipientInfo.senderId = rootState.user._id;
        recipientInfo.delivered = values.recipient.delivered;
        recipientInfo.read = values.recipient.read;
      }

      // 'Outbox'
      if (address.search('device_id:') !== -1) {
        address = address.substring(10, address.length);
        recipientInfo.senderId = address;
        recipientInfo.senderName = rootState.devices.list[address]?.name ?? `<${address}>`;
      }

      recipientInfo.statusTitle = getMessageStatus([values.recipient]);

      return recipientInfo;
    },

    /**
     * Patch a message.
     * @param context
     * @param {Object} message
     * @param {string} message.messageId - Message Id to patch.
     * @param {Partial<import('@/@types/api/message.d.ts').ApiMessage>} message.message
     * @return {Promise<string>} Patched process result.
     */
    async patch({ dispatch }, message) {
      const group = await dispatch('getGroup', null, { root: true });
      const result = await messagesAPI.patch(group._id, message);

      return result;
    },

    /**
     * Create a new message.
     * @param context
     * @param {import('@/@types/api/message.d.ts').ApiMessage} message
     */
    async post({ dispatch }, message) {
      const group = await dispatch('getGroup', null, { root: true });
      await messagesAPI.post(group._id, message);
    },

    /**
     * Construct formatted recipients array.
     * @param context
     * @param {import('@/@types/api/message.d.ts').ApiMessage} message
     * @return {Promise<Array>} - Recipients array.
     */
    async setFormattedRecipients({ dispatch }, message) {
      // Exit if no data.
      if (!message) return;
      if (!Object.prototype.hasOwnProperty.call(message, 'sender')) return;
      if (!Object.prototype.hasOwnProperty.call(message.sender, 'client')) return;
      if (!Object.prototype.hasOwnProperty.call(message, 'recipients')) return;
      if (message.recipients.length === 0) return;

      // Outbox
      const recipients = [];

      // Let's capture all needed data from recipients.
      await Promise.all(
        message.recipients.map(async record => {
          await dispatch('getRecipientInfos', {
            recipient: record,
            recipientTotal: 1,
          })
            .then(recipientInfos => {
              recipients.push(recipientInfos);
            })
            .catch(error => {
              console.log('-=- messages/getRecipientInfos / error: ', error);
            });
        }),
      );

      return recipients;
    },

    /**
     * Construct formatted sender string.
     * @param context
     * @param {import('@/@types/api/message.d.ts').ApiMessage} message
     * @return {Promise<?Object>} Sender string.
     */
    async setFormattedSender({ rootState, dispatch }, message) {
      // Exit if no data.
      if (message.recipients.length === 0) return;

      if (message.sender.client === 'op') {
        // Let's capture all needed data from recipient.
        const recipientInfos = await dispatch('getRecipientInfos', {
          recipient: message.recipients[0],
          recipientTotal: message.recipients.length,
        }).catch(error => {
          console.log('-=- messages/getRecipientInfos / error: ', error);
        });

        // Let's capture all needed data from sender.
        recipientInfos.senderName = message.sender.client;
        recipientInfos.senderId = message.sender.user_id;

        return recipientInfos;
      }
      // Check device_id & user_id.
      if (!Object.prototype.hasOwnProperty.call(message.sender, 'device_id')) {
        if (!Object.prototype.hasOwnProperty.call(message.sender, 'user_id')) {
          // No device_id && no user_id. Bye!
          // return
          return {
            senderName: '',
            senderNameAndRecipients: '',
            senderId: '',
          };
        }
        // No device_id BUT a user_id.
        return {
          senderName: message.sender.user_id,
          senderNameAndRecipients: message.sender.user_id,
          senderId: message.sender.user_id,
        };
      }

      // device_id is unknown.
      if (!rootState.devices.list[message.sender.device_id]) {
        // return '<' + message.sender.device_id + '>'
        return {
          senderName: `<${message.sender.device_id}>`,
          senderNameAndRecipients: `<${message.sender.device_id}>`,
          senderId: message.sender.device_id,
        };
      }

      // device_id is known. A name exists for this device_id.
      if (Object.prototype.hasOwnProperty.call(rootState.devices.list[message.sender.device_id], 'name')) {
        return {
          senderName: rootState.devices.list[message.sender.device_id].name,
          senderNameAndRecipients: rootState.devices.list[message.sender.device_id].name,
          senderId: message.sender.device_id,
        };
      }
      // device_id is known BUT No name exists for this device_id.
      // return '<' + message.sender.device_id + '>'
      return {
        senderName: `<${message.sender.device_id}>`,
        senderNameAndRecipients: `<${message.sender.device_id}>`,
        senderId: message.sender.device_id,
      };
    },

    /**
     * Set all Hot Inbox messages in the store.
     * @param context
     */
    async setHotInboxMessages({ commit, dispatch, getters }) {
      const group = await dispatch('getGroup', null, { root: true });
      const hotInboxMessages = await messagesAPI.getHotInBoxMessages(group._id);

      const hotInboxArray = [];

      await Promise.all(
        hotInboxMessages.map(async message => {
          const hotInbox = {};

          const loadSender = async () => {
            const sender = await dispatch('setFormattedSender', message);
            hotInbox.sender = {
              deviceId: sender.senderId,
              name: sender.senderName,
            };
          };

          // Start async tasks
          const pending = Promise.all([loadSender()]);

          hotInbox.content = getFormattedDetails(message);
          hotInbox.formattedTripName = message.sender.trip ? message.sender.trip.formatted_trip_name : null;
          hotInbox.sendDate = getSendDate(message);
          hotInbox.status = getFormattedStatus(message);
          hotInbox.archived = message.archived || false;
          hotInbox._id = message._id;

          // Wait pending async tasks
          await pending;
          hotInboxArray.push(hotInbox);
        }),
      );

      const notificationsDisabled = localStorage.getItem('settings.op.disable_notif');

      commit('watchUpdates', !notificationsDisabled);

      if (
        state.watchUpdates &&
        getters.hotInboxIds?.size &&
        getters.hotInboxIds.size < hotInboxArray.length
      ) {
        hotInboxArray.forEach(message => {
          if (getters.hotInboxIds.has(message._id)) return;
          const toastId = toast(
            {
              component: MessageToast,
              props: { message },
            },
            {
              closeButtonClassName: 'initial-color',
            },
          );
          // To remove when toast lib fix timeout error
          setTimeout(() => toast.dismiss(toastId), ToastOptions.timeout);
        });
      }

      commit('setHotInbox', hotInboxArray);
      dispatch('changeMessagesStatus');
    },
  },
});

/**
 * Update store.hotInbox at regular intervals
 */
export function messagesHotInboxUpdatePlugin(store) {
  store.dispatch('messages/setHotInboxMessages');
  clearInterval(store.$_hotInboxTimerId);
  store.$_hotInboxTimerId = setInterval(() => {
    store.dispatch('messages/setHotInboxMessages');
  }, MESSAGE_REFRESH_INTERVAL);
}
