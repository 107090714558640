<template>
  <Modal modal-class="modal-device-add" @close="closeModal">
    <template #title>
      {{ $t('addDevice') }}
    </template>

    <template #body>
      <div class="form-group">
        <label class="form-group__label" for="deviceId">{{ $t('deviceId') }}</label>
        <input
          id="deviceId"
          v-model="newDevice.id"
          class="form-group__input"
          type="text"
          required
          :minlength="REQUIRED_CHAR_GUID_LENGTH"
          :maxlength="REQUIRED_CHAR_GUID_LENGTH"
        />
      </div>

      <InputValidationInfos :validate-conditions="deviceIdValidateConditions" />

      <div class="form-group">
        <label class="form-group__label" for="name">
          {{ $t('name') }}
          <span class="form-group__label__optional">{{ $t('optional') }}</span>
        </label>
        <input id="name" v-model="newDevice.name" class="form-group__input" type="text" />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="teams">
          {{ $t('teams') }}
          <span class="form-group__label__optional">{{ $t('optional') }}</span>
        </label>
        <SingleTeamSelector
          id="teamId"
          class="form-group__input form-group__input--multiselect"
          :team-id="newDevice.teams"
          :edit-mode="true"
          no-team-placeholder
          @valueChanged="updateTeams"
        />
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!isFormValid" @click="submitAddModal">
        {{ $t('add') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import InputValidationInfos from '@/components/common/InputValidationInfos.vue';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import SingleTeamSelector from '@/components/ui/SingleTeamSelector.vue';

const REQUIRED_CHAR_GUID_LENGTH = 16;

export default {
  name: 'ModalDeviceAdd',

  components: {
    Btn,
    InputValidationInfos,
    Modal,
    SingleTeamSelector,
  },

  props: {
    /** @type {import('vue').Prop<Array<string>>} */
    existingDevicesIds: {
      type: Array,
      required: true,
    },
  },

  emits: ['close', 'submit'],

  data: () => ({
    REQUIRED_CHAR_GUID_LENGTH,

    /** @type {NewDevice} */
    newDevice: {
      id: null,
      name: null,
      teams: null,
    },
  }),

  computed: {
    /** @return {Array<import('@/components/common/InputValidationInfos.vue').ValidateCondition>} */
    deviceIdValidateConditions() {
      const hasOnlyNumbersAndLetters = /^[a-zA-Z0-9]*$/.test(this.newDevice.id);
      const isIdRequiredLength = this.newDevice?.id?.length === REQUIRED_CHAR_GUID_LENGTH;
      const isIdUnique =
        this.newDevice?.id?.length > 0 && !this.existingDevicesIds.includes(this.newDevice.id);
      return [
        {
          text: this.$t('uniqueId'),
          isValid: isIdUnique,
        },
        {
          text: this.$t('requiredLength', { length: REQUIRED_CHAR_GUID_LENGTH }),
          isValid: isIdRequiredLength && hasOnlyNumbersAndLetters,
        },
      ];
    },

    /** @return {boolean} */
    isFormValid() {
      return this.newDevice.id && this.deviceIdValidateConditions.every(condition => condition.isValid);
    },
  },

  created() {
    this.resetNewDevice();
  },

  methods: {
    /**
     * Create a new device
     * @param {NewDevice} deviceInfos
     */
    async createDevice(deviceInfos) {
      await this.$store.dispatch('devices/post', deviceInfos);
    },

    /**
     * Set newDevice object to null;
     */
    resetNewDevice() {
      this.newDevice = {
        id: null,
        name: null,
        teams: null,
      };
    },
    /**
     * Submit data to back and close modal
     */
    submitAddModal() {
      // TODO replace this, temporary solution. user can set a unique team
      // @ts-ignore
      this.newDevice.teams = this.newDevice.teams ? [this.newDevice.teams] : null;
      this.createDevice(this.newDevice);
      this.$emit('submit', this.newDevice);
      this.closeModal();
    },
    /**
     * Close the current modal.
     * @emits event:close
     */
    closeModal() {
      this.resetNewDevice();
      this.$emit('close');
    },

    /**
     * update the teams field
     * @param {object} updateAction
     */
    updateTeams(updateAction) {
      if (updateAction.value) this.newDevice.teams = updateAction.value;
      else this.newDevice.teams = null;
    },
  },
};

/**
 * @typedef {Object} NewDevice
 * @property {string} id
 * @property {string} name
 * @property {string} teams
 */
</script>

<style lang="scss">
.modal-device-add {
  .modal__body {
    overflow-y: initial;
  }
}
</style>

<i18n locale="fr">
{
  "addDevice": "Ajouter un appareil",
  "deviceId": "Identifiant de l'appareil",
  "name": "Nom de l'appareil",
  "teamId": "Équipe (Courses)",
  "optional": "(optionnel)",
  "requiredLength": "{length} caractères (lettres et chiffres uniquement)",
  "uniqueId": "ID unique"
}
</i18n>

<i18n locale="en">
{
  "addDevice": "Add device",
  "deviceId": "Device ID",
  "name": "Device name",
  "optional": "(optional)",
  "requiredLength": "{length} characters (letters and numbers only)",
  "uniqueId": "Unique ID"
}
</i18n>
