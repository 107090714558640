<template>
  <span class="device-delay" :class="delayClass">
    {{ formattedDelay }}
  </span>
</template>

<script>
import { DelayState } from '@/store/devices';

const DELAY_CLASS = {
  [DelayState.EARLY]: 'too-early',
  [DelayState.LATE]: 'too-late',
  [DelayState.ON_TIME]: 'on-time',
};

export default {
  name: 'DeviceDelay',

  props: {
    // in seconds
    delay: {
      type: Number,
      default: null,
    },
  },

  computed: {
    /** @return {string} */
    delayClass() {
      if (this.delay == null) return '';

      const delayState = this.$store.getters['devices/getDelayState'](this.delay);
      return `device-delay--${DELAY_CLASS[delayState]}`;
    },

    /** @return {string} */
    formattedDelay() {
      if (this.delay == null) return '-';

      const sign = this.delay >= 0 ? '+' : '-';
      const min = Math.floor(Math.abs(this.delay) / 60);

      return /** @type {string} */ (this.$t('delayMn', [sign + min]));
    },
  },
};
</script>

<style lang="scss">
.device-delay {
  &--too-early {
    color: $danger;
  }

  &--too-late {
    color: $warn;
  }

  &--on-time {
    color: $primary-light;
  }
}
</style>

<i18n locale="fr">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="en">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="es">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="de">
{
  "delayMn": "{0} Min."
}
</i18n>

<i18n locale="cz">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="pl">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="it">
{
  "delayMn": "{0} min"
}
</i18n>
