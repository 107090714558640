<template>
  <div class="connector-field-iris-protocol">
    <div class="form-group">
      <label class="form-group__label" :for="name">
        <slot name="label">
          {{ $t('label') }}
        </slot>
      </label>
      <Selector :id="name" v-model:value="objectValue" object :options="irisProtocols" mode="single" />
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed, ref } from 'vue';

import Selector from '@/components/ui/Selector.vue';
import { syncRef } from '@vueuse/core';
import { IRIS_PROTOCOLS } from '@/store/integrations';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'protocol',
  },
});

const irisProtocols = IRIS_PROTOCOLS;

const { value, errorMessage } = useField(computed(() => props.name));

const objectValue = ref(irisProtocols.find(p => p.value === value.value));
syncRef(objectValue, value, {
  immediate: true,
  transform: {
    ltr: objectValue => objectValue?.value,
    rtl: value => irisProtocols.find(p => p.value === value),
  },
});
</script>

<i18n locale="fr">
{
  "label": "Protocole",
  "choose_code": "Sélectionnez un protocole"
}

</i18n>

<i18n locale="en">
{
  "label": "Protocol",
  "choose_code": "Choose a protocol"
}
</i18n>
