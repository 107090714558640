<template>
  <div class="layers-dropdown">
    <div ref="dropdown" class="dropdown" :class="{ 'dropdown--open': openedDropdown }">
      <button
        :aria-expanded="openedDropdown + ''"
        aria-haspopup="true"
        class="dropdown__button"
        @click="dropdownToggle()"
        @mousedown.prevent=""
      >
        <font-awesome-icon icon="fa-layer-group" />
      </button>

      <ul :aria-hidden="!openedDropdown + ''" class="dropdown__menu">
        <template v-for="(value, key) in localModelValue" :key="key">
          <li v-if="displayOptions[key] && key !== 'vehiclesLabels'" class="dropdown__item c-layer">
            <v-checkbox
              v-model="localModelValue[key]"
              color="success"
              :disabled="key === 'vehicles'"
              hide-details
            >
              <template #label>
                {{ $t(key) }}
              </template>
            </v-checkbox>

            <button
              v-if="key === 'vehicles'"
              class="c-layer__param"
              @click="$emit('open-modal')"
              @mousedown.prevent=""
            >
              <font-awesome-icon icon="fa-gear" />
            </button>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapLayersDropdown',

  props: {
    /** @type {Vue.PropOptions<LayerOptions>} */
    modelValue: {
      type: Object,
      required: true,
    },
    /** @type {Vue.PropOptions<DisplayOptions>} */
    displayOptions: {
      type: Object,
      default: () => ({
        vehicles: true,
        stops: true,
        stations: true,
        traffic: true,
        linesShapes: true,
        stopsZones: true,
      }),
    },
  },

  emits: ['update:modelValue', 'open-modal'],

  data: () => ({
    openedDropdown: false,
  }),

  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },

  methods: {
    /**
     * Close dropdown.
     */
    dropdownClose(event) {
      // Prevent closing the dropdown when the click
      // comes from a child element, except the button.
      const findParent = elem => {
        let found = false;

        while (!found && elem) {
          found = elem.parentElement === this.$refs.dropdown;
          // eslint-disable-next-line no-param-reassign
          elem = elem.parentElement;
        }

        return found;
      };

      if (event && event.target && findParent(event.target)) return;

      this.openedDropdown = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open dropdown.
     */
    dropdownOpen() {
      this.openedDropdown = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Toggle dropdown.
     */
    dropdownToggle() {
      if (!this.openedDropdown) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },
  },
};

/**
 * @typedef {Object} DisplayOptions
 * @property {boolean} linesShapes
 * @property {boolean} stopsZones
 * @property {boolean} traffic
 * @property {boolean} vehicles
 * @property {boolean} stations
 * @property {boolean} stops
 */

/**
 * @typedef {object} LayerOptions
 * @extends DisplayOptions
 * @property {string} vehiclesLabels
 */
</script>

<style lang="scss">
.layers-dropdown {
  .c-layer {
    &__param {
      margin-left: auto;
      padding-right: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .dropdown {
    &__button {
      padding: 5px;
      border: 1px solid $border-variant;
      border-radius: 5px;
      background-color: $canvas;
      color: $mapbox-icons;
      line-height: 14px;

      svg {
        font-size: 15px;
      }

      &:hover {
        background-color: change-color($color: $canvas, $alpha: 0.8);
      }
    }

    .tag-icon {
      margin-left: 5px;
    }

    .v-label {
      font-size: 12px;
    }

    &__item {
      display: flex;
    }

    &__menu {
      margin-top: 10px;
      border: 1px solid $border-variant;
      border-radius: 5px;
      background-color: white;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
      font-size: 12px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "linesShapes": "Tracés de lignes",
  "stations": "Stations",
  "stopsZones": "Zones d'arrêt",
  "traffic": "Trafic",
  "vehicles": "Véhicules"
}
</i18n>

<i18n locale="en">
{
  "linesShapes": "Line plots",
  "stations": "Stations",
  "stopsZones": "Stop zones",
  "traffic": "Traffic",
  "vehicles": "Vehicles"
}
</i18n>

<i18n locale="cz">
{
  "stations": "Stanic",
  "stopsZones": "Okruh zastávky",
  "vehicles": "Vozidla",
  "traffic": "Provoz",
  "linesShapes": "Plány linek"
}
</i18n>

<i18n locale="de">
{
  "stations": "Stationen",
  "stopsZones": "Haltezonen",
  "vehicles": "Fahrzeuge",
  "traffic": "Verkehr",
  "linesShapes": "Fahrtrouten"
}
</i18n>

<i18n locale="es">
{
  "stations": "Estaciones",
  "stopsZones": "Zonas de parada",
  "vehicles": "Vehículos",
  "traffic": "Tráfico",
  "linesShapes": "Gráfica de líneas"
}
</i18n>

<i18n locale="it">
{
  "stations": "Stazioni",
  "stopsZones": "Aree di sosta",
  "vehicles": "Veicoli",
  "traffic": "Traffico",
  "linesShapes": "Tracciato delle linee"
}
</i18n>

<i18n locale="pl">
{
  "stations": "Stacje",
  "stopsZones": "Strefy postoju",
  "vehicles": "Pojazdy",
  "traffic": "Ruch uliczny",
  "linesShapes": "Wykaz tras"
}
</i18n>
