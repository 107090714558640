import { GroupBy } from '@/libs/reports';
import i18n from '@/i18n';

/** @enum {string} */
const CategoriesColors = {
  0: '#00B871', // $primary-light
  1: '#AF47E8',
  2: '#5766EE',
  3: '#E14E5D',
  4: '#D9BC10',
  5: '#D947C4',
  6: '#2C974A',
  7: '#F09153',
  8: '#1FACC3',
  9: '#F0D917',
  10: '#75B620',
};

export const PassengerCountReportsHelper = {
  /**
   * @param {PassengerCountDataItem} focusedData
   * @param {Array<string>} customCategories
   * @param {number} seriesIndex
   * @returns {String}
   */
  createTooltip(focusedData, customCategories, seriesIndex) {
    const { t } = i18n.global;
    const numberFormatter = new Intl.NumberFormat(i18n.locale);

    let tooltip = '';
    // Total boardings
    if (seriesIndex === 0) {
      tooltip = `
              <ul class="apexcharts-custom-tooltip">
                <li>${t('dashboard.alighting', [numberFormatter.format(focusedData.alighting)])}</li>
                <li>${t('dashboard.boarding', [numberFormatter.format(focusedData.total_boarding)])}</li>
            `;
      // Specific boardings
    } else {
      tooltip = `
              <ul class="apexcharts-custom-tooltip">
                <li><b>${customCategories[seriesIndex - 1]}</b></li>
                <li>${t('dashboard.boarding', [
                  numberFormatter.format(focusedData.custom_boarding[customCategories[seriesIndex - 1]]),
                ])}</li>
            `;
    }
    return `${tooltip}</ul>`;
  },

  /**
   * @param {import('@/api').PassengerCounts} item
   * @param {GroupBy} type
   * @return {PassengerCountDataItem | Object}
   */

  getGroupedInfos(item, type) {
    switch (type) {
      case GroupBy.DAY: {
        return {
          alighting: item.alighting,
          date: item.date,
          total_boarding: item.total_boarding,
          boarding: item.boarding,
          custom_boarding: item.custom_boarding,
        };
      }

      case GroupBy.ROUTE: {
        return {
          alighting: item.alighting,
          route_short_name: item.route_short_name,
          total_boarding: item.total_boarding,
          boarding: item.boarding,
          custom_boarding: item.custom_boarding,
        };
      }
      default:
        return {};
    }
  },

  /**
   * @param {Array<import('@/api').PassengerCounts>} data
   * @return {Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>}
   */
  getSeries(data) {
    const categories = this.getCustomCategories(data);
    const customCat = categories.reduce((acc, cat) => {
      acc[cat] = [];
      return acc;
    }, []);

    const emptySeries = { totalBoardings: [], ...customCat };

    const seriesData = data.reduce((acc, currentObject) => {
      categories.forEach(cat => {
        acc[cat].push(currentObject?.custom_boarding[cat] || 0);
      });
      acc.totalBoardings.push(currentObject?.total_boarding || 0);
      return acc;
    }, emptySeries);

    const series = Object.values(seriesData).reduce((acc, value, index) => {
      const color = CategoriesColors[index] || CategoriesColors[index - 11] || '#AF47E8';
      acc.push({
        data: value,
        color,
      });
      return acc;
    }, []);
    return series;
  },

  /**
   * @param {Array<import('@/api').PassengerCounts>} data
   * @returns {Array<String>}
   * */
  getCustomCategories(data) {
    return data.length > 0
      ? data.reduce((acc, el) => {
          if (el?.custom_boarding) {
            Object.keys(el.custom_boarding).forEach(key => {
              if (!acc.includes(key)) {
                acc.push(key);
              }
            });
          }
          return acc;
        }, [])
      : [];
  },
};

/**
 * @typedef {Object} PassengerCountDataItem
 * @property {number} alighting
 * @property {number} boarding
 * @property {Object<string, number>} custom_boarding
 * @property {number} total_boarding
 * @property {string} [date]
 * @property {string} [route_short_name]
 */
