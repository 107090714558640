<script setup lang="ts">
import { computed } from 'vue';

import { GroupRoute } from '@/libs/routing';
import { useI18n } from 'vue-i18n';
import { v4 as uuid } from 'uuid';
import { useStore } from 'vuex';
import { FstType, useTripUpdates, type FeedStopTimes } from '@/store-pinia/trip-updates';

import { dateGtfsFormatToObj, timestampFormatHHMM, timestampMidnight } from '@/libs/helpers/dates';
import cloneDeep from 'clone-deep';

import AddExistingStopCard from '@/components/common/ModalTripModification/AddExistingStopCard.vue';
import AddStopButton from '@/components/common/ModalTripModification/AddStopButton.vue';
import AddTemporaryStopCard from '@/components/common/ModalTripModification/AddTemporaryStopCard.vue';
import ConfirmRevertStopCard from '@/components/common/ModalTripModification/ConfirmRevertStopCard.vue';

import { LocationType, type Stop } from '@/@types/gtfs';
import type { Group } from '@/@types/group';

const store = useStore();
const tripUpStore = useTripUpdates();
const { t } = useI18n();

enum StopActionType {
  DISPLACE = 'displace',
  CANCEL = 'cancel',
  DELETE = 'delete',
  RESTORE = 'restore',
}

const props = defineProps({
  routeColor: {
    default: '#00b871', // $primary-light
    type: String,
  },
  delay: {
    type: [Number, null],
    default: null,
  },
  date: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(['mapFocusStop']);

const group = computed<Group>(() => {
  return store.getters.group;
});

const midnight = computed<number>(() => {
  const date = dateGtfsFormatToObj(props.date) || new Date();
  return timestampMidnight(date, group.value.tz);
});

/**
 * Update stopList on click on a row (also trigger emit to update in Modal & trigger fly on map)
 */
function triggerActionOnStop(fst: FeedStopTimes, index: number, action: StopActionType) {
  if (fst.type !== FstType.NEUTRALIZED) {
    switch (action) {
      case StopActionType.DISPLACE: {
        // if regular, set shift mode
        if (fst.type === FstType.REGULAR) tripUpStore.feedStopTimes[index].type = FstType.SHIFT;

        tripUpStore.feedStopTimes[index].inEdition = true;

        tripUpStore.currentIndexNotValidated = index;
        tripUpStore.inAddManualStopMode = true;
        tripUpStore.stopInEdition = cloneDeep(tripUpStore.getAllStops.get(fst.stop_id)) || null;
        scrollToEditionCard();
        break;
      }
      case StopActionType.CANCEL: {
        const typeToSet = fst.type === FstType.REGULAR ? FstType.CANCELED : FstType.REGULAR;
        tripUpStore.feedStopTimes[index].type = typeToSet;
        break;
      }

      case StopActionType.DELETE:
      case StopActionType.RESTORE: {
        tripUpStore.feedStopTimes[index].inDeletion = true;
        scrollToEditionCard();
        break;
      }
      default:
        break;
    }

    emit('mapFocusStop', fst.stop_id);
  }
}

function addTemporaryStop(isExisting: boolean, isBeforeFirstStop: boolean, index: number) {
  let clonedStopTime = cloneDeep(tripUpStore.feedStopTimes[index]);
  const previousStop = cloneDeep(tripUpStore.getAllStops.get(clonedStopTime.stop_id)) || ({} as Stop);

  // take previousStop as base and then initiate new values
  const time = calcDefaultTimeForTemporaryStop(isBeforeFirstStop, index);
  clonedStopTime = Object.assign(clonedStopTime, {
    stop_id: `temporary_${uuid()}`,
    stop_sequence: isBeforeFirstStop ? 0 : +(clonedStopTime.stop_sequence + 0.1).toFixed(1),
    departure_time: time,
    arrival_time: time,
    inEdition: true,
  });

  if (isExisting) {
    clonedStopTime.type = FstType.REUSE;
    tripUpStore.stopInEdition = { ...previousStop, stop_id: clonedStopTime.stop_id };
  } else {
    clonedStopTime.type = FstType.AD_HOC;
    tripUpStore.inAddManualStopMode = true;

    // Add new stop in edition
    tripUpStore.stopInEdition = {
      stop_id: clonedStopTime.stop_id,
      stop_name: t('temporaryStop'),
      stop_lat: undefined,
      stop_lon: undefined,
      location_type: LocationType.STOP,
    };
  }

  if (isBeforeFirstStop) {
    tripUpStore.currentIndexNotValidated = 0;
    tripUpStore.feedStopTimes.unshift(clonedStopTime);
  } else {
    tripUpStore.currentIndexNotValidated = index + 1;
    tripUpStore.feedStopTimes.splice(index + 1, 0, clonedStopTime);
  }
  scrollToEditionCard();
}

/**
 * Calculate default time for temporary stop based on position on list & previous/next stops
 */
function calcDefaultTimeForTemporaryStop(isBeforeFirstStop: boolean, index: number): number {
  const previousStopTime = cloneDeep(tripUpStore.feedStopTimes[index]);

  if (isBeforeFirstStop) {
    return previousStopTime.departure_time - 120;
  } else if (tripUpStore.feedStopTimes.length - 1 === index) {
    return previousStopTime.departure_time + 120;
  } else {
    const nextStopTime = cloneDeep(tripUpStore.feedStopTimes[index + 1]);
    return (nextStopTime.departure_time + previousStopTime.departure_time) / 2;
  }
}

function cancelStopEdition() {
  if (tripUpStore.currentIndexNotValidated) {
    // use reference to update object
    const stopEditToCancel = tripUpStore.feedStopTimes[tripUpStore.currentIndexNotValidated];
    if (stopEditToCancel.type !== FstType.SHIFT) {
      if (!stopEditToCancel.alreadyAddedInList)
        tripUpStore.feedStopTimes.splice(tripUpStore.currentIndexNotValidated, 1);
      else stopEditToCancel.inEdition = false;
    } else {
      if (!tripUpStore.overridedStops.has(stopEditToCancel.stop_id)) {
        stopEditToCancel.type = FstType.REGULAR;
      }
      stopEditToCancel.inEdition = false;
    }
    tripUpStore.currentIndexNotValidated = null;
    tripUpStore.stopInEdition = null;
    tripUpStore.inAddManualStopMode = false;
  }
}

function validateAddTemporaryStop(stop: Stop) {
  if (tripUpStore.currentIndexNotValidated !== null) {
    // use reference to update object
    const stopToValidate = tripUpStore.feedStopTimes[tripUpStore.currentIndexNotValidated];

    stopToValidate.stop_id = stop.stop_id;
    stopToValidate.inEdition = false;

    // Add new stop in store
    if (stopToValidate.type === FstType.AD_HOC) {
      stopToValidate.alreadyAddedInList = true;
      tripUpStore.addCreatedStop(stop.stop_id, stop.stop_name, stop.stop_lat, stop.stop_lon);
    } else {
      tripUpStore.addOverridedStop(stop.stop_id, stop.stop_lat, stop.stop_lon);
    }
    tripUpStore.currentIndexNotValidated = null;
    tripUpStore.stopInEdition = null;
    tripUpStore.inAddManualStopMode = false;
  }
}

function validateAddExistingStop(stop: Stop) {
  if (tripUpStore.currentIndexNotValidated !== null) {
    // use reference to update object
    const stopToValidate = tripUpStore.feedStopTimes[tripUpStore.currentIndexNotValidated];

    stopToValidate.stop_id = stop.stop_id;
    stopToValidate.inEdition = false;
    tripUpStore.addReusedStop(stop.stop_id);

    tripUpStore.stopInEdition = null;
    tripUpStore.currentIndexNotValidated = null;
  }
}

function cancelDeletion() {
  const index = tripUpStore.feedStopTimes.findIndex(fst => fst.inDeletion);
  if (index !== -1) tripUpStore.feedStopTimes[index].inDeletion = false;
}

function validateDeletion(fst: FeedStopTimes) {
  const index = tripUpStore.feedStopTimes.findIndex(fst => fst.inDeletion);
  if (index !== -1) {
    if (fst.type === FstType.SHIFT) {
      tripUpStore.removeOverridedStop(fst.stop_id);
      tripUpStore.feedStopTimes[index].type = FstType.REGULAR;
      tripUpStore.feedStopTimes[index].inDeletion = false;
      emit('mapFocusStop', fst.stop_id);
    } else {
      if (fst.type === FstType.AD_HOC) tripUpStore.removeCreatedStop(fst.stop_id);
      else tripUpStore.removeReusedStop(fst.stop_id);
      tripUpStore.feedStopTimes.splice(index, 1);
    }
  }
}

// #region display time

/**
 * Get required time & format it to display
 */
function getStopTimeHour(stopTime: FeedStopTimes, index: number) {
  let time = stopTime.departure_time;
  // get arrival time instead of departure if we are on last stop
  if (index === tripUpStore.feedStopTimes.length - 1) time = stopTime.arrival_time;

  const dateTs = (midnight.value + withDelay(time)) * 1000;
  return timestampFormatHHMM(dateTs / 1000, { tz: group.value.tz });
}

/**
 * Take a time and apply delay if exist
 * */
function withDelay(time: number): number {
  if (props.delay) {
    return time + props.delay * 60;
  }
  return time;
}
// #endregion

// #region display purpose

function getStopColor(st: FeedStopTimes, isStopName: boolean): string {
  switch (st.type) {
    case FstType.NEUTRALIZED:
      return '#eb5757'; //$danger
    case FstType.CANCELED:
    case FstType.SHIFT:
    case FstType.AD_HOC:
    case FstType.REUSE:
      return '#F99C49'; //$warn
    default:
      return isStopName ? '#333' : '#F5F5F5'; //$text-dark or $background
  }
}

function getIconColor(st: FeedStopTimes): string {
  switch (st.type) {
    case FstType.REGULAR:
      return '#333'; //$text-dark
    default:
      return '#FBFBFB'; //$text-light
  }
}

function getIcon(st: FeedStopTimes): string {
  switch (st.type) {
    case FstType.NEUTRALIZED:
    case FstType.CANCELED:
      return 'fa:fas fa-times';
    case FstType.SHIFT:
      return '$deviateWhite';
    default:
      return 'fa:fas fa-map-marker-alt';
  }
}

function scrollToEditionCard() {
  setTimeout(() => {
    const element = document.getElementById(`updateStopCard`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, 200);
}
// #endregion
</script>
<template>
  <div class="modal-trip-modification__table">
    <v-timeline density="compact" side="end" truncate-line="both" :line-color="routeColor">
      <v-timeline-item
        v-for="(st, index) in tripUpStore.feedStopTimes"
        :key="st.stop_id"
        class="mb-2 modal-trip-modification__table__timeline-line-feed"
        :class="
          [FstType.AD_HOC, FstType.REUSE].includes(st.type)
            ? `modal-trip-modification__table__timeline-line-feed__squared`
            : ``
        "
        :dot-color="getStopColor(st, false)"
        :icon="getIcon(st)"
        :icon-color="getIconColor(st)"
        size="small"
      >
        <div class="modal-trip-modification__table__row">
          <div
            v-if="st.inEdition && [FstType.AD_HOC, FstType.SHIFT].includes(st.type)"
            class="modal-trip-modification__table__row__add"
          >
            <AddTemporaryStopCard
              :disable-name-input="st.type === FstType.SHIFT"
              @cancel="cancelStopEdition"
              @validate="validateAddTemporaryStop"
            />
          </div>
          <div
            v-else-if="st.inEdition && st.type === FstType.REUSE"
            class="modal-trip-modification__table__row__add"
          >
            <AddExistingStopCard @cancel="cancelStopEdition" @validate="validateAddExistingStop" />
          </div>
          <div v-else-if="st.inDeletion" class="modal-trip-modification__table__row__add">
            <ConfirmRevertStopCard :stop-time="st" @cancel="cancelDeletion" @validate="validateDeletion" />
          </div>
          <div
            v-else
            :id="`stop-${st.stop_id + st.stop_sequence}`"
            class="modal-trip-modification__table__row__stop"
            :class="{
              'stop-neutralized': st.type === FstType.NEUTRALIZED,
              'disable-actions': tripUpStore.inStopTimeEdition,
            }"
          >
            <template v-if="!tripUpStore.inStopTimeEdition && tripUpStore.allowDeviationFeature">
              <AddStopButton
                v-if="index === 0"
                :stop="st"
                :is-before-first-stop="true"
                @addStopIsExisting="addStopIsExisting => addTemporaryStop(addStopIsExisting, true, index)"
              />
              <AddStopButton
                :stop="st"
                @addStopIsExisting="addStopIsExisting => addTemporaryStop(addStopIsExisting, false, index)"
              />
            </template>
            <router-link
              :title="tripUpStore.getStopName(st.stop_id) || '-'"
              class="ml-2 modal-trip-modification__table__row__stop-name"
              :class="{
                'stop-name-deactivated': [FstType.NEUTRALIZED, FstType.CANCELED].includes(st.type),
                'stop-name-deactivate-click': st.type === FstType.AD_HOC,
              }"
              :style="{
                color: getStopColor(st, true),
                fontWeight: st.type === FstType.REGULAR ? '500' : '600',
              }"
              :to="
                st.stop_id
                  ? {
                      name: GroupRoute.STOP_DETAILED,
                      params: { groupId: group._id, stopId: st.stop_id },
                    }
                  : ''
              "
              target="_blank"
            >
              {{ tripUpStore.getStopName(st.stop_id) || '-' }}
            </router-link>
            <div class="modal-trip-modification__table__right-part">
              <span
                v-if="st.type === FstType.NEUTRALIZED"
                class="modal-trip-modification__table__neutralized"
              >
                {{ $t('neutralized') }}
              </span>
              <div v-else class="modal-trip-modification__table__actions">
                <v-btn
                  v-if="
                    tripUpStore.allowDeviationFeature &&
                    ![FstType.NEUTRALIZED, FstType.CANCELED, FstType.REUSE].includes(st.type)
                  "
                  class="modal-trip-modification__table__cancel margin-auto btn-display-on-hover"
                  size="x-small"
                  elevation="0"
                  prepend-icon="$deviateBlack"
                  @click="triggerActionOnStop(st, index, StopActionType.DISPLACE)"
                >
                  {{ $t('displace') }}
                </v-btn>
                <v-btn
                  v-if="
                    ![FstType.NEUTRALIZED, FstType.AD_HOC, FstType.REUSE, FstType.SHIFT].includes(st.type)
                  "
                  class="modal-trip-modification__table__cancel margin-auto btn-display-on-hover"
                  size="x-small"
                  elevation="0"
                  :prepend-icon="st.type === FstType.CANCELED ? 'fas fa-redo' : 'fas fa-times'"
                  @click="triggerActionOnStop(st, index, StopActionType.CANCEL)"
                >
                  {{ st.type === FstType.CANCELED ? $t('restore') : $t('cancel') }}
                </v-btn>
                <v-btn
                  v-if="FstType.SHIFT === st.type"
                  class="modal-trip-modification__table__restore margin-auto btn-display-on-hover"
                  size="x-small"
                  elevation="0"
                  prepend-icon="fas fas fa-redo"
                  @click="triggerActionOnStop(st, index, StopActionType.RESTORE)"
                >
                  {{ $t('restore') }}
                </v-btn>
                <v-btn
                  v-if="[FstType.AD_HOC, FstType.REUSE].includes(st.type)"
                  class="modal-trip-modification__table__delete margin-auto btn-display-on-hover"
                  size="x-small"
                  elevation="0"
                  prepend-icon="fas fa-trash"
                  @click="triggerActionOnStop(st, index, StopActionType.DELETE)"
                >
                  {{ $t('delete') }}
                </v-btn>
              </div>

              <div class="margin-auto" :class="delay && st.type !== FstType.NEUTRALIZED ? 'orange-icon' : ''">
                {{ getStopTimeHour(st, index) }}
              </div>
            </div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<style lang="scss">
.modal-trip-modification__table {
  overflow-y: auto;
  width: 50%;
  height: calc(
    96vh - 68px - 40px - 49px - 42px - 25px - 98px - 20px
  ); // 96vh because 2vh margin top&bottom, 68px = header, 40px = modifyService title, 49px = delay input, 42px = cancel trip, 25px = next days, 98px = footer btn, -20px bonus

  padding: 10px;
  border-radius: 5px 0;
  background-color: $canvas;

  /* Hide scrollbar on every bowsers */
  -ms-overflow-style: none;
  scrollbar-width: none;

  .v-timeline--vertical.v-timeline {
    row-gap: 3px;
  }

  .v-timeline {
    margin: 10px 0;
  }

  &__actions {
    display: flex;
    gap: 8px;

    button {
      cursor: pointer;
    }
  }

  &__timeline-line-feed {
    .v-timeline-item__body {
      width: 100%;
    }

    // Small icon fixes to make them appear more centered
    .v-timeline-divider {
      i {
        font-size: 16px;
      }

      i.notranslate:not(.fa-times, .fa-map-marker-alt, .fa-plus) {
        margin-left: -1px;
      }

      i.fa-map-marker-alt {
        margin-left: 1px;
      }
    }

    &:hover {
      .add-temporary-stop__btn {
        display: inline-grid;
      }
    }

    &__squared {
      .v-timeline-divider__dot,
      .v-timeline-divider__inner-dot {
        border-radius: 4px;
      }

      .v-timeline-divider__dot {
        width: 26px;
        height: 26px;
        transform: rotate(-45deg);

        i {
          margin-left: -1px;
          transform: rotate(45deg);
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__cancel,
  &__restore {
    border: 1px solid $border-variant;
    border-radius: 5px;
    background: $canvas;
    color: $text-dark-variant;
    font-size: 12px;

    i.fa-redo {
      font-size: 10px;
    }
  }

  &__delete {
    border-radius: 5px;
    background-color: $danger;
    color: $text-light;
    font-size: 12px;

    i {
      font-size: 12px;
    }

    &:hover {
      background-color: $danger-dark;
    }
  }

  &__neutralized {
    font-weight: $font-weight-semi-bold;
  }

  &__row {
    display: flex;

    &__add {
      display: block;
      width: 100%;
    }

    .disable-actions {
      opacity: 0.7;
      pointer-events: none;
    }

    &:hover {
      .modal-trip-modification__table__row__stop:not(.stop-neutralized) {
        background-color: $background-variant;
      }

      .btn-display-on-hover {
        opacity: 1;
      }
    }

    &__stop {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-left: -25px;
      padding: 24px 12px;
      border-radius: 0 10px 10px 0;
    }

    &__stop-name {
      @include multi-line-ellipsis($font-size: 14px, $line-height: 21px, $lines-to-show: 1);
    }
  }

  &__right-part {
    display: flex;
    flex-shrink: 0;
    gap: 10px;
  }

  .stop-name-deactivated {
    text-decoration: line-through;
  }

  .stop-name-deactivate-click {
    pointer-events: none;
  }

  // vuetify timeline "override"
  .v-timeline-divider__before,
  .v-timeline-divider__after {
    border-right: solid 1px black !important;
    border-left: solid 1px black !important;
  }

  // border is baddly implemented in default vuetify css
  .v-timeline-divider__dot {
    border: 1px solid $text-dark;
  }

  .v-timeline-divider__dot--size-small .v-timeline-divider__inner-dot {
    width: 100%;
    height: 100%;
  }
}

.stop-card {
  padding-top: 10px;

  .v-card {
    border-bottom-right-radius: 0;
    background-color: $canvas;
  }

  &__actions {
    position: absolute;
    right: 0;
    z-index: 100;
    display: flex;
    margin-top: -1px;
    padding: 0 8px 8px;
    border: 1px solid $border-variant;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    background-color: $canvas;
  }
}

@media screen and (max-width: 1300px) {
  .modal-trip-modification__table {
    max-width: calc(100% - 500px);
  }
}
</style>

<i18n locale="fr">
  {
    "neutralized": "Arrêt neutralisé",
    "displace": "Déplacer",
  }
</i18n>

<i18n locale="en">
  {
    "neutralized": "Stop neutralized",
    "displace": "Displace",
  }
</i18n>
