<script setup>
import { watch } from 'vue';
import { MapboxHelper } from '@/components/map/mapboxHelper';

const URGENCY_ICON = new URL(`../../assets/img/urgency-icon.png`, import.meta.url).href;
const URGENCY_SOURCE_ID = 'urgencySource';
const URGENCY_LAYER_ID = 'urgencyLayer';

const props = defineProps({
  /** @type {import('vue').Prop<mapboxgl.Map>} */
  map: {
    type: Object,
    required: true,
  },
  /** @type {import('vue').Prop<Array<GeoJSON.Feature<GeoJSON.Point>>>} */
  positionSource: {
    type: Object,
    required: true,
  },
});

const addUrgencyLayer = () => {
  MapboxHelper.addLayer(
    props.map,
    /** @type {mapboxgl.Layer} */ ({
      id: URGENCY_LAYER_ID,
      type: 'symbol',
      source: URGENCY_SOURCE_ID,
      layout: {
        'icon-image': 'urgencyIcon',
        'icon-size': 0.7,
        'icon-allow-overlap': true,
      },
    }),
  );
};

// Init source and layer
MapboxHelper.addImage(props.map, URGENCY_ICON, 'urgencyIcon');
MapboxHelper.createEmptySource(props.map, URGENCY_SOURCE_ID);
addUrgencyLayer();

// Update source when positionSource changes
watch(
  () => props.positionSource,
  () => {
    MapboxHelper.updateSource(props.map, URGENCY_SOURCE_ID, [props.positionSource]);
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <div class="mapbox-urgency-layer"></div>
</template>
