<template>
  <div class="stop-detailed-info-card">
    <div class="stop-detailed-info-card__title">
      <font-awesome-icon icon="fa-sign-hanging" />
      {{ stopInfo.stop_name }}
      <span v-if="stopInfo.parent_station" class="stop-detailed-info-card__parent-station">
        (
        <router-link
          :to="{
            name: GroupRoute.STOP_DETAILED,
            params: { groupId, stopId: stopInfo.parentStationId },
            query: { date: getISODate(date) },
          }"
        >
          {{ stopInfo.parentStationName }}
        </router-link>
        )
      </span>
    </div>
    <div class="stop-detailed-info-card__radius">
      {{ $t('stopZoneRadius') }}
      <input
        v-model="stopZoneRadius"
        type="number"
        class="stop-detailed-info-card__input"
        :disabled="!$store.getters.hasPermission(Permission.EDIT_GROUP_PARAMETERS)"
      />
      <Btn v-if="hasChanged" type="primary" smaller @click="save">
        <font-awesome-icon icon="fa-check" />
      </Btn>
      <Btn v-if="hasChanged" type="secondary" smaller @click="cancel">
        <font-awesome-icon icon="fa-xmark" class="stop-detailed-info-card__cancel-btn" />
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import { getISODate } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { Permission } from '@/auth';
import { ConfigTarget } from '../SettingsPage/SettingsMixin';

export default {
  name: 'StopDetailedInfoCard',

  components: {
    Btn,
  },

  props: {
    date: {
      type: Date,
      required: true,
    },

    groupId: {
      type: String,
      default: '',
    },

    /** @type {Vue.PropOptions<import('@/store/gtfs').Stop>} */
    stopInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      getISODate,
      GroupRoute,
      Permission,

      initialRadius: null,
      stopZoneRadius: null,
    };
  },

  computed: {
    hasChanged() {
      return this.stopZoneRadius !== this.initialRadius;
    },

    /** @return {{[stopId: string]: number}} */
    stopZoneRadiusExceptions() {
      return this.$store.getters.group.stop_distance_threshold_exceptions || {};
    },

    /** @return {number} */
    stopZoneRadiusDefault() {
      return this.$store.getters.group.stop_distance_threshold;
    },
  },

  watch: {
    stopZoneRadius() {
      this.$store.dispatch('groupPatchStateOnly', this.update());
    },
  },

  created() {
    this.stopZoneRadius = this.stopZoneRadiusExceptions[this.stopInfo.stop_id] || this.stopZoneRadiusDefault;
    this.initialRadius = this.stopZoneRadius;
  },

  methods: {
    cancel() {
      this.stopZoneRadius = this.initialRadius;
    },

    update() {
      const exceptions = { ...this.stopZoneRadiusExceptions };
      exceptions[this.stopInfo.stop_id] = this.stopZoneRadius;
      return { stop_distance_threshold_exceptions: exceptions };
    },

    async save() {
      await this.$store.dispatch('groupPatch', {
        target: ConfigTarget.DISTANCE_THRESHOLDS,
        updates: this.update(),
      });

      this.initialRadius = this.stopZoneRadius;
    },
  },
};
</script>

<style lang="scss">
.stop-detailed-info-card {
  padding: 10px;
  border: 1px solid $text-neutral;
  border-radius: 8px;
  background-color: $canvas;

  &__cancel-btn {
    width: 14px;
  }

  &__input {
    width: 45px;
    margin: 0 10px 0 5px;
    border: 1px solid $border;
    background-color: transparent;

    &:focus {
      border: none;
    }
  }

  &__parent-station {
    margin-left: 5px;
    font-weight: $font-weight-light;
    font-size: 14px;
  }

  &__radius {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 30px;
  }

  &__title {
    margin-bottom: 5px;
    color: $text-dark-variant;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
  }
}
</style>

<i18n locale="fr">
{
  "stopZoneRadius": "Rayon de la zone d'arrêt (m) :"
}
</i18n>

<i18n locale="en">
{
  "stopZoneRadius": "Stop zone radius (m):"
}
</i18n>
