<template>
  <div class="admin-driver-versions">
    <p class="hint">
      {{ $t('hint') }}
      <a href="https://devhints.io/semver">Règles semver</a>
    </p>
    <p>
      <u>Le gestionaire de version fonctionne avec cet ordre de priorité :</u>
      <br />
      - Les règles saisies dans le champ
      <b>déprécié</b>
      prévalent sur le reste.
      <br />
      - Ensuite on attribue les règles semver du status
      <b>à mettre à jour</b>
      <br />
      - Tout autre version ne correspondant à aucune règle restent dans le status
      <b>à jour</b>
    </p>
    {{ $t('to_update') }}
    <v-combobox v-model="toUpdate" class="combo" variant="solo" chips closable-chips multiple></v-combobox>
    <div v-if="toUpdateError" class="error-message">{{ toUpdateError }}</div>
    {{ $t('deprecated') }}
    <v-combobox v-model="deprecated" class="combo" variant="solo" chips closable-chips multiple></v-combobox>
    <div v-if="deprecatedError" class="error-message">{{ deprecatedError }}</div>
    <div class="admin-driver-versions__buttons">
      <Btn type="primary" @click="saveChanges">
        {{ $t('save') }}
      </Btn>
      <Btn type="secondary" @click="cancelChanges">
        {{ $t('cancel') }}
      </Btn>
    </div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div v-if="successMessage" class="success-message">
      {{ successMessage }}
    </div>
  </div>
</template>

<script>
import semver from 'semver';
import Btn from '@/components/ui/Btn.vue';
import { config } from '@/api.js';

/** @enum {string} */
export const UpdateType = {
  TO_UPDATE: 'toUpdate',
  DEPRECATED: 'deprecated',
};

export default {
  name: 'DriverVersions',
  components: {
    Btn,
  },
  emits: ['error'],

  data: () => ({
    toUpdate: [],
    deprecated: [],
    originalToUpdate: [],
    originalDeprecated: [],
    versions: null,
    toUpdateError: '',
    deprecatedError: '',
    errorMessage: '',
    successMessage: '',
  }),
  async created() {
    try {
      const versions = await config.getVersion();
      this.versions = versions;
      this.toUpdate = versions.semver.to_update;
      this.deprecated = versions.semver.deprecated;
      this.originalToUpdate = [...versions.semver.to_update];
      this.originalDeprecated = [...versions.semver.deprecated];
    } catch (error) {
      console.error('Erreur lors de la récupération des versions :', error);
    }
  },

  methods: {
    async validateSemver(values, type) {
      const invalidValues = values.filter(value => {
        const cleanedValue = semver.coerce(value);
        return !semver.valid(cleanedValue);
      });
      switch (type) {
        case UpdateType.TO_UPDATE:
          this.toUpdateError = invalidValues.length > 0 ? `Format invalide: ${invalidValues.join(', ')}` : '';
          break;
        case UpdateType.DEPRECATED:
          this.deprecatedError =
            invalidValues.length > 0 ? `Format invalide: ${invalidValues.join(', ')}` : '';
          break;
        default:
          break;
      }
    },

    async saveChanges() {
      this.resetErrors();
      const toUpdateChanged = JSON.stringify(this.toUpdate) !== JSON.stringify(this.originalToUpdate);
      const deprecatedChanged = JSON.stringify(this.deprecated) !== JSON.stringify(this.originalDeprecated);

      if (toUpdateChanged) {
        this.validateSemver(this.toUpdate, UpdateType.TO_UPDATE);
      }
      if (deprecatedChanged) {
        this.validateSemver(this.deprecated, UpdateType.DEPRECATED);
      }

      if (this.toUpdateError || this.deprecatedError) {
        return;
      }

      const updateaddedValue = this.toUpdate.find(value => !this.originalToUpdate.includes(value));
      const deprecaddedValue = this.deprecated.find(value => !this.originalDeprecated.includes(value));
      const newvalue = {
        deprecated: deprecaddedValue ? [deprecaddedValue] : null,
        to_update: updateaddedValue ? [updateaddedValue] : null,
      };

      const deprecatedSet = new Set(this.deprecated);
      const toUpdateSet = new Set(this.toUpdate);

      const payload = {
        deprecated: deprecatedSet.size > 0 ? Array.from(deprecatedSet) : null,
        to_update: toUpdateSet.size > 0 ? Array.from(toUpdateSet) : null,
      };
      try {
        await config.putVersions(payload);
        this.originalToUpdate = [...this.toUpdate];
        this.originalDeprecated = [...this.deprecated];
        this.successMessage = 'Les versions ont été mises à jour avec succès.';
        setTimeout(() => {
          this.successMessage = '';
        }, 5000);
      } catch (error) {
        if (error) {
          this.$emit('error');
          const errorMessageValues = [newvalue.deprecated, newvalue.to_update]
            .filter(value => value !== null)
            .flat()
            .join(', ');
          this.errorMessage = `Veuillez utiliser une syntaxe correcte. Valeurs ajoutées : ${errorMessageValues}`;
          console.error('Veuillez utiliser une syntaxe correcte :', error);
        }
      }
    },
    cancelChanges() {
      this.toUpdate = [...this.originalToUpdate];
      this.deprecated = [...this.originalDeprecated];
      this.resetErrors();
    },
    resetErrors() {
      this.toUpdateError = '';
      this.deprecatedError = '';
    },
  },
};
</script>

<style lang="scss">
.admin-driver-versions {
  margin-left: 36px;

  &__buttons {
    display: flex;
  }

  .hint {
    font-weight: 600;
  }

  .combo {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .v-field__field {
    padding-left: 10px;
  }

  .v-field-label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .v-combobox .v-field .v-text-field__prefix,
  .v-combobox .v-field .v-text-field__suffix,
  .v-combobox .v-field .v-field__input,
  .v-combobox .v-field.v-field {
    height: 100%;
  }

  .v-field__input > *:not(.v-combobox__selection) {
    border: none;
    box-shadow: none;
  }

  .error-message {
    margin-bottom: 40px;
    color: $danger;
  }

  .success-message {
    margin-bottom: 40px;
    color: $primary-dark;
  }

  .v-chip__close {
    border: none;
    background-color: inherit;
    cursor: pointer;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
  }
}
</style>
<i18n locale="fr">
  {
    "to_update": "Version à mettre à jour",
    "deprecated": "Versions dépréciés (obsolète)",
    "error": "Format invalide",
    "hint": "Veuillez utiliser la syntaxe suivante: MAJOR.MINOR.PATCH (type semver). Plus d'informations sur ce lien : ",
  }
  </i18n>

<i18n locale="en">
  {
    "to_update": "To update versions",
    "deprecated": "Deprecated Versions",
    "error": "Invalid format",
    "hint": "Please use the following syntax: MAJOR.MINOR.PATCH (type semver). More information on this link: ",
  }
  </i18n>
