/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import i18n from '@/i18n';

/** @module Gtfs */
import Api from '@/api';
import { dateGtfsFormatToObj, timestampFormatHHMM, timestampMidnight } from '@/libs/helpers/dates';
import loading from './loading';

const { t } = i18n.global;

const cache = {
  /** @member {Object.<string, Gtfs>} */
  gtfs: {},
};

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

/** @enum {string} */
export const LocationType = {
  STOP: '0',
  STATION: '1',
};

/**
 * Enum for service exception types.
 * @enum {number}
 */
export const ServiceExceptionType = {
  ADD: 1,
  REMOVE: 2,
};

/**
 * Add `gtfsId` property to cache or state.
 * @param {Object} obj - `cache` or `state`.
 * @param {string} gtfsId
 */
const addGtfsId = (obj, gtfsId) => {
  if (!obj.gtfs[gtfsId]) {
    obj.gtfs[gtfsId] = {};
  }
};

/**
 * Check if a calendar is valid on a date.
 * @param {Service} cal
 * @param {string} dateGtfs
 * @return {boolean}
 */
export const isCalendarValid = (cal, dateGtfs) => {
  const date = dateGtfsFormatToObj(dateGtfs);
  const startDate = dateGtfsFormatToObj(cal.start_date);
  const endDate = dateGtfsFormatToObj(cal.end_date);
  if (startDate <= date && date <= endDate) {
    if (cal[daysOfWeek[date.getDay()]] !== null && cal[daysOfWeek[date.getDay()]] !== undefined) {
      return true;
    }
  }

  return false;
};

/** @typedef {typeof state} State */
const state = {
  /** @type {{[gtfsId: string]: GtfsRefs}} */
  gtfs: {},

  /** @type {Array<Publication>} */
  publications: [],
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  getters: {
    /**
     * @template {keyof Gtfs} T
     * @callback GetCachedGtfsTableCallback
     * @param {string} gtfsId
     * @param {T} table
     * @return {Gtfs[T]}
     */
    /**
     * @template {keyof Gtfs} T
     * @return {GetCachedGtfsTableCallback<T>}
     */
    getCachedGtfsTable: state => (gtfsId, table) => {
      if (!state.gtfs[gtfsId] || !state.gtfs[gtfsId][table]) return {};
      const data = state.gtfs[gtfsId][table].reduce((acc, id) => {
        acc[id] = cache.gtfs[gtfsId][table][id];

        return acc;
      }, {});

      return Object.freeze(data);
    },

    /**
     * Get a GTFS id at a given timestamp.
     * (Assuming `state.publications` are sorted by descending `ts`.)
     * @callback GetGtfsAtCallback
     * @param {number} ts - timestamp in seconds.
     * @return {?string}
     */
    /** @return {GetGtfsAtCallback} */
    getGtfsAt: state => ts => {
      if (!state.loading.loading.publications) {
        const pub = state.publications.find(o => o.ts <= ts);
        if (pub) return pub.current_file;
      }
    },

    /**
     * Check if anything is still loading.
     * @return {boolean}
     */
    isLoading(state) {
      return Object.values(state.loading.loading).some(v => v);
    },
  },

  mutations: {
    clear(state) {
      state.publications = [];
      state.gtfs = {};
    },

    setCalendar(state, { gtfsId, calendar }) {
      addGtfsId(state, gtfsId);
      addGtfsId(cache, gtfsId);
      cache.gtfs[gtfsId].calendar = {};

      const calendarIds = [];
      calendar.forEach(service => {
        calendarIds.push(service.service_id);
        cache.gtfs[gtfsId].calendar[service.service_id] = service;
      });

      state.gtfs[gtfsId].calendar = calendarIds;
    },

    setCalendarDates(state, { gtfsId, calendarDates }) {
      addGtfsId(state, gtfsId);
      addGtfsId(cache, gtfsId);
      cache.gtfs[gtfsId].calendarDates = {};

      state.gtfs[gtfsId].calendarDates = [];
      const calendarIds = [];
      const calendarDate = {};
      calendarDates.forEach(date => {
        const id = date._id;
        calendarIds.push(id);
        calendarDate[id] = date;
      });
      state.gtfs[gtfsId].calendarDates = calendarIds;
      cache.gtfs[gtfsId].calendarDates = calendarDate;
    },

    setPublications(state, { publications }) {
      state.publications = publications;
    },

    setRoutes(state, { gtfsId, routes }) {
      addGtfsId(state, gtfsId);
      addGtfsId(cache, gtfsId);
      cache.gtfs[gtfsId].routes = {};

      const routesIds = [];
      routes.forEach(route => {
        routesIds.push(route.route_id);
        cache.gtfs[gtfsId].routes[route.route_id] = route;
      });
      state.gtfs[gtfsId].routes = routesIds;
    },

    setShapes(state, { gtfsId, shapes }) {
      addGtfsId(state, gtfsId);
      addGtfsId(cache, gtfsId);
      cache.gtfs[gtfsId].shapes = {};

      const shapesIds = [];
      shapes.forEach(shape => {
        shapesIds.push(shape.shape_id);
        cache.gtfs[gtfsId].shapes[shape.shape_id] = shape;
      });

      Object.freeze(cache.gtfs[gtfsId].shapes);
      Object.freeze(shapesIds);

      state.gtfs[gtfsId].shapes = shapesIds;
    },

    setStops(state, { gtfsId, stops }) {
      addGtfsId(state, gtfsId);
      addGtfsId(cache, gtfsId);
      cache.gtfs[gtfsId].stops = {};

      const stopsIds = [];
      stops.forEach(stop => {
        stopsIds.push(stop.stop_id);
        cache.gtfs[gtfsId].stops[stop.stop_id] = stop;
      });

      state.gtfs[gtfsId].stops = stopsIds;
    },

    setTrips(state, { gtfsId, trips }) {
      addGtfsId(state, gtfsId);
      addGtfsId(cache, gtfsId);
      cache.gtfs[gtfsId].trips = {};

      const tripsIds = [];
      if (Array.isArray(trips)) {
        trips.forEach(trip => {
          tripsIds.push(trip.trip_id);
          cache.gtfs[gtfsId].trips[trip.trip_id] = trip;
        });
      }

      Object.freeze(cache.gtfs[gtfsId].trips);
      Object.freeze(tripsIds);

      state.gtfs[gtfsId].trips = tripsIds;
    },
  },

  actions: {
    /**
     * Clear GTFS cache.
     */
    async clear({ commit, dispatch }) {
      await dispatch('loading/clear');
      commit('clear');
    },

    /**
     * @param context
     * @param {Object} payload
     * @param {?string} [payload.gtfsId]
     * @param {string} payload.tripId
     * @param {Date} payload.date
     * @return {Promise<string>}
     */
    async formatTripName({ dispatch }, { gtfsId = null, tripId, date }) {
      if (!gtfsId) {
        gtfsId = await dispatch('getGtfsAt', { ts: date.getTime() / 1000 });
      }
      if (!gtfsId) return;

      const group = await dispatch('getGroup', null, { root: true });
      if (!group) return;

      const format = group.driver_trip_format || '%dt - %th';
      const trips = await dispatch('getTripsMap', { gtfsId });
      if (!trips) return;

      /** @type {Trip} */
      const trip = trips[tripId];
      if (!trip) return t('tripNotFound');

      let name = format.replace(/%th/g, trip.trip_headsign || '-');
      name = name.replace(/%tn/g, trip.trip_short_name || '-');
      name = name.replace(/%bl/g, trip.block_id || '-');

      if (format.includes('%dt')) {
        let dt = '-';
        if (trip.stop_times && trip.stop_times.length > 0) {
          const midnight = timestampMidnight(date, group.tz);
          const startTime = midnight + (trip.stop_times[0].departure_time ?? trip.stop_times[0].arrival_time);
          if (startTime) {
            dt = timestampFormatHHMM(startTime, { tz: group.tz });
          }
        }

        name = name.replace(/%dt/g, dt);
      }

      const loadStops = (async () => {
        if (format.includes('%fs') || format.includes('%ls')) {
          let fs = '-';
          let ls = '-';

          if (trip.stop_times && trip.stop_times.length > 0) {
            const stops = await dispatch('getStopsMap', { gtfsId });
            const firstStop = stops[trip.stop_times[0].stop_id];
            if (firstStop) {
              fs = firstStop.stop_name;
            }

            const lastStop = stops[trip.stop_times[trip.stop_times.length - 1].stop_id];
            if (lastStop) {
              ls = lastStop.stop_name;
            }
          }

          name = name.replace(/%fs/g, fs);
          name = name.replace(/%ls/g, ls);
        }
      })();

      const loadRoutes = (async () => {
        if (format.includes('%rsn') || format.includes('%rln')) {
          const routes = await dispatch('getRoutesMap', { gtfsId });
          const route = routes[trip.route_id] || {};
          name = name.replace(/%rsn/g, route.route_short_name || '-');
          name = name.replace(/%rln/g, route.route_long_name || '-');
        }
      })();

      await Promise.all([loadStops, loadRoutes]);
      return name;
    },

    /**
     * Find GTFS calendar for a gtfsId.
     * @param context
     * @param {Object} payload
     * @param {string} payload.gtfsId
     * @return {Promise<Object.<string, Service>>}
     */
    async getCalendar({ state, commit, dispatch }, { gtfsId }) {
      const cached = state.gtfs[gtfsId];
      if (!cached || !cached.calendar) {
        await dispatch('loading/throttleLoading', {
          key: `calendar_${gtfsId}`,
          callback: async () => {
            const group = await dispatch('getGroup', null, { root: true });
            const calendar = await Api.gtfs.getCalendar(group._id, gtfsId);
            commit('setCalendar', { gtfsId, calendar });
          },
        });
      }

      return cache.gtfs[gtfsId].calendar;
    },

    /**
     * Find GTFS calendar exceptions for a gtfsId.
     * @param context
     * @param {Object} payload
     * @param {string} payload.gtfsId
     * @return {Promise<Array<ServiceException>>}
     */
    async getCalendarDates({ state, commit, dispatch }, { gtfsId }) {
      const cached = state.gtfs[gtfsId];
      if (!cached || !cached.calendarDates) {
        await dispatch('loading/throttleLoading', {
          key: `calendarDates_${gtfsId}`,
          callback: async () => {
            const group = await dispatch('getGroup', null, { root: true });
            const calendarDates = await Api.gtfs.getCalendarDates(group._id, gtfsId);
            commit('setCalendarDates', { gtfsId, calendarDates });
          },
        });
      }

      return state.gtfs[gtfsId].calendarDates.map(
        calendarDateId => cache.gtfs[gtfsId].calendarDates[calendarDateId],
      );
    },

    /**
     * Find gtfsId at given timestamp.
     * @param context
     * @param {Object} payload
     * @param {number} payload.ts - timestamp in seconds.
     * @return {Promise<string>}
     * @throw {Error} when not found.
     */
    async getGtfsAt({ dispatch }, { ts }) {
      const publications = await dispatch('getGtfsPublications');
      const gtfs = publications.find(o => o.ts <= ts);

      if (gtfs && Object.prototype.hasOwnProperty.call(gtfs, 'current_file')) return gtfs.current_file;
    },

    /**
     * Get GTFS publications.
     * Warning: `state.publications` will never update after loaded once.
     * @param context
     * @return {Promise<Array<Publication>>}
     */
    async getGtfsPublications({ state, commit, dispatch }) {
      if (state.publications.length > 0) return state.publications;

      await dispatch('loading/throttleLoading', {
        key: 'publications',
        callback: async () => {
          const group = await dispatch('getGroup', null, { root: true });
          const publications = await Api.gtfs.getGtfsPublications(group._id);
          publications.sort((a, b) => b.ts - a.ts);
          commit('setPublications', { publications });
        },
      });

      return state.publications;
    },

    /**
     * Find GTFS publications in interval.
     * @param context
     * @param {Object} payload
     * @param {number} payload.fromTs
     * @param {number} payload.toTs
     * @return {Promise<Array<Publication>>}
     */
    async getGtfsPublicationsIn({ dispatch }, { fromTs, toTs }) {
      const publications = await dispatch('getGtfsPublications');
      const filtered = publications.filter(p => fromTs < p.ts && p.ts < toTs);

      // Add gtfs already published at `fromTs`
      const prevPub = publications.find(p => p.ts <= fromTs);
      if (prevPub) {
        filtered.push(prevPub);
      }

      if (filtered.length === 0 && publications.length > 0) {
        // For simulations running in the past, we use the last publication.
        filtered.push(publications[0]);
      }

      return filtered;
    },

    /**
     * Find GTFS routes for a gtfsId or at a timestamp.
     * @deprecated use getRoutesMap instead
     *
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts]
     * @param {string} [payload.gtfsId]
     * @return {Promise<Array<Route>>}
     * @throw {Error} When no gtfsId or ts are given.
     */
    async getRoutes({ dispatch }, payload = {}) {
      console.warn('store.gtfs.getRoutes is deprecated, use store.gtfs.getRoutesMap');
      return Object.values(await dispatch('getRoutesMap', payload));
    },

    /**
     * Find GTFS routes for a gtfsId or at a timestamp.
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts] - timestamp in seconds.
     * @param {string} [payload.gtfsId]
     * @return {Promise<Object.<string, Route>>}
     * @throw {Error} When no gtfsId or ts are given.
     */
    async getRoutesMap({ state, commit, dispatch }, { ts, gtfsId } = {}) {
      if (!gtfsId) {
        if (ts == null) {
          ts = Date.now() / 1000;
        }
        gtfsId = await dispatch('getGtfsAt', { ts });
      }

      if (gtfsId) {
        const cached = state.gtfs[gtfsId];
        if (!cached || !cached.routes) {
          await dispatch('loading/throttleLoading', {
            key: `routes_${gtfsId}`,
            callback: async () => {
              const group = await dispatch('getGroup', null, { root: true });
              const routes = await Api.gtfs.getRoutes(group._id, gtfsId);
              commit('setRoutes', { gtfsId, routes });
            },
          });
        }

        return cache.gtfs[gtfsId].routes;
      }
    },

    /**
     * Get active services.
     * @deprecated Use `store.gtfs.getServicesMap` instead.
     *
     * @param context
     * @param {Object} payload
     * @param {string} payload.gtfsId
     * @param {string} payload.dateGtfs
     * @return {Promise<Array<string>>} containing each active `serviceId`.
     */
    async getServices({ dispatch }, payload) {
      console.warn('store.gtfs.getServices is deprecated, use store.gtfs.getServicesMap');
      return Object.keys(await dispatch('getServicesMap', payload));
    },

    /**
     * Get active services.
     * @param context
     * @param {Object} payload
     * @param {string} payload.gtfsId
     * @param {string} payload.dateGtfs
     * @return {Promise<Object.<string, boolean>>} containing each active `serviceId`.
     */
    async getServicesMap({ dispatch }, { gtfsId, dateGtfs }) {
      const [calendar, calendarDates] = await Promise.all([
        dispatch('getCalendar', { gtfsId }),
        dispatch('getCalendarDates', { gtfsId }),
      ]);

      const services = /** @type {Object.<string, boolean>} */ ({});
      const calendarArray = Object.values(calendar);
      calendarArray
        .filter(c => isCalendarValid(c, dateGtfs))
        .forEach(s => {
          services[s.service_id] = true;
        });

      calendarDates
        .filter(e => e.date === dateGtfs)
        .forEach(e => {
          if (e.exception_type === ServiceExceptionType.ADD) {
            services[e.service_id] = true;
          } else if (e.exception_type === ServiceExceptionType.REMOVE) {
            delete services[e.service_id];
          }
        });

      return services;
    },

    /**
     * Find GTFS shapes for a gtfsId or at a timestamp. By default, get current gtfs.
     * @deprecated use getShapesMap instead
     *
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts]
     * @param {string} [payload.gtfsId]
     * @return {Promise<Array<Shape>>}
     */
    async getShapes({ dispatch }, payload = {}) {
      console.warn('store.gtfs.getShapes is deprecated, use store.gtfs.getShapesMap');
      return Object.values(await dispatch('getShapesMap', payload));
    },

    /**
     * Find GTFS shapes for a gtfsId or at a timestamp. By default, get current gtfs.
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts] - timestamp in seconds.
     * @param {string} [payload.gtfsId]
     * @return {Promise<Object.<string, Shape>>}
     */
    async getShapesMap({ state, commit, dispatch }, { ts, gtfsId } = {}) {
      if (!gtfsId) {
        if (ts == null) {
          ts = Date.now() / 1000;
        }
        gtfsId = await dispatch('getGtfsAt', { ts });
      }

      const cached = state.gtfs[gtfsId];
      if (!cached || !cached.shapes) {
        await dispatch('loading/throttleLoading', {
          key: `shapes_${gtfsId}`,
          callback: async () => {
            const group = await dispatch('getGroup', null, { root: true });
            const shapes = await Api.gtfs.getShapes(group._id, gtfsId);
            commit('setShapes', { gtfsId, shapes });
          },
        });
      }

      return cache.gtfs[gtfsId].shapes;
    },

    /**
     * Find GTFS stops for a gtfsId or at a timestamp. By default, get current gtfs.
     * @deprecated use getStopsMap instead
     *
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts]
     * @param {string} [payload.gtfsId]
     * @return {Promise<Array<Stop>>}
     */
    async getStops({ dispatch }, payload = {}) {
      console.warn('store.gtfs.getStops is deprecated, use store.gtfs.getStopsMap');
      return Object.values(await dispatch('getStopsMap', payload));
    },

    /**
     * Find GTFS stops for a gtfsId or at a timestamp. By default, get current gtfs.
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts] - timestamp in seconds.
     * @param {string} [payload.gtfsId]
     * @return {Promise<Object.<string, Stop>>} Map<StopId, Stop>
     */
    async getStopsMap({ state, commit, dispatch }, { ts, gtfsId } = {}) {
      if (!gtfsId) {
        if (ts == null) {
          ts = Date.now() / 1000;
        }
        gtfsId = await dispatch('getGtfsAt', { ts });
      }

      const cached = state.gtfs[gtfsId];
      if (!cached || !cached.stops) {
        await dispatch('loading/throttleLoading', {
          key: `stops_${gtfsId}`,
          callback: async () => {
            const group = await dispatch('getGroup', null, { root: true });
            const stops = await Api.gtfs.getStops(group._id, gtfsId);
            commit('setStops', { gtfsId, stops });
          },
        });
      }

      return cache.gtfs[gtfsId].stops;
    },

    /**
     * Find GTFS trips for a gtfsId or at a timestamp. By default, get current gtfs.
     * @deprecated use getTripsMap instead
     *
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts]
     * @param {string} [payload.gtfsId]
     * @return {Promise<Array<Trip>>}
     */
    async getTrips({ dispatch }, payload = {}) {
      console.warn('store.gtfs.geTrips is deprecated, use store.gtfs.getTripsMap');
      return Object.values(await dispatch('getTripsMap', payload));
    },

    /**
     * Find GTFS trips for a gtfsId or at a timestamp. By default, get current gtfs.
     * @param context
     * @param {Object} [payload]
     * @param {number} [payload.ts] - timestamp in seconds.
     * @param {string} [payload.gtfsId]
     * @return {Promise<?Object<string, Trip>>}
     */
    async getTripsMap({ state, commit, dispatch }, { ts, gtfsId } = {}) {
      if (!gtfsId) {
        if (ts == null) {
          ts = Date.now() / 1000;
        }
        gtfsId = await dispatch('getGtfsAt', { ts });
      }

      if (gtfsId) {
        const cached = state.gtfs[gtfsId];
        if (!cached || !cached.trips) {
          await dispatch('loading/throttleLoading', {
            key: `trips_${gtfsId}`,
            callback: async () => {
              const group = await dispatch('getGroup', null, { root: true });
              const trips = await Api.gtfs.getTrips(group._id, gtfsId);
              commit('setTrips', { gtfsId, trips });
            },
          });
        }

        return cache.gtfs[gtfsId].trips;
      }
    },
  },

  modules: {
    loading,
  },
});

/**
 * @typedef {Object} Gtfs
 * @property {{[serviceId: string]: Service}} calendar
 * @property {{[serviceId: string]: ServiceException}} calendarDates
 * @property {{[routeId: string]: Route}} routes
 * @property {{[shapeId: string]: Shape}} shapes
 * @property {{[stopId: string]: Stop}} stops
 * @property {{[tripId: string]: Trip}} trips
 */

/** @typedef {{[table in keyof Gtfs]: Array<string>}} GtfsRefs */

/**
 * Gtfs publication.
 * @typedef {Object} Publication
 * @property {string} current_file - Gtfs id.
 * @property {number} ts - Timestamp in seconds.
 * @property {PublicationType} type
 * @property {string} user_id
 */

/** @enum {string} */
export const PublicationType = {
  INSTANT: 'instant',
  PLANNED: 'planned',
};

/**
 * @typedef {Object} Route
 * @property {string} route_id
 * @property {string} route_long_name
 * @property {string} route_short_name
 * @property {number} route_type
 * @property {string} [agency_id]
 * @property {string} [route_color]
 * @property {string} [route_text_color]
 * @property {boolean} [is_deactivated]
 * @property {boolean} [is_private]
 */

/**
 * @typedef {Object} Service
 * @property {string} service_id
 * @property {string} start_date
 * @property {string} end_date
 * @property {boolean} monday
 * @property {boolean} tuesday
 * @property {boolean} wednesday
 * @property {boolean} thursday
 * @property {boolean} friday
 * @property {boolean} saturday
 * @property {boolean} sunday
 */

/**
 * @typedef {Object} ServiceException
 * @property {string} _id - Unique id.
 * @property {string} service_id
 * @property {ServiceExceptionType} exception_type
 * @property {string} date
 */

/**
 * @typedef {Object} Shape
 * @property {string} shape_id
 * @property {GeoJSON.LineString} geometry
 * @property {Array<string>} routes
 * @property {string} source
 */

/**
 * @typedef {Object} Stop
 * @property {string} stop_id
 * @property {number} stop_lat
 * @property {number} stop_lon
 * @property {string} stop_name
 * @property {string} [stop_code]
 * @property {LocationType} [location_type]
 * @property {string} [parent_station]
 */

/**
 * @typedef {Object} StopTime
 * @property {string} stop_id
 * @property {number} stop_sequence
 * @property {number} arrival_time
 * @property {number} departure_time
 * @property {number} pickup_type
 * @property {number} [shape_dist_traveled]
 */

/**
 * @typedef {Object} Trip
 * @property {string} trip_id
 * @property {string} route_id
 * @property {string} service_id
 * @property {Array<StopTime>} stop_times
 * @property {string} [block_id]
 * @property {number} [direction_id]
 * @property {string} [shape_id]
 * @property {string} [team_id]
 * @property {string} [trip_headsign]
 * @property {string} [trip_short_name]
 */
