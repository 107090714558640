<template>
  <div class="datagrid-no-data">
    <div class="datagrid-no-data__message">{{ $t('datagridNoData', [title]) }}</div>
    <Btn type="primary" @click="$emit('noDataActionTriggered')">
      <font-awesome-icon icon="fa-plus" />
      <span>{{ $t('add') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'DataGridNoData',

  components: {
    Btn,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
  },

  emits: ['noDataActionTriggered'],
};
</script>

<style lang="scss" scoped>
.datagrid-no-data {
  display: flex;
  flex-direction: column;
  height: 10em;
  margin: 25px 30px;
  padding: 15px 15px 15px 14em;
  background-image: url('@/assets/img/datagrid-no-data.png');
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;

  &__message {
    margin-bottom: 1em;
    color: $text-dark-variant;
    font-weight: $font-weight-semi-bold;
    font-size: 24px;
    text-align: left;
  }

  button {
    width: 150px;
  }
}
</style>

<i18n locale="fr">
  {
    "datagridNoData": "Aucun {0} pour le moment..."
  }
  </i18n>

<i18n locale="en">
  {
    "datagridNoData": "No {0} for the moment..."
  }
</i18n>
