<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { GroupRoute } from '@/libs/routing';
import { useMessage } from '@/use/use-message';

import Btn from '@/components/ui/Btn.vue';
import ModalMessageNew from '@/components/ui/ModalMessageNew.vue';

const { t } = useI18n({});
const store = useStore();

const { modalMessageNew, handleSent, showModalMessageNew, closeModalMessageNew } = useMessage();

defineProps({
  gtfsName: {
    type: String,
    default: '',
  },
  hasGtfs: {
    type: Boolean,
    default: false,
  },
  isGtfsLoading: {
    type: Boolean,
    default: false,
  },
});

/** @return {import('@/store').Group} */
const group = computed(() => {
  return store.getters.group;
});

const nbUnreadMessages = computed(() => {
  return store.getters['messages/nbUnreadMessages'];
});
</script>

<template>
  <div class="dashboard-header ga-3 d-flex align-center px-4 flex-wrap">
    <div class="dashboard-header__message">
      {{ t('dashboard.welcome') }}
      <span class="dashboard-header__highlight">{{ group.name }}</span>
      👋
    </div>
    <Btn v-if="!isGtfsLoading && !hasGtfs" :route="{ name: GroupRoute.TRANSPORT_PLAN_LIST }" type="primary">
      <v-icon size="x-small">fa:fas fa-plus</v-icon>
      {{ t('dashboard.addTransportPlan') }}
    </Btn>
    <Btn
      v-else
      :loading="isGtfsLoading"
      small
      outlined
      type="round"
      :route="{ name: GroupRoute.TRANSPORT_PLAN_LIST }"
    >
      <v-icon size="x-small">fa:fas fa-toolbox</v-icon>
      <span class="mx-3">{{ gtfsName }}</span>
      <v-icon class="dashboard-header__arrow">fa:fas fa-arrow-right</v-icon>
    </Btn>
    <Btn
      small
      outlined
      type="round"
      class="dashboard-header__message-btn"
      :route="{ name: GroupRoute.MESSAGE_INBOX }"
    >
      <v-icon size="x-small" class="dashboard-header__icon">fa:fas fa-envelope</v-icon>
      <span v-if="nbUnreadMessages > 0" class="dashboard-header__message-counter">
        {{ nbUnreadMessages }}
      </span>
    </Btn>
    <Btn small outlined type="round" @click="showModalMessageNew()">
      <v-icon size="x-small" class="dashboard-header__icon">fa:fas fa-paper-plane</v-icon>
    </Btn>
    <ModalMessageNew
      v-if="modalMessageNew.shown"
      :recipients="modalMessageNew.recipients"
      @sent="handleSent"
      @close="closeModalMessageNew"
    />
  </div>
</template>

<style lang="scss">
.dashboard-header {
  padding: $view-standard-padding;
  border-bottom: 1px solid $border;
  color: $text-dark;
  font-size: 18px;

  &__arrow {
    padding-bottom: 2px;
    color: $text-dark-variant;
    font-size: 12px;
  }

  &__icon {
    padding: 12px 0;
    font-size: 14px;
  }

  &__highlight {
    font-weight: $font-weight-semi-bold;
  }

  &__message {
    margin-right: 10px;
    margin-left: 2px;
  }

  &__message-btn {
    position: relative;
    display: inline-block;
  }

  &__message-counter {
    position: absolute;
    top: -4px;
    min-width: 1.6em;
    padding: 1px 3px;
    border-radius: 10px;
    background-color: $danger;
    color: $text-light;
    font-size: 0.6em;
    text-align: center;
  }
}
</style>
