<template>
  <div>
    <div class="form-group">
      <label for="incoming_distance_threshold">
        <span class="form-group__label">{{ $t('incoming_distance_threshold') }}</span>
        <p class="form-group__sub-label">
          {{ $t('incoming_distance_threshold-help') }}
        </p>
      </label>
      <input
        id="incoming_distance_threshold"
        v-model.number="values.incoming_distance_threshold"
        class="form-group__input"
        name="incoming_distance_threshold"
        type="number"
        :placeholder="defaults.incoming_distance_threshold.toString()"
      />
    </div>

    <div class="form-group">
      <label for="shape_distance_threshold">
        <span class="form-group__label">{{ $t('shape_distance_threshold') }}</span>
        <p class="form-group__sub-label">
          {{ $t('shape_distance_threshold-help') }}
        </p>
      </label>
      <input
        id="shape_distance_threshold"
        v-model.number="values.shape_distance_threshold"
        class="form-group__input"
        name="shape_distance_threshold"
        type="number"
        :placeholder="defaults.shape_distance_threshold.toString()"
      />
    </div>
    <div class="form-group">
      <div>
        <span class="form-group__label">{{ $t('stopZones') }}</span>
        <p class="form-group__sub-label">
          {{ $t('stopZones-help') }}
        </p>
      </div>

      <table class="table">
        <thead class="table__head">
          <tr class="table__row">
            <th class="table__head-cell">
              {{ $t('stopId') }}
            </th>

            <th class="table__head-cell">
              {{ $t('radius') }}
            </th>

            <th class="table__head-cell" />
          </tr>
        </thead>

        <tbody>
          <tr class="table__row">
            <td class="table__cell">
              {{ $t('defaultValue') }}
            </td>

            <td class="table__cell">
              <input
                v-model.number="values.stop_distance_threshold"
                class="form-group__input"
                :placeholder="defaults.stop_distance_threshold.toString()"
                type="number"
              />
            </td>

            <td class="table__cell" />
          </tr>

          <tr v-for="(exception, index) in exceptions" :key="index" class="table__row">
            <td class="table__cell">
              <input
                v-model="exception[0]"
                class="form-group__input"
                :class="{ 'form-group__input--invalid': duplicateIds[exception[0]] }"
                required
                type="text"
              />
            </td>

            <td class="table__cell">
              <input v-model.number="exception[1]" class="form-group__input" required type="number" />
            </td>

            <td class="table__cell">
              <button class="btn btn-small" @click="removeException(index)" @mousedown.prevent>
                <font-awesome-icon icon="fa-trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="input-group">
        <div class="btn-group">
          <Btn type="secondary" @click="addException">
            <font-awesome-icon icon="fa-plus" />
            <span>{{ $t('add') }}</span>
          </Btn>
        </div>
      </div>
    </div>

    <Btn type="primary" :disabled="!hasChanged || hasError" @click="saveInfos">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import { groupDefaults } from '@/store';

import SettingsMixin, { ConfigTarget } from './SettingsMixin';

export default {
  name: 'StopZones',
  components: { Btn },
  mixins: [SettingsMixin],

  data: () => ({
    defaults: groupDefaults,
    exceptions: [],
  }),

  computed: {
    /** @return {{[stopId: string]: boolean}} */
    duplicateIds() {
      /** @type {Map<string, Array<number>>} */
      const duplicates = new Map();

      this.exceptions.forEach(([stopId, _], index) => {
        if (duplicates.has(stopId)) {
          duplicates.get(stopId).push(index);
        } else {
          duplicates.set(stopId, [index]);
        }
      });

      return [...duplicates.entries()].reduce((acc, [stopId, indexes]) => {
        if (indexes.length > 1) {
          acc[stopId] = true;
        }

        return acc;
      }, /** @type {{[stopId: string]: boolean}} */ ({}));
    },

    /** @return {boolean} */
    hasError() {
      return Object.entries(this.values.stop_distance_threshold_exceptions).some(
        ([stopId, radius]) => !stopId || !radius || this.duplicateIds[stopId],
      );
    },
  },

  watch: {
    exceptions: {
      /** @param {Array<[string, number]>} */
      handler() {
        const value = this.exceptions.reduce((acc, [stopId, radius]) => {
          acc[stopId] = radius;

          return acc;
        }, /** @type {{[stopId: string]: number}} */ ({}));

        this.values.stop_distance_threshold_exceptions = value;
      },
      deep: true,
    },
  },

  created() {
    const unwatch = this.$watch(
      'values.stop_distance_threshold_exceptions',
      function stopDistanceThresholdExceptions() {
        this.$nextTick(() => {
          unwatch();
          this.exceptions = Object.entries(this.values.stop_distance_threshold_exceptions);
        });
      },
      { immediate: true },
    );
  },

  methods: {
    /**
     * Returns default values
     *
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        /** @type {number} */
        stop_distance_threshold: null,
        /** @type {{[stopId: string]: number}} */
        stop_distance_threshold_exceptions: {},
        /** @type {number} */
        incoming_distance_threshold: null,
        /** @type {number} */
        shape_distance_threshold: null,
      };
    },

    addException() {
      this.exceptions.push(['', null]);
    },

    /** @param {number} index */
    removeException(index) {
      this.exceptions.splice(index, 1);
    },
    saveInfos() {
      this.save(ConfigTarget.DISTANCE_THRESHOLDS);
    },
  },
};
</script>

<i18n locale="fr">
{
  "add": "Ajouter",
  "defaultValue": "Valeur par défaut",
  "radius": "Rayon (m)",
  "stopId": "Identifiant d’arrêt",
  "stopZones": "Rayon du tampon des arrêts",
  "stopZones-help": "Taille de la zone dans laquelle un véhicule est considéré comme à l'arrêt.",
  "incoming_distance_threshold": "Distance d'approche (m)",
  "incoming_distance_threshold-help": "Distance d'un véhicule en amont d'un arrêt à partir de laquelle il est considéré à l'approche.",
  "shape_distance_threshold": "Distance d'accrochage aux tracés (m)",
  "shape_distance_threshold-help": "Distance maximum entre un véhicule et un tracé pour que ce dernier soit pris en compte par l'algorithme de détection automatique de course.",
}
</i18n>

<i18n locale="en">
{
  "add": "Add",
  "defaultValue": "Default value",
  "radius": "Radius (m)",
  "stopId": "Stop id",
  "stopZones": "Stop zone radius",
  "stopZones-help": "Radius in which vehicle is considered at stop.",
  "incoming_distance_threshold": "Incoming distance threshold (m)",
  "incoming_distance_threshold-help": "Upstream vehicle distance to consider it as incoming.",
  "shape_distance_threshold": "Shape distance threshold (m)",
  "shape_distance_threshold-help": "Maximum distance between vehicle and shape to consider it in automatic trip detection algorithm.",
}
</i18n>
