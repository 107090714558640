import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  NO_DATA,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { GroupRoute } from '@/libs/routing';

const DropListCell = defineAsyncComponent(() => import('@/components/Table/cells/DropListCell.vue'));
const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const DefaultCell = defineAsyncComponent(() => import('@/components/Table/cells/DefaultCell.vue'));
const Tag = defineAsyncComponent(() => import('@/components/ui/Tag.vue'));

const DutyMultiTextCell = defineAsyncComponent(() => import('./cells/DutyMultiTextCell.vue'));
const DutyMultiBooleanCell = defineAsyncComponent(() => import('./cells/DutyMultiBooleanCell.vue'));
const DutyMultiTagsCell = defineAsyncComponent(() => import('./cells/DutyMultiTagsCell.vue'));
export const DUTY_LS_COLUMNS = 'dutyList/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  STATUS: 'status',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  DRIVER: 'driver',
  VEHICLE: 'vehicle',
  DEVICE: 'device',
  TRIPS: 'tripsFormatted',
  COMMENT: 'comments',
  EMPTY_VEHICLE: 'vehicleEmptyLabels',
  CLEANINGNESS: 'vehicleCleaningnessLabels',
  FUEL_LEVEL: 'vehicleFuelLevelLabels',
};

/** @enum {string} */
export const DutyStatus = {
  IN_PROGRESS: 'inProgress',
  OVER: 'over',
};

export const STATUSES = {
  [DutyStatus.IN_PROGRESS]: {
    color: '#b3b3b3', // $text-neutral
    background: '#EBEBEB',
  },

  [DutyStatus.OVER]: {
    color: '#00b871', // $primary-light
    background: '#E6F7F1', // $transparent-primary
  },
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(DUTY_LS_COLUMNS));

  // Helpers
  const defaultSelected = key => !ls || ls.includes(key);

  return new DataGrid({
    name: 'dutyListDatagrid',
    searchFields: ['driver', 'vehicle'],
    defaultSortBy: [{ key: ColumnKey.START_TIME, order: SortOrder.DESC }],
    columnSelectionLocalStorageKey: DUTY_LS_COLUMNS,
    hasActions: false,

    columns: [
      // STATUS
      new DataGridColumn({
        key: ColumnKey.STATUS,
        title: 'column.status',
        defaultSelected: defaultSelected(ColumnKey.STATUS),
        selectable: false,
        filterable: true,
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: Tag,
            props: {
              text: value,
              color: STATUSES[object.unformattedStatus].color,
              background: STATUSES[object.unformattedStatus].background,
            },
            value,
          };
        },
      }),

      // START TIME
      new DataGridColumn({
        key: ColumnKey.START_TIME,
        title: 'column.startTime',
        defaultSelected: defaultSelected(ColumnKey.START_TIME),
        selectable: false,
      }),

      // END TIME
      new DataGridColumn({
        key: ColumnKey.END_TIME,
        title: 'column.endTime',
        defaultSelected: defaultSelected(ColumnKey.END_TIME),
      }),

      // DRIVER
      new DataGridColumn({
        key: ColumnKey.DRIVER,
        title: 'column.driver',
        defaultSelected: defaultSelected(ColumnKey.DRIVER),
        filterable: true,
        sortable: false,
      }),

      // VEHICLE
      new DataGridColumn({
        key: ColumnKey.VEHICLE,
        title: 'column.vehicle',
        defaultSelected: defaultSelected(ColumnKey.VEHICLE),
        filterable: true,
        sortable: false,
      }),

      // DEVICE
      new DataGridColumn({
        key: ColumnKey.DEVICE,
        selectable: false,
        filterable: true,
        sortable: false,
        title: 'column.device',
        defaultSelected: true,
        cellBuilder([value, object]) {
          return {
            component: ClickableCell,
            props: {
              displayValue: value,
              link: {
                name: GroupRoute.DEVICE_DETAILLED,
                params: { deviceId: object.device_id },
              },
            },
            value,
          };
        },
      }),

      // TRIPS
      new DataGridColumn({
        key: ColumnKey.TRIPS,
        title: 'column.trips',
        defaultSelected: true,
        cellBuilder([value, object]) {
          if (object.tripsWithLinks.length > 0) {
            return {
              component: DropListCell,
              props: { values: object.tripsWithLinks },
              value,
            };
          }
          return { component: DefaultCell, props: { value: NO_DATA }, value: NO_DATA };
        },
      }),

      // EMPTY VEHICLE
      new DataGridColumn({
        key: ColumnKey.EMPTY_VEHICLE,
        title: 'column.checkEmptyVehicle',
        defaultSelected: defaultSelected(ColumnKey.EMPTY_VEHICLE),
        filterable: true,
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: DutyMultiBooleanCell,
            props: { values: object.vehicleEmpty },
          };
        },
      }),

      // CLEANINGNESS
      new DataGridColumn({
        key: ColumnKey.CLEANINGNESS,
        title: 'column.checkCleaningness',
        defaultSelected: defaultSelected(ColumnKey.CLEANINGNESS),
        filterable: true,
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: DutyMultiTagsCell,
            props: { values: object.vehicleCleaningness },
          };
        },
      }),

      // FUEL LEVEL
      new DataGridColumn({
        key: ColumnKey.FUEL_LEVEL,
        title: 'column.checkFuel',
        defaultSelected: defaultSelected(ColumnKey.FUEL_LEVEL),
        filterable: true,
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: DutyMultiTagsCell,
            props: { values: object.vehicleFuelLevel },
          };
        },
      }),

      // COMMENT
      new DataGridColumn({
        key: ColumnKey.COMMENT,
        title: 'column.driverComment',
        defaultSelected: defaultSelected(ColumnKey.COMMENT),
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: DutyMultiTextCell,
            props: { values: value },
          };
        },
      }),
    ],
  });
};
