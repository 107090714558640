<template>
  <div class="tt-dashboard">
    <!-- Selected points -->
    <div class="tt-dashboard__points" :class="{ 'tt-dashboard__points--nopoint': points.length === 0 }">
      <div class="tt-dashboard__points-list">
        <div
          v-for="(processPoint, index) in [$t(`process.0`), $t(`process.1`)]"
          :key="index"
          class="tt-dashboard__points-item"
        >
          <span class="point-number">{{ index + 1 }}</span>
          <span v-if="!points[index]" class="tt-dashboard__point-missing">{{ processPoint }}</span>
          <span v-else class="tt-dashboard__point-unset">
            <span class="point-text">
              {{ $t('point') }} {{ index + 1 }}
              <span v-if="points[index].stopName">
                {{ ` : ${points[index].stopName}` }}
              </span>
            </span>

            <button @click="unsetPoint(index)">
              <font-awesome-icon icon="fa-trash" />
            </button>
          </span>
        </div>
      </div>
      <div class="tt-dashboard__points-reset" @click="reset">{{ $t('reset') }}</div>
    </div>

    <!-- Speed and radius -->
    <div class="tt-dashboard__options">
      <div class="form-group">
        <label for="minimum-speed">{{ $t('speed') }}</label>
        <input id="minimum-speed" v-model="optionsModel.speed" type="number" name="minimum-speed" />
      </div>
      <div class="form-group">
        <label for="radius">{{ $t('radius') }}</label>
        <input id="radius" v-model="optionsModel.radius" type="number" name="radius" />
      </div>
    </div>
  </div>
</template>

<script>
export const DEFAULT_OPTIONS = {
  radius: 50,
  speed: 10,
};

export default {
  name: 'TravelTimeMapOptions',

  props: {
    /** @type {import('vue').Prop<TravelTimeOptions>} */
    options: {
      type: Object,
      required: true,
    },
    /** @type {import('vue').Prop<Array<import('./TravelTimeMap.vue').SelectedPoint>>} */
    points: {
      required: true,
      type: Array,
    },
  },

  emits: ['update:options', 'update:points'],

  computed: {
    optionsModel: {
      /** @return {TravelTimeOptions} */
      get() {
        return this.$props.options;
      },
      set(options) {
        this.$emit('update:options', options);
      },
    },
  },

  methods: {
    reset() {
      this.$emit('update:points', []);

      // // Queue this after `points` are updated
      this.$nextTick(() => {
        this.$emit('update:options', { ...DEFAULT_OPTIONS });
      });
    },

    /**
     * @param {number} index
     */
    unsetPoint(index) {
      const updatedPoints = [...this.points]; // avoid mutating prop directly
      updatedPoints.splice(index, 1);
      this.$emit('update:points', updatedPoints);
    },
  },
};

/**
 * @typedef {Object} TravelTimeOptions
 * @property {number} radius
 * @property {number} speed
 */
</script>

<style scoped lang="scss">
.tt-dashboard {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: $view-standard-padding;
  padding: 10px;
  border: 1px solid $border;
  border-radius: 5px;
  background-color: $canvas;
  font-size: 12px;

  &__options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;

    .form-group {
      display: flex;
      margin: 0 0 5px auto;
      color: $text-dark-variant;

      input {
        width: 50px;
        height: 20px;
        border: 1px solid $border;
        color: $text-dark-variant;
        font-family: $font-poppins;
      }

      label {
        margin-right: 5px;
      }
    }
  }

  &__points {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 15px;

    &--nopoint {
      color: $text-neutral;
    }
  }

  &__points-list {
    .point-number {
      display: inline-block;
      width: 10px;
      margin-right: 15px;
      color: $text-neutral;
      text-align: center;
    }
  }

  &__points-item {
    margin-bottom: 5px;
  }

  &__point-unset {
    .point-text {
      display: inline-block;
      min-width: 40px;
    }

    button {
      display: inline-block;
      width: 20px;
      margin-left: 10px;
      padding: 0;
      border: 1px solid $border;
      border-radius: 5px;
      background-color: $canvas;

      .fa-trash {
        color: $text-dark-variant;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }

  &__points-reset {
    margin: 10px auto 0 25px;
    margin-left: 25px;
    font-weight: $font-weight-semi-bold;
    cursor: pointer;
  }
}
</style>

<i18n locale="fr">
{
  "radius": "Rayon (m) :",
  "speed": "Vitesse minimale (km/h) :",
  "process": {
    "0": "Choisissez un 1er point",
    "1": "Choisissez un 2nd point"
  },
  "reset": "Réinitialiser",
  "point": "Point",
  
}
</i18n>

<i18n locale="en">
{
  "radius": "Radius(m) :",
  "speed": "Minimum speed (km/h) :",
  "process": {
    "0": "Choose the first point",
    "1": "Choose the second point"
  },
  "reset": "Reload",
  "point": "Point",
}
</i18n>

<i18n locale="cz">
{
  "speed": "Minimální rychlost (km/h):",
  "process": {
    "0": "Vyberte první bod (zastávku nebo bod v grafu)",
    "1": "Vyberte druhý bod (zastávku nebo bod v grafu)"
  },
  "reset": "Načíst znovu",
  "radius": "Okruh (m):",
}
</i18n>

<i18n locale="de">
{
  "speed": "Min. Geschwindigkeit (km/h):",
  "process": {
    "0": "Den ersten Punkt auswählen (eine Haltestelle oder einen Punkt auf der Fahrtroute)",
    "1": "Den zweiten Punkt auswählen (eine Haltestelle oder einen Punkt auf der Fahrtroute)"
  },
  "reset": "Neu laden",
  "radius": "Radius (m):",
}
</i18n>

<i18n locale="es">
{
  "speed": "Velocidad mín. (km/h):",
  "process": {
    "0": "Elige el primer punto (una parada o un punto en un terreno)",
    "1": "Elige el segundo punto (una parada o un punto en un terreno)"
  },
  "reset": "Volver a cargar",
  "radius": "Radio (m):",
}
</i18n>

<i18n locale="it">
{
  "speed": "Velocità min. (km/h):",
  "process": {
    "0": "Scegli il primo punto (una fermata o un punto su una tratta)",
    "1": "Scegli il secondo punto (una fermata o un punto su una tratta)"
  },
  "reset": "Ricarica",
  "radius": "Raggio (m):",
}
</i18n>

<i18n locale="pl">
{
  "speed": "Min. prędkość (km/h):",
  "process": {
    "0": "Wybierz pierwszy punkt (postój lub punkt na stanowisku)",
    "1": "Wybierz drugi punkt (postój lub punkt na stanowisku)"
  },
  "reset": "Załaduj ponownie",
  "radius": "Promień (m):",
}
</i18n>
