import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));

const StatusCell = defineAsyncComponent(() => import('./StatusCell.vue'));
const TypeCell = defineAsyncComponent(() => import('./TypeCell.vue'));

export const TRANSPORT_PLAN_LS_COLUMNS = 'transportPlanPage/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  ACTION: 'action',
  ARCHIVED: 'archived',
  NAME: 'name',
  STATUS: 'status',
  TYPE: 'type',
  PUBLICATION_DATE: 'publicationTime',
  MODIFICATION_DATE: 'modificationTime',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(TRANSPORT_PLAN_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnKey => !ls || ls.includes(columnKey);

  return new DataGrid({
    name: 'transportPlanListDatagrid',
    searchFields: ['name'],
    defaultSortBy: [{ key: ColumnKey.PUBLICATION_DATE, order: SortOrder.DESC }],
    rowIdKey: '_id',
    columnSelectionLocalStorageKey: TRANSPORT_PLAN_LS_COLUMNS,
    hasActions: true,
    columns: [
      // NAME
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.name',
        defaultSelected: defaultSelected(ColumnKey.NAME),
        selectable: false,
      }),

      // STATUS
      new DataGridColumn({
        key: ColumnKey.STATUS,
        title: 'column.status',
        defaultSelected: defaultSelected(ColumnKey.STATUS),
        filterable: true,
        sortable: false,
        selectable: false,
        i18nPrefix: 'transportPlanStatus',
        cellBuilder([value, object]) {
          return {
            component: StatusCell,
            props: {
              status: value,
              publicationTime: object.publicationTime,
            },
          };
        },
      }),

      // TYPE
      new DataGridColumn({
        key: ColumnKey.TYPE,
        title: 'column.type',
        filterable: true,
        sortable: false,
        i18nPrefix: 'transportPlanTypes',
        cellBuilder([value, object]) {
          return {
            component: TypeCell,
            props: {
              type: value,
            },
          };
        },
      }),

      // PUBLICATION DATE
      new DataGridColumn({
        key: ColumnKey.PUBLICATION_DATE,
        title: 'column.publicationTime',
        defaultSelected: defaultSelected(ColumnKey.PUBLICATION_DATE),
        selectable: false,
        cellBuilder([value, object]) {
          return {
            component: DateCell,
            props: {
              date: value,
              gtfsPublication: true,
            },
          };
        },
      }),

      // MODIFICATION DATE
      new DataGridColumn({
        key: ColumnKey.MODIFICATION_DATE,
        title: 'column.modificationDate',
        defaultSelected: defaultSelected(ColumnKey.MODIFICATION_DATE),
        filterable: false,
        sortable: true,
        cellBuilder([value, object]) {
          return {
            component: DateCell,
            props: {
              date: value,
            },
            value,
          };
        },
      }),
    ],
  });
};
