import { POSITION, type PluginOptions } from 'vue-toastification';

export const ToastOptions: PluginOptions = {
  shareAppContext: true,
  position: POSITION.TOP_RIGHT,
  timeout: 10000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 1,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false,
  transition: 'Vue-Toastification__fade',
  maxToasts: 5,
  newestOnTop: true,
};
