<template>
  <span>
    <template v-for="(part, i) in parts" :key="i">
      <span :class="{ highlight: i % 2 }">
        {{ part }}
      </span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'Highlighter',

  props: {
    /** @type {Vue.PropOptions<Array<string>>} */
    parts: {
      type: Array,
      required: true,
    },
  },
};
</script>
