<template>
  <div class="vehicle-track-switch">
    <ToggleSwitch id="vehicle-track-switch" :checked="switchOn" @change="switchOn = !switchOn" />
    <label for="vehicle-track-switch" class="vehicle-track-switch__label">
      {{ $t('display') }}
    </label>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

export default {
  name: 'VehicleTrackSwitch',

  components: { ToggleSwitch },

  emits: ['update:displayTrack'],

  data: () => ({
    switchOn: false,
  }),

  watch: {
    switchOn() {
      this.$emit('update:displayTrack', this.switchOn);
    },
  },
};
</script>

<style lang="scss">
.vehicle-track-switch {
  align-self: center;
  padding: 5px;
  border: 1px solid $border-variant;
  border-radius: 4px;
  background-color: $canvas;
  font-size: 12px;

  &__label {
    margin: 0 5px;
  }
}
</style>

<i18n locale="fr">
{
  "display": "Afficher la trace complète du véhicule"
}
</i18n>

<i18n locale="en">
{
  "display": "Display the entire vehicle track"
}
</i18n>
