import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import store from '@/store';
import { Permission } from '@/auth';
import { validateDriverCode } from './index.vue';

const CommentCell = defineAsyncComponent(() => import('@/components/Table/cells/CommentCell.vue'));
const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const SingleTeamSelector = defineAsyncComponent(() => import('@/components/ui/SingleTeamSelector.vue'));

export const DRIVERS_LS_COLUMNS = 'driversPage/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  DRIVER_NAME: 'driver_name',
  CODE: 'code',
  STAFF_NUMBER: 'staff_number',
  TEAM: 'teamName',
  COMMENT: 'comment',
  ACTION: 'action',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(DRIVERS_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'driverListDatagrid',
    searchFields: ['driver_name', 'staff_number'],
    defaultSortBy: [{ key: ColumnKey.DRIVER_NAME, order: SortOrder.ASC }],
    columnSelectionLocalStorageKey: DRIVERS_LS_COLUMNS,
    hasActions: true,

    columns: [
      // DRIVER NAME
      new DataGridColumn({
        key: ColumnKey.DRIVER_NAME,
        title: 'column.driver_name',
        defaultSelected: defaultSelected(ColumnKey.DRIVER_NAME),
        selectable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'driver_name',
            },
          };
        },
      }),

      // STAFF NUMBER
      new DataGridColumn({
        key: ColumnKey.STAFF_NUMBER,
        title: 'column.staff_number',
        defaultSelected: defaultSelected(ColumnKey.STAFF_NUMBER),
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              value,
              editMode,
              type: 'staff_number',
            },
          };
        },
      }),

      // TEAM
      new DataGridColumn({
        key: ColumnKey.TEAM,
        title: 'column.team',
        defaultSelected: defaultSelected(ColumnKey.TEAM),
        filterable: value => ({ value, label: store.getters.getTeamNameById(value) }),
        sortable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: SingleTeamSelector,
            events: { valueChanged },
            props: {
              teamId: object.team_id,
              editMode,
            },
          };
        },
      }),

      // DRIVER CODE
      new DataGridColumn({
        key: ColumnKey.CODE,
        title: 'column.driver_code',
        selectable: false,
        sortable: true,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              value,
              editMode,
              type: 'code',
              formatOptions: { maxLength: 4, validKeysRegex: /^[0-9]*$/ },
              placeholder: '0000',
              validation: validateDriverCode.IS_FOUR_DIGIT_OR_EMPTY,
            },
          };
        },
      }),

      // COMMENT
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_COMMENTS)
        ? [
            new DataGridColumn({
              key: ColumnKey.COMMENT,
              title: 'column.comment',
              defaultSelected: defaultSelected(ColumnKey.COMMENT),
              sortable: false,
              cellBuilder([value, object], { showModal }) {
                return {
                  component: CommentCell,
                  events: { showModal },
                  props: { comment: value },
                };
              },
            }),
          ]
        : []),
    ],
  });
};
