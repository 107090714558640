<template>
  <div class="message-toast">
    <div class="message-toast__header">
      <p class="message-toast__title">
        {{ $t('newMessage') }}
      </p>
    </div>
    <div class="message-toast__body">
      <div class="message-toast__line">
        <span class="message-toast__key">{{ $t('device') }}</span>
        <span class="message-toast__value--important">
          {{ message.sender.name || message.sender.deviceId || '-' }}
        </span>
      </div>
      <div class="message-toast__line">
        <span class="message-toast__key">{{ $t('trip') }}</span>
        <span class="message-toast__value--important">
          {{ message.formattedTripName || '-' }}
        </span>
      </div>
      <div class="message-toast__line">
        <span class="message-toast__key">{{ $t('details') }}</span>
        <span class="message-toast__value">
          {{ viewMore ? message.content : message.content.slice(0, 40) }}
        </span>
      </div>
    </div>
    <div v-if="message.content.length > 40" class="message-toast__footer">
      <span v-if="viewMore" @click.stop="viewMore = false">{{ $t('viewLess') }}</span>
      <span v-else @click.stop="viewMore = true">{{ $t('viewMore') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageToast',

  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    viewMore: false,
  }),
};
</script>

<style lang="scss">
.Vue-Toastification__toast > .Vue-Toastification__close-button.initial-color {
  color: initial;
}

.message-toast {
  &__title {
    font-weight: $font-weight-semi-bold;
    text-align: center;
  }

  &__key {
    margin-right: 5px;

    &::after {
      content: ': ';
    }
  }

  &__value {
    &--important {
      font-weight: $font-weight-semi-bold;
    }
  }

  &__footer {
    text-align: end;
    text-decoration: underline;
  }
}
</style>

<i18n locale="fr">
{
  "newMessage": "Nouveau message",
  "device": "Appareil ",
  "trip": "Course ",
  "details": "Détails ",
  "viewMore": "Voir plus",
  "viewLess": "Voir moins"
}
</i18n>

<i18n locale="en">
{
  "newMessage": "New message",
  "device": "Device",
  "trip": "Trip",
  "details": "Details",
  "viewMore": "View more",
  "viewLess": "View less"
}
</i18n>
