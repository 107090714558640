import { GroupBy } from '@/libs/reports';
import i18n from '@/i18n';

export const TripTrackingReportHelper = {
  /**
   * @param {TripTrackingDataItem} entry
   * @param {boolean} [showGtfs]
   * @returns {string}
   */
  createTooltip(entry, showGtfs) {
    const { t } = i18n.global;
    return `
    <ul class="apexcharts-custom-tooltip">
      ${showGtfs ? `<li>${entry?.gtfs_name || ''}</li>` : ``}
      <li>${t('dashboard.scheduledTripsNumber', [entry?.scheduled || 0])}</li>
      <li>${t('dashboard.trackedTrips', [entry?.tracked || 0])}</li>
      <li>${t('dashboard.percentageTracked', [entry?.percent_tracked || 0])}</li>
    </ul>
    `;
  },

  /**
   * @param {import('@/api').TripTracking} item
   * @param {GroupBy} type
   * @returns {TripTrackingDataItem | Object}
   */
  getGroupedInfos(item, type) {
    switch (type) {
      case GroupBy.DAY: {
        return {
          percent_tracked: item.percent_tracked,
          scheduled: item.scheduled,
          date: item.start_date,
          tracked: item.tracked,
        };
      }

      case GroupBy.ROUTE: {
        return {
          gtfs_name: item.gtfs_name,
          percent_tracked: item.percent_tracked,
          route_short_name: item.route_short_name,
          scheduled: item.scheduled,
          tracked: item.tracked,
        };
      }
      default:
        return {};
    }
  },

  /**
   * @param {Array<import('@/api').TripTracking>} data
   * @return {Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>}
   */
  getSeries(data) {
    return [
      {
        data: data.map(tripTrackingObject => tripTrackingObject?.percent_tracked || 0),
        color: ({ dataPointIndex, w }) => {
          const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
          if (!entry) return '';
          const percentTracked = entry.percent_tracked;
          if (percentTracked < 50) return '#EB5757'; // $danger
          if (percentTracked <= 80) return '#F99C49'; // $warn

          return '#00B871'; // $primary-light
        },
      },
    ];
  },
};

/**
 * @typedef {Object} TripTrackingDataItem
 * @property {number} percent_tracked
 * @property {number} scheduled
 * @property {number} tracked
 * @property {number} scheduled
 * @property {string} [date]
 * @property {string} [route_short_name]
 */
