<template>
  <VueApexCharts type="bar" :options="options" :series="series" />
</template>

<script>
import deepmerge from 'deepmerge';
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: 'BarChart',

  components: {
    VueApexCharts,
  },

  props: {
    /** @type {Vue.PropOptions<Array<number>>} */
    categories: {
      type: Array,
      required: true,
    },

    percentage: {
      type: Boolean,
      default: false,
    },

    /** @type {Vue.PropOptions<Array<BarSerie>>} */
    series: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    /** @return {Object} */
    options() {
      let options = {
        chart: {
          toolbar: { show: false },
        },

        colors:
          this.series.length === 1
            ? ['#5279a0', '#5279a0', '#5279a0', '#5279a0', '#5279a0', '#5279a0', '#4f4f4f']
            : this.series.map(s => s.color),

        legend: { show: false },

        title: {
          align: 'center',
          margin: 0,
          text: this.title,
        },

        xaxis: {
          categories: this.categories,
          type: 'category',
        },
      };

      if (this.series.length > 1) {
        options = deepmerge(options, {
          chart: {
            stacked: true,
            stackType: '100%',
          },

          dataLabels: { enabled: false },

          yaxis: {
            max: 100,
          },
        });
      } else {
        Object.assign(options, {
          plotOptions: {
            bar: {
              dataLabels: { position: 'top' },
              distributed: true,
            },
          },

          tooltip: { enabled: false },
        });
      }

      if (this.percentage) {
        options = deepmerge(options, {
          dataLabels: { formatter: v => `${v}%` },

          yaxis: {
            max: 100,
          },
        });
      }

      return options;
    },
  },
};

/**
 * @typedef {Object} BarSerie
 * @property {Array<number>} data
 * @property {string} [name]
 * @property {string} [color]
 */
</script>

<style lang="scss">
.apexcharts-tooltip-text {
  font-family: $font-poppins !important;
}

.apexcharts-tooltip-title {
  font-family: $font-poppins !important;
}
</style>
