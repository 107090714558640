// cloned file from https://github.com/jquense/yup/blob/master/src/locale.ts
// overrided to add custom messages structure

import type {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
  TupleLocale,
  LocaleObject,
} from '@/@types/yup';
import { printValue, ValidationError } from 'yup';

export let mixed: Required<MixedLocale> = {
  default: 'Ce champ est invalide',
  required: 'Ce champ est requis',
  defined: 'Ce champ doit être défini',
  notNull: 'Ce champ ne peut pas être nul',
  oneOf: 'Ce champ doit correspondre à une des valeurs suivantes: ${values}',
  notOneOf: 'Ce champ ne doit pas corresponds à une des valeurs suivantes: ${values}',
  notType: ({ path, type, value, originalValue }) => {
    const castMsg =
      originalValue != null && originalValue !== value
        ? ` (converti à partir de la valeur \`${printValue(originalValue, true)}\`).`
        : '.';

    return type !== 'mixed'
      ? `Ce champ doit être de type \`${type}\`, ` +
          `mais la valeur finale est: \`${printValue(value, true)}\`` +
          castMsg
      : `Ce champ doit correspondre au type configuré. ` +
          `La valeur validée est: \`${printValue(value, true)}\`` +
          castMsg;
  },
};

export let string: Required<StringLocale> = {
  length: 'Ce champ doit faire ${length} caractères exactement',
  min: 'Ce champ doit faire au moins ${min} caractères',
  max: 'Ce champ doit faire au plus ${max} caractères',
  matches: 'Ce champ doit correspondre à l\'expression suivante: "${regex}"',
  email: 'Ce champ doit être un email valide',
  url: 'Ce champ doit être une URL valide',
  uuid: 'Ce champ doit être un UUID valide',
  trim: 'Ce champ doit être une chaine de caractère taillée',
  lowercase: 'Ce champ doit être une chaine de caractère en miniscule',
  uppercase: 'Ce champ doit être une chaine de caractère en majuscule',
};

export let number: Required<NumberLocale> = {
  min: 'Ce champ doit être plus grand que ou égal à ${min}',
  max: 'Ce champ doit être plus petit que ou égal à ${max}',
  lessThan: 'Ce champ doit être plus petit que ${less}',
  moreThan: 'Ce champ doit être plus grand que ${more}',
  positive: 'Ce champ doit être un nombre positif',
  negative: 'Ce champ doit être un nombre négatif',
  integer: 'Ce champ doit être un entier',
};

export let date: Required<DateLocale> = {
  min: 'Ce champ doit être après ${min}',
  max: 'Ce champ doit être avant ${max}',
};

export let boolean: BooleanLocale = {
  isValue: 'Ce champ doit être ${value}',
};

export let object: Required<ObjectLocale> = {
  noUnknown: 'Ce champ a des clés non spécifiées: ${unknown}',
};

export let array: Required<ArrayLocale> = {
  min: 'Ce champ doit avoir au moins ${min} éléments',
  max: 'Ce champ doit avoir au maximum ${max} éléments',
  length: 'Ce champ doit avoir ${length} éléments',
};

export let tuple: Required<TupleLocale> = {
  notType: params => {
    const { path, value, spec } = params;
    const typeLen = spec.types.length;
    if (Array.isArray(value)) {
      if (value.length < typeLen)
        return `Ce tuple n'as pas assez d'éléments, ${typeLen} attendus mais ${
          value.length
        } obtenus pour la valeur: \`${printValue(value, true)}\``;
      if (value.length > typeLen)
        return `Ce tuple a trop d'éléments, ${typeLen} attendus mais ${
          value.length
        } obtenus pour la valeur: \`${printValue(value, true)}\``;
    }

    return ValidationError.formatError(mixed.notType, params);
  },
};

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}) as LocaleObject;
