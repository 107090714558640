<script setup lang="ts">
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

const imageSource = new URL(`/src/assets/img/urgencies/modal.png`, import.meta.url).href;
</script>

<template>
  <Modal
    modal-class="modal-blocked-urgencies"
    :width="800"
    :hide-footer="true"
    :has-close-btn="false"
    :forbid-closing="true"
    :persistent="true"
    attach="#urgenciesBlockedView"
  >
    <template #body>
      <div class="modal-blocked-urgencies__body">
        <div class="modal-blocked-urgencies__left">
          <div class="modal-blocked-urgencies__lock">
            <span class="modal-blocked-urgencies__lock-background"><v-icon>fa:fas fa-lock</v-icon></span>
          </div>
          <div class="modal-blocked-urgencies__title">{{ $t('title') }}</div>
          <div class="modal-blocked-urgencies__subtitle">{{ $t('subtitle') }}</div>
          <div class="modal-blocked-urgencies__text">
            <v-icon class="modal-blocked-urgencies__icons">fa:fas fa-arrow-circle-right</v-icon>
            <span class="modal-blocked-urgencies__highlighted">{{ $t('first') }}</span>
            <span>{{ $t('second') }}</span>
          </div>
          <div class="modal-blocked-urgencies__text">
            <v-icon class="modal-blocked-urgencies__icons">fa:fas fa-arrow-circle-right</v-icon>
            <span>{{ $t('third') }}</span>
            <span class="modal-blocked-urgencies__highlighted">{{ $t('fourth') }}</span>
          </div>
          <Btn class="modal-blocked-urgencies__btn" link-url="https://web.pysae.com/contact" type="primary">
            <v-icon class="modal-blocked-urgencies__icons">fa:fas fa-plus</v-icon>
            <span>{{ $t('cta') }}</span>
          </Btn>
          <Btn class="modal-blocked-urgencies__btn" link-url="https://web.pysae.com/contact" type="secondary">
            <v-icon class="modal-blocked-urgencies__icons">fa:fas fa-question-circle</v-icon>
            <span>{{ $t('learnMore') }}</span>
          </Btn>
        </div>
        <div class="modal-blocked-urgencies__right">
          <img class="modal-blocked-urgencies__img" :src="imageSource" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-blocked-urgencies {
  backdrop-filter: blur(5px);

  .modal__body {
    overflow-y: initial;
    margin-bottom: 0;
  }

  .modal__header {
    display: none;
  }

  &__icons.v-icon {
    margin-right: 10px;
    font-size: 14px;
  }

  &__btn {
    width: 100%;
    font-weight: $font-weight-semi-bold;
  }

  &__btn:first-of-type {
    margin-bottom: 10px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__highlighted {
    color: $primary-light;
  }

  &__img {
    width: 100%;
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 48%;
    padding-left: 15px;
  }

  &__lock {
    display: flex;
    justify-content: center;
  }

  &__lock-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: $background-variant;

    .v-icon {
      font-size: 18px;
    }
  }

  &__right {
    width: 48%;
  }

  &__subtitle {
    padding-bottom: 20px;
    color: $primary-light;
    font-weight: $font-weight-bold;
    font-size: 24px;
    text-align: center;
  }

  &__text {
    padding-bottom: 10px;
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }

  &__text:last-of-type {
    padding-bottom: 25px;
  }

  &__title {
    padding-top: 10px;
    font-weight: $font-weight-bold;
    font-size: 20px;
    text-align: center;
  }

  .ui-btn + .ui-btn {
    margin-left: 0 !important;
  }
}
</style>

<i18n locale="fr">
  {
    "title": "Profitez de la fonctionnalité",
    "subtitle": "Alertes urgentes",
    "first": "Bouton \"SOS\"",
    "second": " dans l'application Pysae Driver",
    "third": "Réception et gestion des",
    "fourth": " alertes conducteurs",
    "cta": "Activer l'option",
    "learnMore": "En savoir plus"
  }
  </i18n>

<i18n locale="en">
  {
    "title": "Enjoy this feature",
    "subtitle": "Urgent alerts",
    "first": "\"SOS\" button",
    "second": " in the Pysae Driver application",
    "third": "Reception and handling of",
    "fourth": " driver alerts",
    "cta": "Activate feature",
    "learnMore": "Learn more"
  }
  </i18n>
