// we use the plugin https://www.npmjs.com/package/vite-plugin-vuetify
// it will  automatically import all Vuetify components as you use them
// No need to specify it there

import 'vuetify/styles';
import { createVuetify, type IconAliases, type ThemeDefinition } from 'vuetify';
import { aliases as defaultAliases, fa } from 'vuetify/iconsets/fa';
import deviateWhite from '@/assets/img/icons/deviate-white.svg';
import deviateBlack from '@/assets/img/icons/deviate-black.svg';

const aliases: IconAliases = {
  ...defaultAliases,
  deviateWhite,
  deviateBlack,
};

const customTheme: ThemeDefinition = {
  colors: {
    success: '#00b871', // $primary-light
    text: '#333', // $text-dark
  },
};

export default createVuetify({
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
    },
  },
});
