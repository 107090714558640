<template>
  <div class="users-admin">
    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('users.title', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="userListFormatted"
      :loading="loading"
      :datagrid="datagrid"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object._id)"
          :actions="
            [NEW_LINE_ID, inEditionId].includes(propsAction.object._id) ? [] : ['password', 'archive']
          "
          :object="propsAction.object"
          @password="showModal(ModalTypes.PASSWORD, propsAction.object)"
          @archive="showModal(ModalTypes.ARCHIVE, propsAction.object)"
          @save="saveEdits(propsAction.object)"
          @switchOffEditionMode="cancelEditionMode"
        />
      </template>
    </DataGridVuetify>

    <ModalPassword
      v-if="modalShown === ModalTypes.PASSWORD"
      :reset-link="resetLink"
      @close="closeModal"
      @submit="getPasswordResetLink"
    />

    <ModalArchive
      v-if="modalShown === ModalTypes.ARCHIVE"
      :title="$t('users.header')"
      :body="$t('users.body', [currentUser.name])"
      @close="closeModal"
      @submit="submitArchive"
    />

    <Modal v-if="modalShown === ModalTypes.SUPERUSER" @close="saveSuperuserRights(currentUser, false)">
      <template #title>
        {{ currentUser.superuser ? $t('superuser.downgrade.header') : $t('superuser.upgrade.header') }}
      </template>
      <template #body>
        {{
          currentUser.superuser
            ? $t('superuser.downgrade.body', [currentUser.name || currentUser.email])
            : $t('superuser.upgrade.body', [currentUser.name || currentUser.email])
        }}
      </template>
      <template #cta>
        <Btn type="danger" @click="saveSuperuserRights(currentUser, true)">
          {{ currentUser.superuser ? $t('superuser.downgrade.submit') : $t('superuser.upgrade.submit') }}
        </Btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import { admin as ApiAdmin } from '@/api.js';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { ColumnKey, getDatagrid } from '@/pages/AdminPage/Users.conf.js';
import ModalPassword from './ModalPassword.vue';
import ModalArchive from '@/components/ui/ModalArchiveRestore.vue';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

export const ModalTypes = {
  ARCHIVE: 'archive',
  PASSWORD: 'password',
  SUPERUSER: 'superuser',
};

/** @enum {string} */
export const NEW_LINE_ID = 'new-line';

export default {
  name: 'Users',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    Modal,
    ModalArchive,
    ModalPassword,
  },

  props: {
    users: {
      required: true,
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    getDatagrid,
    ModalTypes,
    NEW_LINE_ID,

    /** @type {UserFormatted} */
    currentUser: null,
    /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
    datagrid: getDatagrid(),
    /** @type {?string} */
    inEditionId: null,
    /** @type {?string}  */
    modalShown: null,
    /** @type {?import('@/store').User} */
    originalElement: null,
    /** @type {number} */
    renderedDataLength: null,
    /** @type {string} */
    resetLink: '',
    /** @type {Array<UserFormatted>} */
    userListFormatted: [],
  }),

  computed: {
    /**
     * @returns {Object} Mapping columns types to functions receiving an object with apiData key
     * and returning each columns' cellBuilder props value
     */
    buildCellInjectors() {
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          this.updateValue(apiDataRow, { value, field });
        };

      const bindSuperuserClick = apiDataRow => () => {
        if (apiDataRow._id === NEW_LINE_ID) {
          apiDataRow.superuser = !apiDataRow.superuser;
          this.updateValue(apiDataRow, { value: apiDataRow.superuser, field: 'superuser' });
        } else {
          this.showModal(ModalTypes.SUPERUSER, apiDataRow);
        }
      };

      return {
        [ColumnKey.NAME]: ({ apiData }) => ({
          editMode: apiData._id === this.inEditionId,
          placeholder: this.$t('new.name'),
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.SUPERUSER]: ({ apiData }) => ({
          click: bindSuperuserClick(apiData),
          editMode: apiData._id === this.inEditionId,
        }),
        [ColumnKey.EMAIL]: ({ apiData }) => ({
          editMode: apiData._id === this.inEditionId,
          placeholder: this.$t('new.email'),
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },
  },

  watch: {
    users: {
      immediate: true,
      handler() {
        /** @type {Array<UserFormatted>} */
        const copy = [...this.users];
        copy.forEach(user => {
          user.isSuperuser = user.superuser ? 'true' : 'false';
          user.rolesFormatted = this.formatRoles(user.superuser, user.roles);
        });
        this.userListFormatted = copy;
      },
    },
  },

  methods: {
    /**
     *
     * @param {boolean} superuser
     * @param {object} roles
     */
    formatRoles(superuser, roles) {
      return superuser
        ? [this.$t(`all`)]
        : roles && Object.keys(roles).length > 0
          ? Object.keys(roles).map(r => r)
          : [this.$t(`none`)];
    },

    async addElement() {
      this.userListFormatted.unshift({
        _id: NEW_LINE_ID,
      });
      this.inEditionId = NEW_LINE_ID;
    },

    closeModal() {
      this.currentUser = null;
      this.resetLink = '';
      this.modalShown = null;
    },

    async getPasswordResetLink() {
      try {
        const response = await ApiAdmin.users.requestPasswordResetToken(this.currentUser);
        this.resetLink = `${window.location.href.split('/#')[0]}/#/password/reset?token=${response.token}`;
      } catch {
        this.resetLink = 'An error occured please try later';
      }
    },

    /**
     * Edit User
     * @param {UserFormatted} apiDataRow
     */
    async saveEdits(apiDataRow) {
      this.inEditionId = null;
      if (apiDataRow._id === NEW_LINE_ID) {
        const element = await ApiAdmin.users.create(apiDataRow);
        if (element) {
          element.isSuperuser = element.superuser ? 'true' : 'false';
          element.rolesFormatted = this.formatRoles(element.superuser, element.roles);

          this.userListFormatted.push(element);
          // remove first line, dedicated to new data creation
          this.userListFormatted.splice(0, 1);
        }
      }
    },

    /**
     * Edit right superuser
     * @param {UserFormatted} apiDataRow
     * @param {boolean} hasChanged
     */
    async saveSuperuserRights(apiDataRow, hasChanged) {
      if (hasChanged) {
        apiDataRow.superuser = !apiDataRow.superuser;
        delete apiDataRow.isSuperuser;
        delete apiDataRow.rolesFormatted;
        const element = await ApiAdmin.users.update(apiDataRow);
        if (element) {
          this.updateValue(apiDataRow, { value: apiDataRow.superuser, field: 'superuser' });
        }
      }
      this.closeModal();
    },

    /** display modal
     * @param {string} type
     * @param {UserFormatted} object */
    showModal(type, object) {
      this.modalShown = type;
      this.currentUser = object;
    },

    async submitArchive() {
      const modalContentCopy = { ...this.currentUser };
      await ApiAdmin.users.del(modalContentCopy);
      this.userListFormatted.splice(
        this.userListFormatted.findIndex(el => el._id === modalContentCopy._id),
        1,
      );
      this.closeModal();
    },

    cancelEditionMode() {
      if (this.inEditionId === NEW_LINE_ID) {
        const index = this.userListFormatted.findIndex(el => el._id === NEW_LINE_ID);
        this.userListFormatted.splice(index, 1);
        this.inEditionId = null;
      }
    },

    /**
     * update a value
     * @param {import('@/store').Group} apiDataRow
     * @param {{value: any, field:string}} param1
     */
    updateValue(apiDataRow, { value, field }) {
      this.userListFormatted.find(row => row._id === apiDataRow._id)[field] = value;
    },
  },
};

/**
 * @typedef {Object} UserFormatted
 * @extends {import('@/store').User}
 * @property {string} isSuperuser
 * @property {string} rolesFormatted
 * */
</script>

<i18n locale="fr">
  {
    "superuser": {
      "downgrade": {
          "header": "Modifier les droits d'un utilisateur",
          "body": "Voulez-vous retirer à l'utilisateur {0} les droits superutilisateur ?",
          "submit": "Retirer"
       },
       "upgrade": {
          "header": "Modifier les droits d'un utilisateur",
          "body": "Voulez-vous accorder à l'utilisateur {0} les droits superutilisateur ?",
          "submit": "Accorder"
       }
    },
    "users": {
      "header": "Archiver un utilisateur",
      "title": "Utilisateurs",
      "body": "Voulez-vous archiver l'utilisateur {0} ?",
      "submit": "Archiver"
    },
    "new": {
      "name": "Nouveau nom",
      "email": "Nouvel email",
    },
  }
  </i18n>

<i18n locale="en">
  {
    "superuser": {
      "downgrade": {
          "header": "Modify user's rights",
          "body": "Do you want to downgrade superuser to user?",
          "submit": "Downgrade"
       },
       "upgrade": {
          "header": "Modify user's rights",
          "body": "Do you want to upgrade user {0} to superuser?",
          "submit": "Upgrade"
       }
    },
    "users": {
      "header": "Archive a user",
      "title": "Users",
      "body": "Do you want to archive user {0}?",
      "submit": "Archive"
    },
    "new": {
      "name": "New name",
      "email": "New email",
    }
  }
  </i18n>
