<template>
  <div class="number-display">
    <div class="number-display__title">
      {{ title }}
    </div>
    <div class="number-display__text">
      <div class="number-display__number">
        {{ formattedNumber }}
      </div>
      <div class="number-display__unit">
        <span>{{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberDisplay',

  props: {
    number: {
      type: Number,
      default: 0,
    },

    title: {
      type: String,
      default: '',
    },

    unit: {
      type: String,
      default: '',
    },
  },

  computed: {
    formattedNumber() {
      return new Intl.NumberFormat(this.$i18n.locale).format(this.number);
    },
  },
};
</script>

<style lang="scss">
.number-display {
  position: relative;
  height: 370px;
  border-radius: 9px;
  background-color: $background;
  color: $text-dark;
  font-weight: $font-weight-semi-bold;

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  &__number {
    font-size: 84px;
    line-height: 90px;
  }

  &__title {
    padding: 20px 30px;
    font-size: 21px;
  }

  &__unit {
    font-size: 34px;
  }
}
</style>
