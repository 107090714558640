/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import * as yup from 'yup';
import aepIcon from '@/assets/img/integrations/aep.png';
import hanoverIcon from '@/assets/img/integrations/hanover.png';
import irisIcon from '@/assets/img/integrations/iris.png';
import kubaIcon from '@/assets/img/integrations/kuba.png';
import lumiplanIcon from '@/assets/img/integrations/lumiplan.png';
import paragonIcon from '@/assets/img/integrations/paragon.png';
import webreatheIcon from '@/assets/img/integrations/webreathe.png';
import nmeaIcon from '@/assets/img/integrations/nmea.png';
import dilaxIcon from '@/assets/img/integrations/dilax.png';
import wiibusIcon from '@/assets/img/integrations/wiibus.png';
import businfoIcon from '@/assets/img/integrations/businfo.png';
import gtfsIcon from '@/assets/img/integrations/gtfs.png';
import matawanIcon from '@/assets/img/integrations/matawan.png';
import okapiIcon from '@/assets/img/integrations/okapi.png';
import powerBIIcon from '@/assets/img/integrations/powerBI.png';
import seipraIcon from '@/assets/img/integrations/seipra.png';
import conduentIcon from '@/assets/img/integrations/conduent.png';
import Api from '@/api';

export const IRIS_PROTOCOLS = [
  { value: 'qip', label: 'QIP' },
  { value: 'ibis-ip', label: 'IBIS-IP' },
];

export const KUBA_VERSIONS = [
  { value: 'legacy', label: 'Legacy' },
  { value: 'v8', label: 'V8' },
];

export const GTFS_FIELDS = ['trip_id', 'route_id', 'block_id', 'shape_id', 'ext_pis_code'];

/** @enum {string} */
export const Filter = {
  SIV: 'siv',
  TICKETING: 'ticketing',
  GPS: 'gps',
  COUNT: 'count',
  OTHER: 'other',
};

/** @const {Map<Filter, Array<IntegrationType.labelKey>} */
export const CONNECTOR_CATEGORYS = {
  [Filter.SIV]: ['integrations.hanover.label', 'integrations.lumiplan.label', 'integrations.seipra.label'],
  [Filter.TICKETING]: [
    'integrations.aep.label',
    'integrations.kuba.label',
    'integrations.paragon.label',
    'integrations.conduent.label',
    'integrations.matawan.label',
  ],
  [Filter.GPS]: ['integrations.nmea.label', 'integrations.wiibus.label'],
  [Filter.COUNT]: ['integrations.iris.label', 'integrations.webreathe.label', 'integrations.dilax.label'],
  [Filter.OTHER]: [
    'integrations.powerBI.label',
    'integrations.okapi.label',
    'integrations.gtfs.label',
    'integrations.businfo.label',
  ],
};

/**
 * @type {IntegrationType[]}
 */
export const integrationTypes = [
  {
    labelKey: 'integrations.aep.label',
    descriptionKey: 'integrations.aep.description',
    icon: aepIcon,
    connectors: [{ id: 'aep', defaultConfiguration: { ip_address: '', port: 33333 } }],
  },
  {
    labelKey: 'integrations.hanover.label',
    descriptionKey: 'integrations.hanover.description',
    icon: hanoverIcon,
    connectors: [
      {
        id: 'hanover',
        labelKey: 'integrations.connectorType.serial',
      },
      {
        id: 'hanover_ip',
        labelKey: 'integrations.connectorType.tcpIp',
        defaultConfiguration: { ip_address: '', port: 33335 },
      },
    ],
  },
  {
    labelKey: 'integrations.iris.label',
    descriptionKey: 'integrations.iris.description',
    icon: irisIcon,
    connectors: [
      {
        id: 'qip',
        defaultConfiguration: { hosts: [], protocol: 'qip', door_detection: false },
        fields: {
          iris_protocol: 'protocol',
        },
        validation: {
          protocol: spec =>
            yup
              .string()
              .required()
              .oneOf(IRIS_PROTOCOLS.map(p => p.value)),
        },
      },
    ],
  },
  {
    labelKey: 'integrations.kuba.label',
    descriptionKey: 'integrations.kuba.description',
    icon: kubaIcon,
    connectors: [
      {
        id: 'kuba',
        defaultConfiguration: { gtfs_field: '', ip_address: '', port: 33333, version: 'legacy' },
        fields: {
          kuba_version: 'version',
        },
        validation: {
          version: spec =>
            yup
              .string()
              .required()
              .oneOf(KUBA_VERSIONS.map(v => v.value)),
        },
      },
    ],
  },
  {
    labelKey: 'integrations.lumiplan.label',
    descriptionKey: 'integrations.lumiplan.description',
    icon: lumiplanIcon,
    connectors: [
      {
        id: 'duhip',
        labelKey: 'integrations.connectorType.tcpIp',
        defaultConfiguration: { gtfs_field: '', port: 3838, router: null },
      },
      {
        id: 'lumiplan',
        labelKey: 'integrations.connectorType.serial',
      },
    ],
  },
  {
    connectors: [{ id: 'nmea', defaultConfiguration: { ip_address: '', port: 10110 } }],
    labelKey: 'integrations.nmea.label',
    descriptionKey: 'integrations.nmea.description',
    icon: nmeaIcon,
  },
  {
    connectors: [
      {
        id: 'paragon',
        defaultConfiguration: { hosts: [] },
        validation: {
          hosts: spec => yup.array().of(yup.string().required().ipPort()).required().min(1),
        },
      },
    ],
    labelKey: 'integrations.paragon.label',
    descriptionKey: 'integrations.paragon.description',
    icon: paragonIcon,
  },
  {
    connectors: [{ id: 'silv1', defaultConfiguration: { ip_address: '', port: 33334 } }],
    labelKey: 'integrations.silv1.label',
    descriptionKey: 'integrations.silv1.description',
  },
  {
    connectors: [],
    labelKey: 'integrations.dilax.label',
    descriptionKey: 'integrations.dilax.description',
    icon: dilaxIcon,
    contactUs: true,
  },
  {
    connectors: [],
    labelKey: 'integrations.wiibus.label',
    descriptionKey: 'integrations.wiibus.description',
    icon: wiibusIcon,
    contactUs: true,
  },
  {
    connectors: [],
    labelKey: 'integrations.webreathe.label',
    descriptionKey: 'integrations.webreathe.description',
    contactUs: true,
    icon: webreatheIcon,
  },
  {
    connectors: [{ id: 'businfo', defaultConfiguration: { api_url: '', api_key: '' } }],
    labelKey: 'integrations.businfo.label',
    descriptionKey: 'integrations.businfo.description',
    icon: businfoIcon,
  },
  {
    connectors: [],
    labelKey: 'integrations.gtfs.label',
    descriptionKey: 'integrations.gtfs.description',
    contactUs: true,
    icon: gtfsIcon,
  },
  {
    connectors: [],
    labelKey: 'integrations.matawan.label',
    descriptionKey: 'integrations.matawan.description',
    contactUs: true,
    icon: matawanIcon,
  },
  {
    connectors: [],
    labelKey: 'integrations.okapi.label',
    descriptionKey: 'integrations.okapi.description',
    contactUs: true,
    icon: okapiIcon,
  },
  {
    connectors: [],
    labelKey: 'integrations.powerBI.label',
    descriptionKey: 'integrations.powerBI.description',
    contactUs: true,
    icon: powerBIIcon,
  },
  {
    connectors: [],
    labelKey: 'integrations.seipra.label',
    descriptionKey: 'integrations.seipra.description',
    contactUs: true,
    icon: seipraIcon,
  },
  {
    connectors: [{ id: 'conduent', defaultConfiguration: { ip_address: '', port: 5000 } }],
    labelKey: 'integrations.conduent.label',
    descriptionKey: 'integrations.conduent.description',
    icon: conduentIcon,
  },
];

/**
 * @type {Map<string, IntegrationType>}
 */
const integrationTypeByConnectorId = new Map();
integrationTypes.forEach(integrationType => {
  integrationType.connectors.forEach(connector => {
    if (integrationTypeByConnectorId.has(connector.id)) {
      throw new Error('Duplicate connector id found in integration types');
    }
    integrationTypeByConnectorId.set(connector.id, integrationType);
  });
});

/**
 * @type {Map<string, IntegrationConnectorType>}
 */
const connectorTypeById = new Map();
integrationTypes.forEach(integrationType => {
  integrationType.connectors.forEach(connector => {
    if (connectorTypeById.has(connector.id)) {
      throw new Error('Duplicate connector id found in connector types');
    }
    connectorTypeById.set(connector.id, connector);
  });
});

/**
 * @typedef {typeof state} State
 * */
const state = {
  loadingCount: 0,
  /** @type {Record<string, *>} */
  connectorConfigurations: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,
  getters: {
    loading: () => {
      return state.loadingCount > 0;
    },

    activeIntegrations: state => {
      return Object.entries(state.connectorConfigurations).map(([connectorId, configuration]) => {
        return {
          type: integrationTypeByConnectorId.get(connectorId),
          connector: connectorTypeById.get(connectorId),
          configuration,
        };
      });
    },

    activeIntegrationTypes: state => {
      const activeIntegrationTypes = [
        ...new Set(
          Object.keys(state.connectorConfigurations).map(connectorId =>
            integrationTypeByConnectorId.get(connectorId),
          ),
        ),
      ].filter(s => s !== undefined);

      return activeIntegrationTypes;
    },

    inactiveIntegrationTypes: (state, getters) => {
      return [...integrationTypes].filter(
        integrationType => !getters.activeIntegrationTypes.includes(integrationType),
      );
    },
  },
  mutations: {
    startLoading(state) {
      state.loadingCount += 1;
    },

    stopLoading(state) {
      state.loadingCount -= 1;
    },

    /**
     * @param {State} state
     * @param {Record<string, *>} connectorConfigurations
     */
    setConnectorConfigurations(state, connectorConfigurations) {
      state.connectorConfigurations = connectorConfigurations;
    },

    /**
     * @param {State} state
     * @param {Object} args
     * @param {string} args.connectorId
     */
    deleteConnectorConfiguration(state, { connectorId }) {
      delete state.connectorConfigurations[connectorId];
    },

    /**
     * @param {State} state
     * @param {Object} args
     * @param {string} args.connectorId
     * @param {*} args.configuration
     */
    setConnectorConfiguration(state, { connectorId, configuration }) {
      state.connectorConfigurations[connectorId] = configuration;
    },
  },
  actions: {
    async loadData({ commit, dispatch }) {
      const group = await dispatch('getGroup', null, { root: true });
      try {
        commit('startLoading');
        const connectorConfigurations = await Api.integrations.getAllGroupIntegrations(group._id);
        Object.values(connectorConfigurations).forEach(c => delete c.enabled);
        commit('setConnectorConfigurations', connectorConfigurations);
      } finally {
        commit('stopLoading');
      }
    },

    /**
     * @param context
     * @param {Object} args
     * @param {Integration} args.value
     */
    async saveIntegration({ commit, dispatch }, { value }) {
      const group = await dispatch('getGroup', null, { root: true });
      const groupIntegration = await Api.integrations.replaceOrCreateGroupIntegration(
        group._id,
        value.connector.id,
        { enabled: true, ...value.configuration },
      );

      const configuration = { ...groupIntegration };
      delete configuration.enabled;

      commit('setConnectorConfiguration', { connectorId: value.connector.id, configuration });
    },

    /**
     * @param context
     * @param {Object} args
     * @param {IntegrationType} args.value
     */
    async deleteActiveIntegrationType({ commit, dispatch }, { value }) {
      const group = await dispatch('getGroup', null, { root: true });

      // eslint-disable-next-line no-restricted-syntax
      for (const connector of value.connectors) {
        await Api.integrations.deleteGroupIntegration(group._id, connector.id);
        commit('deleteConnectorConfiguration', { connectorId: connector.id });
      }
    },
  },
});

/**
 * @typedef {Object} Integration
 * @property {IntegrationType} type
 * @property {IntegrationConnectorType} connector
 * @property {*} [configuration]
 */

/**
 * @typedef {Object} IntegrationConnectorType
 * @property {string} [labelKey]
 * @property {string} [descriptionKey]
 * @property {string} id
 * @property {*} [defaultConfiguration]
 * @property {*} [validation]
 */

/**
 * @typedef {Object} IntegrationType
 * @property {string} labelKey
 * @property {string} [descriptionKey]
 * @property {string} [icon]
 * @property {boolean} [contactUs]
 * @property {IntegrationConnectorType[]} connectors
 */
