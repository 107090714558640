<template>
  <div class="cell-progress">
    <div class="progress-percent">
      <!-- progress text -->
      <span v-if="service === null" class="progress-text" :style="{ '--color': `${color}` }">
        {{ value }} %
      </span>
      <span v-else class="progress-text">{{ service.recorded_stops }}/{{ service.planned_stops }}</span>

      <!-- progress bar -->
      <div
        class="progress-bar"
        :style="{ '--perc': `${value}%`, '--color': `${color}`, '--bg-color': `${color}40` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellProgress',
  props: {
    color: {
      default: '#00894e', // $primary-dark
      type: String,
    },
    value: {
      default: 0,
      type: Number,
    },
    /** @type {Vue.PropOptions<import('@/api').Service>} */
    service: {
      default: null,
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.cell-progress {
  --perc: 0%;
  --bg-color: #00894e20;
  --color: #00894e; // $primary-dark

  display: flex;
  justify-content: flex-start;
  text-align: center;

  .progress-percent {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 15em;

    .progress-text {
      padding-bottom: 5px;
      color: var(--color);
      font-weight: $font-weight-semi-bold;
      font-size: 10px;
    }

    .progress-bar {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0.5em;
      border-radius: 0.5em;
      background: var(--bg-color);

      &::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: var(--perc);
        height: 100%;
        background: var(--color);
      }
    }
  }
}
</style>
