<template>
  <div class="password-change">
    <div class="password-change__header">
      <Language />
      <router-link :to="{ name: AuthRoute.LOGIN_FORM }" class="password-change__link">
        {{ $t('backToLogin') }}
      </router-link>
    </div>
    <div class="password-change__container">
      <div class="logo" />
      <form class="password-change__form" @submit.prevent="submit">
        <NewPassword
          :full-page="true"
          @changeNewPasswordValidity="setNewPasswordValidity"
          @newPassword="setNewPassword"
        />

        <div v-if="errorMessage" class="error-message__container">
          <span class="error-message__message">
            {{ $t('unauthorized') }}
          </span>
        </div>

        <div class="password-change__btn">
          <Btn type="primary" :disabled="!isNewPasswordValid">
            {{ $t('confirm') }}
          </Btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { users as usersAPI } from '@/api';
import Language from '@/components/layout/NavBarTop/Language.vue';
import NewPassword from '@/components/common/NewPassword.vue';
import Btn from '@/components/ui/Btn.vue';
import { AuthRoute } from '@/libs/routing';

export default {
  name: 'PasswordChange',

  components: {
    Btn,
    Language,
    NewPassword,
  },

  data: () => ({
    AuthRoute,
    /** @type {Boolean} */
    errorMessage: false,
    /** @type {Boolean} */
    isNewPasswordValid: false,
    /** @type {string} */
    newPassword: '',
  }),

  methods: {
    /**
     * @param {string} newPassword
     * */
    setNewPassword(newPassword) {
      this.newPassword = newPassword;
    },

    /**
     * @param {Boolean} isValid
     */
    setNewPasswordValidity(isValid) {
      this.isNewPasswordValid = isValid;
    },

    /** Send change password information to the api and show a message result */
    async submit() {
      this.errorMessage = false;
      const { token, email } = this.$route.query;
      const result = await usersAPI.updatePassword(email, this.password, { token });
      if (!result.ok) {
        this.errorMessage = true;
      } else {
        this.$router.push({ name: AuthRoute.LOGIN_FORM });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-change {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__btn {
    width: 500px;
    margin-top: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
  }

  &__form {
    width: 500px;
  }

  &__header {
    position: absolute;
    padding: $view-standard-padding;
  }

  &__link {
    padding: $view-standard-padding;
    padding-top: 20px;
    color: $text-dark-variant;
    font-weight: 500;
    font-size: 14px;
  }

  .ui-btn {
    width: 500px;
  }
}
</style>

<i18n locale="fr">
{
  "badConfirm": "Les mots de passe doivent être identiques",
  "backToLogin": "Retour à la page de connexion",
  "confirm": "Confirmer",
  "unauthorized": "Vous n'êtes pas autorisé à faire cela"
}
</i18n>

<i18n locale="en">
{
  "badConfirm": "Passwords have to be the same",
  "backToLogin": "Back to the login page",
  "confirm": "Confirm",
  "unauthorized": "You are not allowed to do this"
}
</i18n>

<i18n locale="cz">
{
  "backToLogin": "Zpátky na přihlášení",
  "newPassword": "Nové heslo",
  "passwordConfirmation": "Potvrzení hesla"
}
</i18n>

<i18n locale="de">
{
  "backToLogin": "Zurück zur Anmeldeseite",
  "newPassword": "Neues Kennwort",
  "passwordConfirmation": "Neues Passwort bestätigen"
}
</i18n>

<i18n locale="es">
{
  "backToLogin": "Volver a la página de inicio de sesión",
  "newPassword": "Nueva contraseña",
  "passwordConfirmation": "Confirmar la nueva password"
}
</i18n>

<i18n locale="it">
{
  "backToLogin": "Torna alla pagina di accesso",
  "newPassword": "Nuova password",
  "passwordConfirmation": "Conferma nuova password"
}
</i18n>

<i18n locale="pl">
{
  "backToLogin": "Powrót do strony logowania",
  "newPassword": "Nowe hasło",
  "passwordConfirmation": "Potwierdź nowe hasło"
}
</i18n>
