<template>
  <div class="error-404">
    <div class="error-404__title">
      {{ $t('oops') }}
    </div>
    <div class="error-404__code">
      {{ $t('404') }}
    </div>
    <div class="error-404__desc">
      {{ $t('desc') }}
    </div>
    <a class="error-404__home" href="https://web.pysae.com/" alt="Home" />
    <div class="error-404__logo" />
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss">
$font-sizes: (
  'max-width: 1440px': 10px,
  'max-width: 1024px': 8px,
  'max-height: 450px': 4px,
);
$bg-img-urls: (
  'min-width: 1440px': url('../../assets/img/404/bg-2880.jpg'),
  'max-width: 1440px': url('../../assets/img/404/bg-1440.jpg'),
  'max-width: 512px': url('../../assets/img/404/bg-512.jpg'),
);

.error-404 {
  @each $breakpoint, $bg-img-url in $bg-img-urls {
    @media screen and ($breakpoint) {
      & {
        background-image: $bg-img-url;
      }
    }
  }

  @each $breakpoint, $font-size in $font-sizes {
    @media screen and ($breakpoint) {
      & {
        font-size: $font-size;
      }
    }
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 256px;
  background: no-repeat center center / cover;

  &__title {
    color: $text-light;
    font-size: 4.5em;
    text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }

  &__code {
    color: rgb(255 255 255 / 60%);
    font-size: 20.5em;
    filter: blur(1px);
  }

  &__desc {
    color: $text-light;
    font-size: 2.5em;
    text-align: center;
  }

  &__home {
    min-width: 6.5em;
    min-height: 6.5em;
    margin-top: 5em;
    border: 0.4em solid $text-light;
    border-radius: 0.5em;
    background: url('../../assets/img/home-smile.svg') no-repeat center center / cover;
  }

  &__logo {
    position: fixed;
    right: 1em;
    bottom: 1em;
    min-width: 13em;
    min-height: 4.4em;
    margin: 0 1em;
    background: url('../../assets/img/pysae-256-white.png') no-repeat center center / contain;
  }
}
</style>

<i18n locale="fr">
{
  "404": "404",
  "desc": "Cette page n'est pas desservie.",
  "oops": "Oups …"
}
</i18n>

<i18n locale="en">
{
  "404": "404",
  "desc": "This page is not serviced.",
  "oops": "Oops …"
}
</i18n>
