<template>
  <div class="searchbox" @click="showSearchResult()">
    <v-icon class="searchbox__icon">fa:fas fa-search</v-icon>
    <input
      v-model="input"
      class="input"
      :placeholder="$t('search')"
      type="text"
      @keypress.enter="navigateToResult(focusedResultIndex)"
    />

    <div v-if="input.length >= MIN_INPUT_LENGTH && searchShow" class="results-wrapper">
      <v-tabs v-model="selectedTab" slider-color="#00b871">
        <v-tab
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :prepend-icon="tab.icon"
          class="results-wrapper__v-tabs"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ul class="results-list">
        <!-- loading -->
        <li v-if="isLoading">
          <div class="result result--empty">
            <font-awesome-icon icon="fa-spinner" />
          </div>
        </li>

        <!-- no result -->
        <li v-else-if="results.length === 0">
          <div class="result result--empty">
            {{ $t('noResults') }}
            <Btn
              v-if="[SearchResultCategory.ALL, SearchResultCategory.TRIPS].includes(selectedTab)"
              :route="{ name: GroupRoute.TRIP_LIST }"
              type="primary"
            >
              {{ $t('seeTrips') }}
            </Btn>
            <Btn
              v-else-if="SearchResultCategory.DEVICES === selectedTab"
              :route="{ name: GroupRoute.DEVICE_LIST }"
              type="primary"
            >
              {{ $t('seeDevices') }}
            </Btn>
            <Btn
              v-else-if="SearchResultCategory.STOPS === selectedTab"
              :route="{ name: GroupRoute.STOP_LIST }"
              type="primary"
            >
              {{ $t('seeStops') }}
            </Btn>
          </div>
        </li>

        <!-- Result list -->
        <template v-if="results.length > 0">
          <li
            v-for="(result, index) in results"
            :key="`${result.type}_${result.id}`"
            class="result"
            :class="{ 'result--focused': index === focusedResultIndex }"
            @mouseenter="focusedResultIndex = index"
          >
            <router-link
              tabindex="0"
              class="result__link"
              :to="getLinkFromObject(result.type, result.id)"
              @click="input = ''"
            >
              <div class="result__icon">
                <font-awesome-icon :icon="getTypeIcon(result.type)" />
              </div>

              <div class="result__infos">
                <div class="result__title">
                  <!-- Status indicator for devices -->
                  <span v-if="result.type === SearchResultType.DEVICE">
                    <span v-if="result.status === DeviceStatus.ONLINE">
                      <font-awesome-icon icon="fa-circle" class="device-status device-status--online" />
                    </span>
                    <span v-else>
                      <font-awesome-icon icon="fa-circle" class="device-status device-status--offline" />
                    </span>
                    "
                  </span>

                  <Highlighter :parts="result.partsName" class="result__title" />
                </div>

                <div class="result__sub">
                  <Highlighter :parts="result.partsSub" />
                </div>
              </div>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import Highlighter from '@/components/ui/Highlighter.vue';
import { getISODate } from '@/libs/helpers/dates';
import { normalize, RE_ESCAPE } from '@/libs/helpers/strings';
import { GroupRoute } from '@/libs/routing';

/** @enum {number} */
const DeviceStatus = {
  OFFLINE: 0,
  ONLINE: 1,
};

/** @enum {string} */
const SearchResultType = {
  DEVICE: 'device',
  STATION: 'station',
  STOP: 'stop',
  TRIP: 'trip',
};

const SearchResultCategory = {
  ALL: 'all',
  DEVICES: 'devices',
  TRIPS: 'trips',
  STOPS: 'stops',
};

const tabs = [
  { value: SearchResultCategory.ALL, label: 'Tout' },
  { value: SearchResultCategory.DEVICES, label: 'Appareils', icon: 'fa:fas fa-mobile-alt' },
  { value: SearchResultCategory.TRIPS, label: 'Courses', icon: 'fa:fas fa-bus' },
  { value: SearchResultCategory.STOPS, label: 'Arrêts', icon: 'fa:fas fa-map-marker-alt' },
];

/** @type {Map.<SearchResultType, string>} */
const TYPE_ICON = new Map([
  [SearchResultType.DEVICE, 'fa:fas fa-mobile-alt'],
  [SearchResultType.STOP, 'fa:fas fa-map-marker-alt'],
  [SearchResultType.STATION, 'fa:fas fa-map-marker-alt'],
  [SearchResultType.TRIP, 'fa:fas fa-bus'],
]);

const MIN_INPUT_LENGTH = 2;

export default {
  name: 'SearchBox',

  components: {
    Btn,
    Highlighter,
  },

  data: () => ({
    DeviceStatus,
    GroupRoute,
    MIN_INPUT_LENGTH,
    SearchResultCategory,
    SearchResultType,
    tabs,
    input: '',

    /** @type {?Array<import('@/store/gtfs').Stop>} */
    listStops: null,

    /** @type {?Array<import('@/store/gtfs').Trip>} */
    listTrips: null,

    searchIn: {
      devices: true,
      stops: true,
      trips: true,
    },
    searchShow: false,
    tripsFormattedNames: null,
    selectedTab: SearchResultCategory.ALL,
    focusedResultIndex: 0,
  }),

  computed: {
    /** @return {?string} */
    groupId() {
      return this.$store.getters.group._id;
    },

    /** @return {boolean} */
    isLoading() {
      let loading = false;

      if (this.searchIn.devices && this.listDevices === null) {
        loading = loading || true;
      }

      if (this.searchIn.stops && this.listStops === null) {
        loading = loading || true;
      }

      if (this.searchIn.trips && this.listTrips === null && this.tripsFormattedNames === null) {
        loading = loading || true;
      }

      return loading;
    },

    /** @return {Array<import('@/store/devices').Device>} */
    listDevices() {
      return Object.values(this.$store.state.devices.list);
    },

    /** @return {Object} */
    results() {
      switch (this.selectedTab) {
        case SearchResultCategory.DEVICES:
          return this.searchDevices;
        case SearchResultCategory.TRIPS:
          return this.searchTrips;
        case SearchResultCategory.STOPS:
          return this.searchStops;
        default:
          return [...this.searchDevices, ...this.searchTrips, ...this.searchStops];
      }
    },

    /** @return {Array<DeviceResult>} */
    searchDevices() {
      if (
        !this.searchIn.devices ||
        this.listDevices === null ||
        this.input === '' ||
        this.input.length < MIN_INPUT_LENGTH
      )
        return [];

      let input = normalize(this.input);
      input = input.replace(RE_ESCAPE, '\\$&');
      const re = new RegExp(input, 'ig');

      /** @type {Array<DeviceResult>} */
      const results = [];
      this.listDevices.forEach(device => {
        if (device.archived) return;

        const normalizedName = normalize(device.name);
        const normalizedId = normalize(device.device_id);

        const matchName = re.exec(normalizedName);
        const matchId = re.exec(normalizedId);
        if (matchName || matchId) {
          let priority;
          if (matchName) priority = matchName.index;
          if (matchId) priority = Math.min(priority, matchId.index);

          let status = DeviceStatus.OFFLINE;
          if (this.$store.state.devices.online[device.device_id]) {
            status = DeviceStatus.ONLINE;
          }

          const name = device.name || /** @type {string} */ (this.$t('noName'));
          results.push({
            id: device.device_id,
            name,
            priority,
            partsName: this.highlightSearchedParts(name),
            partsSub: this.highlightSearchedParts(device.device_id),
            status,
            type: SearchResultType.DEVICE,
          });
        }
      });

      results.sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
        return normalize(a.name) < normalize(b.name) ? -1 : 1;
      });

      return results;
    },

    /** @return {Array<Result>} */
    searchStops() {
      if (
        !this.searchIn.stops ||
        this.listStops === null ||
        this.input === '' ||
        this.input.length < MIN_INPUT_LENGTH
      )
        return [];

      let input = normalize(this.input);
      input = input.replace(RE_ESCAPE, '\\$&');
      const re = new RegExp(input, 'ig');

      /** @type {Array<Result>} */
      const results = [];
      this.listStops.forEach(stop => {
        const normalizedId = normalize(stop.stop_id);
        const normalizedName = normalize(stop.stop_name);

        const matchId = re.exec(normalizedId);
        const matchName = re.exec(normalizedName);
        if (matchId || matchName) {
          let priority;
          if (matchId) priority = matchId.index;
          if (matchName) {
            if (priority) priority = Math.min(priority, matchName.index);
            else priority = matchName.index;
          }

          results.push({
            id: stop.stop_id,
            name: stop.stop_name,
            priority,
            partsName: this.highlightSearchedParts(stop.stop_name),
            partsSub: this.highlightSearchedParts(stop.stop_id),
            type: stop.location_type === '0' ? SearchResultType.STOP : SearchResultType.STATION,
          });
        }
      });

      results.sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
        return normalize(a.name) < normalize(b.name) ? -1 : 1;
      });

      return results;
    },

    /** @return {Array<Result>} */
    searchTrips() {
      if (
        this.input === '' ||
        this.input.length < MIN_INPUT_LENGTH ||
        !this.searchIn.trips ||
        this.listTrips === null ||
        this.tripsFormattedNames === null
      )
        return [];

      let input = normalize(this.input);
      input = input.replace(RE_ESCAPE, '\\$&');
      const re = new RegExp(input, 'ig');

      /** @type {Array<Result>} */
      const results = [];
      this.listTrips.forEach(trip => {
        const normalizedId = normalize(trip.trip_id);
        const normalizedName = normalize(this.tripsFormattedNames.get(trip.trip_id));

        const matchId = re.exec(normalizedId);
        const matchName = re.exec(normalizedName);
        if (matchId || matchName) {
          let priority;
          if (matchId) priority = matchId.index;
          if (matchName) {
            if (priority) priority = Math.min(priority, matchName.index);
            else priority = matchName.index;
          }

          const tripsFormattedNames = this.tripsFormattedNames.get(trip.trip_id);
          results.push({
            id: trip.trip_id,
            name: tripsFormattedNames,
            priority,
            partsName: this.highlightSearchedParts(tripsFormattedNames),
            partsSub: this.highlightSearchedParts(trip.trip_id),
            type: SearchResultType.TRIP,
          });
        }
      });

      results.sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
        return normalize(a.name) < normalize(b.name) ? -1 : 1;
      });

      return results;
    },
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        if (this.groupId != null) {
          this.fetchCollections();
        }
      },
    },

    input() {
      this.focusedResultIndex = 0;
      if (this.input.length < MIN_INPUT_LENGTH) {
        this.selectedTab = SearchResultCategory.ALL;
      }
    },

    $route() {
      this.input = '';
      this.selectedTab = SearchResultCategory.ALL;
    },
  },

  methods: {
    closeSearchResult() {
      this.searchShow = false;
      window.removeEventListener('click', this.closeSearchResult);
    },
    /**
     * Search a part string in a string and split it arround the part if found
     * @param {string} textToSearch
     * @param {string} searchedPart
     * @return {Array<string>}
     */
    highlightSearchedParts(textToSearch, searchedPart = this.input) {
      let result = [];
      if (textToSearch?.toLowerCase().includes(searchedPart.toLowerCase())) {
        // Split string while keeping the 'split' part
        const regex = new RegExp(`(${searchedPart})`, 'i');
        result = textToSearch.split(regex);
      } else {
        result = [textToSearch];
      }
      return result;
    },
    async fetchCollections() {
      await Promise.all([
        this.$store.dispatch('gtfs/getStopsMap').then(listStops => {
          this.listStops = Object.values(listStops);
        }),

        this.$store.dispatch('gtfs/getTripsMap').then(async listTrips => {
          this.listTrips = listTrips ? Object.values(listTrips) : [];
          await this.generateTripsFormattedNames();
        }),
      ]);
    },

    /** @param {string} arg */
    filterResult(arg) {
      Object.keys(this.searchIn).forEach(key => {
        if (arg === 'all' || arg === key) {
          this.searchIn[key] = true;
        } else {
          this.searchIn[key] = false;
        }
      });
    },

    async generateTripsFormattedNames() {
      this.tripsFormattedNames = null;
      if (!this.listTrips) return;

      const tripsFormattedNames = new Map();
      await Promise.all(
        this.listTrips.map(async trip => {
          const name = await this.$store.dispatch('gtfs/formatTripName', {
            tripId: trip.trip_id,
            date: new Date(),
          });
          tripsFormattedNames.set(trip.trip_id, name);
        }),
      );

      this.tripsFormattedNames = tripsFormattedNames;
    },

    /**
     * @param {SearchResultType} objectName
     * @param {string} id
     * @return {import('vue-router').Location}
     */
    getLinkFromObject(objectName, id) {
      switch (objectName) {
        case SearchResultType.STOP:
        case SearchResultType.STATION:
          return { name: GroupRoute.STOP_DETAILED, params: { stopId: id } };
        case SearchResultType.TRIP:
          return {
            name: GroupRoute.TRIP_DETAILED,
            params: { tripId: id },
            query: { date: getISODate(new Date()) },
          };
        case SearchResultType.DEVICE:
          return { name: GroupRoute.DEVICE_DETAILLED, params: { deviceId: id } };
        default:
          return {};
      }
    },

    /**
     * @param {SearchResultType} type
     * @return {string}
     */
    getTypeIcon(type) {
      return TYPE_ICON.get(type);
    },

    showSearchResult() {
      this.searchShow = true;
      window.removeEventListener('click', this.closeSearchResult);
      setTimeout(() => window.addEventListener('click', this.closeSearchResult));
    },

    navigateToResult(index) {
      if (this.results.length === 0) return;
      const result = this.results[index];
      const link = this.getLinkFromObject(result.type, result.id);
      this.$router.push(link);
      this.input = '';
      this.selectedTab = SearchResultCategory.ALL;
    },
  },
};

/**
 * @typedef {Object} Result
 * @property {string} id
 * @property {string} name
 * @property {number} priority
 * @property {SearchResultType} type
 * @property {Array<string>} partsName
 * @property {Array<string>} partsSub
 */

/**
 * @typedef {Result} DeviceResult
 * @property {string} number
 * @property {DeviceStatus} status
 */
</script>

<style lang="scss">
.searchbox {
  position: relative;
  display: flex;
  align-items: center;

  &__icon {
    position: absolute;
    left: 12px;
    padding: 10px;
    padding-left: 5px;
    font-size: 16px;
  }

  input {
    height: 36px;
    padding: 4px 10px 4px 36px;
    border: 1px solid $border;
    border-radius: 5px;
    background-color: $canvas;
    box-shadow: none !important;
    font: inherit;
  }

  input:focus {
    border: 1px solid $text-dark-variant;
  }

  input::placeholder {
    color: red;
    color: $text-neutral;
  }

  .results-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: $dropdown-index;
    overflow: auto;
    width: 500px;
    max-height: 400px;
    margin-top: 8px;
    border: 1px solid $border;
    border-radius: 8px;
    background-color: $canvas;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 30%);

    &__v-tabs {
      margin-left: 5px;
      color: $text-dark !important;
      font-size: 12px;
    }
  }

  .results-list {
    margin-top: -2px;
  }

  .result {
    border-top: 2px solid $border;

    &--empty {
      padding: 18px;
      background-color: $background;
      color: $text-dark-variant;
      font-weight: 600;
      font-size: 16px;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      .ui-btn--link {
        margin-top: -20px;
        margin-left: 15px;
        padding: 2px 8px;
        font-weight: 500;
        font-size: 12px;
      }
    }

    &--focused {
      background-color: $background-variant;

      .result__title {
        text-decoration: underline;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      color: $text-dark;
      font-size: 12px;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: $background-variant;

        .result__title {
          text-decoration: underline;
        }
      }

      .device-status {
        display: inline-block;
        margin-right: 5px;
        font-size: 10px;

        &--online {
          color: $primary-light;
        }

        &--offline {
          color: $danger;
        }
      }
    }

    &__icon {
      padding: 10px;
      padding-left: 5px;
    }

    &__sub {
      color: $text-neutral;
    }

    &__infos {
      display: flex;
      flex: 1;
      flex-direction: column;

      .highlight {
        color: $primary-light;
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "noResults": "Aucun résultat",
  "search": "Recherche",
  "EVERYTHING": "TOUT",
  "seeTrips": "Voir les courses",
  "seeDevices": "Voir les appareils",
  "seeStops": "Voir les arrêts"
}
</i18n>

<i18n locale="en">
{
  "noResults": "No results",
  "search": "Search",
  "EVERYTHING": "ALL",
  "seeTrips": "See trips",
  "seeDevices": "See devices",
  "seeStops": "See stops"
}
</i18n>

<i18n locale="es">
{
  "search": "Buscar",
  "noResults": "Ningún resultado",
  "EVERYTHING": "TODO"
}
</i18n>

<i18n locale="de">
{
  "search": "Suche",
  "noResults": "Kein Ergebnis",
  "EVERYTHING": "ALLE"
}
</i18n>

<i18n locale="cz">
{
  "search": "Vyhledávání",
  "noResults": "Žádný výsledek",
  "EVERYTHING": "VŠE"
}
</i18n>

<i18n locale="pl">
{
  "search": "Wyszukaj",
  "noResults": "Brak wyniku",
  "EVERYTHING": "WSZYSTKIE"
}
</i18n>

<i18n locale="it">
{
  "search": "Cerca",
  "noResults": "Nessun risultato",
  "EVERYTHING": "TUTTO"
}
</i18n>
