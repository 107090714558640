<template>
  <span
    class="route-badge"
    :style="badgeStyle"
    :title="isExperimental ? $t('tooltipRouteExperimental') : value"
    :class="{ 'route-badge--experimental': isExperimental }"
  >
    <v-icon v-if="isExperimental">fa:fas fa-flask</v-icon>
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'RouteBadge',

  props: {
    isExperimental: {
      type: Boolean,
      default: false,
    },
    /** @type {import('vue').Prop<import('@/store/gtfs').Route>} */
    route: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    badgeStyle() {
      if (!this.isExperimental) {
        return {
          'background-color': `#${this.route.route_color || 'FFFFFF'}`,
          color: `#${this.route.route_text_color || '000000'}`,
        };
      }
      return {
        'border-color': `#${this.route.route_color || '#333333'}`,
      };
    },
  },
};
</script>

<style lang="scss">
.route-badge {
  flex-shrink: 0;
  overflow: hidden;
  max-width: 5em;
  padding: 0.2em 0.5em;
  border-radius: 0.2em;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--experimental {
    height: 24px;
    border: 1px solid;
    background-color: $white;
    color: $text-dark;
  }

  .v-icon.fa-flask {
    margin-top: -2px;
    font-size: 10px;
  }
}
</style>
