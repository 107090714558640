import './datadog';

import { createApp } from 'vue';
import VueScrollTo from 'vue-scrollto';
import { v4 as uuid } from 'uuid';
import VueMatomo from 'vue-matomo';
import Toast from 'vue-toastification';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* We import all solid icons */
import { fas } from '@fortawesome/free-solid-svg-icons';
/* We import only the regular icons we need */
import { faMap } from '@fortawesome/free-regular-svg-icons';
import maintenanceChecker from '@/libs/maintenanceChecker';
import '@/assets/scss/components/toast.scss';
import i18n from './i18n';
import vuetify from './libs/vuetify';
import router from './router';
import store from './store/index.js';
import auth0 from './auth0';
import App from './App.vue';
import { ToastOptions } from './libs/toast';
import { createPinia } from 'pinia';

import './yup';

/* add icons to the library */
library.add(fas, faMap);

export const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
// https://github.com/AmazingDreams/vue-matomo
app.use(VueMatomo, {
  host: 'https://pysae.matomo.cloud',
  siteId: window.location.hostname === 'localhost' ? 3 : 1,
  userId: uuid(),
});

app.component('FontAwesomeIcon', FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(auth0);
app.use(vuetify);
app.use(i18n);
app.use(Toast, ToastOptions);
app.use(VueScrollTo);
app.use(maintenanceChecker, {
  router,
  poll: () => fetch('/api/v2/groups'),
});
app.mount('#app');
