<template>
  <ul>
    <li v-for="(value, index) in delays" :key="index" class="device-delay" :class="delayClass(value)">
      {{ value != null ? $t('delayMn', [value]) : '-' }}
    </li>
  </ul>
</template>

<script>
import { DelayState } from '@/store/devices';

const DELAY_CLASS = {
  [DelayState.EARLY]: 'too-early',
  [DelayState.LATE]: 'too-late',
  [DelayState.ON_TIME]: 'on-time',
};

export default {
  name: 'CellDelay',

  props: {
    // in minutes
    /** @type {Vue.PropOptions<number | Array<number>>} */
    delay: {
      type: [Number, Array],
      default: null,
    },
  },

  computed: {
    /** @return {Array<number>} */
    delays() {
      return Array.isArray(this.delay) ? this.delay : [this.delay];
    },
  },

  methods: {
    /**
     * @param {?number} delay
     * @return {string}
     */
    delayClass(delay) {
      if (delay == null) return '';

      // Conversion of delay (minutes) in seconds
      const delaySec = delay * 60;

      const delayState = this.$store.getters['devices/getDelayState'](delaySec);
      return `device-delay--${DELAY_CLASS[delayState]}`;
    },
  },
};
</script>

<style lang="scss">
.device-delay {
  line-height: 1.5em;

  &--too-early {
    color: $danger;
  }

  &--too-late {
    color: $warn;
  }

  &--on-time {
    color: $primary-light;
  }
}
</style>

<i18n locale="fr">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="en">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="es">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="de">
{
  "delayMn": "{0} Min."
}
</i18n>

<i18n locale="cz">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="pl">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="it">
{
  "delayMn": "{0} min"
}
</i18n>
