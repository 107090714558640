/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module */
import Api from '@/api';
import { arrToFakeMap } from '@/libs/helpers/objects';

/**
 * @param {Driver} driver
 * @return {string}
 */
export const formatDriver = driver =>
  driver.staff_number ? `${driver.driver_name} (${driver.staff_number})` : `${driver.driver_name}`;

const setId = obj => {
  if (!obj._id && obj.id) {
    obj._id = obj.id;
    delete obj.id;
  }
};

/** @typedef {typeof state} State */
const state = {
  /**
   * Map<DriverId, Driver>
   * @type {Object<string, Driver>}
   */
  list: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,

  state,

  mutations: {
    setList(state, list) {
      state.list = list;
    },
    addOrUpdate(state, driver) {
      state.list[driver._id] = driver;
    },
    delete(state, driverId) {
      delete state.list[driverId];
    },
  },
  getters: {
    /**
     * Get by id a Driver representation in format 'driver_name (staff_number)'
     * @callback getDriverInfosByIdCallback
     * @param {string} driverId
     * @return {string}
     * */
    /** @return {getDriverInfosByIdCallback} */
    getDriverInfosById: state => driverId => {
      const vehicle = state.list[driverId];
      if (!vehicle) {
        return null;
      }
      return formatDriver(vehicle);
    },
  },

  actions: {
    async loadList({ commit, rootGetters }) {
      let list = await Api.getDriverList(rootGetters.group._id);
      if (Array.isArray(list)) {
        list.forEach(setId);
        list = list.filter(driver => driver.archived !== true);
        commit('setList', arrToFakeMap(list, '_id'));
      }
    },
    async createDriver({ commit, rootGetters }, driver) {
      const data = await Api.drivers.post(rootGetters.group._id, driver);
      if (data.id) setId(data);
      commit('addOrUpdate', { ...driver, ...data });
    },
    async updateDriver({ commit, rootGetters }, { driverId, driver }) {
      await Api.drivers.put(rootGetters.group._id, driverId, driver);
      commit('addOrUpdate', { ...driver, _id: driverId });
    },
    async deleteDriver({ commit, rootGetters }, driverId) {
      await Api.drivers.delete(rootGetters.group._id, driverId);
      commit('delete', driverId);
    },
    async uploadCsv({ dispatch, rootGetters }, file) {
      await Api.drivers.uploadCsv(rootGetters.group._id, file);
      await dispatch('loadList');
    },
  },
});

/**
 * @typedef {Object} Driver
 * @property {boolean} [archived]
 * @property {string} _id // deprecated
 * @property {string} id
 * @property {string} [comment]
 * @property {string} [code]
 * @property {string} driver_name
 * @property {string} staff_number
 * @property {string} [team_id]
 */
