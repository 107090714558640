<template>
  <span>
    <input
      :id="id"
      :checked="checked"
      class="toggle-switch__input"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
      @click="$emit('click', $event)"
    />
    <label :for="id" class="toggle-switch__label" />
  </span>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    checked: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['change', 'click'],
};
</script>
