<template>
  <Modal modal-class="version-modal-details" :hide-footer="true" @close="$emit('close')">
    <template #title>
      <div>
        {{ $t('versionDetails') }}
      </div>
    </template>

    <template #body>
      <div>
        <span>
          {{ $t('Version') }}
        </span>
        <span>
          {{ version.version }}
        </span>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th style="cursor: pointer" @click="toggleSort('group')">
                {{ $t('group') }}
                <i v-if="sortKey === 'group' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
                <i v-if="sortKey === 'group' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
              </th>
              <th style="cursor: pointer" @click="toggleSort('count')">
                {{ $t('count') }}
                <i v-if="sortKey === 'count' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
                <i v-if="sortKey === 'count' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in sortedGroups" :key="group.group">
              <td>
                <a href="#" @click.prevent="goToGroup(group.group_id)">
                  {{ group.group }}
                </a>
              </td>
              <td>{{ group.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'DetailModal',

  components: {
    Modal,
  },

  props: {
    version: {
      required: true,
      type: Object,
    },
  },

  emits: ['close'],

  data() {
    return {
      sortKey: 'group',
      sortOrder: 'asc',
      sortedGroups: [],
    };
  },

  mounted() {
    this.sortedGroups = [...this.version.groups];
    this.applySort();
  },

  methods: {
    goToGroup(groupId) {
      this.$router.push({ name: GroupRoute.DEVICE_LIST, params: { groupId: groupId } });
    },

    toggleSort(key) {
      if (this.sortKey === key) {
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc';
        } else if (this.sortOrder === 'desc') {
          this.sortOrder = '';
          this.sortKey = '';
        } else {
          this.sortOrder = 'asc';
        }
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }

      this.applySort();
    },

    applySort() {
      if (this.sortOrder === '') {
        this.sortedGroups = [...this.version.groups];
        return;
      }

      let modifier = this.sortOrder === 'asc' ? 1 : -1;

      this.sortedGroups.sort((a, b) => {
        if (this.sortKey === 'group') {
          if (a.group < b.group) return -1 * modifier;
          if (a.group > b.group) return 1 * modifier;
          return 0;
        } else if (this.sortKey === 'count') {
          return (a.count - b.count) * modifier;
        }
      });
    },
  },
};
</script>

<i18n locale="fr">
  {
    "versionDetails": "Détails de la version",
    "Version": "Version: ",
    "group": "Groupe",
    "count": "Nombre d'appareils"
  }
</i18n>

<i18n locale="en">
  {
    "versionDetails": "Version Details",
    "Version": "Version: ",
    "group": "Group",
    "count": "Device Count"
  }
</i18n>
