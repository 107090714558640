import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const CheckboxCell = defineAsyncComponent(() => import('@/components/ui/Checkbox.vue'));
const GroupCell = defineAsyncComponent(() => import('@/pages/AdminPage/GroupCell.vue'));
const CellDate = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  DATE_ADDED: 'date_added',
  EMAIL: 'email',
  NAME: 'name',
  SUPERUSER: 'isSuperuser',
  GROUPS: 'rolesFormatted',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'UsersAdmin',
    hasActions: true,
    searchFields: ['name', 'email'],
    defaultSortBy: [{ key: ColumnKey.EMAIL, order: SortOrder.ASC }],
    showColumnSelector: false,
    rowIdKey: '_id',
    columns: [
      // EMAIL
      new DataGridColumn({
        key: ColumnKey.EMAIL,
        title: 'column.email',
        cellBuilder([value, object], { placeholder, valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: value,
              editMode,
              placeholder,
              type: ColumnKey.EMAIL,
              value,
            },
          };
        },
      }),

      // NAME
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.name',
        cellBuilder([value, object], { placeholder, valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              editMode,
              placeholder,
              type: ColumnKey.NAME,
              value,
            },
          };
        },
      }),

      // GROUPS
      new DataGridColumn({
        key: ColumnKey.GROUPS,
        title: 'column.groupList',
        filterable: true,
        cellBuilder([value, object]) {
          return {
            component: GroupCell,
            props: {
              roles: value,
              superuser: object.superuser,
            },
          };
        },
      }),

      // DATE ADDED
      new DataGridColumn({
        key: ColumnKey.DATE_ADDED,
        title: 'column.dateAdded',
        cellBuilder([value, object]) {
          return {
            component: CellDate,
            props: {
              options: { dateFormat: 'datetimeLong' },
              date: value ? new Date(value) : null,
            },
          };
        },
      }),

      // SUPERUSER
      new DataGridColumn({
        key: ColumnKey.SUPERUSER,
        title: 'column.superuser',
        filterable: true,
        sortable: false,
        i18nPrefix: 'superuserStatus',
        cellBuilder([value, object], { click }) {
          return {
            component: CheckboxCell,
            events: { click },
            props: {
              checked: object.superuser || false,
              noAutoUpdate: true,
              id: object._id,
            },
          };
        },
      }),
    ],
  });
};
