/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import { alerts as alertsAPI } from '../api';

/** @typedef {typeof state} State */
const state = {
  /** @type {{[alertId: string]: Alert}} */
  list: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  mutations: {
    clearList(state) {
      state.list = {};
    },

    delete(state, alertId) {
      delete state.list[alertId];
    },

    set(state, alert) {
      state.list[alert.id] = alert;
    },

    setList(state, alerts) {
      state.list = alerts;
    },
  },

  actions: {
    /**
     * Clear alerts list.
     * @param context
     */
    clear({ commit }) {
      commit('clearList');
    },

    /**
     * Delete an alert.
     * @param context
     * @param {string} alertId
     */
    async delete({ commit, dispatch }, alertId) {
      const group = await dispatch('getGroup', null, { root: true });
      await alertsAPI.delete(group._id, alertId);
      commit('delete', alertId);
    },

    /**
     * Retrieve alerts from server.
     * @param context
     * @return {Promise<{[alertId: string]: Alert}>}
     */
    async getList({ commit, dispatch }) {
      const group = await dispatch('getGroup', null, { root: true });
      const alertsArray = await alertsAPI.getList(group._id);
      const alerts = /** @type {{[alertId: string]: Alert}} */ ({});
      alertsArray.forEach(a => {
        alerts[a._id] = a;
      });
      commit('setList', alerts);

      return alerts;
    },

    /**
     * Change some property of an alert.
     * @param context
     * @param {{alertId: string, changes: Partial<Alert>}} data
     */
    async patch({ state, commit, dispatch }, { alertId, changes }) {
      const alert = { ...state.list[alertId], ...changes };
      const group = await dispatch('getGroup', null, { root: true });
      await alertsAPI.put(group._id, alert);
      commit('set', alert);
    },

    /**
     * Create a new alert.
     * @param context
     * @param {Alert} alert - New alert without id.
     * @return {Promise<string>} Id of the new alert.
     */
    async post({ commit, dispatch }, alert) {
      const group = await dispatch('getGroup', null, { root: true });
      const alertId = await alertsAPI.post(group._id, alert);
      alert._id = alertId;
      commit('set', alert);

      return alertId;
    },

    /**
     * Change an existing alert.
     * @param context
     * @param {Alert} alert
     */
    async put({ commit, dispatch }, alert) {
      const group = await dispatch('getGroup', null, { root: true });
      await alertsAPI.put(group._id, alert);
      commit('set', alert);
    },

    /**
     * Send a push notification for an alert.
     * @param context
     * @param {string} alertId
     */
    async sendPush({ state, commit, rootGetters }, alertId) {
      const { last_push: lastPush } = await alertsAPI.sendPushAlert(rootGetters.group._id, alertId);

      const pushList = [];
      const alert = state.list[alertId];
      if (alert.push) pushList.push(...alert.push);
      pushList.push(lastPush);

      commit('set', { ...alert, push: pushList });
    },
  },
});

/**
 * @typedef {Object} Alert
 * @property {string} _id
 * @property {boolean} active
 * @property {string} header_text
 * @property {Array<ActiveDateTime>} [active_date_times]
 * @property {number} cause
 * @property {number} effect
 * @property {string} url
 * @property {Array<AlertEntity>} [informed_entity]
 * @property {string} [description_text]
 * @property {string} [description_raw]
 * @property {Array<number>} [push]
 */

/**
 * @typedef {Object} ActiveDateTime
 * @property {string} start_date
 * @property {string} start_time
 * @property {string} end_date
 * @property {string} end_time
 */

/**
 * @typedef {Object} AlertEntity
 * @property {string} [route_id]
 * @property {string} [stop_id]
 */
