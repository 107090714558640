/** @module */
import Api from '@/api';

/** @typedef {typeof state} State */
const state = {
  /**
   * Map<UrgencyId, Urgency>
   * @type {{key:string, value: Urgency } | Object}
   */
  list: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,

  state,

  mutations: {
    resetUrgencies(state) {
      state.list = {};
    },

    updateUrgencies(state, urgencies) {
      urgencies.forEach(urgency => {
        if (!state.list[urgency.urgency_id]) {
          state.list[urgency.urgency_id] = urgency;
        } else {
          Object.assign(state.list[urgency.urgency_id], urgency);
        }
      });
    },
  },

  getters: {
    /** @returns {Urgency[]} */
    getNewUrgencies: state => {
      return Object.values(state.list).filter(urgency => urgency.acknowledgment === undefined) || [];
    },

    /** @returns {Urgency[]} */
    getAcknowledgedUrgencies: state => {
      const acknowledgedUrgencies = Object.values(state.list).filter(
        urgency => urgency.acknowledgment !== undefined && urgency.resolution === undefined,
      );
      acknowledgedUrgencies.sort((a, b) => {
        return b.device_time - a.device_time;
      });
      return acknowledgedUrgencies;
    },
  },

  actions: {
    async acknowledgeUrgency({ commit }, { groupId, urgencyId }) {
      const response = await Api.urgencies.acknowledge(groupId, urgencyId);
      return response;
    },

    async resolveUrgency({ commit }, { groupId, urgencyId, isFalseAlert, comment }) {
      const response = await Api.urgencies.resolve(groupId, urgencyId, isFalseAlert, comment);
      return response;
    },
  },
});

/**
 * @typedef {Object} Urgency
 * @property {string} id
 * @property {number} api_time
 * @property {Acknowledgment} [acknowledgment]
 * @property {string} device_id
 * @property {DevicePosition} device_position
 * @property {number} device_time
 * @property {string} driver_id
 * @property {string} event_type
 * @property {string} group_id
 * @property {Resolution} [resolution]
 * @property {string} [team_id]
 * @property {TripInfo} trip
 * @property {string} urgency_id
 * @property {string} vehicle_id
 */

/**
 * @typedef {Object} FormattedUrgency
 * @property {string} driver
 * @property {string} vehicle
 * @property {string} device
 * @property {string} trip
 * @property {string} datetime
 * @property {string} position
 * @property {string} [address]
 */

/**
 * @typedef {Object} DevicePosition
 * @property {number} latitude
 * @property {number} longitude
 */

/**
 * @typedef {Object} TripInfo
 * @property {string} gtfs_id
 * @property {string} id
 * @property {string} start_date
 */

/**
 * @typedef {Object} Acknowledgment
 * @property {number} time
 * @property {string} user_id
 */

/**
 * @typedef {Object} Resolution
 * @property {number} time
 * @property {string} user_id
 * @property {boolean} is_false_alert
 * @property {string} comment
 */
