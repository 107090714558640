import { defineAsyncComponent } from 'vue';

import { DataGrid, DataGridColumn } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const DropListCell = defineAsyncComponent(() => import('@/components/Table/cells/DropListCell.vue'));

const PLANNING_LS_COLUMNS = 'planning/columnsSelection';

/** @enum {string} */
const ColumnKey = {
  DRIVER: 'driver',
  VEHICLE: 'vehicle',
  TRIPS: 'trips',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'planningDatagrid',
    rowIdKey: 'trip.id',
    columnSelectionLocalStorageKey: PLANNING_LS_COLUMNS,
    useCustomNoData: true,
    showColumnSelector: false,
    columns: [
      new DataGridColumn({
        key: ColumnKey.DRIVER,
        title: `column.${ColumnKey.DRIVER}`,
        selectable: false,
        filterable: true,
      }),

      new DataGridColumn({
        key: ColumnKey.VEHICLE,
        title: `column.${ColumnKey.VEHICLE}`,
        selectable: false,
        filterable: true,
      }),

      new DataGridColumn({
        key: ColumnKey.TRIPS,
        title: `column.${ColumnKey.TRIPS}`,
        selectable: false,

        cellBuilder([value, object]) {
          return {
            component: DropListCell,
            props: { values: value },
            events: {},
            value,
          };
        },
      }),
    ],
  });
};
