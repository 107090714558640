<script setup lang="ts">
import { computed, ref } from 'vue';
import Btn from '@/components/ui/Btn.vue';
import { useTripUpdates } from '@/store-pinia/trip-updates';

defineProps({
  disableNameInput: {
    type: Boolean,
    default: false,
  },
});
const tripUpStore = useTripUpdates();
const emit = defineEmits(['cancel', 'validate', 'displayValidStop']);

const localLat = ref<number>();
const localLong = ref<number>();

const lat = computed({
  get(): number | undefined {
    return tripUpStore.stopInEdition?.stop_lat || localLat.value;
  },
  set(lat: number | undefined) {
    if (lat && checkValidLat(lat) && tripUpStore.stopInEdition) {
      tripUpStore.stopInEdition.stop_lat = lat;
    } else {
      if (tripUpStore.stopInEdition) tripUpStore.stopInEdition.stop_lat = undefined;
      localLat.value = lat;
    }
  },
});

const long = computed({
  get(): number | undefined {
    return tripUpStore.stopInEdition?.stop_lon || localLong.value;
  },
  set(long: number | undefined) {
    if (long && checkValidLong(long) && tripUpStore.stopInEdition) {
      tripUpStore.stopInEdition.stop_lon = long;
    } else {
      if (tripUpStore.stopInEdition) tripUpStore.stopInEdition.stop_lon = undefined;
      localLong.value = long;
    }
  },
});

const isValidLat = computed(
  () => tripUpStore.stopInEdition?.stop_lat && checkValidLat(tripUpStore.stopInEdition?.stop_lat),
);
const isValidLong = computed(
  () => tripUpStore.stopInEdition?.stop_lon && checkValidLong(tripUpStore.stopInEdition?.stop_lon),
);

const isFormValid = computed(() => {
  return isValidLat.value && isValidLong.value && tripUpStore.stopInEdition?.stop_name;
});

/**
 * Check if latitude is a valid value
 */
function checkValidLat(lat: number): boolean {
  return isFinite(lat) && Math.abs(lat) <= 90;
}
/**
 * Check if longitude is a valid value
 */
function checkValidLong(long: number): boolean {
  return isFinite(long) && Math.abs(long) <= 180;
}
</script>

<template>
  <div id="updateStopCard" class="temporary-stop-card stop-card">
    <v-card v-if="tripUpStore.stopInEdition" elevation="0" width="100%">
      <v-card-item class="py-3">
        <input
          v-model="tripUpStore.stopInEdition.stop_name"
          :disabled="disableNameInput"
          class="form-group__input"
          :class="{ 'form-group__input--disabled': disableNameInput }"
          :placeholder="$t('temporaryStop')"
          maxlength="255"
        />
      </v-card-item>
      <v-divider class="my-0"></v-divider>

      <v-card-item class="py-3">
        <span class="temporary-stop-card__description">{{ $t('addTemproaryStopDescription') }}</span>
        <div class="temporary-stop-card__position">
          <span class="temporary-stop-card__label">lat.</span>
          <input
            v-model="lat"
            class="form-group__input"
            placeholder="0.0"
            :class="{ 'form-group__input--invalid': !isValidLat }"
          />
          <span class="temporary-stop-card__label">long.</span>
          <input
            v-model="long"
            class="form-group__input"
            placeholder="0.0"
            :class="{ 'form-group__input--invalid': !isValidLong }"
          />
        </div>
      </v-card-item>
    </v-card>
    <div class="stop-card__actions">
      <Btn type="secondary" :smaller="true" @click="emit('cancel')">
        {{ $t('cancel') }}
      </Btn>
      <Btn
        type="primary"
        :smaller="true"
        :disabled="!isFormValid"
        @click="emit('validate', tripUpStore.stopInEdition)"
      >
        {{ $t('save') }}
      </Btn>
    </div>
  </div>
</template>

<style lang="scss">
.temporary-stop-card {
  &__position {
    display: flex;
    gap: 10px;
    padding: 8px 0 4px;

    input {
      width: 100%;
    }
  }

  &__description {
    font-weight: 500;
  }

  &__label {
    margin: auto 0;
  }
}
</style>

<i18n locale="fr">
    {
      "addTemproaryStopDescription": "Définissez la position sur la carte ou en saisissant ses coordonnées :",
    }
</i18n>

<i18n locale="en">
    {
      "addTemproaryStopDescription": "Set stop position on the map or enter its coordinates :",
    }
</i18n>
