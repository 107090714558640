<script setup lang="ts"></script>

<template>
  <div>
    <div class="toast__title">{{ $t('error500') }}</div>
    <div>{{ $t('sorry') }}</div>
    <div>{{ $t('tryAgain') }}</div>
  </div>
</template>

<i18n locale="fr">
  {
    "error500": "Erreur 500",
    "sorry": "Nous sommes désolés, une erreur est survenue.",
    "tryAgain": "Réessayez en modifiant les critères ou la période."
  }
  </i18n>

<i18n locale="en">
  {
    "error500": "Error 500",
    "sorry": "We are sorry, an error has occurred.",
    "tryAgain": "Please try again by changing the criteria or the time period."
  }
  </i18n>
