<template>
  <div class="header-datepicker">
    <div v-if="!withoutArrows" class="header-datepicker__col">
      <button
        v-show="showBeforeBtn"
        class="header-datepicker__button"
        :title="$t('previousDay')"
        @click="previous"
        @mousedown.prevent
      >
        <font-awesome-icon icon="fa-angle-left" />
      </button>
    </div>

    <Datepicker
      v-model:value="date"
      :disabled-dates="disabled"
      :has-custom-position="hasCustomPosition"
      input-class="header-datepicker__input"
    />

    <div v-if="!withoutArrows" class="header-datepicker__col">
      <button
        v-show="showAfterBtn"
        class="header-datepicker__button"
        :title="$t('nextDay')"
        @click="next"
        @mousedown.prevent
      >
        <font-awesome-icon icon="fa-angle-right" />
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from './Datepicker.vue';

export default {
  name: 'HeaderDatepicker',

  components: {
    Datepicker,
  },

  props: {
    disabled: {
      default: () => ({
        minDate: null,
        maxDate: null,
      }),
      type: Object,
    },

    hasCustomPosition: {
      type: Boolean,
      default: true,
    },

    skipDisabled: {
      default: false,
      type: Boolean,
    },

    /** @type {Vue.PropOptions<Date>} */
    value: {
      default: () => new Date(),
      type: Date,
    },

    // display or not previous / next day
    withoutArrows: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['update:value'],

  computed: {
    date: {
      /** @return {Date} */
      get() {
        return new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate());
      },

      /** @param {Date} value */
      set(value) {
        const dateValue = new Date(value.getFullYear(), value.getMonth(), value.getDate());
        this.$emit('update:value', dateValue);
      },
    },

    /** @return {Date} */
    dateAfter() {
      const date = new Date(this.date);
      do {
        date.setDate(date.getDate() + 1);
      } while (this.skipDisabled && this.isDisabled(date) && date < this.disabled.maxDate);
      return date;
    },

    /** @return {Date} */
    dateBefore() {
      const date = new Date(this.date);
      do {
        date.setDate(date.getDate() - 1);
      } while (this.skipDisabled && this.isDisabled(date) && date > this.disabled.minDate);
      return date;
    },

    showAfterBtn() {
      if (this.skipDisabled) {
        return new Date(this.date) < this.disabled.maxDate && !this.isDisabled(this.dateAfter);
      }
      return !this.isDisabled(this.dateAfter);
    },

    showBeforeBtn() {
      if (this.skipDisabled) {
        return new Date(this.date) > this.disabled.minDate && !this.isDisabled(this.dateBefore);
      }
      return !this.isDisabled(this.dateBefore);
    },
  },

  methods: {
    /**
     * @param {Date} date
     * @return {boolean}
     */
    isDisabled(date) {
      return (
        (this.disabled.maxDate && date > this.disabled.maxDate) ||
        (this.disabled.minDate && date < this.disabled.minDate) ||
        (this.disabled.isDateInactive && this.disabled.isDateInactive(date))
      );
    },

    /** Set date to next day */
    next() {
      this.date = this.dateAfter;
    },

    /** Set date to previous day */
    previous() {
      this.date = this.dateBefore;
    },
  },
};
</script>

<style lang="scss">
.header-datepicker {
  --border-color: #b9bcc5;
  --font-color: $text-neutral;

  display: flex;
  align-items: center;
  justify-content: center;

  button,
  .calendar-days {
    color: var(--font-color) !important;
    cursor: pointer;
  }

  &:focus-visible {
    border: none;
  }

  &__button {
    border: 0;
    background-color: transparent;
    font-weight: $font-weight-semi-bold;
    font-size: 1em;
    font-family: $font-poppins !important;
    cursor: pointer;
  }

  &__col {
    label {
      cursor: pointer;
    }

    &:first-child {
      padding-right: 0.2em;
      text-align: right;
    }

    &:last-child {
      padding-left: 0.2em;
      text-align: left;
    }
  }

  .datepicker {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 130px;
    height: 36px;
    padding: 0.5em 12px;
    border: 1px solid var(--border-color);
    border-radius: 2em;
    background: $canvas;
    font-size: 1em;
  }

  &__input {
    display: flex;
    justify-content: center;
    width: 90px;
    padding: 5px 0;
    border: none;
    background-color: transparent;
    color: var(--font-color);
    font-weight: $font-weight-semi-bold;
    font-size: 1em;
    font-family: $font-poppins;
    line-height: 1.5em;
    text-align: center;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__label {
    margin: 0 0.5em;
    font-weight: $font-weight-semi-bold;
    font-size: 1em;
    font-family: $font-poppins;
  }
}
</style>

<i18n locale="fr">
{
  "selectDate": "Choisir une date",
  "from": "Du",
  "to": "Au",
  "previousDay": "Jour précédent",
  "nextDay": "Jour suivant"
}
</i18n>

<i18n locale="en">
{
  "selectDate": "Select a date",
  "from": "From",
  "to": "To",
  "previousDay": "Previous day",
  "nextDay": "Next day"
}
</i18n>

<i18n locale="cz">
{
  "selectDate": "Vyberte datum"
}
</i18n>

<i18n locale="de">
{
  "selectDate": "Datum auswählen"
}
</i18n>

<i18n locale="es">
{
  "selectDate": "Selecciona una fecha"
}
</i18n>

<i18n locale="it">
{
  "selectDate": "Seleziona una data"
}
</i18n>

<i18n locale="pl">
{
  "selectDate": "Wybierz datę"
}
</i18n>
