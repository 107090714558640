<template>
  <div class="mapbox-travel-time"></div>
</template>

<script>
import { MapboxHelper } from '@/components/map/mapboxHelper';

const POINTS_SOURCE_ID = 'pointsSource';
const RADIUS_SOURCE_ID = 'radiusSource';

const POINTS_LAYER_ID = 'pointsLayer';
const RADIUS_LAYER_ID = 'radiusLayer';

export default {
  name: 'MapboxTravelTimeLayer',

  props: {
    /** @type {import('vue').Prop<mapboxgl.Map>} */
    map: {
      type: Object,
      required: true,
    },
    /** @type {import('vue').Prop<Array<GeoJSON.Feature<GeoJSON.Polygon>>>} */
    radiusSource: {
      type: Object,
      default: null,
    },
    /** @type {import('vue').Prop<Array<GeoJSON.Feature<GeoJSON.Point>>>} */
    pointsSource: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loaded: false,
  }),
  watch: {
    radiusSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, RADIUS_SOURCE_ID, this.radiusSource);
      },
    },
    pointsSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, POINTS_SOURCE_ID, this.pointsSource);
      },
    },
  },
  created() {
    this.map.once('idle', () => {
      this.loaded = true;
    });

    this.initSourceAndLayer();
  },
  unmounted() {
    MapboxHelper.cleanLayersAndSources(
      this.map,
      [POINTS_LAYER_ID, RADIUS_LAYER_ID],
      [POINTS_SOURCE_ID, RADIUS_SOURCE_ID],
    );
  },
  methods: {
    initSourceAndLayer() {
      MapboxHelper.createEmptySource(this.map, POINTS_SOURCE_ID);
      MapboxHelper.createEmptySource(this.map, RADIUS_SOURCE_ID);
      this.addPointsLayer();
      this.addRadiusLayer();
    },
    addPointsLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: POINTS_LAYER_ID,
          type: 'circle',
          source: POINTS_SOURCE_ID,
          paint: {
            'circle-radius': 6,
            'circle-color': '#05c0ef',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#000000',
          },
        }),
      );
    },
    addRadiusLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: RADIUS_LAYER_ID,
          type: 'fill',
          source: RADIUS_SOURCE_ID,
          paint: {
            'fill-color': '#ff0000',
            'fill-opacity': 0.35,
          },
        }),
      );
    },
  },
};
</script>
