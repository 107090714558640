// cloned file from https://github.com/jquense/yup/blob/master/src/locale.ts
// overrided to add custom messages structure

import type {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
  TupleLocale,
  LocaleObject,
} from '@/@types/yup';
import { printValue, ValidationError } from 'yup';

export let mixed: Required<MixedLocale> = {
  default: 'This field is invalid',
  required: 'This field is required',
  defined: 'This field must be defined',
  notNull: 'This field cannot be null',
  oneOf: 'This field must be one of the following values: ${values}',
  notOneOf: 'This field must not be one of the following values: ${values}',
  notType: ({ path, type, value, originalValue }) => {
    const castMsg =
      originalValue != null && originalValue !== value
        ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
        : '.';

    return type !== 'mixed'
      ? `This field must be a \`${type}\` type, ` +
          `but the final value was: \`${printValue(value, true)}\`` +
          castMsg
      : `This field must match the configured type. ` +
          `The validated value was: \`${printValue(value, true)}\`` +
          castMsg;
  },
};

export let string: Required<StringLocale> = {
  length: 'This field must be exactly ${length} characters',
  min: 'This field must be at least ${min} characters',
  max: 'This field must be at most ${max} characters',
  matches: 'This field must match the following: "${regex}"',
  email: 'This field must be a valid email',
  url: 'This field must be a valid URL',
  uuid: 'This field must be a valid UUID',
  trim: 'This field must be a trimmed string',
  lowercase: 'This field must be a lowercase string',
  uppercase: 'This field must be a upper case string',
};

export let number: Required<NumberLocale> = {
  min: 'This field must be greater than or equal to ${min}',
  max: 'This field must be less than or equal to ${max}',
  lessThan: 'This field must be less than ${less}',
  moreThan: 'This field must be greater than ${more}',
  positive: 'This field must be a positive number',
  negative: 'This field must be a negative number',
  integer: 'This field must be an integer',
};

export let date: Required<DateLocale> = {
  min: 'This field field must be later than ${min}',
  max: 'This field field must be at earlier than ${max}',
};

export let boolean: BooleanLocale = {
  isValue: 'This field field must be ${value}',
};

export let object: Required<ObjectLocale> = {
  noUnknown: 'This field field has unspecified keys: ${unknown}',
};

export let array: Required<ArrayLocale> = {
  min: 'This field field must have at least ${min} items',
  max: 'This field field must have less than or equal to ${max} items',
  length: 'This field must have ${length} items',
};

export let tuple: Required<TupleLocale> = {
  notType: params => {
    const { path, value, spec } = params;
    const typeLen = spec.types.length;
    if (Array.isArray(value)) {
      if (value.length < typeLen)
        return `This field tuple value has too few items, expected a length of ${typeLen} but got ${
          value.length
        } for value: \`${printValue(value, true)}\``;
      if (value.length > typeLen)
        return `This field tuple value has too many items, expected a length of ${typeLen} but got ${
          value.length
        } for value: \`${printValue(value, true)}\``;
    }

    return ValidationError.formatError(mixed.notType, params);
  },
};

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}) as LocaleObject;
