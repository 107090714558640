<template>
  <div class="cell-device">
    <span v-if="noDevice">
      {{ NO_DATA }}
    </span>
    <ul v-else-if="devices.length > 1">
      <li v-for="(value, index) in devices" :key="index">
        {{ value ? value.name || value.id : NO_DATA }}
      </li>
    </ul>

    <router-link
      v-else-if="devices?.length === 1"
      :to="{
        name: GroupRoute.DEVICE_DETAILLED,
        params: { groupId, deviceId: devices[0].id },
        query: { date: getISODate(date) },
      }"
    >
      {{ devices[0].name || devices[0].id }}
    </router-link>
  </div>
</template>

<script>
import { getISODate } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { NO_DATA } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

export default {
  name: 'CellDevice',

  props: {
    date: {
      type: Date,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<import('@/api').TripListDevice>>} */
    devices: {
      type: Array,
      default: null,
    },

    groupId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      GroupRoute,
      NO_DATA,
    };
  },

  computed: {
    /** @returns {boolean} */
    noDevice() {
      return (
        !this.devices ||
        this.devices?.length === 0 ||
        this.devices[0] === null ||
        this.devices[0] === undefined
      );
    },
  },

  methods: {
    getISODate(date) {
      return getISODate(date);
    },
  },
};
</script>

<style lang="scss">
.cell-device {
  line-height: 1.5em;

  li {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
