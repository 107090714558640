<script setup lang="ts"></script>

<template>
  <div>
    <div>{{ $t('savedToastTitle') }}</div>
  </div>
</template>

<i18n locale="fr">
  {
    "savedToastTitle": "La configuration a été appliquée"
  }
  </i18n>

<i18n locale="en">
  {
    "savedToastTitle": "Configuration has been applied"
  }
  </i18n>
