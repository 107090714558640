<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({});

const props = defineProps({
  height: {
    type: [Number, String],
    required: true,
  },
  totalName: {
    type: String,
    required: true,
  },
  /** @type {Vue.PropOptions<Array<DonutData>>} */
  values: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['hover']);

/** @return {Object} */
const options = computed(() => {
  return {
    colors: props.values.map(v => v.color),

    dataLabels: {
      enabled: false,
    },
    legend: false,

    chart: {
      events: {
        dataPointMouseEnter: function (event, chartContext, opts) {
          emit('hover', opts.dataPointIndex);
        },
        dataPointMouseLeave: function (event, chartContext, opts) {
          emit('hover', null);
        },
      },
    },

    plotOptions: {
      pie: {
        donut: {
          expandOnClick: false,
          labels: {
            show: true,
            total: {
              show: true,
              label: '',
              formatter: () => {
                return total.value;
              },
            },
            name: {
              // 'show: false' creates problems
              formatter: () => {
                return '';
              },
            },
            value: {
              offsetY: '-10%',
            },
          },
        },
      },
    },

    states: { hover: { filter: { type: 'none' } } },

    tooltip: { enabled: false },
  };
});

/** @return {Array<Number>} */
const series = computed(() => {
  return props.values.map(v => v.value);
});

/** @return {Number} */
const vueInnerWidth = computed(() => {
  const leftNavBarWidth = localStorage.getItem('settings.op.navLeftClosed') === 'true' ? 84 : 180;
  return window.innerWidth - leftNavBarWidth;
});

/** @return {String} */
const total = computed(() => {
  const totalNumber = props.values.reduce((acc, el) => {
    const result = acc + el.value;
    return result;
  }, 0);
  return vueInnerWidth.value > 1220 ? totalNumber + t(props.totalName, { count: totalNumber }) : totalNumber;
});

/**
 * @typedef {Object} DonutData
 * @property {string} color
 * @property {string} name
 * @property {number} value
 */
</script>

<template>
  <VueApexCharts :height="props.height" type="donut" :options="options" :series="series" />
</template>
