<template>
  <div class="connector-fieldset-router">
    <div class="form-group connector-fieldset-router__switch-container">
      <ToggleSwitch
        id="activate_router_autoconfiguration"
        class="connector-fieldset-router__toggle-switch"
        :checked="toggleSwitchChecked"
        @change="handleToggleSwitchChange"
      />
      <label for="activate_router_autoconfiguration">{{ $t('activate_router_autoconfiguration') }}</label>
    </div>
    <div v-if="toggleSwitchChecked" class="connector-fieldset-router__configuration-container">
      <connector-field-input :name="`${name}.login`" label="Login"></connector-field-input>
      <connector-field-input
        :name="`${name}.password`"
        type="password"
        label="Password"
      ></connector-field-input>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useField } from 'vee-validate';
import ConnectorFieldInput from '@/pages/IntegrationsPage/ConnectorFieldInput.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'router',
  },
});

const { value, setValue } = useField(computed(() => props.name));

const toggleSwitchChecked = computed(() => !!value.value);

/**
 * @param {boolean} check
 */
const handleToggleSwitchChange = check => {
  if (!check) {
    setValue(null);
  } else {
    setValue({ login: '', password: '' });
  }
};
</script>

<style lang="scss">
.connector-fieldset-router {
  margin-bottom: 10px;
  padding: 12px;
  border: 1px solid $text-neutral;
  border-radius: 10px;

  &__switch-container {
    label {
      margin-left: 8px;
      font-weight: $font-weight-semi-bold;
    }
  }

  &__configuration-container {
    padding: 12px;
  }

  .form-group {
    margin-bottom: 0;
  }
}
</style>

<i18n locale="fr">
{
  "activate_router_autoconfiguration": "Auto-configuration du routeur"
}

</i18n>

<i18n locale="en">
{
  "activate_router_autoconfiguration": "Router auto-configuration"
}
</i18n>
