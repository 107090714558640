<template>
  <div class="radial-bar-chart">
    <div class="radial-bar-chart__title">
      {{ title }}
    </div>
    <div class="radial-bar-chart__chart">
      <VueApexCharts height="300" type="radialBar" :options="options" :series="series" />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: 'RadialBarChart',

  components: {
    VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    value: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      options: {
        colors: ['#00b871'], // $primary-light
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: '#00b871', // $primary-light
                fontSize: '59px',
                show: true,
              },
            },
            hollow: {
              size: '50%',
            },
          },
        },
        // removes hover and select effects
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
      series: [this.value],
    };
  },
};
</script>

<style lang="scss">
.radial-bar-chart {
  height: 370px;
  border-radius: 9px;
  background-color: $background;
  color: $text-dark;
  font-weight: $font-weight-semi-bold;

  &__title {
    padding: 20px 30px;
    font-size: 21px;
  }
}
</style>
