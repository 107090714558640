<script setup lang="ts">
import { onMounted, ref } from 'vue';
import Btn from '@/components/ui/Btn.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import type { Stop } from '@/@types/gtfs';
import { useTripUpdates } from '@/store-pinia/trip-updates';

const tripUpStore = useTripUpdates();

const emit = defineEmits(['cancel', 'validate']);

const filteredList = ref<Array<Stop>>([]);
const selectedStop = ref<Stop | null>(null);
const gtfsStops = ref<Array<Stop>>([]);

onMounted(async () => {
  gtfsStops.value = Object.values(tripUpStore.allGtfsStops);
});

function setSearchedList(list: Array<Stop>) {
  filteredList.value = list;
}

function selectStop(stopId: string) {
  if (selectedStop.value?.stop_id === stopId) selectedStop.value = null;
  else selectedStop.value = gtfsStops.value.find(stop => stop.stop_id === stopId) || null;
  // only for display on map puropose
  if (tripUpStore.stopInEdition && selectedStop.value) {
    tripUpStore.stopInEdition = Object.assign(tripUpStore.stopInEdition, {
      stop_name: selectedStop.value.stop_name,
      stop_lat: selectedStop.value.stop_lat,
      stop_lon: selectedStop.value.stop_lon,
    });
  }
}
</script>

<template>
  <div id="updateStopCard" class="add-existing-stop-card stop-card">
    <v-card elevation="0" width="100%">
      <v-card-item class="pa-0">
        <v-list class="add-existing-stop-card__list py-0">
          <v-list-item class="pa-3">
            <SearchBar
              v-if="gtfsStops.length > 0"
              ref="searchGroup"
              :search-fields="['stop_name', 'stop_id']"
              id-key="stop_id"
              :search-list="gtfsStops"
              @filteredList="setSearchedList"
            />
          </v-list-item>
          <template v-if="filteredList.length > 0">
            <v-divider class="mt-0"></v-divider>
            <div class="add-existing-stop-card__scrollable-list custom-scrollbar">
              <v-list-item
                v-for="(item, index) in filteredList"
                :key="index"
                :class="{
                  'add-existing-stop-card__list__item-active': selectedStop?.stop_id === item.stop_id,
                }"
                :value="index"
                :subtitle="item.stop_id"
                :title="item.stop_name"
                class="mx-3"
                @click="selectStop(item.stop_id)"
              >
                <template #prepend>
                  <v-icon class="list-icon">
                    {{
                      selectedStop?.stop_id === item.stop_id ? 'fa:fas fa-check' : 'fa:fas fa-map-marker-alt'
                    }}
                  </v-icon>
                </template>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-card-item>
    </v-card>
    <div class="stop-card__actions">
      <Btn type="secondary" :smaller="true" @click="emit('cancel')">
        {{ $t('cancel') }}
      </Btn>
      <Btn type="primary" :smaller="true" :disabled="!selectedStop" @click="emit('validate', selectedStop)">
        {{ $t('save') }}
      </Btn>
    </div>
  </div>
</template>

<style lang="scss">
.add-existing-stop-card {
  .table-search-bar__input {
    height: 35px;
  }

  .list-icon {
    color: $text-dark;
    opacity: 1 !important;
  }

  &__list {
    .v-list-item-title {
      font-weight: 600;
    }

    .v-list-item {
      border-radius: 8px !important;
    }

    &__item-active {
      border: 1px solid $primary-light;
      color: $primary-light;

      .list-icon {
        color: $primary-light;
      }
    }
  }

  &__scrollable-list {
    overflow: auto;
    max-height: 175px;
  }
}
</style>
