<template>
  <div class="reports-menu-v2">
    <div class="indicator-select">
      <div class="indicator-select__label">
        {{ $t('indicator') }}
      </div>
      <VueMultiselect
        v-model="localMetric"
        :can-deselect="false"
        :can-clear="false"
        :caret="false"
        :options="metrics"
        label="translation"
      />
    </div>
    <PeriodSelect
      :start-date="startDateObj"
      :end-date="endDateObj"
      :limited-period="isLimitedDatePeriod"
      @datesSelected="$emit('submit-period', { startDateValue: $event.from, endDateValue: $event.to })"
    />
    <Btn
      v-if="oldReportLink"
      type="redirect"
      redirect-icon
      class="reports-menu-v2__redirect-link"
      @click="$emit('updateQuery', { metric: oldReportLink })"
    >
      {{ $t('redirectToOldReport') }}
    </Btn>
    <div v-if="hasData || (!hasData && isDataLoading)" class="reports-menu-v2__download">
      <Btn
        type="primary"
        :data-loading="downloadInProgress"
        class="reports-menu-v2__download-btn"
        @click="downloadReport"
      >
        <span v-if="!downloadInProgress">
          <font-awesome-icon icon="fa-download" />
          <span class="download-text">{{ $t('download') }}</span>
        </span>
        <span v-else>
          <font-awesome-icon icon="fa-circle-notch" spin />
          {{ $t('fileInProgress') }}
        </span>
      </Btn>
    </div>
  </div>
</template>

<script>
import VueMultiselect from '@vueform/multiselect';

import api from '@/api';
import PeriodSelect from '@/components/ui/PeriodSelect.vue';
import Btn from '@/components/ui/Btn.vue';
import { dateGtfsFormatToObj } from '@/libs/helpers/dates';
import { triggerDownloadCSV } from '@/libs/csv';
import { ReportPages } from '@/libs/reports';
import { GroupRoute as RouteName } from '@/libs/routing';

/** @enum {{[key in Metric]: string}} */
export const MetricUrlParam = Object.freeze({
  // TODO: complete other metric URL params
  [RouteName.REPORTING_PASSENGER_COUNTS]: 'passenger-counts',
  [RouteName.REPORTING_TRIP_KM]: 'trip-km',
  [RouteName.REPORTING_TRIP_TRACKING]: 'trip-tracking',
  [RouteName.REPORTING_PUNCTUALITY]: 'punctuality',
});

export default {
  name: 'ReportsMenuV2',

  components: {
    Btn,
    PeriodSelect,
    VueMultiselect,
  },

  props: {
    endDate: {
      type: String,
      required: true,
    },

    downloadLink: {
      type: String,
      default: null,
    },

    /** @type {Vue.PropOptions<ReportPages>} */
    metric: {
      type: String,
      required: true,
    },

    startDate: {
      type: String,
      required: true,
    },

    oldReportLink: {
      type: String,
      default: '',
    },

    hasData: {
      type: Boolean,
      default: null,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit-period', 'updateQuery'],

  data() {
    return {
      downloadInProgress: false,
    };
  },

  computed: {
    /** @return {Date} */
    endDateObj() {
      return dateGtfsFormatToObj(this.endDate);
    },

    /** @type {import('vue').ComputedOptions<string>} */
    localMetric: {
      get() {
        return this.metric;
      },

      set(metric) {
        this.$emit('updateQuery', { metric });
      },
    },

    /** @return {Array<import('@/components/ui/BaseSelect.vue').Option>} */
    metrics() {
      return ReportPages.map(metric => ({
        value: metric,
        translation: this.$t(`metrics.${metric}`).toString(),
      }));
    },

    /** @return {import('@/components/ui/BaseSelect.vue').Option} */
    metricValue() {
      return this.metrics.find(metric => metric.value === this.metric);
    },

    /** @return {Date} */
    startDateObj() {
      return dateGtfsFormatToObj(this.startDate);
    },
    isLimitedDatePeriod() {
      return [RouteName.REPORTING_PUNCTUALITY, RouteName.REPORTING_TRAVEL_TIME].includes(this.metric);
    },
  },

  methods: {
    async downloadReport() {
      this.downloadInProgress = true;
      let blobUrl = null;
      if (
        [
          RouteName.REPORTING_PASSENGERS_APP,
          RouteName.REPORTING_CONNECTED_DEVICES,
          RouteName.REPORTING_TRAVEL_TIME,
        ].includes(this.metric) &&
        this.downloadLink
      ) {
        blobUrl = this.downloadLink;
      } else {
        const reportReady = await api.stats.exportReport(
          this.$store.getters.group._id,
          MetricUrlParam[this.metric],
          this.startDate,
          this.endDate,
        );
        const blob = await reportReady.blob();
        const newBlob = new Blob(['\ufeff', blob]);
        blobUrl = window.URL.createObjectURL(newBlob);
      }

      triggerDownloadCSV(blobUrl, `${this.metric}_report_${this.startDate}_to_${this.endDate}.csv`);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);

      this.downloadInProgress = false;
    },
  },
};
</script>

<style lang="scss">
.reports-menu-v2 {
  display: flex;
  align-items: flex-end;
  padding: $view-standard-padding;
  background: $background;
  box-shadow: 0 4px 8px rgb(0 0 0 / 2%);

  .indicator-select {
    width: 220px;
    margin-right: 10px;

    &__label {
      @include font-default-small($text-neutral);

      height: 20px;
      margin-left: 5px;
      font-weight: $font-weight-semi-bold;
      font-style: normal;
      text-transform: uppercase;
    }

    .multiselect {
      border: none;
      background-color: $canvas;

      &.is-active {
        box-shadow: none;
      }
    }

    .multiselect-dropdown {
      background: $canvas;
    }

    .multiselect-option {
      font-weight: $font-weight-semi-bold;
      font-size: 12px;

      &.is-pointed,
      &.is-selected,
      &.is-pointed.is-selected {
        background-color: inherit;
        color: $primary-light;

        &::before {
          content: '';
        }
      }
    }

    .multiselect-single-label {
      width: 100%;
      color: $primary-light;
      font-weight: $font-weight-semi-bold;
      font-size: 12px;
    }

    .multiselect-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 0 15px;
      border: 2px solid $border;
      border-radius: 5px;
      background: $canvas;
      color: $text-dark;
      box-shadow: none;
      cursor: pointer;
      transition-duration: 0.2s;
      transition-property: all;

      &:hover {
        background: $background;
      }

      &::before {
        content: '\f078';
        position: absolute;
        top: calc(50% - (18px / 2));
        left: 190px;
        height: 18px;
        margin: 0;
        border: none;
        color: $text-dark;
        font-weight: $font-weight-semi-bold;
        font-size: 14px;
        font-family: $font-awesome;
        transition-duration: 0.2s;
        transition-property: transform;
      }
    }

    .multiselect.is-open {
      .multiselect-wrapper {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__indicator-label {
    height: 20px;
    margin-left: 5px;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-style: normal;
    text-transform: uppercase;
  }

  &__redirect-link {
    align-self: flex-end;
    height: 40px;
  }

  &__download {
    align-self: flex-end;
    margin-left: auto;
  }

  &__download-btn {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    min-width: 150px;
  }
}
</style>

<i18n locale="fr">
{
  "fileInProgress": "Préparation du fichier...",
  "indicator" : "Indicateur",
  "redirectToOldReport": "Voir l'ancienne version",
  "download": "Télécharger"
}
</i18n>

<i18n locale="en">
{
  "fileInProgress": "File in preparation...",
  "indicator" : "Indicator",
  "redirectToOldReport": "View the old version",
  "download": "Download"
}
</i18n>
