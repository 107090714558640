/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import { StopTimeEvent } from './trips';
import { CurrentStatus } from './devices';

/** @typedef {typeof state} State */
const state = {
  eventFeed: [],
  focusedTimeStamp: 0,
  lastEventTimeStamp: 0,
  focusedStop: null,
  focusedType: null,
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  mutations: {
    setEventFeed(state, event) {
      state.eventFeed = event;
    },

    setFocusedTimeStamp(state, timestamp) {
      state.focusedTimeStamp = timestamp;
    },

    setFocusedStop(state, stopId) {
      state.focusedStop = stopId;
    },

    setFocusedType(state, type) {
      state.focusedType = type;
    },
    setLastEventTimeStamp(state, ts) {
      state.lastEventTimeStamp = ts;
    },
  },
  getters: {
    maxStopSequence(state) {
      const maxStop = state.eventFeed.reduce((prev, current) => {
        return prev.stop_sequence > current.stop_sequence ? prev : current;
      });
      return maxStop.stop_sequence;
    },
  },

  actions: {
    sendEventFeed({ commit, state, getters }, eventFeed) {
      commit('setEventFeed', eventFeed);
      if (!state.focusedTimeStamp) {
        commit('setFocusedTimeStamp', eventFeed[0].time);
      }
    },

    /**
     * Action on click Timeline
     * @param context
     * @param {{timestamp: number, stopId: string, status: string}} data
     */
    setFocusedByTimeline({ state, commit }, { timestamp, stopId, status }) {
      if (status === CurrentStatus.INCOMING_AT) {
        commit('setFocusedType', StopTimeEvent.ARRIVAL);
        commit('setFocusedStop', stopId);
      } else if (status === CurrentStatus.STOPPED_AT) {
        commit('setFocusedType', StopTimeEvent.DEPARTURE);
        commit('setFocusedStop', stopId);
      } else {
        // Special case when StopTime is In transit to, we need to find previous stop
        const currentEventFeed = state.eventFeed.find(event => event.stopId === stopId);
        if (currentEventFeed?.stop_sequence) {
          const previousSequence = currentEventFeed.stop_sequence - 1;
          const previousEvent = state.eventFeed.find(event => event.stop_sequence === previousSequence);
          if (previousEvent) {
            commit('setFocusedStop', previousEvent.stopId);
            commit('setFocusedType', StopTimeEvent.DEPARTURE);
          }
        }
      }
      commit('setFocusedTimeStamp', timestamp);
    },

    /**
     * Action on click on line feed
     * @param context
     * @param {import('@/pages/TripDetailedPage/EventFeed.vue').EventFeedRow} row
     */
    setFocusedByFeed({ commit, state }, row) {
      commit('setFocusedStop', row.stopId);
      commit('setFocusedType', row.type);
      // Case when clicked on event not passed
      const timestamp = row.isTheoreticalOnly ? state.lastEventTimeStamp : row.time;
      commit('setFocusedTimeStamp', timestamp);
    },

    /**
     * Action on click on trip detail map
     * @param context
     * @param {string} stopId
     */
    setFocusedByMap({ state, commit, getters }, stopId) {
      const currentEventFeed = state.eventFeed.find(event => event.stopId === stopId);
      if (currentEventFeed) {
        // Set stop type to departure if not last stop
        if (currentEventFeed.stop_sequence === getters.maxStopSequence) {
          commit('setFocusedType', StopTimeEvent.ARRIVAL);
        } else {
          commit('setFocusedType', StopTimeEvent.DEPARTURE);
        }
        // Case when clicked on event not passed
        if (!currentEventFeed.isTheoreticalOnly) {
          commit('setFocusedTimeStamp', currentEventFeed.time);
        } else {
          commit('setFocusedTimeStamp', state.lastEventTimeStamp);
        }
        commit('setFocusedStop', currentEventFeed.stopId);
      }
    },
    /**
     * Set last event timestamp
     * @param context
     * @param {number} ts
     */
    setLastEventTs({ commit }, ts) {
      commit('setLastEventTimeStamp', ts);
    },
  },
});
