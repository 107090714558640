<template>
  <div class="input-validation-infos form-group">
    <div
      v-for="(condition, index) in validateConditions"
      :key="index"
      :class="condition.isValid ? 'met-requirements' : 'unmet-requirements'"
    >
      <font-awesome-icon icon="fa-check" />
      {{ condition.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputValidationInfos',

  props: {
    /** @type {import('vue').Prop<Array<ValidateCondition>>} */
    validateConditions: {
      type: Array,
      required: true,
    },
  },
};

/**
 * @typedef {Object} ValidateCondition
 * @property {string} text
 * @property {boolean} isValid
 */
</script>

<style lang="scss">
.input-validation-infos {
  &.form-group {
    position: relative;

    &__input {
      position: relative;
    }
  }

  .met-requirements {
    color: $primary-light;
    text-decoration: line-through;
  }

  .unmet-requirements {
    color: $text-neutral;
  }
}
</style>
