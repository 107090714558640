<template>
  <!-- Modal > Detailed Message -->
  <Modal modal-class="inbox-modal-details" @close="$emit('close')">
    <template #title>
      <div>
        {{ $t('inbox') }}
      </div>
    </template>

    <template #body>
      <!-- Sender detail message -->
      <div class="inbox-modal-details__line">
        <span>
          {{ $t('from') }}
        </span>
        <span>
          <!-- Add a link to device-view -->
          <router-link
            v-if="message.senderId"
            class="inbox-modal-details__link"
            :to="{
              name: GroupRoute.DEVICE_DETAILLED,
              params: { deviceId: message.senderId },
              query: { date: timestampToGtfsFormat(message.sendDate) },
            }"
          >
            {{ message.senderName ?? '-' }}
          </router-link>

          <!-- No link to device-view -->
          <template v-else>
            {{ message.senderName ?? '-' }}
          </template>
        </span>
      </div>

      <!-- Content detail message -->
      <div class="inbox-modal-details__line" v-html="message.content" />
    </template>

    <template #cta>
      <div class="inbox-modal-details__cta">
        <Btn type="primary" @click="$emit('reply')">
          {{ $t('message.reply') }}
        </Btn>

        <Btn type="secondary" @click="$emit('mark-unread')">
          {{ $t('message.markAs.unread') }}
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { timestampToGtfsFormat } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'InboxModalDetails',

  components: {
    Btn,
    Modal,
  },

  props: {
    message: {
      required: true,
      type: Object,
    },
  },

  emits: ['close', 'mark-unread', 'reply'],

  data: () => ({
    GroupRoute,
    timestampToGtfsFormat,
  }),
};
</script>

<style lang="scss" scoped>
.inbox-modal-details {
  &__link {
    margin-left: 5px;
    font-weight: $font-weight-semi-bold;
    text-decoration: underline;
  }

  &__line {
    overflow: hidden;
    max-width: 600px;
    padding-top: 20px;
    padding-bottom: 15px;
    text-overflow: ellipsis;
  }

  &__cta {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ui-btn {
      margin-left: 0;
    }
  }
}
</style>

<i18n locale="fr">
{
  "inbox": "Message reçu",
  "close": "Fermer",
  "from": "De",
}
</i18n>

<i18n locale="en">
{
  "inbox": "Inbox",
  "close": "Close",
  "from": "From",
}
</i18n>
