import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { GroupRoute } from '@/libs/routing';
import { LocationType } from '@/store/gtfs';

const RouteCell = defineAsyncComponent(() => import('./RoutesCell.vue'));

export const STOP_LIST_LS_COLUMNS = 'stopList/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  STOP_NAME: 'stopName',
  PARENT_STATION: 'parentStationName',
  STOP_CODE: 'stop_code',
  ROUTES: 'routesFormatted',
  ZONE_RADIUS: 'stopRadius',
};

/** @enum {import('@/components/Table/DataGridVuetify/models/DataGrid.models').CellBuilder} */
const CellBuilders = {
  STOP([value, object], { groupId }) {
    if (object.location_type?.toString() === LocationType.STATION) {
      return {
        value: null,
      };
    }
    if (!value) return null;
    return {
      component: 'router-link',
      props: {
        to: {
          name: GroupRoute.STOP_DETAILED,
          params: { groupId, stopId: object.stop_id },
        },
      },
      value: value || object.stop_id,
    };
  },
  STATION([value, object], { groupId }) {
    if (object.location_type?.toString() === LocationType.STATION) {
      return {
        component: 'router-link',
        props: {
          to: {
            name: GroupRoute.STOP_DETAILED,
            params: {
              groupId,
              stopId: object.stop_id,
            },
          },
        },
        value: object.stop_name,
      };
    }

    if (!value) return null;
    return {
      component: 'router-link',
      props: {
        to: {
          name: GroupRoute.STOP_DETAILED,
          params: {
            groupId,
            stopId: object.parent_station,
          },
        },
      },
      value: value || object.stop_id,
    };
  },
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(STOP_LIST_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'stopListDatagrid',
    searchFields: ['stop_code', 'stopName'],
    defaultSortBy: [{ key: ColumnKey.STOP_NAME, order: SortOrder.ASC }],
    rowIdKey: 'stop_id',
    columnSelectionLocalStorageKey: STOP_LIST_LS_COLUMNS,
    hasActions: true,
    columns: [
      // STOP NAME
      new DataGridColumn({
        key: ColumnKey.STOP_NAME,
        title: 'column.stopName',
        defaultSelected: defaultSelected(ColumnKey.STOP_NAME),
        selectable: false,
        cellBuilder: CellBuilders.STOP,
      }),

      // PARENT STATION
      new DataGridColumn({
        key: ColumnKey.PARENT_STATION,
        title: 'column.parentStation',
        defaultSelected: defaultSelected(ColumnKey.PARENT_STATION),
        cellBuilder: CellBuilders.STATION,
      }),

      // STOP CODE
      new DataGridColumn({
        key: ColumnKey.STOP_CODE,
        title: 'column.stopCode',
        defaultSelected: defaultSelected(ColumnKey.STOP_CODE),
      }),

      // ROUTES
      new DataGridColumn({
        key: ColumnKey.ROUTES,
        title: 'column.routes',
        defaultSelected: defaultSelected(ColumnKey.ROUTES),
        selectable: false,
        filterable: true,
        cellBuilder([value, object]) {
          return {
            component: RouteCell,
            props: {
              routes: object.routes,
            },
          };
        },
      }),

      // STOP RADIUS
      new DataGridColumn({
        key: ColumnKey.ZONE_RADIUS,
        title: 'column.stopRadius',
        defaultSelected: defaultSelected(ColumnKey.ZONE_RADIUS),
      }),
    ],
  });
};
