import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { GroupRoute } from '@/libs/routing';
import { timestampToGtfsFormat } from '@/libs/helpers/dates';

const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const CellDate = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const PriorityCell = defineAsyncComponent(() => import('@/pages/MessageListPage/cells/PriorityCell.vue'));
const DropListCell = defineAsyncComponent(() => import('@/components/Table/cells/DropListCell.vue'));
const StatusCell = defineAsyncComponent(() => import('@/pages/MessageListPage/cells/StatusCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  URGENT: 'isUrgentText',
  RECIPIENTS: 'recipients',
  CONTENT: 'content',
  STATUS: 'statusText',
  SEND_DATE: 'sendDate',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'sentListDatagrid',
    searchFields: [ColumnKey.CONTENT],
    defaultSortBy: [{ key: ColumnKey.SEND_DATE, order: SortOrder.DESC }],
    hasActions: true,
    selectableList: true,
    showColumnSelector: false,

    columns: [
      // PRIORITY
      new DataGridColumn({
        key: ColumnKey.URGENT,
        title: 'column.priority',
        selectable: false,
        filterable: true,
        cellBuilder([value, object]) {
          return {
            component: PriorityCell,
            props: {
              urgent: object.urgent,
            },
            value,
          };
        },
      }),

      // RECIPIENTS
      new DataGridColumn({
        key: ColumnKey.RECIPIENTS,
        title: 'column.recipient',
        selectable: false,
        cellBuilder([value, object]) {
          if (!value || value.length === 0) return { value: '-' };
          return {
            component: DropListCell,
            props: {
              values: value?.map(recipient => {
                const link = recipient.senderId
                  ? {
                      name: GroupRoute.DEVICE_DETAILLED,
                      params: { deviceId: recipient.senderId },
                      query: { date: timestampToGtfsFormat(object.sendDate) },
                    }
                  : null;
                return {
                  text: recipient.senderName ?? '-',
                  link,
                };
              }),
            },
          };
        },
      }),

      // CONTENT MESSAGE
      new DataGridColumn({
        key: ColumnKey.CONTENT,
        title: 'column.messageContent',
        selectable: false,
        cellBuilder([value, object], { click }) {
          return {
            component: ClickableCell,
            events: { click },
            props: {
              displayValue: value,
            },
            value,
          };
        },
      }),

      // STATUS
      new DataGridColumn({
        key: ColumnKey.STATUS,
        title: 'column.status',
        selectable: false,
        filterable: true,
        cellBuilder([value, object]) {
          return {
            component: StatusCell,
            props: {
              status: object.status,
              title: value,
            },
            value,
          };
        },
      }),
      // SENT DATE
      new DataGridColumn({
        key: ColumnKey.SEND_DATE,
        title: 'column.sendDate',
        selectable: false,
        cellBuilder([value, object]) {
          return {
            component: CellDate,
            props: {
              date: value ? new Date(value) : null,
            },
          };
        },
      }),
    ],
  });
};
