<template>
  <div class="maintenance">
    <div class="maintenance__header">
      <div class="maintenance__title">
        {{ $t('sorry') }}
      </div>
      <div class="maintenance__desc">
        <p>{{ $t('maintenance') }}</p>
        <p>{{ $t('comeback') }}</p>
      </div>
    </div>
    <div class="maintenance__logo" />
  </div>
</template>

<script>
import { maintenance } from '@/libs/maintenanceChecker';

export default {
  name: 'Maintenance',

  created() {
    maintenance.poll();
  },
};
</script>

<style lang="scss">
$font-sizes: (
  'max-width: 1440px': 10px,
  'max-width: 1024px': 8px,
  'max-height: 450px': 4px,
);
$bg-img-urls: (
  'min-width: 1440px': url('../../assets/img/maintenance/bg-2880.jpg'),
  'max-width: 1440px': url('../../assets/img/maintenance/bg-1440.jpg'),
  'max-width: 512px': url('../../assets/img/maintenance/bg-512.jpg'),
);

.maintenance {
  @each $breakpoint, $bg-img-url in $bg-img-urls {
    @media screen and ($breakpoint) {
      & {
        background-image: $bg-img-url;
      }
    }
  }

  @each $breakpoint, $font-size in $font-sizes {
    @media screen and ($breakpoint) {
      & {
        font-size: $font-size;
      }
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 8% 15%;
  background: $white no-repeat center bottom / cover;
  color: $primary-light;

  &__header {
    padding: 2em;
    border-radius: 0.7em;
    background: rgb(255 247 247 / 60%);
    backdrop-filter: blur(7px);
  }

  &__title {
    font-size: 4.5em;
  }

  &__desc {
    font-size: 2.5em;
  }

  &__logo {
    position: absolute;
    right: 1em;
    bottom: 1em;
    min-width: 13em;
    min-height: 4.4em;
    margin: 0 1em;
    border-radius: 0.7em;
    background: rgb(255 247 247 / 60%) url('../../assets/img/pysae-title-256.png') no-repeat center center /
      85%;
  }
}
</style>

<i18n locale="fr">
  {
    "comeback": "Notre site est actuellement en maintenance.",
    "maintenance": "N’hésitez pas à revenir un peu plus tard.",
    "sorry": "Désolé …"
  }
  </i18n>

<i18n locale="en">
  {
    "comeback": "Our site is currently under maintenance.",
    "maintenance": "Please feel free to come back later.",
    "sorry": "Sorry …"
  }
  </i18n>
