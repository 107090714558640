<script setup lang="ts"></script>

<template>
  <div>
    <div>{{ $t('deleteToastTitle') }}</div>
  </div>
</template>

<i18n locale="fr">
  {
    "deleteToastTitle": "La configuration a été supprimée",
  }
  </i18n>

<i18n locale="en">
  {
    "deleteToastTitle": "Configuration has been deleted",
  }
  </i18n>
