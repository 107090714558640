import { DelayState } from '@/store/devices';

// #region  Device
export const LAYER_BEARING = {
  type: 'symbol',

  layout: {
    'icon-allow-overlap': true,
    'icon-image': 'bearing',
    'icon-rotate': ['get', 'bearing'],
    'icon-size': 0.6,
  },

  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
};

export const LAYER_COLOR = {
  type: 'circle',

  paint: {
    'circle-color': ['get', 'color'],
    'circle-radius': 6,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-width': 2,
    'circle-translate': [10, -10],
    'circle-opacity': ['get', 'opacity'],
  },
};

export const LAYER_ROUTE_COLOR = {
  type: 'circle',

  paint: {
    'circle-color': ['get', 'routeColor'],
    'circle-radius': 13,
    'circle-opacity': 1,
    'circle-stroke-width': 3,
    'circle-stroke-opacity': 0.15,
  },
};

export const LAYER_ICON = {
  type: 'symbol',

  layout: {
    'icon-allow-overlap': true,
    'icon-image': 'busIcon',
    'icon-size': 0.3,
  },

  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
};

/** @const {Map<DelayState, string>} */
export const DEVICE_DELAY_COLOR = {
  [DelayState.EARLY]: 'eb5757', // danger
  [DelayState.LATE]: 'f99c49', // $warn
  [DelayState.ON_TIME]: '00b871', // $primary-light
};
// #endregion

// #region Stops
export const COMMON_INTERPOLATE = {
  circleColor: ['#ffffff', '#ffffff', '#ffffff'], // same value twice to match 'case' syntax for circle-color in MapboxStops.vue
  circleStrokeColor: '#00b871',
  circleStrokeWidth: [0, 0.2, 10, 0.5, 16, 1, 22, 2],
  circleOpacity: [0, 0.5, 10, 0.8, 16, 0.9, 22, 1],
};

export const INTERPOLATE = {
  stops: {
    ...COMMON_INTERPOLATE,
    circleRadius: [0, 1, 10, 2, 16, 4, 22, 6],
  },
  stopsBigMarkers: {
    ...COMMON_INTERPOLATE,
    circleRadius: [0, 1.8, 10, 4, 16, 7, 22, 10],
    circleStrokeColor: '#000',
    circleColor: ['#000', '#F99C49', '#ffffff'], // unserved, deviation, served
    circleStrokeWidth: [0, 1, 10, 2.5, 16, 3, 22, 7],
  },
  stations: {
    ...COMMON_INTERPOLATE,
    circleRadius: [0, 1, 10, 2, 16, 6, 22, 8],
  },
};
// #endregion
