<template>
  <div class="connector-field-input">
    <div class="form-group">
      <label class="form-group__label" :for="name">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <input
        :id="name"
        v-model="value"
        :type="inputType"
        class="form-group__input"
        :class="errorMessage ? 'form-group__input--invalid' : ''"
        :name="name"
      />
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed } from 'vue';

const props = defineProps({
  inputType: {
    type: String,
    default: 'text',
  },
  label: {
    type: String,
    default: () => undefined,
  },
  name: {
    type: String,
    required: true,
  },
});

const { value, errorMessage } = useField(computed(() => props.name));
</script>
