import i18n from '@/i18n';

export const AvailableLanguages = ['cs', 'de', 'en', 'es', 'fr', 'it', 'pl'] as const;

type AvailableLanguagesType = (typeof AvailableLanguages)[number];

/**
 * Check if language is in available list
 * @param language
 */
function checkLanguage(language: string): language is AvailableLanguagesType {
  return (AvailableLanguages as Readonly<string[]>).includes(language);
}

/**
 * Get Langage from localStorage or navigator by default
 */
export function getLanguage() {
  let language: string;
  const locale = localStorage.getItem('locale');
  if (locale) {
    language = locale;
  } else {
    const navigatorLanguage = navigator.language.split('-')[0];
    language = navigatorLanguage;
  }
  if (checkLanguage(language)) i18n.global.locale = language;
}
