<script setup>
import { setLocale } from 'yup';
import i18n from '@/i18n';
import yupFr from '@/locales/yup/fr';
import yupEn from '@/locales/yup/en';
import { computed } from 'vue';

const { t } = i18n.global;

const current = computed({
  /** @return {string} */
  get() {
    return i18n.global.locale;
  },

  /** @param {string} locale */
  set(locale) {
    i18n.global.locale = locale;
    if (locale === 'fr') {
      setLocale(yupFr);
    } else {
      setLocale(yupEn);
    }

    localStorage.setItem('locale', locale);
  },
});

const locales = computed(() => {
  return Object.keys(i18n.global.messages).map(locale => ({
    label: t('lang', locale),
    value: locale,
  }));
});

function changeLanguage(lang) {
  current.value = lang;
}
</script>

<template>
  <div class="language-dropdown">
    <v-menu offset="8">
      <template #activator="{ isActive, props }">
        <v-btn class="language-dropdown__btn" variant="text" height="36" min-width="40" v-bind="props">
          <v-icon class="mr-2">fa:fas fa-globe</v-icon>
          {{ $t(current.toLocaleUpperCase()) }}
          <v-icon class="ml-2">{{ isActive ? 'fa:fas fa-angle-up' : 'fa:fas fa-angle-down' }}</v-icon>
        </v-btn>
      </template>
      <v-list class="topbar-dropdown__list language-dropdown__list">
        <v-list-item
          v-for="(item, index) in locales"
          :key="index"
          :value="index"
          @click="changeLanguage(item.value)"
        >
          <v-list-item-title :class="{ 'item-selected': item.value === current }">
            {{ $t(item.label) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="scss">
.language-dropdown {
  &__btn {
    cursor: pointer;

    .v-btn__content {
      font-size: 14px;
      text-transform: capitalize;

      i {
        font-size: 16px;
      }
    }
  }

  &__list {
    .item-selected {
      font-weight: 600;
    }
  }
}
</style>
