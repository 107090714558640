<template>
  <div class="connection-cell">
    <font-awesome-icon
      icon="fa-circle"
      class="connection-cell__icon"
      :class="
        status === DeviceConnectionStatus.ONLINE
          ? 'connection-cell__icon--online'
          : 'connection-cell__icon--offline'
      "
    />
    {{ $t(status === DeviceConnectionStatus.ONLINE ? 'online' : 'offline') }}
  </div>
</template>

<script>
/** @enum {string} */
export const DeviceConnectionStatus = {
  OFFLINE: 'offline',
  ONLINE: 'online',
};

export default {
  name: 'Connectioncell',

  props: {
    /** @type {Vue.PropOptions<DeviceConnectionStatus>} */
    status: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      DeviceConnectionStatus,
    };
  },
};
</script>

<style lang="scss">
.connection-cell {
  &__icon {
    padding: 0 3px 1px;
    font-size: 8px;

    &--online {
      color: $primary-light;
    }

    &--offline {
      color: $text-neutral;
    }
  }
}
</style>

<i18n locale="fr">
{
  "online": "Connecté",
  "offline": "Déconnecté",
}
</i18n>

<i18n locale="en">
{
  "online": "Online",
  "offline": "Offline",
}
</i18n>
