<template>
  <textarea />
</template>

<script>
import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';

export default {
  name: 'JoditEditor',
  props: {
    value: { type: String, required: true },
    buttons: {
      type: Array,
      default: () => [
        'paragraph',
        'font',
        'fontsize',
        'brush',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'align',
        'ul',
        'ol',
        'link',
        'image',
        'spellcheck',
        'source',
        'preview',
      ],
    },
    extraButtons: { type: Array, default: null },
    config: {
      type: Object,
      default: () => ({
        showPlaceholder: false,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        toolbarSticky: false,
      }),
    },
    plugins: { type: Array, default: () => [] },
  },
  emits: ['update:value', 'update:rawValue'],
  data: () => ({
    editor: null,
    isGFTSRTMode: false,
    contentCache: '',
  }),
  computed: {
    editorConfig() {
      const config = { ...this.config };
      const buttons = [
        ...this.buttons,
        {
          name: 'GTFS-RT',
          tooltip: this.$t('rawGTFSRTTooltip'),
          data: { active: false },
          exec: editor => {
            this.switchGTFSRT();
          },
        },
      ];

      if (buttons) {
        config.buttons = buttons;
        config.buttonsMD = buttons;
        config.buttonsSM = buttons;
        config.buttonsXS = buttons;
      }

      if (this.extraButtons) config.extraButtons = this.extraButtons;
      config.language = this.$i18n.locale;

      return config;
    },
  },
  watch: {
    value(newValue) {
      if (this.editor.value !== newValue) this.editor.value = newValue;
    },
  },
  mounted() {
    if (this.plugins.length) {
      this.plugins.forEach(plugin => {
        Jodit.plugins.add(plugin.name, plugin.callback);
      });
    }
    // new Jodit(...) deprecated => Jodit.make(...)
    this.editor = Jodit.make(this.$el, this.editorConfig);
    this.editor.value = this.value;
    this.editor.events.on('change', newValue => this.emitValues(newValue));
  },
  beforeUnmount() {
    this.editor.destruct();
  },
  methods: {
    emitValues(value) {
      // Depending on mode, return value or contentCache
      this.$emit('update:value', this.isGFTSRTMode ? this.contentCache : value);
      // Same for raw value, need to switch
      const rawValue = this.isGFTSRTMode
        ? this.formatGTFSRT(this.contentCache)
        : this.formatGTFSRT(this.editor.value);
      this.$emit('update:rawValue', rawValue);
    },
    /**
     * Switch between HTML text and raw text for GTFS-RT description
     */
    switchGTFSRT() {
      this.isGFTSRTMode = !this.isGFTSRTMode;
      this.switchStateButtons();
      if (this.isGFTSRTMode) {
        let GTFSRTValue = this.formatGTFSRT(this.editor.value);
        GTFSRTValue = `<p contenteditable="false"><span style="white-space: pre-line">${GTFSRTValue}</span></p>`;

        this.contentCache = this.editor.value;
        this.editor.value = GTFSRTValue;
      } else {
        this.editor.value = this.contentCache;
      }
    },
    /**
     * Disable/enable all buttons except GTFS-RT on click
     */
    switchStateButtons() {
      const buttons = document.getElementsByClassName('jodit-toolbar-button__button');
      const subButtons = document.getElementsByClassName('jodit-toolbar-button__trigger');
      Array.prototype.forEach.call(buttons, button => {
        if (button.innerText !== 'GTFS-RT') button.disabled = this.isGFTSRTMode;
      });
      Array.prototype.forEach.call(subButtons, button => {
        if (button.innerText !== 'GTFS-RT') button.style.pointerEvents = this.isGFTSRTMode ? 'none' : '';
      });
    },
    /**
     * Convert an html description to a formated raw description to be used in GTFS-RT
     */
    formatGTFSRT(textToFormat) {
      const htmlDoc = document.createElement('DIV');
      let html = textToFormat;
      // Remove potential backspace in code
      html = html.replace(/(\r\n|\n|\r)/gm, '');
      // If no automatic backspace, add it to div, p, br, li...
      const baliseList = [
        'div',
        'p',
        'br',
        'blockquote',
        'pre',
        'h1',
        'h2',
        'h3',
        'h4',
        'li',
        'ol',
        'ul',
        'dt',
        'dd',
      ];
      baliseList.forEach(elem => {
        const regex = new RegExp(`</${elem}>`, 'g');
        // Add simple backspace
        if (['li', 'ol', 'ul', 'dt', 'dd'].includes(elem)) {
          html = html.replace(regex, `</${elem}>\n`);
          // Add double backspace
        } else {
          html = html.replace(regex, `</${elem}>\n\n`);
        }
      });

      htmlDoc.innerHTML = html;
      // Get html text content (without balises)
      let finalContent = htmlDoc.textContent || htmlDoc.innerText || '';

      // Remove useless linebreak at start and end of content
      while (finalContent.startsWith('\n')) finalContent = finalContent.substring(2);
      while (finalContent.endsWith('\n')) finalContent = finalContent.substring(0, finalContent.length - 2);

      return finalContent;
    },
  },
};
</script>

<style lang="scss">
.jodit-workplace {
  min-height: 160px !important;
  border-radius: 2px;
}

.jodit-status-bar,
.jodit-add-new-line,
.jodit-add-new-line_after {
  display: none;
}

.jodit-toolbar__box:not(:empty, :empty) {
  background-color: #f5f5f5;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  background-color: #f5f5f6;
}

.jodit__preview-box.jodit-context {
  overflow-x: inherit;
  min-width: unset !important;
}

.jodit-dialog__content {
  overflow-x: clip;
}

.jodit-dialog__panel {
  width: 320px !important;
  border-radius: 5px;
}

.jodit-ui-button_variant_primary {
  background-color: $primary-light;
}

.jodit-ui-button_variant_primary:hover:not([disabled]) {
  background-color: $primary-dark;
}

.jodit-ui-button {
  border-radius: 5px;
}

.jodit-workplace a,
.jodit-dialog__content a {
  color: initial;
  text-decoration: underline !important;
  cursor: pointer;
}
</style>

<i18n locale="fr">
{
  "rawGTFSRTTooltip": "Vérifier le texte brut pour le mode GTFS-RT",
}
</i18n>

<i18n locale="en">
{
  "rawGTFSRTTooltip": "Check raw text for GTFS-RT Mode"
}
</i18n>
