import { datadogLogs } from '@datadog/browser-logs';
import { version } from './version';
import { ConsoleApiName } from '@datadog/browser-core';

const env = import.meta.env.VITE_DATADOG_ENV;

datadogLogs.init({
  clientToken: 'puba1a5084eb717a22d129d94199481d304', // op-monitoring
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: [ConsoleApiName.error],

  service: 'op',
  env,
  version: `${version}-${env}`,
});

if (env === 'local') {
  datadogLogs.logger.setHandler('console');
}

export default datadogLogs;
