<script setup>
import { computed, ref, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';

import MapboxMap from '@/components/map/MapboxMap.vue';
import MapboxUrgencyLayer from '@/components/map/MapboxUrgencyLayer.vue';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Pagination from '@/components/ui/Pagination.vue';
import { DatetimeFormat, dateGtfsFormatToObj, dateToFormattedString } from '@/libs/helpers/dates';
import { useVehiclesStore } from '@/store-pinia/vehicles';

const NO_DATA = '-';
const vehiclesStore = useVehiclesStore();
const store = useStore();

const props = defineProps({
  /** @type {import('vue').Prop<Array<import('@/store/urgencies').Urgency>>} */
  urgencies: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['handleAlerts', 'close']);

/** @type {import('vue').Ref<number>} */
const activeUrgency = ref(0);
/** @type {import('vue').Ref<string>} */
const formattedTripName = ref('');
/** @type {import('vue').Ref<mapboxgl.Map | null>} */
const map = ref(null);
/** @type {import('vue').Ref<?[number, number]>} */
const mapCenter = ref(null);
/** @type {import('vue').Ref<boolean>} */
const mapLoaded = ref(false);

/** @return {Array<import('@/store/drivers').Driver>} */
const driverList = computed(() => {
  return Object.values(store.state.drivers.list);
});

/** @return {Array<import('@/store-pinia/vehicles').Vehicle>} */
const vehicleList = computed(() => {
  return Object.values(vehiclesStore.list);
});

/** @return {import('@/store/urgencies').FormattedUrgency} */
const formattedUrgency = computed(() => {
  const urgency = props.urgencies[activeUrgency.value];
  const driver = driverList.value.find(driver => driver.id === urgency.driver_id);
  let formattedDriver;
  if (!driver) {
    formattedDriver = NO_DATA;
  } else {
    formattedDriver = driver?.staff_number
      ? `${driver?.driver_name} (${driver.staff_number || ''})`
      : driver.driver_name;
  }
  const vehicle = vehicleList.value.find(vehicle => vehicle.id === urgency.vehicle_id);
  const date = dateToFormattedString(urgency.device_time, {
    format: DatetimeFormat.DDMMYYYY,
    unix: true,
  });
  const time = dateToFormattedString(urgency.device_time, {
    format: DatetimeFormat.HHMMSS,
    unix: true,
  });
  const position = `N ${urgency.device_position?.latitude}° E ${urgency.device_position?.longitude}°`;
  return {
    driver: formattedDriver || NO_DATA,
    vehicle: vehicle?.license_plate || NO_DATA,
    device: urgency.device_id || NO_DATA,
    trip: formattedTripName.value || NO_DATA,
    datetime: date && time ? `${date} - ${time}` : NO_DATA,
    position: position || NO_DATA,
  };
});

/** @return {GeoJSON.Feature<GeoJSON.Point>} */
const positionSource = computed(() => {
  const { longitude, latitude } = props.urgencies[activeUrgency.value].device_position;
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
    properties: {},
  };
});

watch(
  activeUrgency,
  async () => {
    const urgency = props.urgencies[activeUrgency.value];
    const date = dateGtfsFormatToObj(urgency.trip.start_date);
    formattedTripName.value = await generateFormattedTripName(urgency.trip.id, date);
    mapCenter.value = [urgency.device_position.longitude, urgency.device_position.latitude];
  },
  { immediate: true },
);

/**
 * @param {string} tripId
 * @return {Promise<string>}
 */
async function generateFormattedTripName(tripId, date) {
  return store.dispatch('gtfs/formatTripName', {
    tripId,
    date,
  });
}

/** @param {{map: mapboxgl.Map}} event */
function onMapLoad(event) {
  map.value = event.map;
  mapLoaded.value = true;
}

async function loadDrivers() {
  await store.dispatch('drivers/loadList');
}

async function loadVehicles() {
  await vehiclesStore.loadList();
}

onUnmounted(() => emit('close'));

loadDrivers();
loadVehicles();
</script>

<template>
  <Modal
    modal-class="modal-urgency"
    :width="800"
    :hide-footer="true"
    :has-close-btn="false"
    :forbid-closing="true"
    :persistent="true"
  >
    <template #title>
      <div class="modal-urgency__header">
        <div class="modal-urgency__title">{{ $tc('urgencyAlerts', { count: urgencies.length }) }}</div>
        <div class="modal-urgency__nav">
          <Pagination
            :items="urgencies"
            :short-format="true"
            :initial-page="activeUrgency + 1"
            :page-size="1"
            @changePage="
              page => {
                activeUrgency = page.start;
              }
            "
          />
        </div>
      </div>
    </template>

    <template #body>
      <div class="modal-urgency__body">
        <div class="modal-urgency__infos">
          <div v-for="key in Object.keys(formattedUrgency)" :key="key" class="modal-urgency__info">
            <span class="modal-urgency__label">{{ $t(`label.${key}`) }}</span>
            <span class="modal-urgency__data">{{ formattedUrgency[key] }}</span>
          </div>
        </div>
        <div class="modal-urgency__map">
          <MapboxMap
            :gtfs-id="urgencies[activeUrgency].trip.gtfs_id"
            :stops="[]"
            :trips="[]"
            :center="mapCenter"
            @load="onMapLoad"
          >
            <MapboxUrgencyLayer v-if="mapLoaded" :map="map" :position-source="positionSource" />
          </MapboxMap>
        </div>
      </div>
      <div class="modal-urgency__cta">
        <Btn type="secondary" @click="emit('handleAlerts')">
          <span>{{ $t('handleAlerts') }}</span>
          <font-awesome-icon icon="fa-external-link" />
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-urgency {
  .v-overlay__content {
    padding: 0;
    background-color: $white;
  }

  .modal__header {
    justify-content: space-between;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: $text-dark;
  }

  .modal__title {
    color: $white;
    font-size: 20px;
    text-transform: uppercase;
  }

  .modal__body {
    overflow-y: auto;
  }

  &__body {
    display: flex;
    height: 220px;
    border-bottom: 1px solid $background-variant;
  }

  &__header {
    position: relative;
  }

  &__nav {
    position: absolute;
    top: 5px;
    right: 0;

    .pagination-component {
      margin: 0;
      font-size: 12px;

      .v-icon {
        font-size: 12px;
      }
    }
  }

  &__infos {
    width: 50%;
    padding: 30px;
    font-size: 12px;
  }

  &__info {
    margin-bottom: 7px;
  }

  &__label {
    margin-right: 10px;
    font-weight: 600;
  }

  &__data {
    font-weight: 500;
  }

  &__map {
    position: relative;
    width: 50%;
  }

  &__cta {
    padding: 25px 0 15px;

    .ui-btn {
      display: block;
      width: 300px;
      margin: auto;
      border-color: $text-neutral;
      background-color: $background;
      font-weight: 500;
    }
  }
}
</style>

<i18n locale="fr">
{
  "urgencyAlerts": "{count} alerte urgente | {count} alertes urgentes",
  "handleAlerts": "Traiter les alertes",
  "label": {
    "driver": "Conducteur :",
    "vehicle": "Véhicule :",
    "device": "Appareil :",
    "trip": "Course :",
    "datetime": "Date d'envoi :",
    "position": "Position :",
  }
}
</i18n>

<i18n locale="en">
{
  "urgencyAlerts": "Urgency alerts",
  "handleAlerts": "Handle alerts",
  "label": {
    "driver": "Driver :",
    "vehicle": "Vehicle :",
    "device": "Device :",
    "trip": "Trip :",
    "datetime": "Sent on :",
    "position": "Position :",
  }
}
</i18n>
