<template>
  <div class="cell-status">
    <div v-if="hasChildren">
      <button class="cell-status__toggle" @click="toggleChildren">
        <font-awesome-icon
          :icon="status === StateMergingChildren.SHOWN ? 'fa-chevron-down' : 'fa-chevron-right'"
        />
      </button>
    </div>

    <div v-else @mouseover="showTooltip = true" @mouseout="showTooltip = false">
      <Tag
        :text="$t(`tripStatus.${STATUSES[status].localeKey}`)"
        :title="$t(`tripStatus.${STATUSES[status].localeKey}`)"
        :icon="getIcon(status)"
        :color="getColor(status)"
        :background="getBackgroundColor(status)"
      />

      <div
        v-if="status === TripStatusType.PROBLEM && Object.values(problems).length > 0"
        v-show="showTooltip"
        class="cell-status__tooltip problems-tooltip"
      >
        <ul class="problems-tooltip__list">
          <li v-show="problems[Problems.TIME]">
            <font-awesome-icon icon="fa-triangle-exclamation" />
            {{ $t('trackingTooltip.time') }}
          </li>
          <li v-show="problems[Problems.DISTANCE]">
            <font-awesome-icon icon="fa-triangle-exclamation" />
            {{ $t('trackingTooltip.distance') }}
          </li>
          <li v-show="problems[Problems.KILOMETERS]">
            <font-awesome-icon icon="fa-triangle-exclamation" />
            {{ $t('trackingTooltip.vk') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { TripStatusType } from '@/api';

import { StateMergingChildren } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { STATUSES } from '@/pages/TripsListPage/Trips.conf';
import Tag from '@/components/ui/Tag.vue';

/** @enum {string} */
const Problems = {
  TIME: 'time',
  DISTANCE: 'distance',
  KILOMETERS: 'kilometers',
};

export default {
  name: 'CellTripStatus',

  components: { Tag },

  props: {
    hasChildren: {
      type: Boolean,
      default: false,
    },

    problems: {
      type: Object,
      default: () => ({}),
    },

    status: {
      type: String,
      default: null,
    },
  },

  emits: ['toggleChildren'],

  data: () => ({
    Problems,
    STATUSES,
    StateMergingChildren,
    TripStatusType,

    showTooltip: false,
  }),

  methods: {
    toggleChildren() {
      this.$emit('toggleChildren');
    },
    getColor(status) {
      return STATUSES[status].color;
    },
    getBackgroundColor(status) {
      return STATUSES[status].backgroundColor;
    },
    getIcon(status) {
      return STATUSES[status].icon;
    },
  },
};
</script>

<style lang="scss">
.cell-status {
  position: relative;
  overflow: unset;

  &__toggle {
    border: 0;
    background: transparent;
    color: $text-dark-variant;
    font-size: 1.4em;
    cursor: pointer;
  }

  &__tooltip {
    position: absolute;
    top: calc(100% + 0.5em);
    z-index: $tooltip-index;
  }

  .problems-tooltip {
    padding: 10px;
    background-color: $border;
    font-size: 0.9em;
    line-height: 1.5em;
    white-space: nowrap;

    &::before {
      content: '';
      position: absolute;
      top: -0.3em;
      left: 0.5em;
      display: block;
      width: 1em;
      height: 1em;
      background-color: $border;
      transform: rotate(45deg);
    }
  }
}
</style>

<i18n locale="fr">
{
  "trackingTooltip": {
    "time": "Heure de prise de course",
    "distance": "Distance de prise de course",
    "vk": "Kilomètres enregistrés"
  }
}
</i18n>

<i18n locale="en">
{
  "trackingTooltip": {
    "time": "Time trip started",
    "distance": "Trip start distance",
    "vk": "Tracked kilometers"
  }
}
</i18n>

<i18n locale="cz">
{
  "trackingTooltip": {
    "distance": "Vzdálenost jízdy od startu",
    "vk": "Sledované kilometry",
    "time": "Čas začátku jízdy"
  }
}
</i18n>

<i18n locale="de">
{
  "trackingTooltip": {
    "distance": "Entfernung zum Fahrtbeginn",
    "vk": "Kilometer laut Überwachung",
    "time": "Uhrzeit des Fahrtbeginns"
  }
}
</i18n>

<i18n locale="es">
{
  "trackingTooltip": {
    "distance": "Distancia al inicio del servicio",
    "vk": "Kilómetros registrados",
    "time": "Hora de inicio del servicio"
  }
}
</i18n>

<i18n locale="it">
{
  "trackingTooltip": {
    "distance": "Distanza dall'inizio del servizio",
    "vk": "Chilometri tracciati",
    "time": "Orario di inizio del servizio"
  }
}
</i18n>

<i18n locale="pl">
{
  "trackingTooltip": {
    "distance": "Odległość początku usługi",
    "vk": "Śledzone kilometry",
    "time": "Czas rozpoczęcia usługi"
  }
}
</i18n>
