/** @enum {string} */
export const Severity = {
  ERROR: 'Error',
  WARNING: 'Warning',
  INFORMATION: 'Information',
  FATAL: 'Fatal',
};

/** @enum {string} */
export const IssueType = {
  UNUSED_STOP: 'UnusedStop',
  SLOW: 'Slow',
  EXCESSIVE_SPEED: 'ExcessiveSpeed',
  NEGATIVE_TRAVEL_TIME: 'NegativeTravelTime',
  CLOSE_STOPS: 'CloseStops',
  NULL_DURATION: 'NullDuration',
  INVALID_REFERENCE: 'InvalidReference',
  INVALID_ARCHIVE: 'InvalidArchive',
  MISSING_NAME: 'MissingName',
  MISSING_ID: 'MissingId',
  MISSING_COORDINATES: 'MissingCoordinates',
  INVALID_COORDINATES: 'InvalidCoordinates',
  INVALID_ROUTE_TYPE: 'InvalidRouteType',
  MISSING_URL: 'MissingUrl',
  INVALID_URL: 'InvalidUrl',
  INVALID_TIMEZONE: 'InvalidTimezone',
  DUPLICATE_STOPS: 'DuplicateStops',
  MISSING_PRICE: 'MissingPrice',
  INVALID_CURRENCY: 'InvalidCurrency',
  INVALID_TRANSFERS: 'InvalidTransfers',
  INVALID_TRANSFER_DURATION: 'InvalidTransferDuration',
  MISSING_LANGUAGE: 'MissingLanguage',
  INVALID_LANGUAGE: 'InvalidLanguage',
  DUPLICATE_OBJECT_ID: 'DuplicateObjectId',
  UNLOADABLE_MODEL: 'UnloadableModel',
  MISSING_MANDATORY_FILE: 'MissingMandatoryFile',
  EXTRA_FILE: 'ExtraFile',
  IMPOSSIBLE_TO_INTERPOLATE_STOP_TIMES: 'ImpossibleToInterpolateStopTimes',
  INVALID_STOP_LOCATION_TYPE_IN_TRIP: 'InvalidStopLocationTypeInTrip',
  INVALID_STOP_PARENT: 'InvalidStopParent',
  ID_NOT_ASCII: 'IdNotAscii',
  INVALID_SHAPE_ID: 'InvalidShapeId',
  UNUSED_SHAPE_ID: 'UnusedShapeId',
  DUPLICATE_STOP_SEQUENCE: 'DuplicateStopSequence',
  SUB_FOLDER: 'SubFolder',
  // Pysae extra :
  TRIPS_WITHOUT_SHAPES: 'TripsWithoutShapes',
  TRIPS_WITHOUT_DISTANCES: 'TripsWithoutDistances',
  VALIDATION_DISABLED: 'ValidationDisabled',
};

export const MapTypeSeverity = {
  [Severity.ERROR]: [
    IssueType.DUPLICATE_STOP_SEQUENCE,
    IssueType.MISSING_ID,
    IssueType.MISSING_URL,
    IssueType.INVALID_URL,
    IssueType.INVALID_COORDINATES,
    IssueType.INVALID_TIMEZONE,
    IssueType.MISSING_PRICE,
    IssueType.INVALID_CURRENCY,
    IssueType.INVALID_TRANSFERS,
    IssueType.INVALID_TRANSFER_DURATION,
    IssueType.IMPOSSIBLE_TO_INTERPOLATE_STOP_TIMES,
    IssueType.INVALID_SHAPE_ID,
    IssueType.DUPLICATE_OBJECT_ID,
    IssueType.SUB_FOLDER,
    IssueType.TRIPS_WITHOUT_SHAPES,
    IssueType.TRIPS_WITHOUT_DISTANCES,
  ],
  [Severity.WARNING]: [
    IssueType.NEGATIVE_TRAVEL_TIME,
    IssueType.MISSING_NAME,
    IssueType.MISSING_COORDINATES,
    IssueType.NULL_DURATION,
    IssueType.MISSING_LANGUAGE,
    IssueType.INVALID_LANGUAGE,
    IssueType.INVALID_STOP_LOCATION_TYPE_IN_TRIP,
    IssueType.INVALID_STOP_PARENT,
    IssueType.ID_NOT_ASCII,
  ],
  [Severity.INFORMATION]: [
    IssueType.UNUSED_STOP,
    IssueType.SLOW,
    IssueType.EXCESSIVE_SPEED,
    IssueType.CLOSE_STOPS,
    IssueType.INVALID_ROUTE_TYPE,
    IssueType.DUPLICATE_STOPS,
    IssueType.EXTRA_FILE,
    IssueType.UNUSED_SHAPE_ID,
    IssueType.VALIDATION_DISABLED,
  ],
  [Severity.FATAL]: [
    IssueType.INVALID_REFERENCE,
    IssueType.INVALID_ARCHIVE,
    IssueType.UNLOADABLE_MODEL,
    IssueType.MISSING_MANDATORY_FILE,
  ],
};
