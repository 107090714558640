<template>
  <span class="loading-dots" :class="{ 'loading-dots--v2': typeV2 }">
    <span />
    <span />
    <span />
  </span>
</template>

<script>
export default {
  name: 'AnimatedDots',
  props: {
    typeV2: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@keyframes loading-dots {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes loading-dots-v2 {
  from {
    background-color: $text-neutral;
  }

  to {
    background-color: $primary-light;
  }
}

.loading-dots {
  vertical-align: middle;
  white-space: nowrap;

  &--v2 {
    > span {
      width: 1em;
      height: 1em;
      margin: 0 0.5em;
      animation: loading-dots-v2 0.6s alternate infinite;
    }
  }

  > span {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    margin: 0 0.2em;
    border-radius: 50%;
    background-color: $text-neutral;
    animation: loading-dots 0.6s alternate infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
</style>
