/**
 * Simplified definition of a Deferred class.
 */
class Deferred<T> {
  public readonly promise: Promise<T>;

  public resolve!: (value?: PromiseLike<T> | T) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public reject!: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve as (value?: PromiseLike<T> | T) => void;
      this.reject = reject;
    });

    Object.freeze(this);
  }
}

export { Deferred };
