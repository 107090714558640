import { defineAsyncComponent } from 'vue';
import i18n from '@/i18n';

import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const { t } = i18n.global;

const CheckboxCell = defineAsyncComponent(() => import('@/components/ui/Checkbox.vue'));
const RouteBadge = defineAsyncComponent(() => import('@/components/common/RouteBadge.vue'));
const SelectorCell = defineAsyncComponent(() => import('@/components/Table/cells/SelectorCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  ROUTE: 'route_short_name',
  STATUS: 'localIsDeactivated',
  PASSENGERS_INFO_VISIBILITY: 'localIsPrivate',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'routeManagementDatagrid',
    searchFields: [ColumnKey.ROUTE],
    defaultSortBy: [{ key: ColumnKey.ROUTE, order: SortOrder.ASC }],
    rowIdKey: 'route_id',
    hasActions: false,
    showColumnSelector: false,

    columns: [
      // ROUTE
      new DataGridColumn({
        key: ColumnKey.ROUTE,
        title: 'columnTripList.route',
        cellBuilder([value, object]) {
          return {
            component: RouteBadge,
            props: {
              isExperimental: object.localIsDeactivated,
              route: {
                route_color: object.route_color,
                route_text_color: object.route_text_color,
              },
              value,
            },
          };
        },
      }),

      // STATUS
      new DataGridColumn({
        key: ColumnKey.STATUS,
        title: 'column.status',
        tooltip: t('tooltipRouteExperimental'),
        selectable: false,
        i18nPrefix: 'routesStatus',
        cellBuilder([value, object], { valueChanged }) {
          return {
            component: SelectorCell,
            events: { valueChanged },
            props: {
              value: !value ? 'active' : 'experimental',
              type: 'routeStatus',
              options: ['active', 'experimental'],
              i18nPrefix: 'routesStatus',
            },
          };
        },
      }),

      // PASSENGERS INFO VISIBILITY
      new DataGridColumn({
        key: ColumnKey.PASSENGERS_INFO_VISIBILITY,
        title: 'column.passengerInfoVisibility',
        selectable: false,
        sortable: true,
        cellBuilder([value, object], { click }) {
          return {
            component: CheckboxCell,
            events: { click },
            props: {
              checked: !value && !object.localIsDeactivated,
              disabled: object.localIsDeactivated,
              id: object.route_id,
              label: t('routesStatus.visibility'),
            },
          };
        },
      }),
    ],
  });
};
