<template>
  <span>
    <div v-if="active" class="integration-type-card__active-integration-card">
      <div class="integration-type-card__header">
        <div class="integration-type-card__header-badge">
          <img class="integration-type-card__header-image" :src="value.icon ? value.icon : DefaultIcon" />
        </div>
        <div class="integration-type-card__header-title-container">
          <span>{{ $t(value.labelKey) }}</span>
        </div>
      </div>
      <v-menu v-model="showOptions" offset="8" :close-on-content-click="false">
        <template #activator="{ props }">
          <Btn v-bind="props" type="tertiary" @click="showOptions = true">
            <font-awesome-icon icon="fa-gear" />
          </Btn>
        </template>
        <v-card class="mx-auto pa-2 rounded-lg" variant="flat" width="130">
          <div class="integration-type-card__options">
            <div @click="$emit('open', { value })">
              <font-awesome-icon class="integration-type-card__icon" icon="fa-pen" />
              {{ $t('manage') }}
            </div>
          </div>
          <div class="integration-type-card__options">
            <div class="integration-type-card__delete-btn" @click="$emit('delete', { value })">
              <font-awesome-icon class="integration-type-card__icon" icon="fa-trash" />
              {{ $t('deactivate') }}
            </div>
          </div>
        </v-card>
      </v-menu>
    </div>
    <div v-else class="integration-type-card">
      <div class="integration-type-card__header">
        <div class="integration-type-card__header-badge">
          <img class="integration-type-card__header-image" :src="value.icon ? value.icon : DefaultIcon" />
        </div>
        <div class="integration-type-card__header-title-container">
          <span>{{ $t(value.labelKey) }}</span>
        </div>
      </div>
      <div class="integration-type-card__text">
        <span>{{ $t(value.descriptionKey) }}</span>
      </div>
      <div class="integration-type-card__actions">
        <Btn
          class="integration-type-card__activate-button"
          :disabled="!value.contactUs && value.connectors.length === 0"
          type="secondary"
          @click="$emit('open', { value })"
        >
          <font-awesome-icon icon="fa-plus" />
          {{ $t('activate') }}
        </Btn>
      </div>
    </div>
  </span>
</template>

<script setup>
import { ref } from 'vue';
import Btn from '@/components/ui/Btn.vue';
import DefaultIcon from '@/assets/img/integrations/default.png';

defineProps({
  /** @type {import('/@store/integrations').IntegrationType} */
  value: {
    type: Object,
    required: true,
  },
  active: {
    type: Boolean,
  },
});

defineEmits(['delete', 'open']);

const showOptions = ref(false);

/**
 * @typedef OpenEvent
 * @property {import('@/store/integrations').IntegrationType} value
 */

/**
 * @typedef DeleteEvent
 * @property {import('@/store/integrations').IntegrationType} value
 */
</script>

<style lang="scss">
.integration-type-card {
  display: flex;
  flex-direction: column;
  justify-items: start;
  width: 300px;
  height: 300px;
  border: 1px solid $text-neutral;
  border-radius: 5px;
  background: $canvas;
  color: $text-dark-variant;
  padding-inline: 10px;

  &__active-integration-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    height: 50px;
    padding: 8px;
    border: 1px solid $border;
    border-radius: 8px;

    .ui-btn.tertiary:hover:not(:disabled, .link-disabled) {
      background-color: transparent;
    }
  }

  &__delete-btn {
    margin-top: 4px;
    color: $danger !important;
  }

  &__header,
  &__text,
  &__actions {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  &__header-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 35px;
    height: 35px;
    border: 1px solid $border;
    border-radius: 50px;
  }

  &__header-image {
    width: 30px;
  }

  &__icon {
    margin-right: 6px;
  }

  &__header-title-container {
    flex-grow: 1;
    padding-left: 10px;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
    text-align: start;
    text-overflow: ellipsis;
  }

  &__text {
    display: flex;
    flex-grow: 1;
    padding: 0;
    font-size: 14px;
    text-align: justify;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__activate-button {
    svg {
      margin-right: 4px;
    }
  }

  &__options {
    cursor: pointer;
  }
}
</style>

<i18n locale="fr">
{
  "activate": "Activer",
  "manage": "Configurer",
  "deactivate": "Désactiver"
}
</i18n>

<i18n locale="en">
{
  "activate": "Activate",
  "manage": "Manage",
  "deactivate": "Deactivate"
}
</i18n>
