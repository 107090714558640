<template>
  <div class="cell-date" :class="{ 'cell-date__bold': highlight }">
    <font-awesome-icon v-if="gtfsPublication && date > new Date()" class="cell-date__icon" icon="fa-clock" />
    <span v-if="date">
      {{ date.toLocaleString($i18n.locale, options) }}
    </span>
    <span v-else-if="gtfsPublication">
      <font-awesome-icon icon="fa-circle-exclamation" class="cell-date__icon" />
      {{ $t('unpublished') }}
    </span>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: 'DateCell',

  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
    date: {
      type: [String, Date],
      default: null,
    },
    gtfsPublication: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-date {
  font-size: calc(0.6em + 0.3vw);

  &__bold {
    font-weight: $font-weight-semi-bold;
  }

  &__icon {
    margin-right: 5px;
  }
}
</style>

<i18n locale="fr">
{
  "unpublished": "Non publié"
}
</i18n>

<i18n locale="en">
{
  "unpublished": "Unpublished"
}
</i18n>
