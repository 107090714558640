<template>
  <ul class="cell-gtfs">
    <li v-for="(value, index) in gtfs" :key="index" :title="value.name">
      {{ value.name || value.id }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CellGtfs',

  props: {
    /** @type {Vue.PropOptions<Array<import('@/api').TripListGtfs>>} */
    gtfs: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.cell-gtfs {
  @include multi-line-ellipsis($font-size: 12px, $line-height: 1.5, $lines-to-show: 2);
}
</style>
