<template>
  <Modal class="modal-push" @close="$emit('close')">
    <template #title>
      {{ $t('push') }}
    </template>

    <template #body>
      <div class="modal-push__body">
        {{ $t('confirmPush') }}
      </div>
      <div>
        {{ $t('android') }}
      </div>
    </template>

    <template #cta>
      <Btn type="primary" @click="$emit('submit', selectedMessageId)">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'ModalPush',

  components: {
    Btn,
    Modal,
  },

  props: {
    selectedMessageId: {
      default: '',
      type: String,
    },
  },

  emits: ['close', 'submit'],
};
</script>

<style lang="scss" scoped>
.modal-push {
  &__body {
    margin-bottom: 5px;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }
}
</style>

<i18n locale="fr">
{
  "android": "*Android seulement",
  "confirmPush": "Souhaitez-vous envoyer une notification aux voyageurs ?",
  "goBack": "Retour",
  "push": "Envoyer une notification"
}
</i18n>

<i18n locale="en">
{
  "android": "*Android only",
  "confirmPush": "Do you want to send a push notification to passengers?",
  "goBack": "Back",
  "push": "Send a push notification"
}
</i18n>
