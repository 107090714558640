import dayjs from 'dayjs';
import { createRouter, createWebHashHistory, type RouteLocationNormalized } from 'vue-router';

import { UnauthorizedRoute, HomeRoute, AdminRoute, AuthRoute, GroupRoute } from '@/libs/routing';
import DutyList from '@/pages/DutyListPage/index.vue';
import Admin, { ResourceType } from '@/pages/AdminPage/index.vue';
import PassengersMessage from '@/pages/PassengersMessage/index.vue';
import Deviation from '@/pages/DeviationListPage/index.vue';
import DeviceDetail from '@/pages/DeviceDetailPage/index.vue';
import DeviceList from '@/pages/DeviceListPage/index.vue';
import DriverList from '@/pages/DriverListPage/index.vue';
import Group from '@/pages/GroupPage/index.vue';
import GtfsRT from '@/pages/GtfsRTPage/index.vue';
import IntegrationsPage from '@/pages/IntegrationsPage/index.vue';
import LiveMap from '@/pages/LiveMapPage/index.vue';
import Inbox from '@/pages/MessageListPage/Inbox.vue';
import Sent from '@/pages/MessageListPage/Sent.vue';
import NotFoundPage from '@/pages/NotFoundPage/index.vue';
import Dashboard from '@/pages/DashboardPage/index.vue';
import PassengersApp from '@/pages/PassengersAppPage/index.vue';
import PassengersScreen from '@/pages/PassengersScreenPage/index.vue';
import PasswordChange from '@/pages/PasswordChangePage/index.vue';
import PlanningPage from '@/pages/PlanningPage/index.vue';
import Profile from '@/pages/ProfilePage/index.vue';
import TransportPlanList from '@/pages/TransportPlanListPage/index.vue';
import Settings from '@/pages/SettingsPage/index.vue';
import StopDetailed from '@/pages/StopDetailedPage/index.vue';
import StopList from '@/pages/StopListPage/index.vue';
import TripDetailed from '@/pages/TripDetailedPage/index.vue';
import TripsList from '@/pages/TripsListPage/index.vue';
import ErrorPage from '@/pages/ErrorPage/index.vue';
import UrgenciesPage from '@/pages/UrgenciesPage/index.vue';
import UserList from '@/pages/UserListPage/index.vue';
import VehicleList from '@/pages/VehicleListPage/index.vue';

import Login from '@/pages/LoginPage/index.vue';
import LoginForm from '@/pages/LoginPage/LoginForm.vue';
import PasswordReset from '@/pages/LoginPage/PasswordReset.vue';

import Reports from '@/pages/ReportsPage/index.vue';
import NewReportsPunctuality from '@/pages/ReportsPage/ReportsPunctuality/index.vue';
import ReportsConnectedDevice from '@/pages/ReportsPage/ReportsConnectedDevice.vue';
import ReportsPassengersApp from '@/pages/ReportsPage/ReportsPassengersApp.vue';
import ReportsPassengersCounts from '@/pages/ReportsPage/ReportsPassengerCount/index.vue';
import ReportsPunctuality from '@/pages/ReportsPage/ReportsPunctuality.vue';
import ReportsTravelTime from '@/pages/ReportsPage/ReportsTravelTime/index.vue';
import ReportsTripKm from '@/pages/ReportsPage/ReportsTripKm/index.vue';
import ReportsTripTracking from '@/pages/ReportsPage/ReportsTripTracking/index.vue';

import store from '@/store';
import { routesByPermission, Permission } from '@/auth';
import type { Store } from 'vuex';
import { type Ref } from 'vue';
import { useMatomo } from './use/use-matomo';

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'nav__item--active',
  linkExactActiveClass: 'exact-active',

  routes: [
    {
      name: HomeRoute.ROOT,
      path: '/',
      redirect: undefined,
      children: [],
    },
    {
      component: Login,
      name: AuthRoute.LOGIN,
      path: '/login',
      children: [
        {
          component: LoginForm,
          name: AuthRoute.LOGIN_FORM,
          path: '',
          props: route => ({
            query: route.query,
          }),
        },
        {
          component: PasswordReset,
          name: AuthRoute.PASSWORD_RESET,
          path: 'reset',
        },
      ],
    },

    {
      component: PasswordChange,
      name: AuthRoute.PASSWORD_CHANGE,
      path: '/password/reset',
    },

    {
      component: Admin,
      name: AdminRoute.ADMIN,
      path: '/admin/:resource?',
      props: true,
      beforeEnter(to) {
        if (!to.params.resource) {
          return { ...to, params: { resource: ResourceType.GROUPS } };
        }
        return true;
      },
    },

    {
      component: Group,
      name: GroupRoute.GROUP,
      path: '/:groupId',
      props: true,
      children: [
        {
          component: DutyList,
          name: GroupRoute.DUTY_LIST,
          path: GroupRoute.DUTY_LIST,
          props: route => ({
            query: route.query,
          }),
        },

        {
          component: PassengersMessage,
          name: GroupRoute.PASSENGERS_MESSAGE,
          path: GroupRoute.PASSENGERS_MESSAGE,
        },

        {
          component: Dashboard,
          name: GroupRoute.DASHBOARD,
          path: GroupRoute.DASHBOARD,
        },

        {
          component: DeviceDetail,
          name: GroupRoute.DEVICE_DETAILLED,
          path: `${GroupRoute.DEVICE_DETAILLED}/:deviceId`,
          props: route => ({
            deviceId: route.params.deviceId,
            query: route.query,
          }),
          meta: {
            parent: GroupRoute.DEVICE_LIST,
            crumbName: async (ref: Ref<string>, route: RouteLocationNormalized, store: Store<any>) => {
              const deviceId = route.params.deviceId as string;
              ref.value = deviceId;

              /** @type {import('./store/devices').Device} */
              const device = await store.dispatch('devices/getDevice', { deviceId });
              ref.value = device?.name || deviceId;
            },
          },
        },

        {
          component: Deviation,
          name: GroupRoute.DEVIATION_LIST,
          path: GroupRoute.DEVIATION_LIST,
        },

        {
          component: DeviceList,
          name: GroupRoute.DEVICE_LIST,
          path: GroupRoute.DEVICE_LIST,
        },

        {
          component: DriverList,
          name: GroupRoute.DRIVER_LIST,
          path: GroupRoute.DRIVER_LIST,
        },

        {
          component: GtfsRT,
          name: GroupRoute.GTFS_RT,
          path: GroupRoute.GTFS_RT,
        },

        {
          component: IntegrationsPage,
          name: GroupRoute.INTEGRATIONS,
          path: GroupRoute.INTEGRATIONS,
        },

        {
          component: LiveMap,
          name: GroupRoute.LIVE_MAP,
          path: GroupRoute.LIVE_MAP,
          props: route => ({
            date: route.query.date,
          }),
        },

        {
          component: Inbox,
          name: GroupRoute.MESSAGE_INBOX,
          path: GroupRoute.MESSAGE_INBOX,
        },
        {
          component: Sent,
          name: GroupRoute.MESSAGE_SENT,
          path: GroupRoute.MESSAGE_SENT,
        },

        {
          component: PassengersApp,
          name: GroupRoute.PASSENGERS_APP,
          path: GroupRoute.PASSENGERS_APP,
        },

        {
          component: PassengersScreen,
          name: GroupRoute.PASSENGERS_SCREEN,
          path: GroupRoute.PASSENGERS_SCREEN,
        },

        {
          component: PlanningPage,
          name: GroupRoute.PLANNING,
          path: GroupRoute.PLANNING,
          props: route => ({
            query: route.query,
          }),
        },

        {
          component: Profile,
          name: GroupRoute.PROFILE,
          path: GroupRoute.PROFILE,
        },

        {
          component: Reports,
          name: GroupRoute.REPORTS,
          path: GroupRoute.REPORTS,
          children: [
            {
              component: NewReportsPunctuality,
              name: GroupRoute.REPORTING_PUNCTUALITY,
              path: GroupRoute.REPORTING_PUNCTUALITY,
            },
            {
              component: ReportsConnectedDevice,
              name: GroupRoute.REPORTING_CONNECTED_DEVICES,
              path: GroupRoute.REPORTING_CONNECTED_DEVICES,
            },
            {
              component: ReportsPassengersApp,
              name: GroupRoute.REPORTING_PASSENGERS_APP,
              path: GroupRoute.REPORTING_PASSENGERS_APP,
            },
            {
              component: ReportsPassengersCounts,
              name: GroupRoute.REPORTING_PASSENGER_COUNTS,
              path: GroupRoute.REPORTING_PASSENGER_COUNTS,
            },
            {
              component: ReportsPunctuality,
              name: GroupRoute.REPORTING_OLD_PUNCTUALITY,
              path: GroupRoute.REPORTING_OLD_PUNCTUALITY,
            },
            {
              component: ReportsTravelTime,
              name: GroupRoute.REPORTING_TRAVEL_TIME,
              path: GroupRoute.REPORTING_TRAVEL_TIME,
            },
            {
              component: ReportsTripKm,
              name: GroupRoute.REPORTING_TRIP_KM,
              path: GroupRoute.REPORTING_TRIP_KM,
            },
            {
              component: ReportsTripTracking,
              name: GroupRoute.REPORTING_TRIP_TRACKING,
              path: GroupRoute.REPORTING_TRIP_TRACKING,
            },
          ],
        },

        {
          component: TransportPlanList,
          name: GroupRoute.TRANSPORT_PLAN_LIST,
          path: GroupRoute.TRANSPORT_PLAN_LIST,
        },

        {
          component: Settings,
          name: GroupRoute.SETTINGS,
          path: GroupRoute.SETTINGS,
        },

        {
          component: StopDetailed,
          name: GroupRoute.STOP_DETAILED,
          path: `${GroupRoute.STOP_DETAILED}/:stopId`,
          props: route => ({
            stopId: route.params.stopId,
            query: route.query,
          }),
          meta: {
            parent: GroupRoute.STOP_LIST,
            crumbName: async (ref: Ref<string>, route: RouteLocationNormalized, store: Store<any>) => {
              const stopId = route.params.stopId as string;
              ref.value = stopId;

              const date = (route.query.date as string) ?? new Date().toISOString();
              const ts = dayjs(date).utc().unix();
              /** @type {{[key: string]: import('./store/gtfs').Stop}} */
              const stops = await store.dispatch('gtfs/getStopsMap', { ts });
              const stop = stops[stopId];
              ref.value = stop?.stop_name || stopId;
            },
          },
        },

        {
          component: StopList,
          name: GroupRoute.STOP_LIST,
          path: GroupRoute.STOP_LIST,
        },

        {
          component: TripDetailed,
          name: GroupRoute.TRIP_DETAILED,
          path: `${GroupRoute.TRIP_DETAILED}/:tripId`,
          props: route => ({
            tripId: route.params.tripId,
            query: route.query,
          }),
          meta: {
            parent: GroupRoute.TRIP_LIST,
            crumbName: async (ref: Ref<string>, route: RouteLocationNormalized, store: Store<any>) => {
              const tripId = route.params.tripId as string;
              ref.value = tripId;

              const dateQuery = route.query.date as string;
              const date = dateQuery ? dayjs(dateQuery).toDate() : new Date();
              const tripName = await store.dispatch('gtfs/formatTripName', { tripId, date });
              ref.value = tripName || tripId;
            },
          },
        },

        {
          component: TripsList,
          name: GroupRoute.TRIP_LIST,
          path: GroupRoute.TRIP_LIST,
          props: route => ({
            query: route.query,
          }),
        },

        {
          component: UrgenciesPage,
          name: GroupRoute.URGENCIES,
          path: GroupRoute.URGENCIES,
        },

        {
          component: UserList,
          name: GroupRoute.USER_LIST,
          path: GroupRoute.USER_LIST,
        },

        {
          component: VehicleList,
          name: GroupRoute.VEHICLE_LIST,
          path: GroupRoute.VEHICLE_LIST,
        },

        {
          component: ErrorPage,
          name: 'unauthorizedInternal',
          path: '403-error',
          props: () => ({
            statusCode: 403,
            backButton: true,
          }),
        },
      ],
    },

    {
      component: ErrorPage,
      name: 'unauthorizedExternal',
      path: '/403-error',
      props: () => ({
        statusCode: 403,
        backButton: true,
      }),
    },

    {
      component: NotFoundPage,
      name: GroupRoute.NOT_FOUND,
      path: '/:pathMatch(.*)*',
    },
  ],
});

router.beforeEach(async (to, from) => {
  // Handle custom tracking Matomo
  const { matomo } = useMatomo();
  const isViewIgnored = [GroupRoute.GROUP, HomeRoute.ROOT].includes(to.name as GroupRoute | HomeRoute);

  if (matomo && !isViewIgnored) {
    // handle specific case of reports
    let url = '';
    if (to.matched[1]?.name === GroupRoute.REPORTS) {
      url = `/op2/reporting/${to.matched[2]?.name?.toString()}`;
    } else {
      url = `/op2/${to.name?.toString()}`;
    }
    matomo.trackPageView(url);
  }

  if (
    to.name &&
    routesByPermission[Permission.DEFAULT].has(to.name as UnauthorizedRoute | GroupRoute | AuthRoute)
  ) {
    return true;
  }

  const authenticated = await store.dispatch('authenticate');
  if (!authenticated) {
    const redirect = { name: AuthRoute.LOGIN_FORM };
    return to.fullPath === '/' ? redirect : { ...redirect, query: { next: to.fullPath } };
  }

  if (!store.getters.group?._id && (to.params.groupId || to.name === HomeRoute.ROOT)) {
    await store.dispatch('groupsUpdate');
  }

  if (to.name === HomeRoute.ROOT) {
    let groupId = localStorage.getItem('settings.op.lastGroupId');
    if (!groupId || !store.getters.groupIds.includes(groupId)) {
      groupId = store.getters.groupIds[0];
    }
    if (store.getters.isAuthorizedRoute(GroupRoute.DASHBOARD))
      return { name: GroupRoute.DASHBOARD, params: { groupId } };
    return { name: GroupRoute.TRIP_LIST, params: { groupId } };
  }

  if (to.params.groupId && to.params.groupId !== from.params?.groupId) {
    await store.dispatch('groupChange', to.params.groupId);
    if (!store.getters.groupIds.includes(to.params.groupId)) {
      return { name: GroupRoute.NOT_FOUND };
    }
  }

  if (to.name === GroupRoute.GROUP) {
    if (store.getters.isAuthorizedRoute(GroupRoute.DASHBOARD)) return { ...to, name: GroupRoute.DASHBOARD };
    return { ...to, name: GroupRoute.TRIP_LIST };
  }

  if (store.getters.isAuthorizedRoute(to.name)) {
    return true;
  }

  return to.params.groupId
    ? { name: UnauthorizedRoute.INTERNAL, params: { groupId: to.params.groupId } }
    : { name: UnauthorizedRoute.EXTERNAL };
});

export default router;
