<template>
  <div class="punctuality-params">
    <div class="form-group__label">
      {{ $t('editCategories') }}
    </div>

    <div>
      <div class="form-group__sub-label">
        {{ $t('description') }}
      </div>

      <table class="table">
        <thead class="table__head">
          <tr>
            <th class="table__head-cell">
              {{ $t('start') }}
            </th>

            <th class="table__head-cell">
              {{ $t('end') }}
            </th>

            <th class="table__head-cell">
              {{ $t('onTime') }}
            </th>

            <th class="table__head-cell" />
          </tr>
        </thead>

        <tbody class="punctuality-params__table">
          <tr v-for="(_, index) in categories.cats.concat([Infinity])" :key="index" class="table__row">
            <td class="table__cell">
              <input
                v-if="categories.cats[index - 1] !== undefined"
                class="form-group__input form-group__input--disabled"
                disabled
                type="text"
                :value="categories.cats[index - 1]"
              />

              <input
                v-else
                class="form-group__input form-group__input--disabled"
                disabled
                type="text"
                :value="`-${$t('infinity')}`"
              />
            </td>

            <td class="table__cell">
              <input
                v-if="categories.cats[index] !== undefined"
                v-model.number="categories.cats[index]"
                class="form-group__input"
                required
                type="number"
              />

              <input
                v-else
                class="form-group__input form-group__input--disabled"
                disabled
                type="text"
                :value="$t('infinity')"
              />
            </td>

            <td class="table__cell punctuality-params__checkbox-cell">
              <Checkbox
                :id="`${index}`"
                :checked="categories.bounds.down <= index && index <= categories.bounds.up"
                :disabled="
                  categories.bounds.down < index &&
                  index < categories.bounds.up &&
                  categories.bounds.down <= categories.bounds.up
                "
                @change="onChangeBounds(index)"
              />
            </td>

            <td class="table__cell">
              <div class="punctuality-params__buttons">
                <Btn type="secondary" :smaller="true" @click="insertCategory(index)">
                  <font-awesome-icon icon="fa-plus" />
                </Btn>
                <Btn type="secondary" :smaller="true" @click="removeCategory(index)">
                  <font-awesome-icon icon="fa-minus" />
                </Btn>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Btn type="primary" :disabled="!isValid" @click="submit">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import { ConfigTarget } from './SettingsMixin';
import cloneDeep from 'clone-deep';

export default {
  name: 'PunctualityParams',
  components: { Btn, Checkbox },
  emits: ['save', 'change'],

  data() {
    return {
      categories: {
        bounds: { down: 2, up: 3 },
        cats: [-120, 0, 120, 300],
      },
      /** @type {string} */
      origValue: null,
    };
  },

  computed: {
    /** @return {boolean} */
    isValid() {
      const { cats } = this.categories;
      this.$emit('change', JSON.stringify(this.categories) !== this.origValue);

      return (
        cats.length > 0 &&
        cats.find((cat, index) => index > 0 && cat <= cats[index - 1]) == null &&
        this.categories.bounds.down <= this.categories.bounds.up &&
        JSON.stringify(this.categories) !== this.origValue
      );
    },
  },

  created() {
    if (this.$store.getters.group.categories) {
      this.categories = cloneDeep(this.$store.getters.group.categories);
    }

    this.origValue = JSON.stringify(this.categories);
  },

  methods: {
    /** @param {number} index */
    insertCategory(index) {
      this.categories.cats.splice(index, 0, null);

      if (index < this.categories.bounds.down) {
        this.categories.bounds.down += 1;
      }

      if (index <= this.categories.bounds.up) {
        this.categories.bounds.up += 1;
      }
    },

    /** @param {number} index */
    onChangeBounds(index) {
      if (this.categories.bounds.down > this.categories.bounds.up) {
        this.categories.bounds = {
          down: index,
          up: index,
        };
      } else if (index < this.categories.bounds.down) {
        this.categories.bounds.down = index;
      } else if (index === this.categories.bounds.down) {
        this.categories.bounds.down += 1;
      } else if (index === this.categories.bounds.up) {
        this.categories.bounds.up -= 1;
      } else {
        this.categories.bounds.up = index;
      }
    },

    /**
     * @param {number} index
     */
    removeCategory(index) {
      this.categories.cats.splice(index, 1);

      if (index < this.categories.bounds.down) {
        this.categories.bounds.down -= 1;
      }

      if (index <= this.categories.bounds.up) {
        this.categories.bounds.up -= 1;
      }
    },

    async submit() {
      if (!this.isValid) return;

      await this.$store.dispatch('groupPatch', {
        target: ConfigTarget.REPORTS,
        updates: { categories: this.categories },
      });

      this.origValue = JSON.stringify(this.categories);
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss">
.punctuality-params {
  &__buttons {
    display: flex;
    justify-content: center;

    .ui-btn {
      height: 28px;
    }
  }

  .table {
    &__cell {
      text-align: center;
    }

    td {
      vertical-align: middle;
    }
  }

  &__checkbox-cell {
    .v-selection-control {
      justify-content: center;
    }

    .v-input--horizontal {
      grid: none;
    }
  }

  &__table {
    button {
      width: 28px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "editCategories": "Catégories horaires",
  "end": "Fin (s)",
  "description": "Ce tableau vous permet de définir les catégories utilisées pour le rapport de ponctualité ainsi que d'indiquer celles qui sont considérées comme à l’heure.",
  "infinity": "Infini",
  "onTime": "À l'heure",
  "start": "Début (s)"
}
</i18n>

<i18n locale="en">
{
  "editCategories": "Time categories",
  "end": "End (s)",
  "description": "This table allows you to define the categories used for the punctuality report as well as to indicate which are considered to be on time.",
  "infinity": "Infinite",
  "onTime": "On time",
  "start": "Start (s)"
}
</i18n>
