<template>
  <div class="table-search-bar-v2">
    <v-icon size="small" class="table-search-bar-v2__icon">fa:fas fa-search</v-icon>
    <input
      v-model="input"
      class="table-search-bar-v2__input"
      :class="disabled ? 'table-search-bar-v2__input--disabled' : ''"
      :disabled="disabled"
      :placeholder="$t('search')"
      type="text"
      @input="handleInput"
    />
  </div>
</template>

<script>
import { normalize } from '@/libs/helpers/strings';

const DEBOUNCE_MS = 400;

export default {
  name: 'TableSearchBarV2',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    /** @type {Vue.PropOptions<Array<string>>} */
    searchFields: {
      type: Array,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<Object>>} */
    searchList: {
      type: Array,
      required: true,
    },
    idKey: {
      type: String,
      required: false,
      default: '_id',
    },
  },

  emits: ['filteredList', 'update:hasInput'],

  data() {
    return {
      DEBOUNCE_MS,

      /** @type {?Map<{[id: string]: string}>} */
      concatValuesMap: null,
      /** @type {String} */
      input: '',
      timeoutID: undefined,
      timer: null,
    };
  },

  watch: {
    input() {
      this.triggerResearch();
    },

    searchList: {
      immediate: true,
      handler() {
        if (this.searchList.length > 0) {
          this.createConcatValuesMap();
        }
      },
      deep: true,
    },
  },

  methods: {
    triggerResearch() {
      // If a timeout job is already started, cancel it and start a new one with the last input
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      // Trigger timeout after 500ms
      this.timeoutID = setTimeout(() => {
        this.search();
      }, 500);

      if (this.input.length > 0) {
        this.$emit('update:hasInput', true);
      } else {
        this.$emit('update:hasInput', false);
      }
    },

    handleInput() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.search, DEBOUNCE_MS);
    },

    updateSearchResults() {
      this.triggerResearch();
    },

    search() {
      if (this.input.length === 0 || !this.concatValuesMap) {
        this.$emit('filteredList', null);
      } else {
        const inputs = normalize(this.input).split(' ');
        const filteredList = this.searchList.reduce((acc, elem) => {
          // search every 'inputs' (spaced by ' ') in values to look for searched words
          const element = this.concatValuesMap.get(elem[this.idKey]);
          if (inputs.every(input => element.includes(input))) acc.push(elem[this.idKey]);
          return acc;
        }, []);
        this.timeoutID = undefined;
        this.$emit('filteredList', filteredList);
      }
    },

    /**
     * Create a concateneted string of all the fields that will be searched for each object and store it in a Map with the object id
     */
    createConcatValuesMap() {
      this.concatValuesMap = new Map();
      this.searchList.forEach(element => {
        const concatValues = this.searchFields
          .reduce((acc, field) => {
            if (element[field]) {
              acc.push(normalize(element[field]));
            }
            return acc;
          }, [])
          .join(' ');
        this.concatValuesMap.set(element[this.idKey], concatValues);
      });
    },

    /**
     * Reset the search bar
     */
    resetSearch() {
      this.input = '';
    },
  },
};
</script>

<style lang="scss">
.table-search-bar-v2 {
  position: relative;
  height: 32px;

  &__icon {
    top: 25%;
    left: 30px;
    transform: translate(0, -65%);
  }

  &__input {
    width: 130px;
    height: 32px;
    padding: 4px 10px 4px 35px;
    border: none;
    border-radius: 4px;
    font: inherit;
    font-size: 14px;

    &--disabled {
      opacity: 0.6;
    }

    &:hover:not(:focus, :disabled) {
      background: $background-variant;
    }

    &:not(:focus) {
      &::placeholder {
        color: $text-dark;
        font-weight: 500;
        text-align: center;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      width: 170px;
      border: 1px solid $border-variant;
      box-shadow: none;
      transition-duration: 0.2s;
      transition-property: all;
    }
  }
}
</style>
