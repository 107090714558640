<template>
  <Modal :show="show" class="reset-link" @close="$emit('close')">
    <template #title>
      {{ $t('title') }}
    </template>

    <template #body>
      <Btn class="reset-link__generator" type="secondary" @click="$emit('submit')">
        <span>{{ $t('submit') }}</span>
      </Btn>
      <div class="reset-link__link">
        <label for="reset-link" class="form-group__label reset-link__subtitle">
          {{ $t('resetLink') }}
        </label>
        <div class="reset-link__copy-link">
          <input
            id="reset-link"
            ref="clone"
            class="reset-link__input"
            type="text"
            :value="resetLink"
            readonly
            @focus="$event.target.select()"
          />
          <Btn :title="$t('copy')" type="icon-only" @click="copy">
            <font-awesome-icon icon="fa-copy" />
          </Btn>
        </div>
      </div>
    </template>

    <!-- Keep empty template to avoid default button -->
    <template #cta>
      <span></span>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'ModalPassword',

  components: {
    Btn,
    Modal,
  },

  props: {
    resetLink: {
      default: '',
      type: String,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'submit'],

  methods: {
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
  },
};
</script>

<style lang="scss">
.reset-link {
  color: $text-dark;

  &__generator {
    display: flex;
    align-items: center;
    height: 29px;
    color: $text-dark-variant;
  }

  &__link {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0;
  }

  &__subtitle {
    font-size: 14px;
  }

  &__copy-link {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__input {
    width: 100%;
    height: 30px;
    margin-right: 0.5rem;
    font: inherit;
  }
}
</style>

<i18n locale="fr">
{
  "title": "Changer le mot de passe de l'utilisateur",
  "resetLink": "Lien pour changer le mot de passe",
  "submit": "Générer un lien pour changer le mot de passe",
  "close": "Fermer"
}
</i18n>

<i18n locale="en">
{
  "title": "Reset user password",
  "resetLink": "Reset link:",
  "submit": "Generate a password reset link",
  "close": "Close"
}
</i18n>
