import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const ColorPickerCell = defineAsyncComponent(() => import('@/components/Table/cells/ColorPickerCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  NAME: 'name',
  COLOR: 'color',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'teamListDatagrid',
    searchFields: [ColumnKey.NAME],
    defaultSortBy: [{ key: ColumnKey.NAME, order: SortOrder.ASC }],
    rowIdKey: 'team_id',
    hasActions: true,
    showColumnSelector: false,

    columns: [
      // NAME
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.teamName',
        selectable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object.team_id,
              value: value || object.team_id,
              editMode,
              type: ColumnKey.NAME,
            },
          };
        },
      }),

      // COLOR
      new DataGridColumn({
        key: ColumnKey.COLOR,
        title: 'column.teamColor',
        selectable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: ColorPickerCell,
            events: { valueChanged },
            props: {
              _id: object.team_id,
              value,
              editMode,
            },
          };
        },
      }),
    ],
  });
};
