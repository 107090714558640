<template>
  <div class="passengers-messages">
    <div class="passengers-messages__header">
      <div class="passengers-messages__header-side">
        <Btn
          v-if="$store.getters.hasPermission(Permission.VIEW_GTFS_RT)"
          type="secondary"
          :route="{ name: GroupRoute.GTFS_RT }"
        >
          <div class="gtfsrt-status-wrapper">
            <div class="gtfsrt-status-indicator" />
            {{ $t('gtfsRT') }}
          </div>
        </Btn>
        <Btn
          v-if="$store.getters.hasPermission(Permission.EXPORT_PASSENGERS_MESSAGES)"
          type="secondary"
          @click="downloadData"
        >
          <i class="fas fa-download" aria-hidden="true"></i>
          <span class="btn-text">{{ $t('download') }}</span>
        </Btn>
        <Btn v-if="hasEditPermission" type="primary" @click="showModal()">
          <font-awesome-icon icon="fa-paper-plane" />
          <span class="btn-text">{{ $t('newMessage') }}</span>
        </Btn>
      </div>
    </div>

    <div v-if="passengersMessagesList.length === 0 && !loading" class="passengers-messages__no-data">
      <div class="passengers-messages__no-data-message">
        <div>{{ $t('noData') }}</div>
        <div>{{ $t('createNewInfo') }}</div>
      </div>
      <img alt="illustration" class="illustration" src="@/assets/img/no_data_illustration.svg?url" />
    </div>

    <DataGridVuetify
      v-else
      v-model:rendered-data-length="renderedDataLength"
      :data="passengersMessagesList"
      :datagrid="datagrid"
      :loading="loading"
      :show-header="false"
      :tabs="tabs"
      :build-cell-injectors="buildCellInjectors"
    >
      <template #actions="propsAction">
        <ActionCell
          :actions="getActionsByStatus(propsAction.object.status)"
          :object="propsAction.object"
          :active="propsAction.object.active"
          @archive="showModalRemove(propsAction.object)"
          @copy="copyMessage(propsAction.object)"
          @edit="showModal(propsAction.object)"
          @push="showModalPush(propsAction.object)"
          @display="toggleMessageStatus(propsAction.object, true)"
          @pause="toggleMessageStatus(propsAction.object, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalPassengersMessage
      v-if="modalShown"
      :options-entities="optionsEntities"
      :selected-message="selectedMessage"
      @close="closeModal"
    />

    <ModalPush
      v-if="modalPushShown"
      :selected-message-id="selectedMessage?._id"
      @close="closeModal"
      @submit="submitModalPush"
    >
      <template #cta>
        <Btn type="primary" @click="submitModalPush(selectedMessage._id)">
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          <span class="btn-text">{{ $t('sendPush') }}</span>
        </Btn>
      </template>
    </ModalPush>

    <ModalArchiveRestore
      v-if="modalRemoveShown"
      :body="$t('confirmDeleteMessage')"
      :title="$t('deleteMessage')"
      @close="closeModal"
      @submit="submitModalRemove"
    />
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import {
  ColumnKey,
  getDatagrid,
  PASSENGERS_MESSAGE_LS_COLUMNS,
} from '@/pages/PassengersMessage/PassengersMessage.conf.js';
import ModalPassengersMessage from '@/components/common/ModalPassengersMessage.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import { GroupRoute } from '@/libs/routing';
import { Permission } from '@/auth';
import { LocationType } from '@/store/gtfs';
import { alerts as alertsAPI } from '@/api';
import ModalPush from './ModalPush.vue';

/**
 * @param {string} dateString
 * @param {string} timeString
 * @returns {Date|null}
 */
export function parseDateTime(dateString, timeString) {
  if (!dateString || !timeString) {
    return null;
  }

  return new Date(
    `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}T${timeString}`,
  );
}

/** @enum {string} */
export const MessageStatus = {
  DISPLAYED: 'displayed',
  SCHEDULED: 'scheduled',
  PAUSED: 'paused',
  EXPIRED: 'expired',
  DRAFT: 'draft',
};

/** @enum {string} */
export const Cause = {
  1: 'unknown',
  2: 'other',
  3: 'technical',
  4: 'strike',
  5: 'demonstration',
  6: 'accident',
  7: 'holiday',
  8: 'weather',
  9: 'maintenance',
  10: 'construction',
  11: 'police',
  12: 'medical',
};

/** @enum {string} */
export const Effect = {
  1: 'noService',
  2: 'reduced',
  3: 'delays',
  4: 'detour',
  5: 'additional',
  6: 'modified',
  7: 'other',
  8: 'unknown',
  9: 'stopMoved',
};

/** @enum {string} */
export const ModalType = {
  ARCHIVE: 'archive',
  EDIT: 'edit',
  PUSH: 'push',
};

/** @enum {string} */
export const Status = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export default {
  name: 'PassengersMessages',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    ModalPassengersMessage,
    ModalPush,
    ModalArchiveRestore,
  },

  data: () => ({
    GroupRoute,
    PASSENGERS_MESSAGE_LS_COLUMNS,
    ColumnKey,
    Permission,
    Status,

    /** @type {Array<import('@/store/alerts').Alert>} */
    passengersMessagesList: [],
    /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
    datagrid: getDatagrid(),
    /** @type {Boolean} */
    loading: true,
    /** @type {Boolean} */
    modalShown: false,
    /** @type {boolean} */
    modalRemoveShown: false,
    /** @type {boolean} */
    modalPushShown: false,
    /** @type {Array<OptionsEntities>} */
    optionsEntities: [],
    /** @type {number} */
    renderedDataLength: null,
    /** @type {?import('@/store/alerts').Alert} */
    selectedMessage: null,
    /** @type {Array<string>} */
    statusCategories: Object.values(Status),
    /**
     * @type {Object.<MessageStatus, string[]>}
     * @property {string[]} MessageStatus.DISPLAYED
     * @property {string[]} MessageStatus.SCHEDULED
     * @property {string[]} MessageStatus.PAUSED
     * @property {string[]} MessageStatus.EXPIRED
     * @property {string[]} MessageStatus.DRAFT
     */
    actionsByStatusMap: {
      [MessageStatus.DISPLAYED]: ['pause', 'edit', 'copy', 'push', 'archive'],
      [MessageStatus.SCHEDULED]: ['display', 'edit', 'copy', 'archive'],
      [MessageStatus.PAUSED]: ['display', 'edit', 'copy', 'archive'],
      [MessageStatus.EXPIRED]: ['copy', 'edit', 'archive'],
      [MessageStatus.DRAFT]: ['display', 'edit', 'copy', 'archive'],
    },

    /**
     * @type {Object.<Status, MessageStatus[]>}
     * @property {MessageStatus[]} Status.ACTIVE
     * @property {MessageStatus[]} Status.INACTIVE
     * @property {MessageStatus[]} Status.ALL
     */
    tabsFilterMap: {
      [Status.ACTIVE]: [MessageStatus.DISPLAYED],
      [Status.INACTIVE]: [
        MessageStatus.SCHEDULED,
        MessageStatus.PAUSED,
        MessageStatus.EXPIRED,
        MessageStatus.DRAFT,
      ],
      [Status.ALL]: [
        MessageStatus.DISPLAYED,
        MessageStatus.SCHEDULED,
        MessageStatus.PAUSED,
        MessageStatus.EXPIRED,
        MessageStatus.DRAFT,
      ],
    },
  }),

  computed: {
    /** @return {{[key in ColumnTypes]: (data: {apiData: import('@/store/alerts').Alert}) => Object}} */
    buildCellInjectors() {
      const bindActiveCheckbox = apiDataRow => () => {
        this.toggleActive(apiDataRow);
      };

      return {
        [ColumnKey.ACTIVE]: ({ apiData }) => ({ click: bindActiveCheckbox(apiData) }),
        [ColumnKey.CAUSE]: ({ apiData }) => ({ modelI18n: this.$i18n }),
        [ColumnKey.EFFECT]: ({ apiData }) => ({ modelI18n: this.$i18n }),
        [ColumnKey.ENTITY]: ({ apiData }) => ({ optionsEntities: this.optionsEntities }),
      };
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {boolean} */
    hasEditPermission() {
      return this.$store.getters.hasPermission(Permission.EDIT_PASSENGERS_MESSAGES);
    },

    /** @return {Array<import('@/components/Table/DataGridVuetify/index.vue').Tab>} */
    tabs() {
      return this.statusCategories.map(status => ({
        value: status,
        name: this.$t(`tabs.${status}`),
        counter: null,
        dataList: [],
        filterField: ColumnKey.STATUS,
        filterValues: this.getTabsFilterValue(status),
        isDefaultActive: Status.ALL === status,
        icon: status === Status.ACTIVE ? 'fas fa-check' : status === Status.INACTIVE ? 'fas fa-times' : '',
      }));
    },
  },

  watch: {
    'group._id': function groupId() {
      this.$store.dispatch('alerts/getList');
    },
  },

  async created() {
    await this.$store.dispatch('alerts/getList');
    this.updateOptionsEntities();
    this.formatMessagesList();
    this.loading = false;
  },

  methods: {
    getActionsByStatus(status) {
      return this.actionsByStatusMap[status] ?? [];
    },

    async toggleMessageStatus(object, active) {
      object.active = active;
      this.loading = true;
      const updatedMessage = await this.$store.dispatch('alerts/put', object);

      if (updatedMessage) {
        const messageIndex = this.passengersMessagesList.findIndex(msg => msg._id === updatedMessage._id);

        if (messageIndex !== -1) {
          this.passengersMessagesList.splice(messageIndex, 1, updatedMessage);
          this.formatMessagesList();
        }
      }
      this.loading = false;
    },

    getStatus(alert) {
      const now = new Date();
      if (alert.draft) {
        return MessageStatus.DRAFT;
      }

      if (!alert.active_date_times?.length) {
        return alert.active ? MessageStatus.DISPLAYED : MessageStatus.PAUSED;
      }

      const activeDateTime = alert.active_date_times[0];
      const startDateTime = parseDateTime(activeDateTime.start_date, activeDateTime.start_time);
      const endDateTime = parseDateTime(activeDateTime.end_date, activeDateTime.end_time);
      const isActive = !!alert.active;

      if (startDateTime && now < startDateTime) {
        return isActive ? MessageStatus.SCHEDULED : MessageStatus.DRAFT;
      }
      if (endDateTime && now > endDateTime) {
        return MessageStatus.EXPIRED;
      }
      return isActive ? MessageStatus.DISPLAYED : MessageStatus.PAUSED;
    },

    copyMessage(message) {
      const passengersMessage = { ...message, active: false };
      passengersMessage.header_text = `${this.$t('copy')} ${passengersMessage.header_text}`;
      delete passengersMessage._id;
      this.showModal(passengersMessage);
    },

    formatMessagesList() {
      const unformattedList = Object.values(this.$store.state.alerts.list);
      const formattedList = unformattedList.reduce((acc, message) => {
        acc.push({
          ...message,
          active: message.active || false,
          description: message.description_raw || message.description_text,
          // We use infinity value and not null, so that the sort order works properly
          timeDisplay:
            message.active_date_times?.length > 0 ? message.active_date_times[0].start_date : Infinity,
          causeFormatted:
            message.cause && Cause[message.cause] ? this.$t(`cause.${Cause[message.cause]}`) : null,
          effectFormatted:
            message.effect && Effect[message.effect] ? this.$t(`effect.${Effect[message.effect]}`) : null,
          pushFormatted: message.push ? new Date(message.push[message.push.length - 1] * 1000) : null,
          entities: message.informed_entity,
          status: this.getStatus(message),
        });
        return acc;
      }, []);
      this.passengersMessagesList = formattedList;
    },

    async closeModal() {
      this.modalShown = false;
      this.selectedMessage = null;
      this.loading = true;
      await this.$store.dispatch('alerts/getList');
      this.formatMessagesList();
      this.loading = false;
      this.modalRemoveShown = false;
      this.modalPushShown = false;
    },

    async downloadData() {
      await alertsAPI.download(this.group._id);
    },

    getTabsFilterValue(status) {
      return this.tabsFilterMap[status] ?? this.tabsFilterMap[Status.ALL];
    },

    /**
     * Show modal to edit given alert or create a new one.
     * @param {import('@/store/alerts').Alert} [message]
     */
    showModal(message = /** @type {import('@/store/alerts').Alert} */ ({})) {
      // Set default data and override with given alert
      this.selectedMessage = {
        header_text: '',
        active: true,
        description_text: '',
        active_date_times: [
          {
            start_date: '',
            end_date: '',
            start_time: '00:00',
            end_time: '23:59',
          },
        ],
        cause: '',
        effect: '',
        informed_entity: [],
        ...cloneDeep(message),
      };

      this.modalShown = true;
    },

    /** @param {import('@/store/alerts').Alert} message */
    showModalPush(message) {
      this.selectedMessage = /** @type {import('@/store/alerts').Alert} */ ({
        _id: message._id,
      });
      this.modalPushShown = true;
    },

    /** @param {import('@/store/alerts').Alert} message */
    showModalRemove(message) {
      this.selectedMessage = /** @type {import('@/store/alerts').Alert} */ ({
        _id: message._id,
      });
      this.modalRemoveShown = true;
    },

    async submitModalRemove() {
      await this.$store.dispatch('alerts/delete', this.selectedMessage._id);
      this.closeModal();
    },

    async submitModalPush(messageId) {
      this.$store.dispatch('alerts/sendPush', messageId);
      this.closeModal();
    },

    /** @param {import('@/store/alerts').Alert} message */
    async toggleActive(message) {
      await this.$store.dispatch('alerts/patch', {
        alertId: message._id,
        changes: {
          active: !message.active,
        },
      });
      this.formatMessagesList();
    },

    async updateOptionsEntities() {
      const ts = Date.now();
      const [routes, stops] = await Promise.all([
        (async () => {
          /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
          const routes = await this.$store.dispatch('gtfs/getRoutesMap', {
            ts,
          });

          return Object.values(routes).map(r => {
            const value = { route_id: r.route_id };
            return {
              name: r.route_short_name,
              key: JSON.stringify(value),
              value,
            };
          });
        })(),
        (async () => {
          /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
          const stops = await this.$store.dispatch('gtfs/getStopsMap', { ts });

          return Object.values(stops).map(s => {
            const value = { stop_id: s.stop_id };
            let stopName = `${s.stop_name} - ${s.stop_code || s.stop_id}`;
            if (s.location_type?.toString() === LocationType.STATION) {
              stopName += this.$t('station');
            }
            return {
              name: stopName,
              key: JSON.stringify(value),
              value,
            };
          });
        })(),
      ]);

      this.optionsEntities = [
        {
          label: /** @type {string} */ (this.$t('routes')),
          values: routes,
        },
        {
          label: /** @type {string} */ (this.$t('stops')),
          values: stops,
        },
      ];
    },
  },
};

/**
 * @typedef {Object} OptionsEntities
 * @property {string} label
 * @property {Array<RouteOption | StopOption>} values
 */

/**
 * @typedef {Object} RouteOption
 * @property {string} name
 * @property {string} key
 * @property {{route_id: string}} value
 */

/**
 * @typedef {Object} StopOption
 * @property {string} name
 * @property {string} key
 * @property {{stop_id: string}} value
 */

/**
 * @typedef {object} PassengersMessage
 * @extends {Object<import('@/store/alerts').Alert>}
 * @property {string} description
 * @property {Object<import('@/store/alerts').ActiveDateTime>} timeDisplay
 * @property {?string} causeFormatted
 * @property {?string} effectFormatted
 * @property {Date} pushFormatted
 * @property {Array<import('@/store/alerts').AlertEntity>} entities
 */
</script>

<style lang="scss" scoped>
.passengers-messages {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
  }

  &__header-side {
    display: flex;

    .btn-text {
      margin-left: 2px;
    }

    .fas,
    .fa-paper-plane,
    .font-awesome-icon {
      margin-right: 8px;
    }

    .ui-btn {
      padding: 0 15px;
      line-height: 40px;
    }
  }

  &__no-data {
    position: relative;
    padding: 20px 30px;

    .illustration {
      width: auto;
      height: auto;
      max-height: 800px;
    }
  }

  &__no-data-message {
    position: absolute;
    top: 115px;
    left: 200px;
    color: $secondary;
    font-weight: $font-weight-semi-bold;
    font-size: 21px;
    line-height: 31px;
  }
}

.fa-paper-plane {
  color: white;
}

.gtfsrt-status-wrapper {
  display: flex;
  align-items: center;
}

.gtfsrt-status-indicator {
  width: 6px;
  height: 6px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: $primary-light;
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 change-color($primary-light, $alpha: 0.2);
  }

  30% {
    box-shadow: 0 0 0 5px change-color($primary-light, $alpha: 0.2);
  }

  31% {
    box-shadow: 0 0 0 0 change-color($primary-light, $alpha: 0.2);
  }

  100% {
    box-shadow: 0 0 0 0 change-color($primary-light, $alpha: 0.2);
  }
}
</style>

<i18n locale="fr">
{
  "tabs": {
    "active": "Affichés",
    "inactive": "Non affichés",
    "all": "Tous",
  },
  "all": "Tous",
  "active": "Actif",
  "activePlural": "Actifs",
  "confirmDeleteMessage": "Êtes-vous sûr de vouloir supprimer le message ?",
  "confirmPush": "Êtes-vous sûr de vouloir envoyer une notification push ?",
  "copy": "Copie",
  "createNewInfo": "Créez un nouveau message voyageurs en cliquant sur ce bouton.",
  "deleteMessage": "Supprimer le message",
  "displayed": "Affiché",
  "draft": "Brouillon",
  "editMessage": "Modifier",
  "expired": "Expiré",
  "gtfsRT": "GTFS-RT",
  "message": "Message",
  "newMessage": "Envoyer un message voyageurs",
  "noData": "Aucune donnée disponible.",
  "paused": "En Pause",
  "periodDisplayed": "Du {startDate} au {endDate}",
  "scheduled": "Prévu",
  "sendPush": "Envoyer une notification push",
  "station": " (station)"
}
</i18n>

<i18n locale="en">
{
  "tabs": {
    "active": "Active",
    "inactive": "Inactive",
    "all": "All",
  },
  "all": "All",
  "active": "Active",
  "activePlural": "Active",
  "confirmDeleteMessage": "Are you sure you want to delete the message?",
  "confirmPush": "Are you sure you want to send a push notification?",
  "createNewInfo": "Create a new passenger message with this button.",
  "copy": "Copy",
  "deleteMessage": "Delete the message",
  "displayed": "Displayed",
  "draft": "Draft",
  "editMessage": "Edit",
  "expired": "Expired",
  "gtfsRT": "GTFS-RT",
  "message": "Message",
  "newMessage": "Send passengers message",
  "noData": "No data available.",
  "paused": "Paused",
  "periodDisplayed": "From the {startDate} to the {endDate}",
  "scheduled": "Scheduled",
  "sendPush": "Send a push notification",
  "station": " (station)"
}
</i18n>
