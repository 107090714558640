/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module devices */
import deepmerge from 'deepmerge';

import Api from '@/api';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { segmentInfo } from '@/libs/helpers/geo';
import { eventStreamToStateStream } from '@/libs/helpers/objects';
import loading from './loading';

/** @enum {string} */
export const CurrentStatus = Object.freeze({
  IN_TRANSIT_TO: 'IN_TRANSIT_TO',
  INCOMING_AT: 'INCOMING_AT',
  STOPPED_AT: 'STOPPED_AT',
});

/** @enum {string} */
export const DelayState = Object.freeze({
  ON_TIME: 'onTime',
  LATE: 'tooLate',
  EARLY: 'tooEarly',
});
/** @enum {string} */
export const AlternativeState = Object.freeze({
  DEAD_RUNNING: 'deadRunning',
  ROUTING: 'routing',
});
export const OFF_ITINERARY = 'offItinerary';

/** @enum {string} */
export const DeviceMode = Object.freeze({
  SEQUENCE: 'sequence',
  TRIP: 'trip',
});

/**
 * Find device's mode and filter using `drive_mode` or `trip_filter`.
 * @param {Device} device
 * @return {?{name: ?DeviceMode, param: ?string}} containing the mode (`name`) and the corresponding id (`param`).
 */
export function getDeviceMode(device) {
  if (device.drive_mode) {
    return {
      name: device.drive_mode,
      param: device.trip_filter && (device.trip_filter.block_id || device.trip_filter.trip_id),
    };
  }

  // Old (before 2.3) method to get mode.
  if (device.trip_filter) {
    if (device.trip_filter.trip_id) {
      return {
        name: DeviceMode.TRIP,
        param: device.trip_filter.trip_id,
      };
    }
    return {
      // unknown mode, probably dead run
      name: null,
      param: null,
    };
  }
}

/**
 * Find if a device is in dead run.
 * @param {Device} device
 * @return {boolean}
 */
export const isDeadRun = device => !!device.trip_filter && device.trip_filter.trip_id === null;

/** @typedef {typeof state} State */
const state = {
  /** @type {Array<Event>} */
  lastChanges: [],

  /** @type {{[deviceId: string]: Device}} */
  list: {},

  /** @type {string} */
  mapLabelFormat: '',

  /** @type {{[deviceId: string]: boolean}} */
  online: {},

  /** @type {{[deviceId: string]: boolean}} */
  visible: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  getters: {
    /**
     * Get the delay state of a device.
     * @callback GetDelayStateCallback
     * @param {number} delay
     * @return {DelayState}
     */
    /** @return {GetDelayStateCallback} */
    getDelayState: (state, getters, rootState, rootGetters) => delay => {
      const [minOnTimeDelay, maxOnTimeDelay] = rootGetters.group.driver_ontime_interval;

      if (delay > maxOnTimeDelay) {
        return DelayState.LATE;
      }
      if (delay < minOnTimeDelay) {
        return DelayState.EARLY;
      }
      return DelayState.ON_TIME;
    },

    /**
     * Map of connected devices.
     * @return {{[deviceId: string]: Device}}
     */
    onlineDevices: state =>
      Object.keys(state.online).reduce((acc, id) => {
        acc[id] = state.list[id];

        return acc;
      }, /** @type {{[deviceId: string]: Device}} */ ({})),

    /**
     * getDevicesList.
     * @return {{[deviceId: string]: Device}}
     */
    getDevicesInfos(state) {
      return state.list;
    },

    /**
     * Map of visible devices, filtered with `group.delay_device_offline_visible`.
     * @return {{[deviceId: string]: Device}}
     */
    visibleDevices: state =>
      Object.keys(state.visible).reduce((acc, id) => {
        acc[id] = state.list[id];

        return acc;
      }, /** @type {{[deviceId: string]: Device}} */ ({})),
  },

  mutations: {
    /** Clear state. */
    clear(state) {
      state.lastChanges = [];
      state.list = {};
      state.online = {};
      state.visible = {};
    },

    /**
     * Remove a device from the list.
     * @param {string} deviceId
     */
    deleteDevice(state, deviceId) {
      delete state.list[deviceId];
    },

    /**
     * Patch multiple device.
     * @param {Array<Device>} devices
     */
    patchDevices(state, devices) {
      const list = /** @type {{[deviceId: string]: Device}} */ ({});

      devices.forEach(d => {
        if (!state.list[d.device_id]) {
          list[d.device_id] = Object.freeze(d);
        } else {
          list[d.device_id] = Object.freeze({ ...state.list[d.device_id], ...d });
        }
      });

      state.list = deepmerge(state.list, list, { arrayMerge: (_, newArr) => newArr });
    },

    /**
     * Set/update a device in the list.
     * @param {Device} device
     */
    setDevice(state, device) {
      state.list[device.device_id] = device;
    },

    /**
     * Set the list of device.
     * @param {Object.<string, Device>} devices
     */
    setDevices(state, devices) {
      state.list = devices;
    },
    /**

    /**
     * Add a deviceId in `state.online` list.
     * @param {string} deviceId
     */
    setHidden(state, deviceId) {
      delete state.visible[deviceId];
    },

    /**
     * Set last changes from WebSocket.
     * @param {Array<Event>} events
     */
    setLastChanges(state, events) {
      state.lastChanges = events;
    },

    /**
     * Set map device label format.
     * @param {string} format
     */
    setMapLabelFormat(state, format) {
      state.mapLabelFormat = format;
    },

    /**
     * Remove a deviceId in `state.online` list.
     * @param {string} deviceId
     */
    setOffline(state, deviceId) {
      delete state.online[deviceId];
    },

    /**
     * Add a deviceId in `state.online` list.
     * @param {string} deviceId
     */
    setOnline(state, deviceId) {
      state.online[deviceId] = true;
    },

    /**
     * Add a deviceId in `state.online` list.
     * @param {string} deviceId
     */
    setVisible(state, deviceId) {
      state.visible[deviceId] = true;
    },
  },

  actions: {
    /**
     * Clear cache and state.
     * @param context
     */
    async clear({ commit, dispatch }) {
      await dispatch('loading/clear');
      commit('clear');
    },

    /**
     * Delete a device.
     * @param context
     * @param {string} deviceId
     */
    async delete({ commit, dispatch }, deviceId) {
      const group = await dispatch('getGroup', null, { root: true });
      await Api.devices.deleteDevice(group._id, deviceId);
      commit('deleteDevice', deviceId);
    },

    /**
     * Create a device.
     * @param context
     * @param {Device} device
     */
    async post({ commit, dispatch }, device) {
      const group = await dispatch('getGroup', null, { root: true });
      await Api.devices.post(group._id, device);
      commit('setDevice', device);
    },

    /**
     * Get a device.
     * @param context
     * @param {Object} payload
     * @param {string} payload.deviceId
     * @return {Promise<Device>}
     */
    async getDevice({ state, commit, dispatch }, { deviceId }) {
      if (!state.list[deviceId]) {
        await dispatch('loading/throttleLoading', {
          key: `device_${deviceId}`,
          callback: async () => {
            const group = await dispatch('getGroup', null, { root: true });
            const device = await Api.devices.getDevice(group._id, deviceId);

            commit('setDevice', device);
          },
        });
      }

      return state.list[deviceId];
    },

    /**
     * Get list of devices.
     * @param context
     * @return {Promise<{[deviceId: string]: Device}>}
     */
    async getDevices({ state, commit, dispatch }) {
      await dispatch('loading/throttleLoading', {
        key: 'devices',
        callback: async () => {
          const group = await dispatch('getGroup', null, { root: true });
          const devices = {};
          const arrDevices = await Api.devices.getDevices(group._id);
          arrDevices.forEach(d => {
            devices[d.device_id] = d;
          });
          commit('setDevices', devices);
        },
      });

      return state.list;
    },

    /**
     * Get list of events for a device.
     * @param context
     * @param {Object} payload
     * @param {string} payload.deviceId
     * @param {string} [payload.dateGtfs] - Filter on a day. Defaults to current day.
     * @param {number} [payload.fromTs]
     * @param {number} [payload.toTs]
     * @param {string} [payload.tripId]
     * @return {Promise<Array<Event>>}
     */
    async getEventsOf(
      { dispatch },
      { deviceId, dateGtfs = null, fromTs = null, toTs = null, tripId = null },
    ) {
      if (fromTs === null || toTs === null) {
        dateGtfs = dateGtfs || dateObjToGtfsFormat(new Date());
        const date = dateGtfsFormatToObj(dateGtfs);
        fromTs = date.getTime() / 1000;
        toTs = date.getTime() / 1000 + 86400;
      }

      // Move fromTs 1h backward to catch one snapshot
      const reqFromTs = fromTs - 3600;

      const events = await dispatch('loading/throttleLoading', {
        key: `events_${deviceId}_${reqFromTs}_${toTs}`,
        callback: async () => {
          const group = await dispatch('getGroup', null, { root: true });
          const events = await Api.devices.getEvents(group._id, {
            deviceId,
            fromTs: reqFromTs,
            toTs,
            tripId,
          });

          events.sort((a, b) => a.ts - b.ts);
          let snapshotFound = false;
          const filteredEvents = [];
          let prevEvent = '';
          events.forEach(e => {
            // Keep only the first snapshot
            if (e.snapshot && !snapshotFound) {
              // Ignore first snapshot latlng to avoid position jump on device start
              delete e.latlng;
              filteredEvents.push(e);
              snapshotFound = true;
            }
            if (!e.snapshot) {
              const currEvent = JSON.stringify(e);
              // Remove consecutive duplicated events
              if (currEvent !== prevEvent) {
                filteredEvents.push(e);
                prevEvent = currEvent;
              }
            }
          });

          // Bearing and speed calc
          // As of 2020/12/23, will be done in API and recorded in history. But it is
          // still missing for previous history events, so we need to keep it here for now.
          let prev;
          for (let i = 0; i < filteredEvents.length; i += 1) {
            if (
              !Object.prototype.hasOwnProperty.call(filteredEvents[i], 'latlng') ||
              !Object.prototype.hasOwnProperty.call(filteredEvents[i], 'ts')
            ) {
              break;
            }

            if (
              prev &&
              filteredEvents[i].ts >= fromTs &&
              (filteredEvents[i].bearing == null || filteredEvents[i].speed == null)
            ) {
              const seg = segmentInfo(
                { lat: prev.latlng[0], lng: prev.latlng[1] },
                { lat: filteredEvents[i].latlng[0], lng: filteredEvents[i].latlng[1] },
              );

              if (filteredEvents[i].bearing == null) {
                filteredEvents[i].bearing = seg.bearing;
              }

              if (filteredEvents[i].speed == null) {
                const dt = filteredEvents[i].ts - prev.ts;
                if (dt !== 0) filteredEvents[i].speed = seg.distance / dt;
              }
            }

            prev = filteredEvents[i];
          }

          // State stream
          const eventsStream = eventStreamToStateStream(filteredEvents);
          const startIdx = eventsStream.findIndex(e => e.ts >= fromTs);

          return eventsStream.slice(startIdx);
        },
      });

      return events;
    },

    /**
     * Change an existing device.
     * @param context
     * @param {Device} device
     */
    async put({ commit, dispatch }, device) {
      const group = await dispatch('getGroup', null, { root: true });
      device = await Api.devices.put(group._id, device);
      commit('setDevice', device);
    },

    /**
     * Archive or Restore an existing device.
     * @param context
     * @param {string} groupId
     * @param {string} deviceId
     * @param {boolean} toArchive
     */
    async archiveRestoreById({ state, commit, dispatch }, { groupId, deviceId, toArchive }) {
      if (toArchive) {
        await Api.devices.archive(groupId, deviceId);
      } else {
        await Api.devices.unarchive(groupId, deviceId);
      }
    },

    /**
     * Update devices from received messages.
     * @param context
     * @param {Array<Device>} messages
     */
    async updateDevices({ state, commit, rootGetters }, messages) {
      // Split and merge events by device ids
      const events = /** @type {{[deviceId: string]: Array<Device>}} */ ({});
      messages.forEach(e => {
        if (!events[e.device_id]) events[e.device_id] = [e];
        else events[e.device_id].push(e);
      });

      // Update each device
      const devices = /** @type {Array<Device>} */ ([]);
      Object.entries(events).forEach(([deviceId, event]) => {
        const device = Object.assign({}, ...event);
        delete device.update_info;

        devices.push(device);
      });
      commit('patchDevices', devices);

      commit('setLastChanges', messages);
    },
  },

  modules: {
    loading,
  },
});

/**
 *
 * @param {(string|null|undefined)[]} teams
 * @return {(function(Device): (boolean))}
 */
export function deviceTeamsFilterFunction(teams) {
  return device => {
    if (
      (teams.includes(null) || teams.includes(undefined) || teams.includes('')) &&
      !device.team_id &&
      (!device.teams || device.teams.length === 0)
    ) {
      return true;
    }

    if (device.team_id && teams.includes(device.team_id)) {
      return true;
    }

    if (device.teams) {
      for (const deviceTeam of device.teams) {
        if (teams.includes(deviceTeam)) {
          return true;
        }
      }
    }

    return false;
  };
}

/** @type {import('vuex').Plugin<import('.').State>} */
export function devicesUpdatePlugin(store) {
  setInterval(() => {
    Object.keys(store.state.devices.visible).forEach(deviceId => {
      const device = store.state.devices.list[deviceId];
      if (!device) return;
      const deviceTs = device.ts_system ?? device.ts;
      if (!deviceTs) return; // Devices created via "new device" in device-list have no ts until first connection in Driver
      checkTimestamp(deviceId, deviceTs);
    });
  }, 5000);

  store.subscribe(mutation => {
    updateOnlineDevices(mutation);
  });

  /**
   * Listen to group change, to update properties `mapLabelFormat`.
   */
  store.watch(
    (_, getters) => getters.group.map_vehicle_tooltip_pattern,
    () => {
      let format = localStorage.getItem('settings.op.mapDeviceLabelFormat');
      if (!format) {
        format = store.getters.group.map_vehicle_tooltip_pattern || '';
      }

      if (format !== store.state.devices.mapLabelFormat) {
        store.commit('devices/setMapLabelFormat', format);
      }
    },
    { immediate: true },
  );

  /**
   * Listen to commit that changes `state.devices` to keep list of connected/visible devices.
   * @param {Object} mutation
   */
  function updateOnlineDevices({ type, payload }) {
    if (['devices/setDevice', 'devices/setDevices', 'devices/patchDevices'].includes(type)) {
      let devices = payload; // when `type === 'devices/patchDevices'`
      if (type === 'devices/setDevice') {
        devices = [payload];
      } else if (type === 'devices/setDevices') {
        devices = Object.values(payload);
      }

      devices.forEach(d => {
        if (!d.ts) return; // Devices created via "new device" in device-list have no ts until first connection in Driver
        checkTimestamp(d.device_id, d.ts_system ?? d.ts);
      });
    }
  }

  /**
   * Check device timestamp to set the status.
   * @param {string} deviceId
   * @param {number} ts
   */
  function checkTimestamp(deviceId, ts) {
    const now = Date.now() / 1000;
    const timeOffline = ts - now + store.getters.group.delay_device_online;
    const timeVisible = ts - now + store.getters.group.delay_device_offline_visible;

    if (timeOffline < 0) {
      if (store.state.devices.online[deviceId]) {
        store.commit('devices/setOffline', deviceId);
      }

      if (timeVisible < 0) {
        if (store.state.devices.visible[deviceId]) {
          store.commit('devices/setHidden', deviceId);
        }
      } else if (!store.state.devices.visible[deviceId]) {
        store.commit('devices/setVisible', deviceId);
      }
    } else {
      if (!store.state.devices.online[deviceId]) {
        store.commit('devices/setOnline', deviceId);
      }

      if (!store.state.devices.visible[deviceId]) {
        store.commit('devices/setVisible', deviceId);
      }
    }
  }
}

/**
 * Device object coming from API.
 * @typedef {Object} Device
 * @property {string} app_version
 * @property {string} app_version_states
 * @property {string} device_id
 * @property {string} device_model
 * @property {string} device_os
 * @property {string} installer
 * @property {number} ts
 * @property {number} [ts_system]
 * @property {boolean} [archived]
 * @property {string} [assigned_driver_id]
 * @property {string} [assigned_vehicle_id]
 * @property {number} [bearing]
 * @property {?string} [current_activity_log_assignation]
 * @property {CurrentStatus} [current_status]
 * @property {string} [current_stop]
 * @property {number} [current_stop_sequence]
 * @property {?number} [delay]
 * @property {?string} [gtfs_id]
 * @property {number} [history_last_latlng_ts]
 * @property {string} [last_message_id]
 * @property {[number, number]} [latlng] - [ latitude, longitude ]
 * @property {string} [name]
 * @property {string} [route_id]
 * @property {number} [shape_dist_traveled]
 * @property {boolean} [simulation_mode]
 * @property {boolean} [skipped_last_position]
 * @property {number} [speed]
 * @property {string} [stop_id]
 * @property {?string} [team_id]
 * @property {?string[]} [teams]
 * @property {?DeviceTrip} [trip]
 * @property {TripFilter} [trip_filter]
 * @property {string} [trip_id] Deprecated in favor of `Device.trip`
 * @property {DeviceMode} [drive_mode]
 * @property {boolean} [trip_pending]
 * @property {boolean} [off_itinerary]
 * @property {?number} [vehicle_load]
 */

/**
 * @typedef {object} DeviceExtended
 * @extends Device
 * @property {string} isGtfsUpToDate
 * @property {string} vehicleInfos
 * @property {string} driverInfos
 */

/**
 * @typedef {Object} DeviceTrip
 * @property {string} trip_id
 * @property {string} start_date
 */

/**
 * TripFilter object coming from API.
 * @typedef {{trip_id: string|null} | {block_id: string}} TripFilter
 */

/**
 * Event object coming from API.
 * Every optionnal property is present only when it changes from preceding event.
 * @typedef {Object} Event
 * @property {string} device_id - Device Id.
 * @property {number} ts - Event timestamp.
 * @property {string} [app_version]
 * @property {number} [bearing]
 * @property {CurrentStatus} [current_status] - Bus current status.
 * @property {number} [current_stop_sequence] - Current stop sequence.
 * @property {number} [delay] - Bus delay on stop id.
 * @property {string} [gtfs_id] - Gtfs Id
 * @property {number} [gps_ppm]
 * @property {Array} [latlng] - [ latitude, longitude ]
 * @property {number} [latlng_accuracy]
 * @property {number} [shape_dist_traveled] - Shape dist traveled.
 * @property {boolean} [snapshot]
 * @property {number} [speed]
 * @property {string} [stop_id] - Stop id.
 * @property {?DeviceTrip} [trip]
 * @property {TripFilter} [trip_filter]
 * @property {string} [trip_id] Deprecated in favor of `Event.trip`
 * @property {boolean} [trip_pending]
 * @property {{client_app: 'op'|'driver'}} [update_info]
 */
