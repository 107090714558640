<template>
  <Tag :text="$t(status)" :title="$t(status)" :color="color" :background="backgroundColor" :icon="icon" />
</template>

<script>
import Tag from '@/components/ui/Tag.vue';

/** @enum {string} */
export const DeviceStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
};

export default {
  name: 'StatusCell',

  components: { Tag },

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    backgroundColor() {
      switch (this.status) {
        case DeviceStatus.ACTIVE:
          return '#00b8711A'; // $primary-light 10%
        case DeviceStatus.INACTIVE:
          return '#f99c491A'; // $warn 10%
        case DeviceStatus.ARCHIVED:
          return '#FBDDDD';
        default:
          return '';
      }
    },

    color() {
      switch (this.status) {
        case DeviceStatus.ACTIVE:
          return '#00b871'; // $primary-light
        case DeviceStatus.INACTIVE:
          return '#f99c49'; // $warn
        case DeviceStatus.ARCHIVED:
          return '#A41414';
        default:
          return '';
      }
    },

    icon() {
      switch (this.status) {
        case DeviceStatus.ACTIVE:
          return 'fa-check';
        case DeviceStatus.INACTIVE:
          return 'fa-clock';
        case DeviceStatus.ARCHIVED:
          return 'fa-box-archive';
        default:
          return '';
      }
    },
  },
};
</script>

<i18n locale="fr">
{
  "active": "Actif",
  "inactive": "Inactif",
  "archived": "Archivé",
}
</i18n>

<i18n locale="en">
{
  "active": "Active",
  "inactive": "Inactive",
  "archived": "Archived",
}
</i18n>
