<template>
  <VueMultiselect
    :id="id"
    v-model="selectedValue"
    :can-clear="canClear"
    :clear-on-blur="false"
    :close-on-select="mode === 'single' ? true : false"
    :groups="groups"
    :group-hide-empty="true"
    :group-options="groupOptions"
    :hide-selected="false"
    :label="labelProp"
    :mode="mode"
    :no-results-text="$t('noResult')"
    :no-options-text="$t('noOption')"
    :object="object"
    :options="options"
    :placeholder="placeholder"
    :searchable="searchable"
    :value-prop="valueProp"
    @change="selectOption"
    @close="isOpen = false"
    @open="open"
  >
    <template v-for="(_, slot) in $slots" #[slot]="props">
      <slot :name="slot" v-bind="props"></slot>
    </template>
    <template #caret>
      <span class="selector__caret" :class="{ 'selector__caret--open': isOpen }"></span>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from '@vueform/multiselect';

export default {
  name: 'Selector',

  components: { VueMultiselect },

  props: {
    canClear: {
      type: Boolean,
      default: true,
    },

    groups: {
      type: Boolean,
      default: false,
    },

    groupOptions: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      required: true,
    },

    labelProp: {
      type: String,
      default: 'label',
    },

    mode: {
      type: String,
      default: '',
    },

    object: {
      type: Boolean,
      default: false,
    },

    openDirection: {
      type: String,
      default: 'bottom',
    },

    options: {
      type: Array,
      default: () => [],
    },

    placeholder: {
      required: false,
      default: '',
      type: String,
    },

    searchable: {
      type: Boolean,
      default: true,
    },

    value: {
      type: [String, Array, Object, Number],
      default: null,
    },

    valueProp: {
      type: String,
      required: false,
      default: 'value',
    },
  },

  emits: ['open', 'update:value'],

  data() {
    return {
      /** @type {Boolean} */
      isOpen: false,
    };
  },

  computed: {
    selectedValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('update:value', value);
      },
    },
  },

  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open', this.id);
    },

    selectOption(value) {
      this.$emit('update:value', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  &__caret {
    &::before {
      content: '\f078';
      position: absolute;
      top: calc(50% - (18px / 2));
      right: 15px;
      height: 18px;
      color: $text-dark;
      font-weight: $font-weight-semi-bold;
      font-family: $font-awesome;
      line-height: 18px;
      transition-duration: 0.5s;
      transition-property: transform;
    }

    &--open::before {
      transform: rotate(180deg);
    }
  }
}
</style>
