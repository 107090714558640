import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import { type DateTimeFormats } from '@intlify/core-base';

const AVAILABLE_LANGUAGE_LIST = ['fr', 'en', 'es', 'de', 'it', 'pl', 'cs'];

function loadLocaleDateTimeFormats(): DateTimeFormats {
  const languageDateFormat: DateTimeFormats = {};
  AVAILABLE_LANGUAGE_LIST.forEach(key => {
    languageDateFormat[key] = {
      dateShort: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },

      dateVeryShort: {
        year: 'numeric',
        month: 'numeric',
      },

      datetimeLong: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },

      datetimeShort: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },

      timeLong: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },

      timeShort: {
        hour: 'numeric',
        minute: 'numeric',
      },

      weekday: {
        weekday: 'long',
      },

      dayMonth: {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
      },
    };
  });

  return languageDateFormat;
}

const i18n = createI18n({
  locale: import.meta.env.VITE_I18N_LOCALE || 'fr',
  allowComposition: true,
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'fr',
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  datetimeFormats: loadLocaleDateTimeFormats(),
});

export default i18n;
