import type { ExtendedWindow } from '@/@types/global';

declare let window: ExtendedWindow;

/**
 * Version coming from git describe
 */
export const version = window.__PYSAE_OP_VERSION__?.startsWith('v')
  ? window.__PYSAE_OP_VERSION__.substring(1)
  : window.__PYSAE_OP_VERSION__;

if (!version) {
  console.error('__PYSAE_OP_VERSION__ is not defined');
}
