<template>
  <Modal class="modal-integration-connector-delete" @close="$emit('close')">
    <template #title>
      {{ $t('deactivateIntegration') }}
    </template>

    <template #subtitle>
      {{ $t(integrationType.labelKey) }}
    </template>

    <template #cta>
      <Btn type="danger" @click="$emit('delete')">
        <font-awesome-icon icon="fa-trash" />
        {{ $t('deactivate') }}
      </Btn>
    </template>
  </Modal>
</template>

<script setup>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

defineEmits(['close', 'delete']);

defineProps({
  /** @type {import('vue').PropType<import('/@store/integrations').IntegrationType>} */
  integrationType: {
    type: Object,
    required: true,
  },
});
</script>

<style lang="scss">
.modal-integration-connector-delete {
  svg {
    margin-right: 5px;
  }
}
</style>

<i18n locale="fr">
{
  "deactivate": "Désactiver",
  "deactivateIntegration": "Désactiver l'intégration",
}
</i18n>

<i18n locale="en">
{
  "deactivate": "Deactivate",
  "deactivateIntegration": "Deactivate integration",
}
</i18n>
