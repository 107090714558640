import { compareObjectKeys } from '@/libs/helpers/objects';

/**
 * Get some value from localStorage and parse it from JSON, while catching errors.
 */
export function safeLSParse(key: string): any | null {
  try {
    const lsItem = localStorage.getItem(key);
    if (lsItem) return JSON.parse(lsItem);
    return null;
  } catch (e) {
    return null;
  }
}

export class DatagridLocalStorageHelper {
  groupId: string;
  localStorageKey: string;
  propertyKey: string;
  filters: { [key: string]: { [key: string]: boolean } };
  lsFilters: { [key: string]: { [key: string]: boolean } };

  constructor(
    groupId: string,
    localStorageKey: string,
    propertyKey: string,
    filters: { [key: string]: { [key: string]: boolean } },
  ) {
    this.groupId = groupId;
    this.localStorageKey = localStorageKey;
    this.propertyKey = propertyKey;
    this.filters = filters;
    this.lsFilters = this.loadLsFilters();
  }

  /**
   * Compare current filters keys & local filter fetched from LS & return if keys are not different
   */
  getLocalFilter(): { [key: string]: boolean } | null {
    const localFilter = this.lsFilters[this.propertyKey];
    const currentFilter = this.filters?.[this.propertyKey];
    // Do not return saved filters if current filter has different keys
    if (localFilter && compareObjectKeys(currentFilter, localFilter)) {
      return localFilter;
    }
    return null;
  }

  saveFilters(filters: { [key: string]: { [key: string]: boolean } }) {
    const newObject = {
      groupId: this.groupId,
      filters,
    };
    localStorage.setItem(this.localStorageKey, JSON.stringify(newObject));
    this.filters = filters;
    this.lsFilters = filters;
  }

  /**
   * Add filters in LS or update if already exist
   */
  addOrUpdateFilter(filter: { [key: string]: boolean }) {
    const filters = this.fetchFilters() || {};
    filters[this.propertyKey] = filter;
    this.saveFilters(filters);
  }

  /**
   * Remove filter from LS
   */
  removeFilter() {
    const filters = this.fetchFilters();
    if (filters) {
      delete filters[this.propertyKey];
      this.saveFilters(filters);
    }
  }

  /**
   * Fetch filter from LS if current group is equal to groupId saved in LS filter
   */
  fetchFilters(): { [key: string]: { [key: string]: boolean } } | null {
    const stored = safeLSParse(this.localStorageKey);
    // Keep filter only if groupId match
    if (stored && stored.groupId === this.groupId) return stored.filters;
    return null;
  }

  /**
   * Load filters from localStorage if exist
   */
  loadLsFilters(): { [key: string]: { [key: string]: boolean } } {
    const filters = this.fetchFilters();
    if (filters) return filters;
    return {};
  }
}
