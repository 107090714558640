<template>
  <div class="reports">
    <AnimatedDots v-if="isLoading" class="reports__loading reports__loading-dots" :type-v2="true" />
    <NoReportData v-else-if="!hasData" />
    <template v-else>
      <div class="reports__abstract">
        <NumberDisplay :title="$t('totalConnected')" :number="totalConnected" />
        <NumberDisplay :title="$t('averageConnected')" :number="averageConnected" />
      </div>
      <div class="reports__barcharts">
        <BarChart3
          :height="chartHeight"
          :options="dayChart.options"
          :categories="dayChart.categories"
          :series="dayChart.series"
          :title="$t('chartDayTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { stats as ApiStats } from '@/api';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import BarChart3 from '@/components/ui/BarChart3.vue';
import NoReportData from '@/components/ui/NoReportData.vue';
import NumberDisplay from '@/components/ui/NumberDisplay.vue';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { toCSV } from '@/libs/csv';

export default {
  name: 'ReportsConnectedDevice',

  components: {
    AnimatedDots,
    BarChart3,
    NumberDisplay,
    NoReportData,
  },

  props: {
    /** @type {Object} */
    chartHeight: {
      type: Number,
      default: 620,
    },

    /** @type {Object} */
    chartWidth: {
      type: Number,
      default: 1200,
    },

    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },
  },

  emits: ['downloadLink', 'hasData'],

  data: () => ({
    /** @type {import('./index.vue').ChartData} */
    dayChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },

    isLoading: false,
  }),

  computed: {
    /** @return {number} */
    averageConnected() {
      if (!this.dayChart.data.length) return 0;
      return Math.round(
        this.dayChart.data.reduce((acc, connectedDevicesObject) => acc + connectedDevicesObject.devices, 0) /
          this.dayChart.data.length,
      );
    },

    downloadLink() {
      const columns = ['date', 'yaxisTitle'].map(column => this.$t(`${column}`));

      const data = [
        columns,
        ...this.dayChart.data.map(obj => [this.$d(dateGtfsFormatToObj(obj.date), 'dateShort'), obj.devices]),
      ];

      const link = toCSV(data);

      return link;
    },

    /** @return {number} */
    totalConnected() {
      return this.dayChart.data.reduce(
        (acc, connectedDevicesObject) => acc + connectedDevicesObject.devices,
        0,
      );
    },
    hasData() {
      const result = this.dayChart.data.length !== 0;
      this.$emit('hasData', result);
      return result;
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.loadData();
        }
      },
    },

    downloadLink() {
      this.$emit('downloadLink', this.downloadLink);
    },
  },

  methods: {
    async loadData() {
      this.isLoading = true;

      const startDate = dateObjToGtfsFormat(new Date(this.dateInterval.start * 1000));
      const endDate = dateObjToGtfsFormat(new Date(this.dateInterval.end * 1000));

      const response = await ApiStats.getDailyDevices(this.$store.getters.group._id, startDate, endDate);

      this.dayChart.data = response;
      this.dayChart.series = this.getSeries(this.dayChart.data);
      this.dayChart.options = this.getOptions(this.dayChart.data);
      this.dayChart.categories = this.dayChart.data.map(el =>
        this.$d(dateGtfsFormatToObj(el.date), 'dayMonth').replace(/\./g, ''),
      );

      this.isLoading = false;
    },

    /**
     * @param {Array<import('@/api').DailyDevices>} data
     * @param {'day'|'route'} chartType
     * @return {Object}
     */
    getOptions(data) {
      return {
        tooltip: {
          custom: ({ dataPointIndex, w }) => `
            <ul class="apexcharts-custom-tooltip">
              <li>${this.$t('connectedDevices', [
                data[dataPointIndex + w.config.page * w.config.barsPerScreen].devices,
              ])}</li>
            </ul>
            `,
        },
      };
    },

    /**
     * @param {Array<import('@/api').DailyDevices>} data
     * @return {Array<import('@/components/ui/BarChart2.vue').ApexChartSerie>}
     */
    getSeries(data) {
      const seriesData = data.reduce(
        (acc, currentObject) => {
          acc.devices.push(currentObject.devices);
          return acc;
        },
        {
          /** @type {Array<number>} */
          devices: [],
        },
      );
      return [
        {
          data: seriesData.devices,
          color: () => '#00B871', // $primary-light
        },
      ];
    },
  },
};
</script>

<i18n locale="fr">
{
  "averageConnected": "Moyenne des appareils connectés par jour",
  "chartDayTitle": "Appareils connectés par jour",
  "connectedDevices": "{0} appareils connectés",
  "date": "Date",
  "totalConnected": "Total des appareils connectés",
  "yaxisTitle" : "Appareils connectés"
}
</i18n>

<i18n locale="en">
{
  "averageConnected": "Average connected devices per day",
  "chartDayTitle": "Connected devices per day",
  "connectedDevices": "{0} connected devices",
  "date": "Date",
  "totalConnected": "Total of connected devices",
  "yaxisTitle" : "Connected devices"
}
</i18n>
