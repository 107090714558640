<template>
  <div class="modal-message">
    <div class="modal-message__dialog">
      <div class="modal-message__dialog-content">
        <Btn type="icon-only" class="modal-message__btn-close btn" @click="close">
          <i class="fas fa-times" />
        </Btn>
        <div class="passenger-modal-content custom-scrollbar">
          <div class="modal-message__header">
            <div class="modal-message__title">
              <span>{{ $t('passengersMessage') }}</span>
            </div>
          </div>

          <div class="modal-message__body">
            <section>
              <div class="form-group">
                <label class="form-group__label" for="header_text">
                  {{ $t('header') }}
                </label>
                <input
                  id="header_text"
                  v-model="currentMessage.header_text"
                  class="form-group__input"
                  required
                  type="text"
                />
              </div>

              <div class="form-group">
                <div class="modal-passengers-message__description">
                  <label class="form-group__label" for="description_text">
                    {{ $t('description') }}
                  </label>
                </div>
                <TextEditor
                  id="description_text"
                  ref="description"
                  v-model:value="currentMessage.description_text"
                  v-model:raw-value="currentMessage.description_raw"
                />
                <label class="modal-passengers-message__information-label">
                  {{ $t('descriptionGtfsInfo') }}
                </label>
              </div>

              <div class="form-group">
                <v-checkbox id="timePeriod" v-model="timePeriodToDefine" color="success" hide-details>
                  <template #label>
                    {{ $t('defineTimePeriod') }}
                  </template>
                </v-checkbox>
              </div>

              <template v-if="timePeriodToDefine === true">
                <div v-for="(adt, i) in currentMessage.active_date_times" :key="i">
                  <div class="form-group date-time-form">
                    <label class="form-group__label" for="start_date">
                      {{ $t('startDate') }}
                    </label>
                    <Datepicker
                      id="start_date"
                      v-model:value="adt.start_date"
                      :disabled-dates="disabledStartDates"
                      class="datepicker__arrow"
                      :class="{ 'datepicker__arrow--open': isStartDateOpen }"
                      input-class="form-group__input"
                      required
                      :has-custom-position="false"
                      @opened="isStartDateOpen = true"
                      @closed="isStartDateOpen = false"
                    />
                  </div>

                  <div class="form-group date-time-form">
                    <label class="form-group__label" for="start_time">{{ $t('startTime') }}</label>
                    <input
                      id="start_time"
                      v-model="adt.start_time"
                      class="form-group__input"
                      pattern="[0-9]{2}:[0-9]{2}"
                      type="time"
                    />
                  </div>

                  <div class="form-group date-time-form">
                    <label class="form-group__label" for="end_date">
                      {{ $t('endDate') }}
                    </label>
                    <Datepicker
                      id="end_date"
                      v-model:value="adt.end_date"
                      :disabled-dates="{ minDate: adt.start_date, maxDate: null }"
                      class="datepicker__arrow"
                      :class="{ 'datepicker__arrow--open': isEndDateOpen }"
                      input-class="form-group__input"
                      required
                      :has-custom-position="false"
                      @opened="isEndDateOpen = true"
                      @closed="isEndDateOpen = false"
                    />
                  </div>

                  <div class="form-group date-time-form">
                    <label class="form-group__label" for="end_time">
                      {{ $t('endTime') }}
                    </label>
                    <input
                      id="end_time"
                      v-model="adt.end_time"
                      class="form-group__input"
                      pattern="[0-9]{2}:[0-9]{2}"
                      type="time"
                    />
                  </div>
                </div>
              </template>

              <div class="form-group">
                <label class="form-group__label" for="url">
                  {{ $t('url') }}
                </label>
                <input
                  id="url"
                  v-model="currentMessage.url"
                  class="form-group__input"
                  :placeholder="$t('urlPlaceholder')"
                  :class="isUrlValid ? '' : 'form-group__input--invalid'"
                  type="text"
                />
              </div>

              <div class="form-group">
                <label class="form-group__label" for="cause">
                  {{ $t('cause.title') }}
                </label>
                <Selector
                  id="cause"
                  v-model:value="currentMessage.cause"
                  :options="causeOptions"
                  mode="single"
                  @open="scrollToElement"
                />
              </div>

              <div class="form-group">
                <label class="form-group__label" for="effect">
                  {{ $t('effect.title') }}
                </label>
                <Selector
                  id="effect"
                  v-model:value="currentMessage.effect"
                  :options="effectOptions"
                  mode="single"
                  @open="scrollToElement"
                />
              </div>

              <div class="form-group">
                <label class="form-group__label" for="informed_entity">
                  {{ $t('entities') }}
                </label>
                <Selector
                  id="infomed_entity"
                  v-model:value="currentMessage.informed_entity"
                  groups
                  group-options="values"
                  :options="optionsEntities"
                  mode="tags"
                  label-prop="name"
                  value-prop="key"
                  @open="scrollToElement"
                />
              </div>

              <div class="form-group">
                <v-checkbox
                  id="active-passengers-message"
                  v-model="currentMessage.active"
                  color="success"
                  hide-details
                >
                  <template #label>
                    {{ $t('active') }}
                  </template>
                </v-checkbox>
              </div>
            </section>
          </div>
        </div>
        <div class="passenger-modal-buttons">
          <div class="modal-message__cta">
            <Btn type="primary" :disabled="!formIsValid" @click="submitModal">
              {{ $t('submit') }}
            </Btn>
          </div>

          <Btn type="secondary" class="modal__secondary-btn" @click="close">
            {{ $t('cancel') }}
          </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// We cannot use our Modal component here, as the wysiwyg cannot work with vuetify Modal.
import cloneDeep from 'clone-deep';
import Btn from '@/components/ui/Btn.vue';
import Datepicker from '@/components/ui/Datepicker.vue';
import Selector from '@/components/ui/Selector.vue';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { Cause, Effect, parseDateTime } from '@/pages/PassengersMessage/index.vue';
import TextEditor from '@/pages/PassengersMessage/TextEditor.vue';
export default {
  name: 'ModalPassengersMessage',

  components: {
    Btn,
    Datepicker,
    Selector,
    TextEditor,
  },

  props: {
    /** @type {Vue.PropOptions<Array<import('@/pages/PassengersMessage/index.vue').OptionsEntities>>} */
    optionsEntities: {
      default: () => [],
      type: Array,
    },

    /** @type {Vue.PropOptions<import('@/pages/PassengersMessage/index.vue').PassengersMessage>} */
    selectedMessage: {
      required: true,
      type: Object,
    },
  },

  emits: ['close'],

  data() {
    return {
      Cause,
      Effect,
      /** @type {?import('@/pages/PassengersMessage/index.vue').PassengersMessage} */
      currentMessage: null,
      /** @type {?import('@/components/ui/Datepicker.vue').DisabledDates} */
      disabledStartDates: {},
      /** @type {boolean} */
      isEndDateOpen: false,
      /** @type {boolean} */
      isStartDateOpen: false,
      /** @type {?number} */
      lastCursorPosition: null,
      /** @type {Boolean} */
      timePeriodToDefine: false,
      isUrlValid: true,
    };
  },

  computed: {
    causeOptions() {
      return Object.entries(Cause).map(([value, name]) => ({
        value,
        label: this.$t(`cause.${name}`),
      }));
    },

    effectOptions() {
      return Object.entries(Effect).map(([value, name]) => ({
        value,
        label: this.$t(`effect.${name}`),
      }));
    },

    /** @return {boolean} */
    formIsValid() {
      return Object.keys(this.formValidation).every(k => {
        if (k === 'active_date_times') {
          return Object.values(this.formValidation[k]).every(adt => Object.keys(adt).every(k => adt[k]));
        }
        return this.formValidation[k] && this.isUrlValid;
      });
    },

    /** @return {MessageValidation} */
    formValidation() {
      if (!this.currentMessage) return /** @type {MessageValidation} */ ({});

      const validation = /** @type {MessageValidation} */ ({
        header_text: this.currentMessage.header_text.trim() !== '',
      });

      if (this.timePeriodToDefine === true) {
        validation.active_date_times = {};
        const timeRE = /[0-9]{2}:[0-9]{2}/;
        Object.values(this.currentMessage.active_date_times).forEach((adt, i) => {
          validation.active_date_times[i] = {
            start_date: adt.start_date !== null && adt.start_date !== '',
            start_time: timeRE.test(adt.start_time),
            end_date: adt.end_date !== null && adt.end_date !== '',
            end_time: timeRE.test(adt.end_time),
          };
        });
      }

      return validation;
    },
  },
  watch: {
    'currentMessage.active_date_times': {
      handler(newValue) {
        const adt = newValue[0];
        this.disabledStartDates = adt.end_date
          ? {
              minDate: new Date(),
              maxDate: adt.end_date,
            }
          : {
              minDate: new Date(),
              maxDate: null,
            };
      },
      deep: true,
    },

    'currentMessage.url': function currentMessageUrl(newValue, oldValue) {
      const urlRegex = new RegExp(
        "^(?:http(s)?://)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
      );
      if (newValue) this.isUrlValid = urlRegex.test(newValue);
      else this.isUrlValid = true;
    },
  },
  created() {
    this.currentMessage = cloneDeep(this.selectedMessage);
    if (this.currentMessage.active_date_times && this.currentMessage.active_date_times.length > 0) {
      if (this.currentMessage.active_date_times[0].start_date) {
        this.timePeriodToDefine = true;
      }
      // Convert period data
      this.currentMessage.active_date_times = this.currentMessage.active_date_times.map(adt => {
        adt.start_date === ''
          ? (adt.start_date = new Date())
          : (adt.start_date =
              adt.start_date instanceof Date ? adt.start_date : dateGtfsFormatToObj(adt.start_date));
        adt.end_date === ''
          ? (adt.end_date = null)
          : (adt.end_date =
              (typeof adt.end_date) instanceof Date ? adt.end_date : dateGtfsFormatToObj(adt.end_date));
        return adt;
      });
    }
    // Convert object to stringified multiselect values
    if (this.currentMessage.informed_entity) {
      this.currentMessage.informed_entity = this.currentMessage.informed_entity.map(JSON.stringify);
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    /**
     * @param {string} id
     * Scroll inside the modal so that the multiselect dropdown is fully visible
     */
    scrollToElement(id) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    },

    async submitModal() {
      if (!this.formIsValid) return;

      const message = cloneDeep(this.currentMessage);

      // Cleanup
      delete message.description;
      delete message.timeDisplay;
      delete message.entities;
      delete message.causeFormatted;
      delete message.effectFormatted;
      delete message.pushFormatted;
      if (!message.cause || message.cause === '') {
        delete message.cause;
      } else {
        message.cause = parseInt(message.cause);
      }
      if (!message.effect || message.effect === '') {
        delete message.effect;
      } else {
        message.effect = parseInt(message.effect);
      }
      if (message.description_text === '') {
        delete message.description_text;
      }
      if (message.description_raw === '') {
        delete message.description_raw;
      }
      if (message.informed_entity.length === 0) {
        delete message.informed_entity;
      } else {
        // Convert stringified multiselect values to object
        message.informed_entity = message.informed_entity.map(JSON.parse);
      }
      if (this.timePeriodToDefine === false) {
        delete message.active_date_times;
      } else {
        // Convert period data
        message.active_date_times = message.active_date_times.map(adt => {
          adt.end_date = dateObjToGtfsFormat(adt.end_date);
          adt.end_time = `${adt.end_time}:00`.substring(0, 8);
          adt.start_date = dateObjToGtfsFormat(adt.start_date);
          adt.start_time = `${adt.start_time}:00`.substring(0, 8);
          return adt;
        });
      }

      const now = new Date();
      let isBeforeStartPeriod = false;

      if (message.active_date_times?.length > 0) {
        const startDateTime = parseDateTime(
          message.active_date_times[0].start_date,
          message.active_date_times[0].start_time,
        );
        isBeforeStartPeriod = startDateTime && now < startDateTime;
      }

      message.draft = !message.active && (!message._id || isBeforeStartPeriod);

      // Create or update
      if (message._id) {
        await this.$store.dispatch('alerts/put', message);
      } else {
        await this.$store.dispatch('alerts/post', message);
      }

      this.close();
    },
  },
};

/**
 * @typedef {Object} ActiveDateTimeValidation
 * @property {boolean} start_date
 * @property {boolean} start_time
 * @property {boolean} end_date
 * @property {boolean} end_time
 */

/**
 * @typedef {Object} MessageValidation
 * @property {boolean} header_text
 * @property {{[i: number]: ActiveDateTimeValidation}} [active_date_times]
 */
</script>

<style lang="scss" scoped>
.modal-passengers-message {
  &__description {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  &__information-label {
    display: flex;
    justify-content: flex-end;
    margin: 5px;
    font-size: 12px;
  }

  .form-group {
    max-width: 840px;
    text-align: left;
  }

  .v-checkbox .v-label {
    font-weight: $font-weight-semi-bold;
  }
}

.modal-message {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;

  &__cta {
    margin-bottom: 10px;
  }

  &__dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgb(0 0 0 / 50%);

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 900px;
      max-width: calc(100% - 20px);
      max-height: calc(100% - 20px);
      padding: 20px;
      border-radius: 10px;
      background-color: $background;

      .modal-message__btn-close {
        position: absolute;
        top: 12px;
        right: 12px;
        border-width: 0 !important;
        cursor: pointer;
      }

      .passenger-modal-content {
        overflow-y: scroll;
        height: 80%;
        padding-right: 4px;

        .modal-message__header {
          width: 100%;
          margin: 10px 0;
          text-align: center;

          .modal-message__title {
            color: #333;
            font-weight: 600;
            font-size: 18px;
          }
        }

        .modal-message__body {
          padding-top: 10px;

          .v-label {
            font-weight: 600;
          }
        }
      }

      .passenger-modal-buttons {
        margin-top: 10px;

        .primary,
        .secondary,
        .danger {
          width: 100%;
        }
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "active": "Actif",
  "passengersMessage": "Message voyageurs",
  "definedPeriod": "Période définie",
  "defineTimePeriod": "Définir une période d'affichage",
  "description": "Description",
  "endDate": "Date de fin",
  "endTime": "Heure de fin",
  "url": "URL",
  "urlPlaceholder": "https://www.votre-lien.com/informations-a-propos-de-l-alerte",
  "entities": "Éléments concernés (tout le réseau par défaut)",
  "header": "En-tête",
  "message": "Message",
  "startDate": "Date de début",
  "startTime": "Heure de début",
  "timeDisplay": "Période d'affichage",
  "descriptionGtfsInfo": "Dans le GTFS-RT, la description sera retranscrite en texte brut."
}
</i18n>

<i18n locale="en">
{
  "active": "Active",
  "passengersMessage": "Passenger information",
  "always": "Always",
  "customized": "Customized",
  "definedPeriod": "Defined period",
  "defineTimePeriod": "Define a time period",
  "description": "Description",
  "endDate": "End date ",
  "endTime": "Finish time",
  "url": "URL",
  "urlPlaceholder": "https://www.your-url.com/informations-about-alert",
  "entities": "Concerned elements (all the network by default)",
  "header": "Header",
  "message": "Message",
  "startDate": "Start date ",
  "startTime": "Start time",
  "timeDisplay": "Time display",
  "descriptionGtfsInfo": "In the GTFS-RT, the description will be transcribed in plain text."

}
</i18n>

<i18n locale="cz">
{
  "active": "Aktivní",
  "passengersMessage": "Informace o cestujících",
  "always": "Vždy",
  "definedPeriod": "Určené období",
  "description": "Podrobný popis (možnost)",
  "endDate": "Datum ukončení",
  "endTime": "Čas konce",
  "entities": "Dotčené prvky (dle výchozích nastavení celá síť)",
  "message": "Zpráva",
  "startDate": "Datum začátku",
  "startTime": "Čas začátku"
}
</i18n>

<i18n locale="de">
{
  "active": "Aktiviert",
  "passengersMessage": "Informationen für Passagiere",
  "always": "Immer",
  "definedPeriod": "Festgelegte Zeitspanne",
  "description": "Detaillierte Beschreibung (optional)",
  "endDate": "Enddatum",
  "endTime": "Endzeit",
  "entities": "Betroffene Elemente (standardmäßig das gesamte Netzwerk)",
  "message": "Mitteilung",
  "startDate": "Startdatum",
  "startTime": "Startzeit"
}
</i18n>

<i18n locale="es">
{
  "active": "Activo",
  "passengersMessage": "Información del pasajero",
  "always": "Siempre",
  "definedPeriod": "Periodo definido",
  "description": "Descripción detallada (opcional)",
  "endDate": "Fecha de finalización",
  "endTime": "Hora de finalización",
  "entities": "Elementos afectados (por defecto, toda la red)",
  "message": "Mensaje",
  "startDate": "Fecha de inicio",
  "startTime": "Hora de inicio"
}
</i18n>

<i18n locale="it">
{
  "active": "Attivo",
  "passengersMessage": "Informazioni sui passeggeri",
  "always": "Sempre",
  "definedPeriod": "Periodo definito",
  "description": "Descrizione dettagliata (opzionale)",
  "endDate": "Data di fine",
  "endTime": "Orario di fine",
  "entities": "Elementi interessati (tutta la rete per impostazione predefinita)",
  "message": "Messaggio",
  "periodDisplayed": "Dal {startDate} al {endDate}",
  "sendPush": "Invia una notifica push",
  "startDate": "Data di inizio",
  "startTime": "Orario di inizio"
}
</i18n>

<i18n locale="pl">
{
  "active": "Aktywna",
  "passengersMessage": "Dane pasażera",
  "always": "Zawsze",
  "definedPeriod": "Zdefiniowany okres",
  "description": "Szczegółowy opis (opcja)",
  "endDate": "Data końcowa",
  "endTime": "Czas zakończenia",
  "entities": "Stosowne elementy (domyślnie cała sieć)",
  "message": "Wiadomość",
  "periodDisplayed": "Od {startDate} do {endDate}",
  "sendPush": "Wyślij powiadomienie PUSH",
  "startDate": "Data początkowa",
  "startTime": "Czas rozpoczęcia"
}
</i18n>
