<script setup>
import { Permission } from '@/auth';
import { AuthRoute, GroupRoute } from '@/libs/routing';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const viewSettingsPermission = computed(() => {
  return store.getters.hasPermission(Permission.VIEW_SETTINGS_BUTTON);
});

const userName = computed(() => {
  return store.state.user.name ?? store.state.user.email;
});

/** Log out user */
async function logout() {
  await store.dispatch('logout');
  router.replace({ name: AuthRoute.LOGIN_FORM });
}
</script>
<template>
  <div class="option-menu-dropdown">
    <v-menu offset="8">
      <template #activator="{ props }">
        <v-btn class="option-menu-dropdown__btn" variant="text" height="36" v-bind="props">
          <v-icon size="small">fa:fas fa-ellipsis-v</v-icon>
        </v-btn>
      </template>
      <v-list class="topbar-dropdown__list">
        <v-list-subheader>{{ userName }}</v-list-subheader>
        <v-divider />

        <v-list-item @click="router.push({ name: GroupRoute.PROFILE })">
          <template #prepend>
            <font-awesome-icon class="mr-3" icon="fa-user" />
          </template>
          <v-list-item-title>{{ $t('profile.profile') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="viewSettingsPermission" @click="router.push({ name: GroupRoute.SETTINGS })">
          <template #prepend>
            <font-awesome-icon class="mr-3" icon="fa-cog" />
          </template>
          <v-list-item-title>{{ $t('profile.settings') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout()">
          <template #prepend>
            <font-awesome-icon class="mr-3" icon="fa-power-off" />
          </template>
          <v-list-item-title>{{ $t('profile.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="scss">
.option-menu-dropdown {
  &__btn {
    min-width: 40px;
    max-width: 40px;
    cursor: pointer;

    .v-btn__content {
      i {
        font-size: 16px;
      }
    }
  }
}
</style>

<i18n locale="fr">
    {
      "profile": {
        "logout": "Déconnexion",
        "profile": "Profil",
        "settings": "Paramètres du réseau"
      }
    }
    </i18n>

<i18n locale="en">
    {
      "profile": {
        "logout": "Log off",
        "profile": "Profile",
        "settings": "Network settings"
      }
    }
    </i18n>

<i18n locale="cz">
    {
      "profile": {
        "logout": "Odhlásit se",
        "profile": "Profil",
      }
    }
    </i18n>

<i18n locale="de">
    {
      "profile": {
        "logout": "Abmelden",
        "profile": "Profil"
      }
    }
    </i18n>

<i18n locale="es">
    {
      "profile": {
        "logout": "Cerrar sesión",
        "profile": "Perfil"
      }
    }
    </i18n>

<i18n locale="it">
    {
      "profile": {
        "logout": "Disconnettiti",
        "profile": "Profilo"
      }
    }
    </i18n>

<i18n locale="pl">
    {
      "profile": {
        "logout": "Wyloguj się",
        "profile": "Profil"
      }
    }
    </i18n>
