<template>
  <tr class="event-feed-trip-update">
    <!-- Event -->
    <td colspan="4">
      <div
        class="event-feed-trip-update__container"
        :class="{
          'info-style': [UpdateType.STOP_INFO, UpdateType.COMMENT].includes(tripUpdate.type),
        }"
      >
        <!-- Icon -->
        <font-awesome-icon :icon="eventIcon" class="event-feed-trip-update__icon" />
        <!-- Content -->
        <div v-if="tripUpdate.type === UpdateType.COMMENT">
          {{ $t('eventFeed.comment') }} {{ tripUpdate.content }}
        </div>
        <div v-else-if="tripUpdate.type === UpdateType.DELAY">
          <span v-if="tripUpdate.content > 0">
            {{ $t('eventFeed.delayColon') }}
            {{ $t('eventFeed.delayMin', [Math.abs(tripUpdate.content) / 60]) }}
          </span>
          <span v-else-if="tripUpdate.content < 0">
            {{ $t('eventFeed.advance') }}
            {{ $t('eventFeed.delayMin', [Math.abs(tripUpdate.content) / 60]) }}
          </span>
        </div>
        <div v-else-if="tripUpdate.type === UpdateType.DO_NOT_SERVE">
          {{ $t('eventFeed.doNotServe') }}
          <span v-for="(stop, index) in tripUpdate.content" :key="index">
            <span>{{ stop }}</span>
            <span v-if="index !== tripUpdate.content.length - 1">{{ ', ' }}</span>
          </span>
        </div>
        <div v-else-if="tripUpdate.type === UpdateType.STOP_INFO">
          {{ tripUpdate.content.stopName }} {{ $t('eventFeed.infoStop', [tripUpdate.content.information]) }}
        </div>
        <div v-else-if="tripUpdate.type === UpdateType.TRIP_CANCELED">
          {{ $t('eventFeed.canceled') }}
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { UpdateType } from '@/api';
import { ScheduleRelationship } from '@/store/trips';

export default {
  name: 'EventFeedTripUpdate',

  props: {
    /** @type {Vue.propsOptions<Array<import('./EventFeed.vue').FormattedTripUpdate>>} */
    tripUpdate: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    UpdateType,
    ScheduleRelationship,
  }),

  computed: {
    /** @return {String} */
    eventIcon() {
      switch (this.tripUpdate.type) {
        case UpdateType.COMMENT:
          return 'fa-comment-dots';
        case UpdateType.DO_NOT_SERVE:
          return 'fa-arrows-split-up-and-left';
        case UpdateType.DELAY:
          return 'fa-stopwatch';
        case UpdateType.TRIP_CANCELED:
          return 'fa-xmark';
        case UpdateType.STOP_INFO:
          return 'fa-comment-dots';
        default:
          return 'fa-bus';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-feed-trip-update {
  &__container {
    display: flex;
    align-content: center;
    margin: 8px 5px 0;
    padding: 5px 10px;
    border: 1px solid $warn;
    border-radius: 8px;
    background-color: $transparent-warn;
    color: $warn;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;

    &.info-style {
      border-color: $blue;
      background-color: $transparent-blue;
      color: $blue;
    }
  }

  &__icon {
    align-self: center;
    height: 14px;
    margin-right: 10px;
  }
}
</style>
