import { defineStore } from 'pinia';
import Api from '@/api';
import { arrToFakeMap } from '@/libs/helpers/objects';
import { computed, ref } from 'vue';
import type { Dictionary } from '@/@types/global';
import { useStore } from 'vuex';

export interface Vehicle {
  archived: boolean;
  id: string;
  _id: string; //deprecated;
  comment?: string;
  fleet_number: string;
  license_plate: string;
  capacity?: number;
  team_id?: string;
}

export const useVehiclesStore = defineStore('vehicles', () => {
  // #region state :
  // TODO -call to useStore to be replaced on the next store refactor
  const store = useStore();
  const groupId = computed<string>(() => store.getters.group._id);
  const list = ref<Dictionary<Vehicle>>({});
  // #endregion

  // #region Mutations :
  function setList(newList: Dictionary<Vehicle>) {
    list.value = newList;
  }
  function addOrUpdate(vehicle: Vehicle) {
    list.value[vehicle.id] = vehicle;
  }
  function deleteVehicleById(vehicleId: string) {
    delete list.value[vehicleId];
  }
  // #endregion

  // #region Actions
  async function loadList() {
    let list = await Api.vehicles.get(groupId.value);
    if (Array.isArray(list)) {
      list = list.filter(vehicle => vehicle.archived !== true);
      setList(arrToFakeMap(list, 'id'));
    }
  }
  async function createVehicle(vehicle: Vehicle) {
    const data = await Api.vehicles.post(groupId.value, vehicle);
    vehicle.id = data;
    addOrUpdate({ ...vehicle, id: data });
  }
  async function updateVehicle(vehicleId: string, vehicle: Vehicle) {
    if (!vehicle.team_id) vehicle.team_id = '';

    // remove _id and id from the request body to avoid 422 error in api
    const vehiclePayload: any = { ...vehicle };
    delete vehiclePayload._id;
    delete vehiclePayload.id;

    await Api.vehicles.put(groupId.value, vehicleId, vehiclePayload);
    addOrUpdate({ ...vehicle, id: vehicleId });
  }
  async function deleteVehicle(vehicleId: string) {
    await Api.vehicles.delete(groupId.value, vehicleId);
    deleteVehicleById(vehicleId);
  }
  // #endregion

  // # region helpers
  /**
   * Get by id a Vehicle representation in format 'license_plate (fleet_number)'
   * */
  function getVehicleInfosById(vehicleId: string): string | null {
    const vehicle = list.value[vehicleId];
    if (!vehicle) {
      return null;
    }
    return formatVehicle(vehicle);
  }

  function formatVehicle(vehicle: Vehicle) {
    return vehicle.license_plate
      ? `${vehicle.license_plate} (${vehicle.fleet_number})`
      : `${vehicle.license_plate}`;
  }
  // #endregion

  return {
    list,
    loadList,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    formatVehicle,
    getVehicleInfosById,
  };
});
