<template>
  <div class="device-cell-gtfs">
    <font-awesome-icon
      v-if="isGtfsUpToDate === GtfsUpdateState.NO_DATA"
      icon="fa-circle-question"
      class="device-cell-gtfs__icon--grey"
      :title="$t('gtfsVersion.noGtfs')"
    />
    <font-awesome-icon
      v-else-if="isGtfsUpToDate === GtfsUpdateState.UPTODATE"
      icon="fa-check"
      class="device-cell-gtfs__icon--green"
      :title="$t('gtfsVersion.upToDate')"
    />
    <font-awesome-icon
      v-else
      icon="fa-xmark"
      class="device-cell-gtfs__icon--red"
      :title="$t('gtfsVersion.needsUpdate')"
    />
  </div>
</template>

<script>
export const GtfsUpdateState = {
  UPTODATE: 'yes',
  OUTDATED: 'no',
  NO_DATA: 'noData',
};

export default {
  name: 'GtfsCellDevice',

  props: {
    isGtfsUpToDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      GtfsUpdateState,
    };
  },
};
</script>

<style lang="scss">
.device-cell-gtfs {
  &__icon {
    padding: 0 2px;

    &--green {
      color: $primary-light;
    }

    &--red {
      color: $danger;
    }

    &--grey {
      color: $text-neutral;
    }
  }
}
</style>

<i18n locale="fr">
{
  "gtfsVersion": {
    "needsUpdate": "Le fichier GTFS n'est pas à jour",
    "noGtfs": "Pas de fichier Gtfs",
    "upToDate": "Le fichier Gtfs est à jour"
  },
}
</i18n>

<i18n locale="en">
{
  "gtfsVersion": {
    "needsUpdate": "GTFS file needs an update",
    "noGtfs": "GTFS file not found",
    "upToDate": "Up-to-date GTFS file"
  },
}
</i18n>
