<template>
  <Btn
    class="connector-filter"
    :class="{ 'connector-filter--active': active }"
    :type="active ? 'secondary-active' : 'tertiary'"
    @click="$emit('toggleFilter', value)"
  >
    {{ $t(value) }}
    <font-awesome-icon v-if="active" icon="fa-xmark" />
  </Btn>
</template>

<script setup>
import Btn from '@/components/ui/Btn.vue';

defineProps({
  /** @type {import('/@store/integrations').IntegrationType} */
  value: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
  },
});

defineEmits(['toggleFilter']);
</script>

<style lang="scss">
.connector-filter {
  vertical-align: middle;
  gap: 8px;
  min-width: 55px;
  padding: 10px;
  border: 1px solid $text-dark;
  border-radius: 50px;
}
</style>

<i18n locale="fr">
{
  "siv": "SIV",
  "ticketing": "Billettique",
  "gps": "GPS",
  "count": "Comptage voyageurs",
  "other": "Autres",
}
</i18n>

<i18n locale="en">
{
  "siv": "PIS",
  "ticketing": "Ticketing",
  "gps": "GPS",
  "count": "Passsenger count",
  "other": "Others",
}
</i18n>
