import cloneDeep from 'clone-deep';
import i18n from '@/i18n';
import { formatSecondsToHHMMSS } from '@/libs/helpers/dates';
import { NO_DATA } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const { d } = i18n.global;

export const TravelTimeDataFormatter = {
  /**
   * Add data in the format expected by the datagrid.
   * @param {import('./index.vue').PointInfo} d
   * @returns {TravelTimeRow}
   */
  formatData(d) {
    const newObj = cloneDeep(d);
    newObj.dateFormatted = this.getLocaleDate(d.date);
    newObj.point1TsFormatted = this.getLocaleTimeLong(d.point1Ts);
    newObj.point2TsFormatted = this.getLocaleTimeLong(d.point2Ts);
    newObj.durationFormatted = this.getHHMMSS(d.duration);
    return newObj;
  },

  /**
   * Return localized time in the format 'HH:MM:SS'
   * @param {number} value
   * @returns {string}
   */
  getLocaleTimeLong(value) {
    if (!value) return NO_DATA;
    return d(value * 1000, 'timeLong');
  },

  /**
   * Return HH:MM:SS from seconds
   * @param {number} value
   * @returns {string}
   */
  getHHMMSS(value) {
    if (!value) return NO_DATA;
    return formatSecondsToHHMMSS(value);
  },

  /**
   * Return date in the format 'DD/MM/YYYY'
   * @param {Date} value
   * @returns {string}
   */
  getLocaleDate(value) {
    if (!value) return NO_DATA;
    return d(value);
  },
};

/**
 * @typedef {Object} TravelTimeRow
 * @extends import("./index.vue").PointInfo
 * @property {string} dateFormatted
 * @property {string} point1TsFormatted
 * @property {string} point2TsFormatted
 * @property {string} durationFormatted
 */
