<script setup>
import { computed } from 'vue';
import CardHeader from './CardHeader.vue';
import CardOverlay from './CardOverlay.vue';
import BarChart from '@/components/ui/BarChart3.vue';

const props = defineProps({
  categories: {
    type: Array,
    required: true,
  },
  hasGtfs: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  percentage: {
    type: Boolean,
    default: false,
  },
  placeholderName: {
    type: String,
    required: true,
  },
  series: {
    type: Array,
    required: true,
  },
  showDataLabels: {
    type: Boolean,
    default: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    required: true,
  },
  tooltip: {
    type: Object,
    required: true,
  },
});

defineEmits(['redirect']);

/** @returns {Number} */
const vueInnerWidth = computed(() => {
  const leftNavBarWidth = localStorage.getItem('settings.op.navLeftClosed') === 'true' ? 84 : 180;
  return window.innerWidth - leftNavBarWidth;
});

/** @returns {Number} */
const chartHeight = computed(() => {
  if (vueInnerWidth.value > 1220) return 330;
  return 230;
});

const chartOptions = computed(() => {
  return {
    xaxis: {
      categories: props.categories,
      labels: {
        show: true,
        rotate: -45,
        style: {
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: false,
      },
    },
    dataLabels: {
      formatter: val => {
        if (!props.showDataLabels) {
          return '';
        } else if (!props.percentage) {
          return val;
        } else {
          return `${val} %`;
        }
      },
    },
    yaxis: {
      max: props.percentage ? 100 : null,
      min: 0,
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '70%',
      },
    },
    grid: {
      strokeDashArray: 5,
      yaxis: {
        lines: { show: true },
      },
    },
    tooltip: props.tooltip,
    noData: { text: '' },
  };
});
</script>

<template>
  <v-card class="dashboard__card" elevation="0">
    <CardHeader :title="props.title" :subtitle="props.subtitle" @click="$emit('redirect')" />
    <v-skeleton-loader v-if="props.loading" type="image" height="inherit" />
    <CardOverlay
      v-else-if="!props.hasGtfs"
      :height="chartHeight"
      :image="`${placeholderName}-chart-placeholder`"
      class="dashboard__card-overlay"
    />
    <BarChart
      v-else
      :categories="props.categories"
      :series="props.series"
      :height="chartHeight"
      :options="chartOptions"
    />
  </v-card>
</template>

<style lang="scss">
.dashboard {
  &__card {
    height: 100%;
  }

  .apexcharts-custom-tooltip {
    padding: 4px;

    &__title {
      margin-bottom: 8px;
      font-weight: $font-weight-semi-bold;
      text-align: center;

      &.too_early {
        color: $danger;
      }

      &.on_time {
        color: $primary-light;
      }

      &.too_late {
        color: $warn;
      }
    }

    &__subtitle {
      margin-top: 8px;
      font-weight: $font-weight-semi-bold;
    }
  }

  // Fade all columns except last - Hack but not an option in apexcharts
  .apexcharts-series {
    .apexcharts-bar-area {
      fill-opacity: 0.6;

      &:last-of-type {
        fill-opacity: 1;
      }
    }
  }

  .barchart {
    background-color: $canvas;
  }

  .apexcharts-xaxis-label:last-of-type {
    font-weight: $font-weight-semi-bold;
  }
}
</style>
