<template>
  <TimelineV2
    v-if="showTimeline && deviceTripEvents.length > 0"
    ref="timeline"
    class="trip-detailed__timeline"
    :selected-date="selectedDate"
    :tz="group.tz"
    :events="deviceTripEvents"
    :is-trip-running="isTripRunning"
  />
</template>

<script>
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';

import TimelineV2 from './TimelineV2.vue';

export default {
  name: 'TimelineContainer',

  components: { TimelineV2 },

  props: {
    selectedDate: {
      type: Date,
      default: () => new Date(),
    },

    showTimeline: {
      type: Boolean,
      default: false,
    },

    tripId: {
      type: String,
      required: true,
    },

    isTripRunning: {
      default: false,
      type: Boolean,
    },
    mapboxDevice: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:mapboxDevice'],

  data() {
    return {
      /** @type {Array<import('@/store/devices').Event>} */
      deviceTripEvents: [],
      timerNow: {
        _id: null,
        ts: Date.now() / 1000,
      },
      /** @type {number} */
      vkIndex: 0,
    };
  },

  computed: {
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {?import('@/store/devices').Device} */
    chosenDevice() {
      if (this.chosenDeviceId) {
        return this.$store.state.devices.list[this.chosenDeviceId];
      }

      return null;
    },

    /** @return {?string} */
    chosenDeviceId() {
      if (this.tripVkHistory && this.tripVkHistory.length) {
        if (this.tripVkHistory[this.vkIndex]) {
          return this.tripVkHistory[this.vkIndex].device_id;
        }
      }

      if (this.deviceIdPending) {
        return this.deviceIdPending;
      }

      return null;
    },

    /** @return {?string} */
    deviceIdPending() {
      /** @type {{[deviceId: string]: import('@/store/devices').Device}} */
      const online = this.$store.getters['devices/onlineDevices'];
      const pending = Object.keys(online).find(key => {
        if (!online[key]) return null;
        return (
          (online[key].trip
            ? online[key].trip.trip_id === this.tripId &&
              online[key].trip.start_date === this.selectedDateGtfs
            : online[key].trip_id === this.tripId) && // Deprecated: driver < 3.0.?
          online[key].trip_pending
        );
      });

      return pending;
    },

    /** @return {Array<import('@/store/devices').Event>} */
    lastDeviceEvents() {
      return this.$store.state.devices.lastChanges;
    },

    /** @return {string} */
    selectedDateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {Array<import('@/store/trips').VkHistory>} */
    tripVkHistory() {
      /** @type {{[gtfsTripId: string]: {[deviceId: string]: import('@/store/trips').VkHistory}}} */
      const vkHistories = this.$store.state.trips.vkHistory[this.selectedDateGtfs] || {};

      const tripVk = Object.values(vkHistories)
        .reduce((acc, vkByDevice) => {
          return acc.concat(Object.values(vkByDevice));
        }, [])
        .filter(vk => vk.trip_id === this.tripId);
      tripVk.sort((a, b) => b.vk - a.vk);

      return tripVk;
    },
  },

  watch: {
    chosenDeviceId() {
      if (this.chosenDeviceId && this.deviceTripEvents.length > 0) {
        this.$emit('update:mapboxDevice', { events: this.deviceTripEvents, id: this.chosenDeviceId });
      } else {
        this.$emit('update:mapboxDevice', null);
      }
    },

    deviceTripEvents: {
      deep: true,
      handler() {
        if (this.chosenDeviceId && this.deviceTripEvents.length > 0) {
          const lastItemTs = this.deviceTripEvents[this.deviceTripEvents.length - 1]?.ts;
          this.$store.dispatch('tripDetailed/setLastEventTs', lastItemTs);

          this.$emit('update:mapboxDevice', { events: this.deviceTripEvents, id: this.chosenDeviceId });
        } else {
          this.$emit('update:mapboxDevice', null);
        }
      },
    },

    lastDeviceEvents: {
      deep: true,
      handler() {
        /** @type {Partial<import('@/store/devices').Event>} */
        const incrementalEvent = {};

        this.lastDeviceEvents
          .filter(e => e.device_id === this.chosenDeviceId)
          .forEach(event => {
            Object.assign(incrementalEvent, event);

            if (this.isDeviceOnTrip(incrementalEvent)) {
              if (this.deviceTripEvents.length > 0) {
                const hadUpdateInfo = !!event.update_info;

                // eslint-disable-next-line no-param-reassign
                event = {
                  ...this.deviceTripEvents[this.deviceTripEvents.length - 1],
                  ...event,
                };

                if (!hadUpdateInfo) delete event.update_info;
              }

              this.deviceTripEvents.push(event);
            }
          });
      },
    },

    /** @return {boolean} */
    selectedDateIsToday() {
      return this.selectedDateGtfs === dateObjToGtfsFormat(new Date());
    },
    async selectedDateGtfs() {
      // On date actualization, get vk History
      await this.fetchVkHistory();
      await this.onVkIndexChange();
    },
  },

  async created() {
    await this.fetchVkHistory();
    await this.onVkIndexChange();

    this.timerNow._id = setInterval(() => {
      this.timerNow.ts = Date.now() / 1000;
    }, 1000);
  },

  unmounted() {
    clearInterval(this.timerNow._id);
  },

  methods: {
    fetchActivityLog() {
      if (this.tripVkHistory.length === 0) return;

      this.$store.dispatch('activityLog/loadEntries', this.tripVkHistory[this.vkIndex].date);
    },

    async fetchChosenDevice() {
      if (this.chosenDeviceId) {
        await this.$store.dispatch('devices/getDevice', {
          deviceId: this.chosenDeviceId,
        });
      }
    },

    async fetchDeviceTripEvents() {
      let deviceTripEvents = [];
      if (this.chosenDeviceId) {
        let fromTs = Date.now() / 1000;
        let toTs = fromTs;
        const vkHistory = this.tripVkHistory[this.vkIndex];
        if (vkHistory) {
          // Keep few seconds before start to capture potential "update_info" event.
          // Won't be necessary when pending km are added to Vk.
          fromTs = vkHistory.start_ts - 10;
          toTs = vkHistory.end_ts;
        }
        deviceTripEvents = await this.$store.dispatch('devices/getEventsOf', {
          deviceId: this.chosenDeviceId,
          fromTs,
          toTs,
          // tripId not set here and events filtered after, otherwise it's not
          // sure that a snaphot is captured (can cause unknown trip_filter
          // for instance)
        });
      }

      deviceTripEvents = deviceTripEvents.filter(e => e.trip_id === this.tripId);
      this.deviceTripEvents = deviceTripEvents;
    },

    async fetchVkHistory() {
      await this.$store.dispatch('trips/getVkHistory', {
        dateGtfs: this.selectedDateGtfs,
      });
    },

    /**
     * @param {Partial<import('@/store/devices').Event>} event
     * @return {boolean}
     */
    isDeviceOnTrip(event) {
      const device = this.$store.state.devices.list[event.device_id];

      /**
       * @template {keyof import('@/store/devices').Device} T
       * @param {T} prop
       * @return {import('@/store/devices').Device[T]}
       */
      const getProp = prop => (prop in event ? event[prop] : device[prop]);
      const tripDescriptor = getProp('trip');

      return (
        ('trip' in device // Condition only for version check
          ? tripDescriptor &&
            tripDescriptor.trip_id === this.tripId &&
            tripDescriptor.start_date === this.selectedDateGtfs
          : // Deprecated
            this.selectedDateIsToday && getProp('trip_id') === this.tripId) &&
        this.$store.state.devices.online[device.device_id]
      );
    },

    async onVkIndexChange() {
      // Clear info rows
      this.deviceTripEvents = [];

      await Promise.all([this.fetchDeviceTripEvents(), this.fetchChosenDevice(), this.fetchActivityLog()]);
    },
  },
};
</script>
