<template>
  <div class="error-page">
    <div class="error-page__text-block">
      <div class="error-page__title">
        <v-icon class="mr-4">{{ statusIcon }}</v-icon>
        <span v-if="statusCode">{{ statusCode }}</span>
      </div>
      <div class="error-page__subtitle mt-4">
        {{ text ? text : $t(i18nKey) }}
      </div>
      <Btn v-if="backButton" type="primary" @click="$router.push('/')">
        {{ $t('goBack') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import { AuthRoute } from '@/libs/routing';

export default {
  name: 'ErrorPage',

  components: { Btn },

  props: {
    text: {
      type: String,
      default: undefined,
    },
    statusCode: {
      type: Number,
      default: undefined,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      AuthRoute,
    };
  },

  computed: {
    i18nKey() {
      if (this.statusCode === 403) {
        return '403Text';
      } else if (this.statusCode === 404) {
        return '404Text';
      }
      return 'defaultText';
    },
    statusIcon() {
      if (this.statusCode === 403) {
        return 'fa:fas fa-lock';
      } else if (this.statusCode === 404) {
        return 'fa:fas fa-ban';
      } else {
        return 'fa:fas fa-triangle-exclamation';
      }
    },
  },
};
</script>

<style lang="scss">
.error-page {
  height: 100%;
  background-image: url('@/assets/img/error_page_illustration.svg');
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  color: $text-dark-variant;

  &__subtitle {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 28px;
  }

  &__text-block {
    height: 50%;
    padding: 70px;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: 96px;
  }
}
</style>

<i18n locale="fr">
{
  "403Text": "Vous n'avez pas accès à cette page.",
  "404Text": "Cette page n'a pas été trouvée.",
  "defaultText": "Cette page ne peut pas être affichée.",
  "goBack": "Retour à la page d'accueil",
  "contact": "Veuillez contacter votre administrateur."
}
</i18n>

<i18n locale="en">
{
  "403Text": "You do not have access to this page.",
  "404Text": "This page could not be found.",
  "defaultText": "This page could not be displayed.",
  "goBack": "Back to the home page",
  "contact": "Please contact your administrator."
}
</i18n>
