<template>
  <Tag
    :text="$t(status)"
    :title="$t(`title.${status}`, [publicationTime?.toLocaleString($i18n.locale, dateOptions)])"
    :color="color"
    :background="backgroundColor"
    :icon="icon"
  />
</template>

<script>
import Tag from '@/components/ui/Tag.vue';

/** @enum {string} */
export const TransportPlanStatus = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  OVER: 'over',
  PENDING: 'pending',
};

export default {
  name: 'StatusCell',

  components: { Tag },

  props: {
    status: {
      type: String,
      required: true,
    },
    publicationTime: {
      type: Date,
      default: null,
    },
  },

  computed: {
    backgroundColor() {
      switch (this.status) {
        case TransportPlanStatus.ACTIVE:
          return '#00b8711A'; // $primary-light 10%
        case TransportPlanStatus.PENDING:
          return '#f99c491A'; // $warn 10%
        case TransportPlanStatus.DRAFT:
          return '#4f4f4f1A'; // $text-dark-variant 10%
        case TransportPlanStatus.OVER:
          return '#4065C31A'; // $blue 10%
        default:
          return '';
      }
    },

    color() {
      switch (this.status) {
        case TransportPlanStatus.ACTIVE:
          return '#00b871'; // $primary-light
        case TransportPlanStatus.PENDING:
          return '#f99c49'; // $warn
        case TransportPlanStatus.DRAFT:
          return '#4f4f4f'; // $text-dark-variant
        case TransportPlanStatus.OVER:
          return '#4065C3'; // $blue
        default:
          return '';
      }
    },

    dateOptions() {
      return {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
      };
    },

    icon() {
      switch (this.status) {
        case TransportPlanStatus.ACTIVE:
          return 'fa-check';
        case TransportPlanStatus.PENDING:
          return 'fa-clock';
        case TransportPlanStatus.DRAFT:
          return 'fa-pen';
        case TransportPlanStatus.OVER:
          return 'fa-check';
        default:
          return '';
      }
    },
  },
};
</script>

<i18n locale="fr">
{
  "active": "Actif",
  "draft": "Brouillon",
  "over": "Terminé",
  "pending": "En attente",
  "title": {
    "active": "Unique plan de transport actif",
    "draft": "Ce plan de transport n'est pas publié",
    "over": "Ce plan de transport n'est plus actif",
    "pending": "Ce plan de transport sera actif le {0}",
  }
}
</i18n>

<i18n locale="en">
{
  "active": "Active",
  "draft": "Draft",
  "over": "Over",
  "pending": "Pending",
  "title": {
    "active": "Only active transport plan",
    "draft": "This transport plan is not published",
    "over": "This transport plan is no longer active",
    "pending": "This transport plan will be active on {0}",
  }
}
</i18n>
