import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { getISODate } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import store from '@/store';
import { Permission } from '@/auth';

const RouteBadge = defineAsyncComponent(() => import('@/components/common/RouteBadge.vue'));
const CellDelay = defineAsyncComponent(() => import('@/pages/TripsListPage/cells/CellDelay.vue'));

export const STOP_DETAILED_LS_COLUMNS = 'stopDetailled/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  ROUTE: 'routeName',
  TRIP: 'tripFormattedName',
  THEORETICAL_TIME: 'theoreticalTime',
  REAL_TIME: 'realTime',
  DELAY: 'delay',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(STOP_DETAILED_LS_COLUMNS));
  const deactivatedRoutes = store.getters.group.deactivated_routes;

  // Helpers
  const defaultSelected = columnKey => !ls || ls.includes(columnKey);

  return new DataGrid({
    name: 'stopDetailedDatagrid',
    defaultSortBy: [{ key: ColumnKey.THEORETICAL_TIME, order: SortOrder.ASC }],
    columnSelectionLocalStorageKey: STOP_DETAILED_LS_COLUMNS,
    columns: [
      // ROUTE
      new DataGridColumn({
        key: ColumnKey.ROUTE,
        title: 'column.route',
        defaultSelected: defaultSelected(ColumnKey.ROUTE),
        filterable: true,
        selectable: false,
        sortable: true,
        cellBuilder([value, object]) {
          const route = object.route;
          return {
            component: RouteBadge,
            props: {
              isExperimental: deactivatedRoutes.includes(route.route_id),
              route: {
                route_color: route.route_color,
                route_text_color: route.route_text_color,
                route_short_name: value,
              },
              value,
            },
          };
        },
      }),

      // TRIP
      new DataGridColumn({
        key: ColumnKey.TRIP,
        title: 'column.trip',
        selectable: true,
        cellBuilder([value, object], { date, groupId }) {
          return store.getters.hasPermission(Permission.VIEW_TRIP_VIEW)
            ? {
                component: 'RouterLink',
                props: {
                  to: {
                    name: GroupRoute.TRIP_DETAILED,
                    params: { groupId, tripId: object.tripId },
                    query: { date: getISODate(date) },
                  },
                },
                value,
              }
            : null;
        },
      }),

      // THEORETICAL TIME
      new DataGridColumn({
        key: ColumnKey.THEORETICAL_TIME,
        defaultSelected: defaultSelected(ColumnKey.THEORETICAL_TIME),
        selectable: false,
        title: 'column.theoreticalTime',
      }),

      // REAL TIME
      new DataGridColumn({
        key: ColumnKey.REAL_TIME,
        defaultSelected: defaultSelected(ColumnKey.REAL_TIME),
        selectable: false,
        title: 'column.realTime',
      }),

      // STOP CODE
      new DataGridColumn({
        key: ColumnKey.DELAY,
        defaultSelected: defaultSelected(ColumnKey.DELAY),
        selectable: true,
        sortable: true,
        title: 'column.delay',
        cellBuilder([value, object]) {
          return {
            component: CellDelay,
            props: { delay: value },
          };
        },
      }),
    ],
  });
};
