<template>
  <div class="pagination-component">
    <template v-if="shortFormat">
      <v-icon class="nav-arrow" :disabled="pager.currentPage === 1" @click="setPage(pager.currentPage - 1)">
        fa:fas fa-chevron-left
      </v-icon>
      <div class="nav-counter">
        <span>{{ pager.endIndex + 1 }}</span>
        <span>/</span>
        <span>{{ pager.totalItems }}</span>
      </div>
      <v-icon
        class="nav-arrow"
        :disabled="pager.currentPage === pager.totalPages"
        @click="setPage(pager.currentPage + 1)"
      >
        fa:fas fa-chevron-right
      </v-icon>
    </template>
    <template v-else>
      <ul v-if="pager.pages && pager.pages.length" class="pagination">
        <li class="page-item previous" :class="{ disabled: pager.currentPage === 1 }">
          <a class="page-link" @click="setPage(pager.currentPage - 1)">{{ '<' }}</a>
        </li>
        <li v-if="!pager.pages.includes(1)" class="page-item first">
          <a class="page-link" @click="setPage(1)">{{ 1 }}</a>
        </li>
        <li v-if="!pager.pages.includes(1) && pager.startPage > 2" class="page-item disabled">
          <a class="page-link">...</a>
        </li>
        <li
          v-for="page in pager.pages"
          :key="page"
          class="page-item page-number"
          :class="{ active: pager.currentPage === page }"
        >
          <a class="page-link" @click="setPage(page)">{{ page }}</a>
        </li>
        <li
          v-if="!pager.pages.includes(pager.totalPages) && pager.endPage < pager.totalPages - 1"
          class="page-item disabled"
        >
          <a class="page-link">...</a>
        </li>
        <li
          v-if="pager.endPage !== pager.totalPages"
          class="page-item last"
          :class="{ disabled: pager.currentPage === pager.totalPages }"
        >
          <a class="page-link" @click="setPage(pager.totalPages)">{{ pager.totalPages }}</a>
        </li>
        <li class="page-item next" :class="{ disabled: pager.currentPage === pager.totalPages }">
          <a class="page-link" @click="setPage(pager.currentPage + 1)">{{ '>' }}</a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    items: {
      type: Array,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    maxPages: {
      type: Number,
      default: 3,
    },
    shortFormat: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changePage'],

  data() {
    return {
      pager: {},
    };
  },
  watch: {
    items: {
      handler() {
        this.setPage(this.initialPage);
      },
      deep: true,
    },
  },

  created() {
    // set to initial page
    this.setPage(this.initialPage);
  },

  methods: {
    setPage(page) {
      const { items, pageSize, maxPages } = this;

      // get new pager object for specified page
      const pager = this.paginate(items.length, page, pageSize, maxPages);
      // update pager
      this.pager = pager;

      const result = {
        start: pager.startIndex,
        end: pager.endIndex,
      };
      // emit change page event to parent component
      this.$emit('changePage', result);
    },
    paginate(totalItems = null, currPage = 1, pageSize = 10, maxPages = 10) {
      let currentPage = currPage;
      // calculate total pages
      const totalPages = Math.ceil(totalItems / pageSize);

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let endPage = null;
      let startPage = null;
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // calculate start and end item indexes
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize - 1, totalItems);

      // create an array of pages to ng-repeat in the pager control
      const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);

      // return object with all pager properties required by the view
      return {
        totalItems,
        currentPage,
        pageSize,
        totalPages,
        startPage,
        endPage,
        startIndex,
        endIndex,
        pages,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-component {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em; /* Safari */
  user-select: none; /* Standard syntax */

  .nav-counter {
    margin: 0 10px;
  }

  .nav-arrow {
    font-size: 14px;
  }
}

.pagination {
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    list-style: none;
    font-weight: $font-weight-semi-bold;
    text-align: center;
  }

  a {
    display: block;
    float: left;
    padding: 6px 12px;
    cursor: pointer;
  }

  .page-link {
    position: relative;
    display: block;
    width: 36px;
    height: 34px;
    margin-left: -1px;
    padding: 0.5rem 0.75rem;
    color: #007bff;
    line-height: 1.45;
  }

  a:not([href], [tabindex]) {
    color: inherit;
    text-decoration: none;
  }

  .page-item.active .page-link {
    z-index: 1;
    border-radius: 4px;
    background-color: $primary-light;
    color: #fff;
  }

  .page-item.disabled .page-link {
    color: $text-neutral;
    cursor: auto;
    pointer-events: none;
  }
}
</style>
