<script setup>
import dayjs from 'dayjs';
import { computed } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { GroupRoute } from '@/libs/routing';

import RegulationStatusCell from './RegulationStatusCell.vue';

const props = defineProps({
  trip: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const emit = defineEmits(['close', 'closeRefresh', 'confirm']);

/** @return {import('@/store').Group} */
const group = computed(() => {
  return store.getters.group;
});

/** @return {import("vue-router").RouterLinkProps} */
const tripLink = computed(() => {
  return {
    name: GroupRoute.TRIP_DETAILED,
    params: { group: group.value.group_id, tripId: props.trip.id },
    query: { date: props.trip.service_date },
  };
});

async function confirmDelete() {
  const tripUpdates = {
    query: {
      gtfs_id: props.trip.gtfs_id,
      trip_id: props.trip.id,
      start_date: props.trip.service_date,
    },
    body: {
      delay: 0,
      is_canceled: false,
      skipped_stop_time_seqs: [],
      comment: null,
      // We don't want the stop info to be deleted
      stop_infos: props.trip.stop_infos,
    },
  };

  await store.dispatch('trips/updateTrip', tripUpdates);
  emit('closeRefresh');
}
</script>

<template>
  <Modal
    modal-class="modal-trip-updates-delete"
    hide-footer
    :show-secondary-btn="false"
    :width="800"
    @close="emit('close')"
  >
    <template #title>
      {{ $t('deleteTripUpdatesTitle') }}
    </template>
    <template #subtitle>
      {{ $t('deleteTripUpdatesConfirmation') }}
    </template>

    <template #body>
      <div class="modal-trip-updates-delete__content">
        <div class="modal-trip-updates-delete__content-item">
          <span class="modal-trip-updates-delete__content-label">{{ $t('trip') }}</span>
          <router-link class="modal-trip-updates-delete__link" :to="tripLink">
            {{ trip.formatted_name }}
          </router-link>
        </div>
        <div class="modal-trip-updates-delete__content-item">
          <span class="modal-trip-updates-delete__content-label">{{ $t('date') }}</span>
          <span class="modal-trip-updates-delete__date">
            {{ dayjs(trip.service_date, 'YYYYMMDD').format('DD/MM/YYYY') }}
          </span>
        </div>
        <div class="modal-trip-updates-delete__content-item">
          <span class="modal-trip-updates-delete__content-label">{{ $t('types') }}</span>
          <span class="modal-trip-updates-delete__tags">
            <RegulationStatusCell :regulation="trip.updates" class="test" />
          </span>
        </div>
      </div>

      <div class="modal__cta">
        <Btn type="danger" @click="confirmDelete">
          <font-awesome-icon icon="fa-trash" class="modal-trip-updates-delete__btn-icon" />
          {{ $t('removeTripModifications') }}
        </Btn>
      </div>
      <div class="modal-trip-updates-delete__second-btn">
        <Btn type="secondary" @click="emit('close')">
          {{ $t('keepTripModifications') }}
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-trip-updates-delete {
  &__btn-icon {
    margin-right: 5px;
  }

  &__content {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid $border;
    border-radius: 8px;
    background-color: $canvas;
  }

  &__content-item:not(:last-child) {
    margin-bottom: 10px;
  }

  &__content-label {
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }

  &__date {
    margin-left: 10px;
  }

  &__link {
    margin-left: 10px;
    font-weight: $font-weight-semi-bold;
    text-decoration: underline;
    text-decoration-color: $underline;
  }

  &__second-btn {
    .ui-btn {
      width: 100%;
    }
  }

  &__tags {
    display: inline-block;
    margin-left: 10px;
  }

  .modal__body {
    margin-bottom: 0;
  }
}
</style>

<i18n locale="fr">
{
  "deleteTripUpdatesTitle": "Supprimer les modifications de course ?",
  "deleteTripUpdatesConfirmation": "Cette course sera réinitialisée",
  "trip": "Course :",
  "date": "Date :",
  "types": "Types :",
  "removeTripModifications": "Supprimer les modifications",
  "keepTripModifications": "Conserver les modifications"
}
</i18n>

<i18n locale="en">
{
  "deleteTripUpdatesTitle": "Remove trip modifications?",
  "deleteTripUpdatesConfirmation": "This trip will be reinitialized",
  "trip": "Trip:",
  "date": "Date:",
  "types": "Types:",
  "removeTripModifications": "Remove trip modifications",
  "keepTripModifications": "Keep trip modifications"
}
</i18n>
