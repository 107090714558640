<template>
  <div ref="timeline" class="timeline">
    <div
      @mousemove="getTimeDuringMouseMove"
      @mouseover="mouseOverTimeBar"
      @mouseleave="mouseOutTimeBar"
      @mousedown="selectTimelineBar"
    >
      <div ref="timeline-bar" class="timeline__bar timeline-bar" />
      <div class="timeline__bar timeline-bar timeline-bar--dark" :style="{ width: widthOfReadingBar + '%' }">
        <div class="timeline__slide-bar" @mousedown="selectTimelineBar" />
      </div>
    </div>

    <div class="timeline__btns timeline-btns">
      <button class="timeline-btns__btn timeline-btns__btn--reverse" @click="reverse" @mousedown.prevent />

      <button
        v-if="isPlaying"
        class="timeline-btns__btn timeline-btns__btn--pause"
        @click="pause"
        @mousedown.prevent
      />

      <button v-else class="timeline-btns__btn timeline-btns__btn--play" @click="play" @mousedown.prevent />

      <button class="timeline-btns__btn timeline-btns__btn--advance" @click="advance" @mousedown.prevent />

      <input
        v-show="editTime"
        ref="input-reading-time"
        v-model="readingTimeEdit"
        class="reading-time"
        type="time"
      />

      <div v-show="!editTime" class="reading-time" @click="selectEditTime()">
        {{ readingTime }}
      </div>

      <button
        v-show="!editTime"
        class="timeline-btns__btn--edit-time"
        @click="selectEditTime()"
        @mousedown.prevent
      >
        <font-awesome-icon icon="fa-pencil" aria-hidden="true" />
      </button>

      <button
        v-show="editTime"
        class="timeline-btns__btn--edit-time"
        @click="changeTime()"
        @mousedown.prevent
      >
        <font-awesome-icon icon="fa-check" />
      </button>
    </div>

    <div v-show="showSwitchRealTime">
      <span class="label-switch">{{ $t('realTime') }}</span>
      <label class="switch">
        <input id="timeline-realtime" :checked="switchChecked" type="checkbox" @click="switchRealTime()" />
        <span class="slider" />
      </label>
    </div>

    <div v-show="showTimeOfTimeline" class="timeline__time timeline-time" :style="{ left: timePosition }">
      {{ timeSelected }}
    </div>
  </div>
</template>

<script>
import { dateObjToGtfsFormat, getTzDelta, timestampFormatHHMM } from '@/libs/helpers/dates';

const StatusValue = {
  ENDED: 'ended',
  RUNNING: 'running',
};

/** @type {number} */
const SPEED_TIMER = 500;

/**
 * @module Timeline
 */
export default {
  name: 'Timeline',

  props: {
    /** @type {Vue.PropOptions<Array<import('@/store/devices').Event>>} */
    events: {
      required: true,
      type: Array,
    },

    tz: {
      type: String,
      required: true,
    },

    /** @type {Vue.PropOptions<Date>} */
    selectedDate: {
      type: Date,
      default: () => new Date(),
    },

    tripStatus: {
      type: String,
      default: null,
    },
  },

  emits: ['change'],

  data: () => ({
    intervalId: null,
    editTime: null,
    eventIdx: null,
    /** @type {number} */
    eventIdxDuringMove: null,
    isPlaying: false,
    readingTimeEdit: null,
    /** @type {boolean} */
    selectBar: false,
    showTimeOfTimeline: null,
    showTimeline: true,
    statusValue: StatusValue,
    switchChecked: null,
    timePosition: null,
    timeSelected: null,
    tsSelectedDuringMouseMove: null,
  }),

  computed: {
    dateOfDayGtfs() {
      return dateObjToGtfsFormat(new Date());
    },

    firstTs() {
      if (this.events[0]) return this.events[0].ts;

      return null;
    },

    lastTs() {
      if (this.events.length) return this.events[this.events.length - 1].ts;

      return null;
    },

    readingTime() {
      if (this.events[this.eventIdx]) {
        return timestampFormatHHMM(this.events[this.eventIdx].ts, { tz: this.tz });
      }

      return null;
    },

    selectedDateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {boolean} */
    showSwitchRealTime() {
      return this.tripStatus === this.statusValue.RUNNING || (!this.tripStatus && !this.dayPast);
    },

    widthOfReadingBar() {
      if (this.events.length > 0) {
        // calculate percentage of events selected
        const percentOfTs = (100 * this.eventIdx) / (this.events.length - 1);
        // change width of timeline depending timestamp selected
        if (percentOfTs > 100 || this.switchChecked) return 100;

        return percentOfTs;
      }

      return null;
    },

    /** @return {boolean} */
    dayPast() {
      return this.selectedDateGtfs < this.dateOfDayGtfs || !this.events.length;
    },
  },
  watch: {
    dayPast() {
      this.eventIdx = this.events.length - 1;
      if (!this.dayPast) this.switchChecked = true;
      else this.switchChecked = false;
    },

    events: {
      handler(val) {
        if ((this.switchChecked || this.dayPast) && val.length) {
          this.initTimeline();
        }
      },
      deep: true,
    },

    eventIdx() {
      if (this.events[this.eventIdx]) {
        this.$emit('change', this.events[this.eventIdx].ts);
      }
    },
  },
  created() {
    this.initTimeline();
  },

  unmounted() {
    clearInterval(this.$_intervalId);
  },

  methods: {
    advance() {
      if (this.eventIdx === this.events.length - 1) {
        this.isPlaying = false;
        return;
      }
      this.eventIdx += 1;
    },

    changeTime() {
      if (!this.readingTimeEdit) return;

      this.switchChecked = false;
      this.editTime = false;

      const hour = this.readingTimeEdit.split(':')[0];
      const min = this.readingTimeEdit.split(':')[1];
      const selectedDate = new Date(this.selectedDate);
      const tzDelta = getTzDelta(selectedDate, this.tz);

      selectedDate.setHours(hour);
      selectedDate.setMinutes(min);
      selectedDate.setSeconds(-tzDelta);

      // Get the first occurence of selected time in the timeline (handle overnight trips)
      const startCutoff = new Date(this.firstTs * 1000 - 600000);
      while (selectedDate < startCutoff) {
        selectedDate.setDate(selectedDate.getDate() + 1);
      }

      const time = selectedDate.getTime() / 1000;

      if (time > this.lastTs) {
        this.eventIdx = this.events.length - 1;
      } else {
        this.eventIdx = this.events.findIndex(e => e.ts >= time);
      }
    },
    /**
     * Get time selected during mouse move
     */
    getTimeDuringMouseMove(event) {
      // Get width of timeline
      const offsetBar = /** @type {HTMLElement} */ (this.$refs['timeline-bar']).offsetWidth;
      // Get position of mouse in the timeline
      const offsetXMouse = event.pageX - /** @type {HTMLElement} */ this.$refs.timeline.offsetLeft;
      // Set position for time follow mouse
      this.timePosition = `${event.offsetX - 20}px`;
      // get percentage traveled by the mouse, in the timeline, for to get timestamp
      const percentOfBar = (offsetXMouse / offsetBar) * 100;
      if (this.events.length > 0) {
        this.eventIdxDuringMove = Math.trunc(this.events.length * (percentOfBar / 100));
        if (!this.events[this.eventIdxDuringMove]) return;
        this.tsSelectedDuringMouseMove = this.events[this.eventIdxDuringMove].ts;
        // get ts selected converted in time
        this.timeSelected = timestampFormatHHMM(this.tsSelectedDuringMouseMove, {
          refDate: this.selectedDate,
          tz: this.tz,
        });
      }
      if (this.selectBar) {
        this.eventIdx = this.eventIdxDuringMove;
      }
    },

    initTimeline() {
      if (this.showSwitchRealTime) {
        this.switchChecked = true;
        this.eventIdx = this.events.length - 1;
      } else {
        this.switchChecked = false;
        this.eventIdx = 1;
      }
    },

    mouseDown() {
      this.selectBar = true;
      window.addEventListener('mouseup', this.mouseUp);
      window.addEventListener('mousemove', this.getTimeDuringMouseMove);
    },

    mouseOverTimeBar() {
      this.showTimeOfTimeline = true;
    },

    mouseOutTimeBar() {
      this.showTimeOfTimeline = false;
    },

    mouseUp() {
      this.selectBar = false;
      window.removeEventListener('mouseup', this.mouseUp);
      window.removeEventListener('mousemove', this.getTimeDuringMouseMove);
    },

    pause() {
      this.isPlaying = false;
      clearInterval(this.intervalId);
    },

    play() {
      if (this.eventIdx === this.events.length - 1) {
        this.pause();
        return;
      }

      this.isPlaying = true;

      // timer to change ts
      const changeEvent = () => {
        this.eventIdx += 1;
        if (this.eventIdx === this.events.length - 1) {
          this.pause();
        }
      };

      this.intervalId = setInterval(changeEvent, SPEED_TIMER);
    },

    reverse() {
      if (this.eventIdx === 0) return;
      this.switchChecked = false;
      this.eventIdx -= 1;
    },

    /**
     * select input for to edit timer
     */
    selectEditTime() {
      this.editTime = true;
      this.readingTimeEdit = this.readingTime;
      this.$nextTick(() => {
        const elem = /** @type {HTMLElement} */ (this.$refs['input-reading-time']);
        elem.focus();
        elem.select();
      });
    },

    /**
     * Get ts index of event selected at click
     */
    selectTimelineBar() {
      this.eventIdx = this.eventIdxDuringMove;
      this.mouseDown();
      this.switchChecked = false;
    },
    /**
     * get timestamp from trip view
     */
    setMapTimestamp(ts) {
      this.switchChecked = false;
      for (let i = 0; this.events[i]; i += 1) {
        if (this.events[i].ts > ts) {
          this.eventIdx = i - 1;
          break;
        }
      }
    },

    /**
     * action in switch real time
     */
    switchRealTime() {
      if (this.switchChecked) this.switchChecked = false;
      else {
        this.switchChecked = true;
        this.eventIdx = this.events.length - 1;
        this.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reading-time {
  width: 50px;
  height: 25px;
  margin-top: 13px;
  margin-left: 30px;
  padding: 2px;
  border: solid 1px $border;
  color: $text-dark-variant;
}

.reading-time::-webkit-outer-spin-button,
.reading-time::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

.reading-time::-webkit-clear-button {
  display: none;
}

.timeline {
  position: relative;
  height: 44px;
  border: solid $border 1px;
  border-top: none;

  &__bar {
    position: absolute;
    user-select: none;
  }

  &__slide-bar {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: $text-dark-variant;
    cursor: pointer;
    pointer-events: none;
    user-select: none;
  }

  &__btns {
    display: flex;
    justify-content: center;
  }

  &__time {
    position: absolute;
    top: -30px;
  }
}

.timeline-bar {
  width: 100%;
  height: 10px;
  background-color: $text-neutral;
  cursor: pointer;

  &--dark {
    background-color: $text-dark-variant;
  }
}

.timeline-btns__btn {
  width: 40px;
  height: 32px;
  margin-top: 10px;
  border-top: none;
  border-right: solid $border 1px;
  border-bottom: none;
  border-left: none;
  background-color: $canvas;
  background-position: center;
  background-size: 30%; // change size button
  background-repeat: no-repeat;
  cursor: pointer;

  &--advance {
    background-image: url('../../assets/img/picto_avance_timeline.png');
  }

  &--edit-time {
    display: inline;
    margin-top: 13px;
    margin-left: 10px;
    border: none;
    background: none;
    color: $warn;
    cursor: pointer;
  }

  &--pause {
    background-image: url('../../assets/img/picto_pause_timeline.png');
  }

  &--play {
    background-image: url('../../assets/img/picto_play_timeline.png');
  }

  &--reverse {
    border-left: solid $border 1px;
    background-image: url('../../assets/img/picto_reculer_timeline.png');
  }
}

.timeline-time {
  padding: 0 2px;
  border: solid 1px $border;
  background-color: $canvas;
  pointer-events: none;
}

.switch {
  position: absolute;
  top: 23px;
  right: 100px;
  display: inline-block;
  width: 25px;
  height: 10px;

  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 34px;
    background-color: $background;
    transition: 0.4s;

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 15px;
      height: 15px;
      border: solid 1px $border-variant;
      border-radius: 100%;
      background-color: $canvas;
      transition: 0.4s;
    }
  }

  input {
    // Visually hide, but focusable
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;

    &:checked + .slider {
      background-color: $transparent-primary;

      &::before {
        border: none;
        background: $primary-light;
        transform: translateX(10px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 5px $primary-light;
    }
  }
}

.label-switch {
  position: absolute;
  top: 18px;
  right: 10px;
  font-size: 13px;
}
</style>

<i18n locale="fr">
{
  "realTime": "Temps réel"
}
</i18n>

<i18n locale="en">
{
  "realTime": "Real time"
}
</i18n>

<i18n locale="cz">
{
  "realTime": "Reálný čas"
}
</i18n>

<i18n locale="de">
{
  "realTime": "Echtzeit"
}
</i18n>

<i18n locale="es">
{
  "realTime": "Tiempo real"
}
</i18n>

<i18n locale="it">
{
  "realTime": "Tempo reale"
}
</i18n>

<i18n locale="pl">
{
  "realTime": "Czas rzeczywisty"
}
</i18n>
