<template>
  <div ref="timeline" class="timeline">
    <VueDatepicker
      :class="{ active: !realTimeOn }"
      :model-value="eventTimePicker"
      time-picker
      disable-time-range-validation
      :clearable="false"
      position="right"
      :min-time="calculateMinMaxTime(firstTs)"
      :max-time="calculateMinMaxTime(lastTs, true)"
      text-input
      hide-input-icon
      :text-input-options="textInputOptions"
      :select-text="$t('timePickerConfirm')"
      :cancel-text="$t('timePickerCancel')"
      @update:modelValue="handleUpdateTimepicker"
      @focus="handleFocusTimepicker"
    ></VueDatepicker>

    <div>
      <Btn
        v-if="isTripRunning"
        class="timeline__btn"
        :class="{ active: realTimeOn }"
        type="link-style"
        @click="switchRealTime()"
      >
        {{ $t('realTime') }}
      </Btn>
    </div>

    <Slider
      v-model="sliderValue"
      show-tooltip="focus"
      :min="firstTs"
      :lazy="false"
      :format="displayedValue"
      :max="lastTs"
      class="timeline__slider"
      @start="slideStart()"
      @end="slideEnd()"
    />
  </div>
</template>

<script>
import Slider from '@vueform/slider';
import VueDatepicker from '@vuepic/vue-datepicker';
import dayjs from 'dayjs';
import Btn from '@/components/ui/Btn.vue';
import {
  dateObjToGtfsFormat,
  timestampFormatHHMM,
  timeHHmmToTimeObj,
  timeObjToTimestamp,
} from '@/libs/helpers/dates';

/**
 * @module Timeline
 */
export default {
  name: 'TimelineV2',

  components: { Btn, Slider, VueDatepicker },

  props: {
    /** @type {Vue.PropOptions<Array<import('@/store/devices').Event>>} */
    events: {
      required: true,
      type: Array,
    },

    tz: {
      type: String,
      required: true,
    },

    /** @type {Vue.PropOptions<Date>} */
    selectedDate: {
      type: Date,
      default: () => new Date(),
    },

    isTripRunning: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    sliderValue: null,
    isLeftArrowUpdate: false,
    outsideUpdate: false,
    eventTimePicker: null,
    eventIdx: 0,
    realTimeOn: false,
    textInputOptions: {
      enterSubmit: true,
      format: 'HH:mm',
    },
    isUserSliding: false,
    firstTs: null,
    lastTs: null,
  }),

  computed: {
    /** @return {string} */
    dateOfDayGtfs() {
      return dateObjToGtfsFormat(new Date());
    },

    /** @return {string} */
    selectedDateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {boolean} */
    dayPast() {
      return this.selectedDateGtfs < this.dateOfDayGtfs || !this.events.length;
    },

    /** @return {number} */
    timestamp() {
      return this.$store.state.tripDetailed.focusedTimeStamp;
    },
  },

  watch: {
    dayPast() {
      this.eventIdx = this.events.length - 1;
      if (!this.dayPast) this.realTimeOn = true;
      else this.realTimeOn = false;
    },

    timestamp() {
      const currentTs = this.events[this.eventIdx]?.ts;
      // Remove realTime if timestamp is emited from another component & time is not last event
      if (this.realTimeOn && this.timestamp !== this.events[this.events.length - 1]?.ts) {
        this.realTimeOn = false;
      }

      // Update slider value only if timestamp is updated from another component
      if (!this.realTimeOn && this.timestamp !== currentTs) {
        this.outsideUpdate = true;
        this.sliderValue = this.timestamp;
      }
    },

    sliderValue() {
      const eventCounts = this.events.length;
      if (this.realTimeOn && this.sliderValue !== this.events[eventCounts - 1]?.ts) {
        this.realTimeOn = false;
      }
      if (this.realTimeOn) {
        this.eventIdx = eventCounts - 1;
      } else {
        // Search for the nearest event object based on ts
        const upperResult = this.events.filter(event => event.ts > this.sliderValue);
        let result = null;
        if (upperResult && upperResult.length > 0)
          result = upperResult.reduce((a, b) => {
            return Math.abs(b.ts - this.sliderValue) <= Math.abs(a.ts - this.sliderValue) ? b : a;
          });

        // set default index to 0, then search for real index depending on context
        let index = 0;
        if (result) {
          index = this.events.findIndex(event => event.ts === result.ts);
        } else if (this.isLeftArrowUpdate) {
          // No result, Arrow picker case : If last index, try to select a value before last index
          index = eventCounts - 2;
          this.isLeftArrowUpdate = false;
        } else {
          // case we are at the end of slider
          return;
        }
        this.eventIdx = index;
      }

      this.eventTimePicker = timeHHmmToTimeObj(
        timestampFormatHHMM(this.events[this.eventIdx]?.ts, { tz: this.tz }),
      );
    },

    events: {
      handler(val) {
        if ((this.realTimeOn || this.dayPast) && val.length) {
          this.initTimeline();
        }

        // set first Ts for timeline
        if (!this.firstTs && this.events[0]) {
          this.firstTs = this.events[0].ts;
        }
        // set last ts for timeline
        // No update on lastTs while user sliding to avoid unwanted behavior (slider is unfocused)
        if (!this.isUserSliding && this.events.length) {
          this.lastTs = this.events[this.events.length - 1].ts;
        }

        if (this.realTimeOn) {
          this.sliderValue = this.events[this.events.length - 1].ts;
        }
      },
      deep: true,
      immediate: true,
    },

    eventIdx() {
      // Don't dispatch if update come from store
      if (this.events[this.eventIdx] && !this.outsideUpdate) {
        this.$store.dispatch('tripDetailed/setFocusedByTimeline', {
          timestamp: this.events[this.eventIdx].ts,
          stopId: this.events[this.eventIdx].stop_id,
          status: this.events[this.eventIdx].current_status,
        });
      }
      this.outsideUpdate = false;
    },
  },

  mounted() {
    // Implement key left & right to change time
    window.addEventListener('keydown', e => {
      const focusedElementsToIgnore = document.activeElement.classList;
      // check if we're not focused on input or slider-handler to avoid bugs
      if (
        !focusedElementsToIgnore.contains('slider-handle') &&
        !focusedElementsToIgnore.contains('dp__input') &&
        !focusedElementsToIgnore.contains('mapboxgl-canvas')
      ) {
        if (e.key === 'ArrowRight') {
          this.sliderValue += 20;
        } else if (e.key === 'ArrowLeft') {
          this.isLeftArrowUpdate = true;
          this.sliderValue -= 20;
        }
      }
    });
  },

  created() {
    this.initTimeline();
  },

  methods: {
    initTimeline() {
      if (this.isTripRunning) {
        this.realTimeOn = true;
        this.eventIdx = this.events.length - 1;
      } else {
        this.realTimeOn = false;
        this.eventIdx = 0;
      }
      this.sliderValue = this.events[this.eventIdx].ts;
    },

    /**
     * action in switch real time
     */
    switchRealTime() {
      if (this.realTimeOn) this.realTimeOn = false;
      else {
        this.realTimeOn = true;
        this.sliderValue = this.events[this.events.length - 1].ts;
      }
    },

    /**
     * Take a timestamp and return the time value in HH:mm format
     * @param {number} timestamp
     * @returns {string}
     */
    displayedValue(timestamp) {
      return timestampFormatHHMM(timestamp, { tz: this.tz });
    },

    /**
     * Take a timeObject and convert it to a timestamp (depending on selectedDate) given to the slider
     * @param {import('@/libs/helpers/dates').TimeObject} value
     */
    handleUpdateTimepicker(value) {
      const newTs = timeObjToTimestamp(this.selectedDateGtfs, value);
      this.sliderValue = newTs;
    },
    /**
     * Action on focus Timepicker : disable realTime actualization
     */
    handleFocusTimepicker() {
      this.realTimeOn = false;
    },

    /**
     * take a timestamp and return an object time with only hours
     * datepicker min/max hours does not work correctly so we only limit hours
     * @param {number} timestamp
     * @param {boolean} last
     * @returns {import('@/libs/helpers/dates').TimeObject}
     */
    calculateMinMaxTime(timestamp, last = false) {
      const time = dayjs.unix(timestamp);
      let hour = time.hour();
      if (last) hour += 1;
      return {
        hours: hour,
        minutes: null,
        seconds: null,
      };
    },
    slideStart() {
      this.isUserSliding = true;
    },
    slideEnd() {
      this.isUserSliding = false;
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
.timeline {
  --slider-bg: #e6e6e6; // $border
  --slider-connect-bg: #b3b3b3; // $border-variant
  --slider-handle-ring-color: #00000025;
  --slider-height: 4px;
  --slider-tooltip-bg: #00b871; // $primary-light
  --dp-font-family: 'Poppins', arial, sans-serif;

  display: flex;
  height: 36px;
  border: solid 1px $border;
  border-radius: 7px;
  background-color: $canvas;

  &__btn {
    width: 100px;
    height: 25px;
    margin-top: 5px;
    padding: 0 10px;
    border-radius: 3px;
    background-color: $background !important;
    text-align: center !important;

    &.active {
      background-color: $green-flat !important;
      color: $primary-light;
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  &__slider {
    width: 100%;
    margin: 15px 20px 10px 10px;
  }

  // Datepicker
  .dp__main {
    width: 85px;
    height: 10px;
    margin-right: 10px;
    padding: 2px;
  }

  .dp__overlay {
    border-radius: 9px;
  }

  // dp input
  .dp__input {
    height: 25px;
    margin: 3px 0 0 3px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: $background;
    font-size: 14px;
    text-align: center;
  }

  .active {
    .dp__input {
      background-color: $green-flat;
      color: $primary-light;
    }
  }

  .dp__input_focus {
    box-shadow: none;
  }

  .dp__action_button {
    margin-left: 8px;
    padding: 3px 5px;
    border-radius: 5px;
    font-family: $font-poppins;
  }

  .dp__action_select:disabled {
    background: $text-neutral;
    cursor: not-allowed;
  }

  .dp__action_cancel:hover {
    border-color: $border-variant;
    background-color: $border;
  }

  .dp__action_select:hover:not(:disabled) {
    background-color: darken($primary-light, 5%);
  }

  .dp__inc_dec_button:hover {
    background: $border;
  }

  .dp__inc_dec_button_disabled,
  .dp__inc_dec_button_disabled:hover {
    background: none;
    color: $transparent-border-variant;
  }

  .dp__selection_preview {
    color: transparent;
  }

  .dp__time_display {
    min-width: 45px;
    padding: 5px;
    border-radius: 16px;
  }

  .dp__time_col_reg {
    padding: 0 15px;
  }

  .dp__menu {
    left: 260px !important;
  }

  .dp__arrow_bottom {
    left: 30px;
  }
}
</style>

<i18n locale="fr">
{
  "realTime": "Temps réel",
  "timePickerCancel": "Annuler",
  "timePickerConfirm": "Valider"
}
</i18n>

<i18n locale="en">
{
  "realTime": "Real time",
  "timePickerCancel": "Cancel",
  "timePickerConfirm": "Confirm"
}
</i18n>
