import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import store from '@/store';
import { Permission } from '@/auth';
import { validateCapacity } from './index.vue';

const CommentCell = defineAsyncComponent(() => import('@/components/Table/cells/CommentCell.vue'));
const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const SingleTeamSelector = defineAsyncComponent(() => import('@/components/ui/SingleTeamSelector.vue'));

export const VEHICLES_LS_COLUMNS = 'vehiclesPage/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  FLEET_NUMBER: 'fleet_number',
  LICENSE_PLATE: 'license_plate',
  TEAM: 'teamName',
  COMMENT: 'comment',
  CAPACITY: 'capacity',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(VEHICLES_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'vehicleListDatagrid',
    searchFields: ['fleet_number', 'license_plate'],
    defaultSortBy: [{ key: ColumnKey.FLEET_NUMBER, order: SortOrder.ASC }],
    columnSelectionLocalStorageKey: VEHICLES_LS_COLUMNS,
    hasActions: true,

    columns: [
      // FLEET NUMBER
      new DataGridColumn({
        key: ColumnKey.FLEET_NUMBER,
        title: 'column.fleet_number',
        defaultSelected: defaultSelected(ColumnKey.FLEET_NUMBER),
        selectable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'fleet_number',
            },
          };
        },
      }),

      // LICENSE PLATE
      new DataGridColumn({
        key: ColumnKey.LICENSE_PLATE,
        title: 'column.license_plate',
        defaultSelected: defaultSelected(ColumnKey.LICENSE_PLATE),
        selectable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'license_plate',
            },
          };
        },
      }),

      // CAPACITY
      new DataGridColumn({
        key: ColumnKey.CAPACITY,
        title: 'column.capacity',
        defaultSelected: defaultSelected(ColumnKey.CAPACITY),
        selectable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'capacity',
              placeholder: '100',
              validation: validateCapacity.IS_VALID_NUMBER_RANGE,
            },
          };
        },
      }),

      // TEAM
      new DataGridColumn({
        key: ColumnKey.TEAM,
        title: 'column.team',
        defaultSelected: defaultSelected(ColumnKey.TEAM),
        filterable: value => ({ value, label: store.getters.getTeamNameById(value) }),
        sortable: false,
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: SingleTeamSelector,
            events: { valueChanged },
            props: {
              teamId: object.team_id,
              editMode,
            },
          };
        },
      }),

      // COMMENT
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_COMMENTS)
        ? [
            new DataGridColumn({
              key: ColumnKey.COMMENT,
              title: 'column.comment',
              defaultSelected: defaultSelected(ColumnKey.COMMENT),
              sortable: false,
              cellBuilder([value, object], { showModal }) {
                return {
                  component: CommentCell,
                  events: { showModal },
                  props: { comment: value },
                };
              },
            }),
          ]
        : []),
    ],
  });
};
