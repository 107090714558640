<template>
  <Modal modal-class="modal-import-resources" @close="closeModal">
    <template #title>
      <span>{{ $t('importResources') }}</span>
    </template>

    <template #body>
      <div class="modal-import-resources__body">
        <span>{{ $t('importResource') }}</span>
        <a :href="$t('link')" class="modal-import-resources__link" target="_blank">
          {{ $t('documentation') }}
        </a>

        <div v-if="fileCompared === true" class="modal-import-resources__comparision">
          <div>{{ $t('resourcesToAdd', [resourcesToAdd]) }}</div>
          <div>{{ $t('resourcesToEdit', [resourcesToEdit]) }}</div>
          <div>{{ $t('resourcesToDelete', [resourcesToDelete]) }}</div>
        </div>

        <div v-else-if="fileComparing" class="modal-import-resources__loading">
          <AnimatedDots />
        </div>

        <div class="modal-import-resources__input">
          <input
            id="csv_file"
            accept=".csv"
            type="file"
            name="csv_file"
            class="form-control"
            @change="compareFile($event)"
          />
        </div>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!fileCompared" @click="submitImportCsv">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import api from '@/api';
import Modal from '@/components/layout/Modal.vue';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import Btn from '@/components/ui/Btn.vue';

import ApiErrorsMixin from './ApiErrorsMixin';

export default {
  name: 'ModalImportResource',

  components: {
    AnimatedDots,
    Btn,
    Modal,
  },

  mixins: [ApiErrorsMixin],

  props: {
    resourceType: {
      type: String,
      required: true,
    },
    body: {
      default: '',
      type: String,
    },
  },

  emits: ['close'],

  data() {
    return {
      csvFile: null,
      /** @type {boolean} */
      fileCompared: false,
      /** @type {boolean} */
      fileComparing: false,
      /** @type {number} */
      resourcesToAdd: 0,
      /** @type {number} */
      resourcesToEdit: 0,
      /** @type {number} */
      resourcesToDelete: 0,
    };
  },

  methods: {
    /** Reset all variable used for csv import */
    cleanCsv() {
      this.resourcesToAdd = 0;
      this.resourcesToEdit = 0;
      this.resourcesToDelete = 0;
      this.fileCompared = false;
      this.csvFile = null;
    },

    closeModal() {
      this.cleanCsv();
      this.$emit('close');
    },

    /**
     * @param {event} e
     * Compare csv uploaded file data with current resources list
     */
    async compareFile(e) {
      // @ts-ignore
      if (!e.target.files[0]) {
        this.fileCompared = false;
        return;
      }
      // File is not compared yet
      this.fileCompared = false;
      // File is being compared
      this.fileComparing = true;
      // @ts-ignore
      // Get csv file from user event
      // @ts-ignore
      [this.csvFile] = e.target.files;
      // Send csv file to the API and get results
      api.importResource
        .uploadCsv(this.$store.getters.group._id, this.csvFile, this.resourceType, true)
        .then(data => {
          if ('to_create' in data) {
            this.resourcesToEdit = data.to_update;
            this.resourcesToAdd = data.to_create;
            this.resourcesToDelete = data.to_delete;
            this.fileCompared = true;
          }
        })
        .finally(() => {
          this.fileComparing = false;
        });
    },

    /** Send request to the api to import csv file */
    async submitImportCsv() {
      if (!this.csvFile) return;
      this.fileCompared = false;

      api.importResource
        .uploadCsv(this.$store.getters.group._id, this.csvFile, this.resourceType)
        .then(() => {
          this.fileCompared = true;
          this.$emit('close');
          // @ts-ignore
          this.showError();
        });
    },
  },
};
</script>

<style lang="scss">
.modal-import-resources {
  &__body {
    margin-bottom: 15px;
  }

  &__comparision {
    margin: 20px 0;
  }

  &__input {
    margin-top: 10px;
  }

  &__link {
    color: $text-dark-variant;
    text-decoration: underline;
  }

  &__loading {
    margin: 20px 0;
  }
}
</style>
<i18n locale="fr">
{
  "documentation": "documentation en ligne",
  "importResource": "Pour importer votre fichier, veuillez consulter la ",
  "importResources": "Importer des ressources",
  "link": "https://support.pysae.com/fr/articles/4768320-gestion-des-conducteurs",
  "resourcesToAdd": "Ressources à ajouter : {0}",
  "resourcesToEdit": "Ressources à mettre à jour : {0}",
  "resourcesToDelete": "Ressources à archiver : {0}",
 }
</i18n>
<i18n locale="en">
{
  "documentation": "online documentation",
  "importResource": "To import your file, please refer to the ",
  "importResources": "Import resources",
  "link": "https://support.pysae.com/en/articles/4768320-gestion-des-conducteurs",
  "resourcesToAdd": "Resources to add: {0}",
  "resourcesToEdit": "Resources to update: {0}",
  "resourcesToDelete": "Resources to archive: {0}",
}
</i18n>
