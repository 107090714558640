<script setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Api from '@/api';
import auth0 from '@/auth0';
import Btn from '@/components/ui/Btn.vue';
import SeparatorLineWithText from '@/components/ui/SeparatorLineWithText.vue';
import { AuthRoute } from '@/libs/routing';

const route = useRoute();
const router = useRouter();

/** @type {import('vue').Ref<String | null>} */
const email = ref();
/** @type {import('vue').Ref<String | null>} */
const password = ref();
/** @type {import('vue').Ref<Boolean>} */
const showError = ref(false);
/** @type {import('vue').Ref<Boolean>} */
const isMaintenanceError = ref(false);

function loginByAuth0() {
  localStorage.setItem('byAuth0', true);
  auth0.loginWithRedirect();
}

/** Send log information to the api */
async function login() {
  localStorage.removeItem('byAuth0');
  try {
    await Api.sendAuth(email.value, password.value);
  } catch (e) {
    // If answer status is not 401, it's not an auth error,
    // So we display a maintenance error message
    e.response?.status !== 401 ? (isMaintenanceError.value = true) : (isMaintenanceError.value = false);
    showError.value = true;
  }
  router.push(route.query?.next ?? route.redirectedFrom?.fullPath ?? '/');
}
</script>

<template>
  <div class="login-form">
    <Btn type="secondary" class="login-form__sso" @click="loginByAuth0">
      <v-icon size="x-small">fa:fas fa-lock</v-icon>
      {{ $t('login.SSO-login') }}
    </Btn>
    <SeparatorLineWithText text-key="or" />
    <form class="login-form__form" @submit.prevent="login">
      <div>
        <div class="form-group__label" for="email">
          {{ $t('login.email') }}
        </div>
        <input id="email" v-model="email" class="form-group__input" type="email" />
      </div>
      <div>
        <div class="form-group__label" for="password">
          {{ $t('login.password') }}
        </div>
        <input id="password" v-model="password" type="password" class="form-group__input" />
        <router-link class="login-form__forgot" :to="{ name: AuthRoute.PASSWORD_RESET }">
          {{ $t('login.forgotPassword') }}
        </router-link>
      </div>
      <div v-if="showError" class="login-form__incorrect">
        <v-icon size="small">fa:fas fa-exclamation-circle</v-icon>
        {{ $t(isMaintenanceError ? 'login.maintenance' : 'login.incorrect') }}
      </div>
      <Btn type="primary" class="login-form__btn">{{ $t('login.login') }}</Btn>
    </form>
    <div class="login-form__no-account">
      {{ $t('login.noAccount') }}
      <a href="https://web.pysae.com/contact">
        {{ $t('login.contactUs') }}
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__btn {
    margin-top: 10px;
  }

  &__forgot {
    color: $primary-light;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: $primary-light;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__incorrect {
    margin-top: 10px;
    color: $danger;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    text-align: center;

    .v-icon {
      margin-right: 5px;
    }
  }

  &__no-account {
    font-weight: 500;
    text-align: center;

    a {
      color: $primary-light;
      font-weight: $font-weight-semi-bold;
    }
  }

  &__sso {
    i {
      margin: 0 5px 2px 0;
    }
  }
}
</style>
