<template>
  <Modal hide-footer modal-class="modal-register-devices" @close="$emit('close')">
    <template #title>
      {{ $t('authentificationCode') }}
    </template>

    <template #subtitle>
      <div class="modal-register-devices__code">{{ code.code }}</div>
      <div class="modal-register-devices__validation-date">{{ $t('valid', [expiracyDate]) }}</div>
    </template>

    <template #body>
      <div class="modal-register-devices__body">
        <div class="modal-register-devices__img-container">
          <img src="@/assets/img/device-registration.png" />
        </div>
        <div class="modal-register-devices__steps">
          <div class="modal-register-devices__step">
            <div class="modal-register-devices__step-number">1</div>
            <div>
              {{ $t('step1.1') }}
              <b>{{ $t('step1.2') }}</b>
            </div>
          </div>
          <div class="modal-register-devices__step">
            <div class="modal-register-devices__step-number">2</div>
            <div>
              <b>{{ $t('step2.1') }}</b>
              {{ $t('step2.2') }}
              <i>{{ $t('step2.3') }}</i>
            </div>
          </div>
          <div class="modal-register-devices__step">
            <div class="modal-register-devices__step-number">3</div>
            {{ $t('step3') }}
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';

export default {
  name: 'ModalRegisterDevices',

  components: {
    Modal,
  },

  props: {
    /** @type {Vue.PropOtions<import('@/api').RegistrationCode>} */
    code: {
      type: Object,
      required: true,
    },
  },

  emits: ['close'],

  computed: {
    /** @return {string} */
    expiracyDate() {
      return new Date(this.code.expire * 1000).toLocaleString(this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss">
.modal-register-devices {
  &.modal .v-overlay__content {
    padding: 20px 0 0 !important;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid $border;
    background-color: $white;
  }

  &__code {
    width: 100%;
    margin-top: 5px;
    color: $primary-light;
    font-weight: $font-weight-semi-bold;
    font-size: 30px;
    letter-spacing: 5px;
    text-align: center;
  }

  &__img-container {
    flex-grow: 1;
    width: 120px;
    text-align: center;
  }

  &__step-number {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $primary-light;
    color: $white;
    font-size: 14px;
    text-align: center;
  }

  &__step {
    display: flex;
    padding: 8px;
  }

  &__steps {
    flex-grow: 3;
    margin-left: 10px;
  }

  &__validation-date {
    width: 100%;
    font-weight: $font-weight-light;
    font-size: 12px;
    text-align: center;
  }

  .modal__body {
    overflow-y: auto;
    margin-bottom: 0;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
  }
}
</style>

<i18n locale="fr">
{
  "authentificationCode": "Code d'authentification",
  "step1.1": "Rendez-vous sur l'application ",
  "step1.2": "Pysae Driver.",
  "step2.1": "Saisissez ce code ",
  "step2.2": "dans le champs ",
  "step2.3": "Entrer un code.",
  "step3": "Votre appareil est enrôlé et prêt à l'usage !",
  "valid": "Valable jusqu'au {0}"
}
</i18n>

<i18n locale="en">
{
  "authentificationCode": "Authentification code",
  "step1.1": "Go to ",
  "step1.2": "Pysae Driver application.",
  "step2.1": "Enter this code ",
  "step2.2": "in ",
  "step2.3": "Enter a code.",
  "step3": "Your device is registered and ready to be used!",
  "valid": "Valid until {0}"
}
</i18n>
