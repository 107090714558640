<template>
  <div class="screen-view">
    <header>
      <Btn type="primary" :link-url="screenUrl">
        <span>{{ $t('openPassengersDisplay') }}</span>
      </Btn>
      <Btn type="secondary" :route="{ name: GroupRoute.GTFS_RT }">
        {{ $t('gtfsRT') }}
      </Btn>
      <Btn type="secondary" :route="{ name: GroupRoute.PASSENGERS_APP }">
        {{ $t('passengersApp') }}
      </Btn>
    </header>
    <!-- Toggle for pub -->
    <div class="form-group">
      <ToggleSwitch
        id="display-screen"
        :checked="values.pub_screen"
        class="settings-info__toggle-switch"
        @change="onToggleChange"
      />
      <span class="form-group__label switch-label">{{ $t('activateScreen') }}</span>
    </div>

    <!-- Display information for pub_screen -->
    <div class="screen-view__display-info" :class="{ published: values.pub_screen }">
      {{ values.pub_screen ? $t('displayScreenInfo') : $t('nonDisplayScreenInfo') }}
    </div>
    <main>
      <!-- Left side of the wiew -->
      <section>
        <div class="subsection">
          <div class="subsection__title">
            {{ $t('subsectionTitle.screenUrl') }}
          </div>
          <CopyContent :content="screenUrl" :link-url="screenUrl" />
        </div>
        <div class="subsection">
          <div class="subsection__title">
            {{ $t('subsectionTitle.QRCode') }}
          </div>
          <div class="screen-view__qr-code">
            <img :src="qrcodeSrc" alt="QR code" />
            <Btn type="icon-only" :title="$t('download')" @click="downloadQRCode">
              <font-awesome-icon icon="fa-download" />
            </Btn>
          </div>
          <div class="screen-view__info">{{ $t('infoQRCode') }}</div>
        </div>
        <div class="subsection">
          <div class="subsection__title">
            {{ $t('subsectionTitle.IFrame') }}
          </div>
          <CopyContent class="screen-view__iframe" :content="iFrame" :plain-text="true" />
          <div class="screen-view__info">{{ $t('infoIFrame') }}</div>
        </div>
        <!-- Logo -->
        <GroupLogoInput
          v-model:logo-url="values.logo_url"
          v-model:trigger-save-logo="saveLogo"
          @isLogoValid="checkLogoValidity"
          @logoHasChanged="checkLogoHasChanged"
        ></GroupLogoInput>
        <Btn type="primary" :disabled="!hasChanged && !isLogoChangedAndValid" @click="saveInfo">
          <font-awesome-icon icon="fa-floppy-disk" />
          <span>{{ $t('save') }}</span>
        </Btn>
      </section>

      <!-- Right side of the view -->
      <section>
        <div class="baseline">
          {{ $t('baselineRight.segment1') }}
          <span class="baseline__strong baseline__offsetline">{{ $t('baselineRight.segment2') }}</span>
          {{ $t('baselineRight.segment3') }}
        </div>
        <div class="screen-image">
          <img alt="Screen desktop" src="@/assets/img/screen-desktop.png" class="screen-image__desktop" />
          <img alt="Screen mobile" src="@/assets/img/screen-mobile.png" class="screen-image__mobile" />
        </div>
        <Btn type="secondary" :link-url="screenUrl">
          <font-awesome-icon icon="fa-arrow-up-right-from-square" />
          <span>{{ $t('takeMeThere') }}</span>
        </Btn>
      </section>
    </main>
  </div>
</template>

<script>
import { baseAppUrl, qrCodes as qrcodesAPI } from '@/api';
import { GroupRoute } from '@/libs/routing';

import GroupLogoInput from '@/components/common/GroupLogoInput.vue';
import Btn from '@/components/ui/Btn.vue';
import CopyContent from '@/components/ui/CopyContent.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

import SettingsMixin, { ConfigTarget } from '@/pages/SettingsPage/SettingsMixin';

export default {
  name: 'PassengersScreen',

  components: {
    CopyContent,
    Btn,
    GroupLogoInput,
    ToggleSwitch,
  },

  mixins: [SettingsMixin],

  data() {
    return {
      GroupRoute,
      logoHasChanged: false,
      islogoValid: true,
      saveLogo: false,
      qrcodeSrc: '',
    };
  },

  computed: {
    /** @return {string} */
    iFrame() {
      return `<iframe \n src="${this.screenUrl}" width="100%" height="100%"\n></iframe>`;
    },

    /** @return {string} */
    screenUrl() {
      return encodeURI(`${baseAppUrl}/screen/#/${this.groupId}/all`);
    },

    /** @return {?string} */
    groupId() {
      return this.$store.getters.group._id;
    },
    isLogoChangedAndValid() {
      return this.logoHasChanged && this.islogoValid;
    },
  },

  async created() {
    const res = await qrcodesAPI.get({ content: this.screenUrl, filename: `QR-code-${this.groupId}` });
    const blob = await res.blob();
    this.qrcodeSrc = window.URL.createObjectURL(blob);
  },

  unmounted() {
    window.URL.revokeObjectURL(this.qrcodeSrc);
  },

  methods: {
    async downloadQRCode() {
      await qrcodesAPI.download({
        content: this.screenUrl,
        filename: `QR-code-${this.groupId}`,
        label: this.$t('infoQRCode'),
      });
    },

    /**
     * Returns default values
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        pub_screen: false,
        logo_url: '',
      };
    },
    async saveInfo() {
      this.saveLogo = true;
      this.save(ConfigTarget.SCREEN_APP);
    },
    checkLogoValidity(value) {
      this.islogoValid = value;
    },
    checkLogoHasChanged(value) {
      this.logoHasChanged = value;
    },
    /**
     * Handle toggle change and save
     * @param {boolean} checked
     */
    onToggleChange(checked) {
      this.values.pub_screen = checked;
      this.saveInfo();
    },
  },
};

/**
 * @typedef {Object} UrlsList
 * @property {string} alerts
 * @property {string} feed
 * @property {string} gtfs
 * @property {string} tripUpdates
 * @property {string} vehiclePosition
 */
</script>

<style lang="scss">
.screen-view {
  padding: $view-standard-padding;

  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .ui-btn {
      &:nth-child(3) {
        margin-left: auto;
      }
    }
  }

  main {
    display: flex;
    justify-content: space-between;

    section {
      flex-basis: 50%;

      &:nth-child(2) {
        .ui-btn {
          margin-left: 65%;
        }
      }
    }
  }

  &__iframe {
    textarea {
      height: 100px;
    }
  }

  &__info {
    width: 75%;
    padding: 10px;
    border-radius: 8px;
    background-color: rgb(0 184 113 / 10%);
    color: $primary-light;
  }

  &__qr-code {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 115px;
      margin-right: 15px;
      border: 2px solid transparent;
      border-radius: 5px;
      background: linear-gradient(357deg, #fff, #9d9d9d) border-box;
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      transform: rotate(-5.23deg);
    }

    .ui-btn {
      margin-left: 10px;
    }
  }

  .baseline {
    width: 90%;
    margin-bottom: 20px;
    color: $secondary;
    font-weight: $font-weight-semi-bold;
    font-size: 16px;

    &__strong {
      color: $primary-light;
    }
  }

  .subsection {
    margin-bottom: 20px;

    &__title {
      margin-bottom: 15px;
      color: $text-dark;
      font-weight: $font-weight-semi-bold;
    }
  }

  .screen-image {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 80%;
    margin-bottom: 80px;
    padding: 3px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    box-shadow: 12px 10px 25px rgb(0 0 0 / 15%);

    &__desktop {
      width: 100%;
    }

    &__mobile {
      position: absolute;
      top: 45%;
      right: -13%;
      width: 28%;
    }
  }

  &__display-info {
    width: fit-content;
    margin: 10px 0 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: $transparent-text-secondary;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    line-height: 1.6em;

    &.published {
      background-color: $transparent-primary;
      color: $primary-light;
    }
  }
}

.form-group {
  margin-bottom: 20px;

  &__label {
    display: inline-block;
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
  }

  &__toggle-switch {
    margin-right: 10px;
  }
}

.switch-label {
  margin-left: 10px;
}
</style>

<i18n locale="fr">
  {
  "baselineRight": {
    "segment1": "Sur votre site Internet, en affichage à l'arrêt ou dans les gares, ",
    "segment2": "diffusez facilement une information en temps réel à vos voyageurs ",
    "segment3": "grâce à une simple URL."
  },
  "subsectionTitle": {
    "screenUrl" : "Votre URL d'affichage voyageurs",
    "QRCode": "Votre QR code d'affichage voyageurs",
    "IFrame": "Votre IFrame",
  },
  "infoQRCode": "Ce QR code vous permet d'accéder à l'information en temps réel sur l'ensemble de votre réseau.",
  "infoIFrame": "Intégrez l'information temps réel de votre réseau à votre site web ou application.",
  "gtfsRT": "GTFS-RT",
  "takeMeThere": "M'y emmener",
  "openPassengersDisplay": "Ouvrir mon affichage voyageurs",
  "passengersApp": "Application voyageurs",
  "activateScreen": "Activer l'affichage voyageurs",
  "comingSoon": "Bientôt disponible",
  "displayScreenInfo": "Votre option Affichage voyageurs est activée",
  "nonDisplayScreenInfo": "Votre option Affichage voyageurs est désactivée",
}
</i18n>

<i18n locale="en">
{
  "baselineRight": {
    "segment1": "On your website, on display at stops or in stations, ",
    "segment2": "easily distribute real-time information to your passengers ",
    "segment3": "with a simple URL."
  },

  "subsectionTitle": {
    "screenUrl" : "Your passengers display URL",
    "QRCode": "Your passengers display QR Code",
    "IFrame": "Your IFrame",
  },
  "infoQRCode": "This QR code allows you to have access to real time information on your entire network.",
  "infoIFrame": "Integrate real-time information into your website or application.",
  "gtfsRT": "GTFS-RT",
  "takeMeThere": "Take me there",
  "openPassengersDisplay": "Open my passengers display",
  "passengersApp": "Passengers app",
  "activateScreen": "Activate passengers screen display",
  "comingSoon": "Coming soon",
  "displayScreenInfo": "Your Passengers Screen option is enable",
  "nonDisplayScreenInfo": "Your Passengers Screen option is disable",
}
</i18n>
