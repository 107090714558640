<template>
  <input
    :value="value"
    :max="maxValue"
    :min="minValue"
    :step="step"
    type="range"
    @input="$emit('input', parseInt($event.target.value))"
  />
</template>

<script>
export default {
  name: 'RangeInput',

  props: {
    maxValue: { default: 100, type: Number },
    minValue: { default: 0, type: Number },
    step: { default: 1, type: Number },
    value: { default: 0, type: Number },
  },

  emits: ['input'],
};
</script>

<style lang="scss">
input[type='range'] {
  width: 100%;
  margin: 5.5px 0;
  background-color: transparent;
  appearance: none;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 9px;
  border: 0.2px solid rgb(0 184 113 / 49%);
  border-radius: 19.5px;
  background: rgb(0 184 113 / 49%);
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -5.7px;
  border: 4.4px solid $primary-light;
  border-radius: 50px;
  background: $canvas;
  cursor: pointer;
  appearance: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: rgb(0 184 113 / 49%);
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 9px;
  border: 0.2px solid rgb(0 184 113 / 49%);
  border-radius: 19.5px;
  background: rgb(0 184 113 / 49%);
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 4.4px solid $primary-light;
  border-radius: 50px;
  background: $canvas;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 9px;
  border-width: 6.5px 0;
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

input[type='range']::-ms-fill-lower {
  border: 0.2px solid rgb(0 184 113 / 49%);
  border-radius: 2.6px;
  background: rgb(0 184 113 / 49%);
}

input[type='range']::-ms-fill-upper {
  border: 0.2px solid rgb(0 184 113 / 49%);
  border-radius: 2.6px;
  background: rgb(0 184 113 / 49%);
}

input[type='range']::-ms-thumb {
  width: 20px;
  height: 20px;
  margin-top: 0;
  border: 4.4px solid $primary-light;
  border-radius: 50px;
  background: $canvas;
  cursor: pointer;

  /* Needed to keep the Edge thumb centered */
}

input[type='range']:focus::-ms-fill-lower {
  background: rgb(0 184 113 / 49%);
}

input[type='range']:focus::-ms-fill-upper {
  background: rgb(0 184 113 / 49%);
}
</style>
