<template>
  <div class="single-team-selector">
    <div v-if="!editMode" class="team-display">
      <v-chip v-if="selectedTeam" variant="elevated" density="compact" :color="teamColor">
        {{ selectedTeam.title }}
      </v-chip>
      <span v-else>{{ placeholder ?? (noTeamPlaceholder ? $t('noTeam') : $t('visibleByAll')) }}</span>
    </div>
    <div v-else>
      <v-select
        :model-value="teamId"
        :placeholder="placeholder ?? (noTeamPlaceholder ? $t('noTeam') : $t('visibleByAll'))"
        chips
        :items="teamsOptions"
        variant="outlined"
        hide-details
        :multiple="false"
        menu-icon="fa:fas fa-chevron-down"
      >
        <template #item="{ props, item }">
          <v-list-item v-bind="props" title="" @click.stop.capture.prevent="select(item.value)">
            <v-radio
              :label="item.title"
              :model-value="item.value === teamId"
              color="success"
              base-color="text"
              true-icon="fa:fas fa-check-circle"
            ></v-radio>
          </v-list-item>
        </template>

        <template #no-data>
          <div>
            {{ $t('noData') }}
          </div>
        </template>

        <template #chip="{ item }">
          <v-chip :color="teamColor" variant="elevated" @click.prevent.stop.capture="select(item.value)">
            {{ item.title }}
            <font-awesome-icon icon="fa-xmark" class="chip-icon" />
          </v-chip>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleTeamSelector',

  props: {
    color: {
      default: null,
      type: String,
    },

    editMode: {
      default: false,
      type: Boolean,
    },

    teamId: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    noTeamPlaceholder: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['valueChanged'],

  computed: {
    /**
     * Recreate a list of team objects
     * @returns {Array<TeamOption>}
     */
    teamColor() {
      return this.color ?? this.selectedTeam?.color ?? '#333333';
    },
    teamsOptions() {
      /* eslint-disable camelcase */
      return this.$store.getters.activeTeams
        .map(({ team_id, name, color }) => ({
          value: team_id,
          title: name ?? team_id,
          color,
        }))
        .sort();
      /* eslint-enable camelcase */
    },
    selectedTeam() {
      return this.teamsOptions.find(t => t.value === this.teamId);
    },
  },

  methods: {
    select(selection) {
      const value = selection === this.teamId ? null : selection;
      this.$emit('valueChanged', { value, field: 'team_id' });
    },
  },
};

/** @typedef {Object} TeamOption
 * @param {string} color
 * @param {string} title
 * @param {string} value
 */
</script>

<style lang="scss">
.single-team-selector {
  .team-display {
    position: relative;

    .v-chip {
      margin-left: 0;
      border: none;
      border-radius: 3px;
      color: $white;
      box-shadow: none;

      &.v-chip--size-default {
        padding: 5px;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "visibleByAll": "  Visible par tous",
  "noTeam": "  Pas d'équipe"
}
</i18n>

<i18n locale="en">
{
  "visibleByAll": "  Visible by all",
  "noTeam": "  No team"
}
</i18n>
