<template>
  <div class="settings-driver">
    <div class="form-group">
      <label class="form-group__label" for="driver_call_number">{{ $t('driver_call_number') }}</label>
      <input
        id="driver_call_number"
        v-model="values.driver_call_number"
        class="form-group__input"
        name="driver_call_number"
        type="text"
      />
    </div>

    <div class="form-group settings-driver__message-values">
      <label for="driver_message_values">
        <span class="form-group__label">{{ $t('driver_message_values') }}</span>
        <p class="form-group__sub-label">
          {{ $t('driver_message_values-help') }}
        </p>
      </label>

      <div v-for="(driverMessage, index) in values.driver_message_values" :key="index" class="input-group">
        <input
          v-model="driverMessage.message"
          class="form-group__input form-group__input--inline"
          name="driver_message_values"
          required
          type="text"
        />

        <div class="btn-group">
          <Btn
            class="btn"
            type="secondary"
            :disabled="index === 0"
            @click="driverMessageMoveUp(index)"
            @mousedown.prevent
          >
            <font-awesome-icon icon="fa-arrow-up" />
          </Btn>
          <Btn
            class="btn"
            type="secondary"
            :disabled="index === values.driver_message_values.length - 1"
            @click="driverMessageMoveDown(index)"
            @mousedown.prevent
          >
            <font-awesome-icon icon="fa-arrow-down" />
          </Btn>
          <Btn class="btn" type="secondary" @click="driverMessageRemove(index)" @mousedown.prevent>
            <font-awesome-icon icon="fa-minus" />
          </Btn>
        </div>
      </div>

      <Btn type="secondary" @click="driverMessageAdd" @mousedown.prevent>
        <font-awesome-icon icon="fa-plus" />
      </Btn>
    </div>

    <div class="form-group">
      <label class="form-group__label" for="driver_option_messages_block_send">
        {{ $t('driver_option_messages_block_send') }}
      </label>
      <div>
        <ToggleSwitch
          id="driver_option_messages_block_send"
          class="settings-driver__toggle-switch"
          :checked="values.driver_option_messages_block_send"
          @change="values.driver_option_messages_block_send = !values.driver_option_messages_block_send"
        />
        <label for="driver_option_messages_block_send">
          {{ $t('driver_option_messages_block_send-help') }}
        </label>
      </div>
    </div>

    <div class="form-group">
      <label class="form-group__label">{{ $t('embedded_router') }}</label>
      <div class="embedded_router">
        <div>
          <ToggleSwitch
            id="activate_embedded_router"
            class="settings-driver__toggle-switch"
            :checked="isEmbeddedRouterChecked"
            @change="checkEmbeddedRouter"
          />
          <label for="activate_embedded_router">{{ $t('activate_embedded_router') }}</label>
        </div>

        <template v-if="isEmbeddedRouterChecked">
          <div class="embedded_router__inputs">
            <div>
              <label class="required" for="embedded_router_id">{{ $t('login') }}</label>
              <div>
                <input
                  id="embedded_router_id"
                  v-model="values.router.login"
                  class="form-group__input"
                  name="embedded_router_id"
                  type="text"
                />
                <span v-show="msg.routerLogin" class="error-msg">{{ msg.routerLogin }}</span>
              </div>
            </div>
            <div>
              <label class="required" for="embedded_router_pwd">{{ $t('pwd') }}</label>
              <div>
                <input
                  id="embedded_router_pwd"
                  v-model="values.router.password"
                  class="form-group__input"
                  name="embedded_router_pwd"
                  type="password"
                />
                <span v-show="msg.routerPassword" class="error-msg">{{ msg.routerPassword }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="form-group">
      <label class="form-group__label" for="pis_dead_mileage_code">{{ $t('pis_dead_mileage_code') }}</label>
      <input
        id="pis_dead_mileage_code"
        v-model="values.pis_dead_mileage_code"
        class="form-group__input"
        name="pis_dead_mileage_code"
        type="text"
      />
    </div>

    <Btn type="primary" :disabled="!hasChanged || hasError" @click="saveInfos">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

import SettingsMixin, { ConfigTarget } from './SettingsMixin';

const defaults = {
  router: { login: '', password: '' },
};

export default {
  name: 'Driver',

  components: { Btn, ToggleSwitch },
  mixins: [SettingsMixin],

  data: () => ({
    msg: {},
  }),

  computed: {
    /** @return {boolean} */
    hasError() {
      return this.hasDriverMessageError || this.hasRouterError;
    },

    /** @return {boolean} */
    hasDriverMessageError() {
      return Object.values(this.values.driver_message_values).some(dm => dm.message === '');
    },

    /** @return {boolean} */
    hasRouterError() {
      return this.isRouterLoginEmpty || this.isRouterPasswordEmpty;
    },

    /** @return {boolean} */
    isEmbeddedRouterChecked() {
      return this.values.router !== null && Object.keys(this.values.router).length !== 0;
    },

    /** @return {boolean} */
    isRouterLoginEmpty() {
      return this.isEmbeddedRouterChecked && this.isFieldEmpty(this.values.router.login);
    },

    /** @return {boolean} */
    isRouterPasswordEmpty() {
      return this.isEmbeddedRouterChecked && this.isFieldEmpty(this.values.router.password);
    },
  },

  watch: {
    'values.router.login': {
      immediate: true,
      handler(_, old) {
        const slug = 'routerLogin';
        const isDisplayed = this.isRouterLoginEmpty && old !== undefined;

        this.requiredFieldChangeHandler({ slug, isDisplayed });
      },
    },

    'values.router.password': {
      immediate: true,
      handler(_, old) {
        const slug = 'routerPassword';
        const isDisplayed = this.isRouterPasswordEmpty && old !== undefined;

        this.requiredFieldChangeHandler({ slug, isDisplayed });
      },
    },
  },

  methods: {
    /**
     * Returns default values
     *
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        driver_call_number: '',
        /** @type {Array<import('@/store').DriverMessage>} */
        driver_message_values: [],
        driver_option_messages_block_send: false,
        pis_dead_mileage_code: '',
        router: {},
      };
    },

    /**
     * Set/clear error message.
     * @param {Object} args
     * @param {String} args.slug - The key corresponding to the required field (msg[slug]).
     * @param {Boolean} args.isDisplayed - True if the error message has to be displayed, false otherwise.
     * @param {String} args.msg - Translated message.
     */
    setMsg({ slug, isDisplayed, msg }) {
      if (!isDisplayed) {
        delete this.msg[slug];
      } else {
        this.msg[slug] = msg;
      }
    },

    /**
     * Change handler function for required fields.
     * @param {Object} args
     * @param {String} args.slug - The key corresponding to the required field (msg[slug]).
     * @param {Boolean} args.isDisplayed - True if the error message has to be displayed, false otherwise.
     */
    requiredFieldChangeHandler(args) {
      this.setMsg({ ...args, msg: `* ${this.$t('required_field')}` });
    },

    /**
     * @param {String} field
     */
    isFieldEmpty(field) {
      return !field || field === '';
    },

    driverMessageAdd() {
      this.values.driver_message_values.push({
        message: '',
      });
    },

    /** @param {number} index */
    driverMessageMoveDown(index) {
      this.values.driver_message_values.splice(
        index + 1,
        0,
        ...this.values.driver_message_values.splice(index, 1),
      );
    },

    /** @param {number} index */
    driverMessageMoveUp(index) {
      this.values.driver_message_values.splice(
        index - 1,
        0,
        ...this.values.driver_message_values.splice(index, 1),
      );
    },

    /** @param {number} index */
    driverMessageRemove(index) {
      this.values.driver_message_values.splice(index, 1);
    },

    /**
     * @param {boolean} check
     */
    checkEmbeddedRouter(check) {
      check ? (this.values.router = { ...defaults.router }) : (this.values.router = null);
    },
    saveInfos() {
      this.save(ConfigTarget.DRIVER_APP);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-driver {
  &__toggle-switch {
    margin-right: 1em;
  }

  .btn-group {
    height: 34px;
    margin-bottom: 2px;
    margin-left: 0.5em;

    > .ui-btn {
      padding: 0 10px;
    }
  }

  li {
    padding: 0.2em 0;
  }

  .embedded_router__inputs {
    display: flex;
    flex-flow: column nowrap;

    > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;

      > div {
        display: flex;
        flex: 4;
        flex-flow: column nowrap;
      }
    }

    &:not(:last-child) > div {
      padding-bottom: 0.5em;
    }

    &:not(:first-child) > div {
      padding-top: 0.5em;
    }

    label {
      flex: 1;
    }

    input {
      flex: 3;
      width: auto;
    }
  }

  &__message-values {
    .input-group {
      padding-bottom: 5px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "activate_embedded_router": "Activer la configuration du routeur",
  "choose_code": "Sélectionnez un code",
  "driver_call_number": "Numéro de téléphone",
  "driver_message_values": "Message conducteurs prédéfinis",
  "driver_message_values-help": "Définit la liste des messages proposés au conducteur lorsqu'il déclare une perturbation",
  "driver_option_messages_block_send": "Messages",
  "driver_option_messages_block_send-help": "Empêcher l’envoi de messages par les conducteurs",
  "login": "Identifiant",
  "pis_dead_mileage_code": "Code Haut-le-Pied pour le pupitre SIV",
  "pwd": "Mot de passe",
  "embedded_router": "Routeur embarqué",
  "required_field": "Champ obligatoire"
}
</i18n>

<i18n locale="en">
{
  "activate_embedded_router": "Activate the router configuration",
  "choose_code": "Choose a code",
  "driver_call_number": "Phone number",
  "driver_message_values": "Predefined driver messages",
  "driver_message_values-help": "Set a list of messages presented to drivers to declare a perturbation",
  "driver_option_messages_block_send": "Messages",
  "driver_option_messages_block_send-help": "Prevent driver from sending custom messages",
  "login": "Login",
  "pis_dead_mileage_code": "PIS dead mileage code",
  "pwd": "Password",
  "embedded_router": "Embedded router",
  "required_field": "Required field"
}
</i18n>
