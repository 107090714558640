<template>
  <Tag :text="version" :color="color" :background="backgroundColor" :icon="icon" :title="$t(status)" />
</template>

<script>
import Tag, { TagTypes } from '@/components/ui/Tag.vue';

/** @enum {string} */
export const AppVersionStates = {
  DEPRECATED: 'DEPRECATED',
  TO_UPDATE: 'TO_UPDATE',
  UP_TO_DATE: 'UP_TO_DATE',
};

export default {
  name: 'AppVersionCell',

  components: { Tag },

  props: {
    status: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
  },

  computed: {
    backgroundColor() {
      switch (this.status) {
        case 'UP_TO_DATE':
          return TagTypes.SUCCESS.backgroundColor;
        case 'TO_UPDATE':
          return TagTypes.WARNING.backgroundColor;
        case 'DEPRECATED':
          return TagTypes.DANGER.backgroundColor;
        default:
          return TagTypes.NEUTRAL.backgroundColor;
      }
    },

    color() {
      switch (this.status) {
        case 'UP_TO_DATE':
          return TagTypes.SUCCESS.color;
        case 'TO_UPDATE':
          return TagTypes.WARNING.color;
        case 'DEPRECATED':
          return TagTypes.DANGER.color;
        default:
          return TagTypes.NEUTRAL.color;
      }
    },

    icon() {
      return this.status === 'DEPRECATED' ? 'fa-circle-exclamation' : '';
    },
  },
};
</script>

<i18n locale="fr">
  {
     "UP_TO_DATE": "Cette version est à jour. Vous pouvez utiliser Pysae Driver !",
     "TO_UPDATE": "Cette version de Pysae Driver est trop ancienne, veuillez la mettre à jour.",
     "DEPRECATED": "Cette version de Pysae Driver n'est plus maintenue, veuillez la mettre à jour.",
  }
 </i18n>
<i18n locale="en">
  {
     "UP_TO_DATE": "This version is up to date. You can use Pysae Driver!",
     "TO_UPDATE": "This version of Pysae Driver is too old, consider updating it.",
     "DEPRECATED": "This version of Pysae Driver is not maintained anymore, please update it.",
  }
 </i18n>
