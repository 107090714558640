<template>
  <div class="group-logo">
    <div class="form-group">
      <label class="form-group__label">{{ $t('logoFile') }}</label>
      <div class="form-group__sub-label">{{ $t('logoFileHelp') }}</div>
      <input
        id="logo_file"
        ref="logo_file"
        name="logo_file"
        type="file"
        accept=".jpg, .png, .jpeg"
        class="group-logo__hidden-input"
        required
        @change="event => (file = event.target.files[0])"
      />
      <div class="group-logo__logo-input">
        <input class="form-group__input" :placeholder="file?.name" @click="$refs.logo_file.click()" />
        <Btn type="icon-only" class="folder-btn" @click="$refs.logo_file.click()">
          <font-awesome-icon icon="fa-folder-open" />
        </Btn>
        <Btn type="icon-only" :disabled="willDeleteLogo" :title="$t('deleteLogo')" @click="deleteLogo">
          <font-awesome-icon icon="fa-trash" />
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import api from '@/api';
import Btn from '@/components/ui/Btn.vue';

const toast = useToast();

export default {
  name: 'GroupLogoInput',

  components: {
    Btn,
  },

  props: {
    logoUrl: {
      type: String,
      required: true,
    },
    triggerSaveLogo: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['isLogoValid', 'logoHasChanged', 'update:logoUrl', 'update:triggerSaveLogo'],

  data: () => ({
    toast,

    /** @type {File} */
    file: null,
    /** @type {string} */
    image: null,
    /** @type {boolean} */
    willDeleteLogo: false,
  }),
  computed: {
    groupId() {
      return this.$store.getters.group.group_id;
    },
  },

  watch: {
    file() {
      this.checkFileValid();
    },
    triggerSaveLogo() {
      if (this.triggerSaveLogo) this.saveLogo();
    },
  },

  methods: {
    async checkFileValid() {
      this.willDeleteLogo = false;
      if (this.image) URL.revokeObjectURL(this.image);

      if (this.file) {
        try {
          this.image = await new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
              const reg = /image\/(jpg|png|jpeg)/i;
              if (reg.test(this.file.type) && image.height === 64) {
                resolve(image.src);
              } else {
                reject();
              }
            };

            image.onerror = () => {
              reject();
            };

            image.src = URL.createObjectURL(this.file);
            this.$emit('logoHasChanged', true);
            this.$emit('update:logoUrl', `/api/v2/groups/${this.groupId}/logo`);
            this.$emit('isLogoValid', true);
          });
        } catch (e) {
          this.image = null;
          const toastId = this.toast.error(this.$t('imageError'));
          setTimeout(() => toast.dismiss(toastId), 5000);
          this.file = null;
          this.$emit('isLogoValid', false);
        }
      } else {
        this.image = null;
      }
    },

    deleteLogo() {
      this.willDeleteLogo = !this.willDeleteLogo;
      this.$refs.logo_file.value = '';
      this.file = null;
      this.$emit('update:logoUrl', undefined);
      this.$emit('logoHasChanged', true);
      this.$emit('isLogoValid', true);
    },

    async saveLogo() {
      if (this.willDeleteLogo) {
        await api.logo.delete(this.groupId);
        this.willDeleteLogo = false;
      } else if (this.file) {
        const fd = new FormData();
        fd.append('logo', this.file);
        await api.logo.put(this.groupId, fd);
        this.$refs.logo_file.value = '';
        this.file = null;
      }
      this.$emit('update:triggerSaveLogo', false);
      this.$emit('logoHasChanged', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-logo {
  &__logo-input {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__hidden-input {
    display: none;
  }

  .ui-btn + .ui-btn {
    margin-left: 0 !important;
  }
}
</style>

<i18n locale="fr">
{
  "deleteLogo": "Image par défaut",
  "imageError": "Attention, l'image sélectionnée est incorrecte, veuillez vérifier son extension et sa taille.",
  "logoFile": "Logo du réseau",
  "logoFileHelp": "Format : 64 pixels de hauteur en jpeg, jpg ou png",
}
</i18n>

<i18n locale="en">
{
  "deleteLogo": "Reset default image",
  "imageError": "Warning: selected file is invalid. Please check format and dimensions.",
  "logoFile": "Group logo",
  "logoFileHelp": "Format: 64 pixels high, in jpeg, jpg or png",
}
</i18n>
