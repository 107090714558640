<script setup lang="ts">
import { type PropType } from 'vue';
import Btn from '@/components/ui/Btn.vue';
import { FstType, useTripUpdates, type FeedStopTimes } from '@/store-pinia/trip-updates';

const tripUpStore = useTripUpdates();

const emit = defineEmits(['cancel', 'validate']);

defineProps({
  stopTime: {
    required: true,
    type: Object as PropType<FeedStopTimes>,
  },
});
</script>

<template>
  <div id="updateStopCard" class="confirm-revert-stop-card stop-card">
    <v-card elevation="0" width="100%">
      <v-card-item>
        <span class="confirm-revert-stop-card__title">
          {{ tripUpStore.getStopName(stopTime.stop_id) || '-' }}
        </span>
      </v-card-item>

      <v-divider class="my-0"></v-divider>
      <v-card-item>
        <span class="confirm-revert-stop-card__subtitle">
          {{ $t(stopTime.type === FstType.SHIFT ? 'revertStopModification' : 'removeTemporaryStop') }}
        </span>
      </v-card-item>
    </v-card>
    <div class="stop-card__actions">
      <Btn type="secondary" :smaller="true" @click="emit('cancel')">
        {{ $t('cancel') }}
      </Btn>
      <Btn type="danger" :smaller="true" @click="emit('validate', stopTime)">
        <v-icon>{{ stopTime.type === FstType.SHIFT ? 'fa:fas fa-redo' : 'fa:fas fa-trash' }}</v-icon>
        {{ $t(stopTime.type === FstType.SHIFT ? 'restore' : 'delete') }}
      </Btn>
    </div>
  </div>
</template>

<style lang="scss">
.confirm-revert-stop-card {
  &__title {
    color: $warn;
    font-weight: 600;
    font-size: 16px;
  }

  &__subtitle {
    font-weight: 500;
  }

  .stop-card__actions {
    i {
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "revertStopModification": "Restaurer l'arrêt à sa position initiale ?",
    "removeTemporaryStop": "Supprimer cet arrêt temporaire de la liste ?",
  }
  </i18n>

<i18n locale="en">
  {
    "revertStopModification": "Restore the stop to its original position?",
    "removeTemporaryStop": "Remove this temporary stop from the list?",
  }
  </i18n>
