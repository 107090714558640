<template>
  <div class="connector-field-ip-address">
    <div class="form-group">
      <label class="form-group__label" :for="name">
        <slot name="label">
          {{ $t('label') }}
        </slot>
      </label>
      <input
        :id="name"
        v-model="value"
        class="form-group__input"
        :class="errorMessage ? 'form-group__input--invalid' : ''"
        :name="name"
      />
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'ip_address',
  },
});

const { value, errorMessage } = useField(computed(() => props.name));
</script>

<i18n locale="fr">
{
  "label": "Adresse IP"
}

</i18n>

<i18n locale="en">
{
  "label": "IP Address"
}
</i18n>
