<template>
  <div class="trip-management">
    <h1>{{ $t('tripValidation') }}</h1>
    <div class="form-group">
      <label for="start_distance_max">
        <span class="form-group__label">{{ $t('start_distance_max') }}</span>
        <p class="form-group__sub-label">
          {{ $t('start_distance_max-help') }}
        </p>
      </label>
      <input
        id="name"
        v-model.number="values.trip_validation_rules.start_distance_max"
        class="form-group__input"
        name="start_distance_max"
        required
        :placeholder="defaults.trip_validation_rules.start_distance_max.toString()"
        type="text"
      />
    </div>

    <div class="form-group">
      <div>
        <span class="form-group__label">{{ $t('start_delay') }}</span>
        <p class="form-group__sub-label">
          {{ $t('start_delay-help') }}
        </p>
      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <label class="input-group-text" for="start_delay_min">{{ $t('between') }}</label>
        </div>

        <input
          id="start_delay_min"
          v-model.number="values.trip_validation_rules.start_delay_min"
          class="form-group__input form-group__input--inline--grouped"
          :class="{ 'form-group__input--invalid': hasErrorDelayMinMax }"
          name="start_delay_min"
          type="number"
          required
          :placeholder="defaults.trip_validation_rules.start_delay_min.toString()"
        />

        <div class="input-group-prepend">
          <label class="input-group-text" for="start_distance_max">{{ $t('and') }}</label>
        </div>

        <input
          id="start_distance_max"
          v-model.number="values.trip_validation_rules.start_delay_max"
          class="form-group__input form-group__input--inline--grouped"
          :class="{ 'form-group__input--invalid': hasErrorDelayMinMax }"
          name="start_distance_max"
          type="number"
          required
          :placeholder="defaults.trip_validation_rules.start_delay_max.toString()"
        />
      </div>
    </div>

    <div class="form-group">
      <label for="vk_percent_min">
        <span class="form-group__label">{{ $t('vk_percent_min') }}</span>
        <p class="form-group__sub-label">
          {{ $t('vk_percent_min-help') }}
        </p>
      </label>
      <input
        id="vk_percent_min"
        v-model.number="values.trip_validation_rules.vk_percent_min"
        class="form-group__input"
        name="vk_percent_min"
        required
        :placeholder="defaults.trip_validation_rules.vk_percent_min.toString()"
        type="text"
      />
    </div>

    <h1>{{ $t('tripGrouping') }}</h1>

    <div class="form-group">
      <ToggleSwitch
        id="merging-gtfs"
        :checked="values.trip_list_configuration.merging.gtfs"
        class="trip-management__toggle-switch"
        @change="values.trip_list_configuration.merging.gtfs = !values.trip_list_configuration.merging.gtfs"
      />
      <label for="merging-gtfs">{{ $t('merging.gtfs') }}</label>
    </div>

    <div class="form-group">
      <ToggleSwitch
        id="merging-device"
        :checked="values.trip_list_configuration.merging.device"
        class="trip-management__toggle-switch"
        @change="
          values.trip_list_configuration.merging.device = !values.trip_list_configuration.merging.device
        "
      />
      <label for="merging-device">{{ $t('merging.device') }}</label>
    </div>

    <Btn type="primary" :disabled="!hasChanged || hasError" @click="saveInfos">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';
import { groupDefaults } from '@/store';

import SettingsMixin, { ConfigTarget } from './SettingsMixin';

/**
 * @typedef {Object} TripListConfiguration
 * @property {{device: boolean, gtfs: boolean}} merging
 */

export default {
  name: 'TripValidation',
  components: { Btn, ToggleSwitch },
  mixins: [SettingsMixin],

  data: () => ({
    defaults: groupDefaults,
  }),

  computed: {
    /** @return {boolean} */
    hasError() {
      return this.hasErrorDelayMinMax || this.missingAnyTripValidationRules;
    },
    hasErrorDelayMinMax() {
      return (
        this.values.trip_validation_rules.start_delay_min > this.values.trip_validation_rules.start_delay_max
      );
    },
    missingAnyTripValidationRules() {
      return (
        !this.values.trip_validation_rules.start_distance_max ||
        !this.values.trip_validation_rules.start_delay_min ||
        !this.values.trip_validation_rules.start_delay_max ||
        !this.values.trip_validation_rules.vk_percent_min
      );
    },
  },

  methods: {
    /**
     * Returns default values
     *
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        /** @type {import('@/store').TripValidationRules} */
        trip_validation_rules: {
          start_distance_max: null,
          start_delay_max: null,
          start_delay_min: null,
          vk_percent_min: null,
        },

        /** @type {TripListConfiguration} */
        trip_list_configuration: {
          merging: {
            device: false,
            gtfs: false,
          },
        },
      };
    },
    saveInfos() {
      this.save(ConfigTarget.TRIPS);
    },
  },
};
</script>

<style lang="scss">
.trip-management {
  h1 {
    font-size: 1.3em;
  }

  &__toggle-switch {
    margin-right: 1em;
  }
}
</style>

<i18n locale="fr">
{
  "merging": {
    "gtfs": "Regrouper les plans de transport",
    "device": "Regrouper les appareils"
  },
  "and": "et",
  "between": "Entre",
  "start_delay": "Heure de prise de course (s)",
  "start_delay-help": "Intervalle de temps pendant lequel la prise de course est considérée comme à l'heure.",
  "start_distance_max": "Distance de prise de course (m)",
  "start_distance_max-help": "Distance maximale par rapport au premier arrêt pour laquelle la prise de course est considérée comme correcte (hors acheminement).",
  "tripGrouping": "Regroupement des courses",
  "tripValidation": "Critères de validation des courses",
  "vk_percent_min": "Nombre de km enregistrés (%)",
  "vk_percent_min-help": "% de kilomètres enregistrés à partir duquel la course est considérée comme réalisée."
}
</i18n>

<i18n locale="en">
{
  "merging": {
    "gtfs": "Regroup the transport plans",
    "device": "Regroup the devices"
  },
  "and": "and",
  "between": "Between",
  "start_delay": "Start trip time (s)",
  "start_delay-help": "Delay interval in which trip start is considered on time.",
  "start_distance_max": "Start trip distance (m)",
  "start_distance_max-help": "Maximum start trip distance to the first stop that is considered valid (not including routing).",
  "tripGrouping": "Trip grouping",
  "tripValidation": "Trip validation criteria",
  "vk_percent_min": "Recorded kms (%)",
  "vk_percent_min-help": "% of recorded kilometers from which trip is considered done."
}
</i18n>
