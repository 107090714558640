<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, type PropType } from 'vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { FstType, useTripUpdates, type TripUpdates } from '@/store-pinia/trip-updates';

import { clearEmptyValues } from '@/libs/helpers/objects';
import { dateObjToGtfsFormat, dateGtfsFormatToObj } from '@/libs/helpers/dates';

import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import Btn from '@/components/ui/Btn.vue';
import Calendar from '@/libs/calendar';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import MapboxMap from '@/components/map/MapboxMap.vue';
import Modal from '@/components/layout/Modal.vue';
import StopTimesFeed from '@/components/common/ModalTripModification/StopTimesFeed.vue';

import type { MapStop } from '@/@types/mapbox';

const { t } = useI18n();
const store = useStore();
const tripUpStore = useTripUpdates();

const props = defineProps({
  date: {
    required: true,
    type: String,
  },

  gtfsId: {
    required: true,
    type: String,
  },

  tripFormattedName: {
    required: true,
    type: String,
  },

  tripId: {
    required: true,
    type: String,
  },

  tripUpdates: {
    default: () => ({}),
    type: Object as PropType<TripUpdates>,
  },
});

const emit = defineEmits(['close', 'refresh']);

const loading = ref<Boolean>(false);

const routeColor = ref<string>('#000000');
const mapBounds = ref<mapboxgl.LngLatBounds | null>(null);
const mapInstance = ref<mapboxgl.Map | null>(null);

const startDateValue = ref<Date>(new Date());
const endDateValue = ref<Date>(new Date());
const disabledDates = ref<{
  minDate: Date | null;
  maxDate: Date | null;
}>({
  minDate: null,
  maxDate: null,
});

const limitedDelay = computed<number | null>({
  get() {
    return tripUpStore.delay ? tripUpStore.delay / 60 : null;
  },

  set(val: number | null) {
    let valueToSet = null;
    if (!val) valueToSet = null;
    else if (val > 999) valueToSet = 999;
    else if (val < -999) valueToSet = -999;
    else valueToSet = val;
    tripUpStore.delay = valueToSet ? valueToSet * 60 : null;
  },
});

const delayWording = computed<String>(() => {
  if (tripUpStore.delay && tripUpStore.delay > 0) {
    return t('delayMinutes', tripUpStore.delay);
  }
  if (tripUpStore.delay && tripUpStore.delay < 0) {
    return t('advanceMinutes', -tripUpStore.delay);
  }
  return t('minutes');
});

const mapStops = computed<Array<MapStop>>(() => {
  const mapStopList: Array<MapStop> = [];

  tripUpStore.feedStopTimes.forEach(fst => {
    mapStopList.push({
      id: fst.stop_id,
      highlight: false,
      unserved: fst.type === FstType.CANCELED,
      deviation: [FstType.AD_HOC, FstType.REUSE, FstType.SHIFT].includes(fst.type),
      stop_sequence: fst.stop_sequence,
    });
  });

  return mapStopList;
});

const hasDateRangeChanged = computed<boolean>(() => {
  if (
    (dateObjToGtfsFormat(startDateValue.value) !== props.date ||
      dateObjToGtfsFormat(endDateValue.value) !== props.date) &&
    Object.keys(props.tripUpdates).length !== 0
  ) {
    return true;
  }
  return false;
});

onMounted(async () => {
  loading.value = true;

  await tripUpStore.initStore(props.gtfsId, props.tripId, props.tripUpdates);

  if (props.date) {
    startDateValue.value = dateGtfsFormatToObj(props.date) || new Date();
    endDateValue.value = dateGtfsFormatToObj(props.date) || new Date();
  }

  const routes = await store.dispatch('gtfs/getRoutesMap', {
    gtfsId: props.gtfsId,
  });

  // route color for design
  const route = routes[tripUpStore.trip.route_id] || null;
  routeColor.value = route?.route_color ? `#${route.route_color}` : '#000000';

  // Define calendar inactive dates to limit date picker
  disabledDates.value = await Calendar.getInactiveDatesForATrip(tripUpStore.trip.service_id, props.gtfsId);

  loading.value = false;
});

onUnmounted(() => {
  tripUpStore.$reset();
});

/**
 * Cancel a stop
 */
function cancelStop(event: mapboxgl.MapLayerMouseEvent, stopSequence?: number) {
  if (!tripUpStore.inStopTimeEdition) {
    const stopId = event?.features?.[0]?.properties?.id;
    const stops = tripUpStore.feedStopTimes.filter(stop => stop.stop_id === stopId);

    const currentStopSequence = stopSequence || stops[0].stop_sequence;
    scrollToElement(stopId + currentStopSequence);

    // Normal stop - no duplicate
    if (stops.length === 1) {
      // only update on click if stop is regular/canceled
      if ([FstType.CANCELED, FstType.REGULAR].includes(stops[0].type)) {
        const typeToSet = stops[0].type === FstType.CANCELED ? FstType.REGULAR : FstType.CANCELED;
        const indexToUpdate = tripUpStore.feedStopTimes.findIndex(stop => stop.stop_id === stopId);
        if (indexToUpdate !== -1) tripUpStore.feedStopTimes[indexToUpdate].type = typeToSet;
      }

      // Stop with multiple services
      // if there is a stopSequence, it means that the tooltip has been clicked
    } else if (typeof stopSequence === 'number') {
      const indexToUpdate = tripUpStore.feedStopTimes.findIndex(stop => stop.stop_sequence === stopSequence);
      if (
        indexToUpdate !== -1 &&
        [FstType.CANCELED, FstType.REGULAR].includes(tripUpStore.feedStopTimes[indexToUpdate].type)
      ) {
        const typeToSet =
          tripUpStore.feedStopTimes[indexToUpdate].type === FstType.CANCELED
            ? FstType.REGULAR
            : FstType.CANCELED;
        if (indexToUpdate !== -1) tripUpStore.feedStopTimes[indexToUpdate].type = typeToSet;
      }
    }
  }
}

async function submitModalModify() {
  const tripUpdates = {
    query: {
      gtfs_id: props.gtfsId,
      trip_id: props.tripId,
      start_date: dateObjToGtfsFormat(startDateValue.value),
      end_date: dateObjToGtfsFormat(endDateValue.value),
    },
    body: clearEmptyValues(tripUpStore.updatedTripUpdate),
    many: dateObjToGtfsFormat(startDateValue.value) !== dateObjToGtfsFormat(endDateValue.value),
  };

  await store.dispatch('trips/updateTrip', tripUpdates);

  emit('close');
  emit('refresh');
}

function onMapLoad({ map }: { map: mapboxgl.Map }) {
  map.once('idle', () => {
    mapInstance.value = map;
  });
}

function limitDelayLength(e: KeyboardEvent) {
  const maxLen = tripUpStore.delay || 0 < 0 ? 4 : 3;
  if (tripUpStore.delay && tripUpStore.delay.toString().length === maxLen && e.key !== 'Backspace') {
    e.preventDefault();
  }
}

function getModalWidth() {
  return window.innerWidth * 0.8;
}

/**
 * Fly action on map based on stopId
 */
function flyTo(stopId: string) {
  const relatedStop = tripUpStore.getAllStops.get(stopId);
  if (!relatedStop) return;
  mapInstance.value?.flyTo({
    center: [relatedStop.stop_lon, relatedStop.stop_lat],
    zoom: 15,
    speed: 0.8,
  });
}

/**
 * Scroll to the stop in the timeline
 */
function scrollToElement(stopId: string) {
  const element = document.getElementById(`stop-${stopId}`);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
</script>

<template>
  <Modal modal-class="modal-trip-modification" hide-footer :width="getModalWidth()" @close="$emit('close')">
    <template #title>
      {{ $t('tripModificationTitle') }}
    </template>
    <template #subtitle>
      {{ tripFormattedName }}
    </template>

    <template #body>
      <div class="modal-trip-modification__modal-body">
        <div v-if="loading">
          <AnimatedDots />
        </div>

        <template v-else>
          <div class="modal-trip-modification__delay">
            <label class="form-group__label modal-trip-modification__part-title" for="delay">
              {{ $t('addDelay') }}
            </label>
            <div class="modal-trip-modification__delay-input">
              <font-awesome-icon icon="fa-clock" :class="tripUpStore.delay ? 'orange-icon' : ''" />
              <input
                id="delay"
                v-model.number="limitedDelay"
                type="number"
                :min="-999"
                placeholder="00"
                :max="999"
                class="form-group__input form-group__small-number"
                @keydown="limitDelayLength"
              />
              {{ delayWording }}
            </div>
          </div>

          <div class="modal-trip-modification__stops">
            <div class="modal-trip-modification__part-title mt-3">
              {{ $t('modifyServicing') }}
            </div>
            <div class="modal-trip-modification__stops-container">
              <StopTimesFeed
                :route-color="routeColor"
                :delay="tripUpStore.delay ? tripUpStore.delay / 60 : null"
                :date="date"
                @mapFocusStop="flyTo"
              />

              <div class="modal-trip-modification__map">
                <MapboxMap
                  v-model:bounds="mapBounds"
                  border-radius="0 4px 0 0"
                  :gtfs-id="props.gtfsId"
                  :stops="mapStops"
                  :trips="[{ id: tripId, highlight: false }]"
                  :stops-options="{
                    stopsZones: false,
                    stopsBigMarkers: true,
                    showUnserved: true,
                    stopSelectorData: tripUpStore.trip.stop_times,
                  }"
                  trip-update-mode
                  @load="onMapLoad"
                  @click:stop="cancelStop($event)"
                  @click:stopTooltip="cancelStop($event.event, $event.stopSequence)"
                >
                  <div v-if="tripUpStore.isCanceled" class="modal-trip-modification__disabled-trip">
                    <span class="modal-trip-modification__disabled-trip__title">
                      {{ $t('canceledTrip') }}
                    </span>
                    <Btn type="secondary" @click="tripUpStore.isCanceled = !tripUpStore.isCanceled">
                      <font-awesome-icon icon="fa-rotate-right" />
                      <span>{{ $t('restoreTrip') }}</span>
                    </Btn>
                  </div>
                </MapboxMap>
              </div>
              <div class="modal-trip-modification__cancel-all">
                <v-checkbox id="cancel-trip" v-model="tripUpStore.isCanceled" color="success" hide-details>
                  <template #label>
                    <span class="modal-trip-modification__part-title">
                      {{ $t('cancelTrip') }}
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="modal-trip-modification__footer">
        <div class="modal-trip-modification__period-select">
          <span class="period-select__label">{{ $t('from') }}</span>
          <HeaderDatepicker
            v-model:value="startDateValue"
            :disabled="disabledDates"
            :has-custom-position="false"
            without-arrows
            class="datepicker-input"
          />
          <span class="period-select__label">{{ $t('to') }}</span>
          <HeaderDatepicker
            v-model:value="endDateValue"
            :disabled="disabledDates"
            :has-custom-position="false"
            without-arrows
            class="datepicker-input"
          />
        </div>
        <div class="modal-trip-modification__btn-container">
          <Btn type="secondary" @click="$emit('close')">{{ $t('cancel') }}</Btn>
          <Btn
            type="primary"
            :disabled="
              (!tripUpStore.hasTripUpdatesChanges && !hasDateRangeChanged) || tripUpStore.inStopTimeEdition
            "
            @click="submitModalModify"
          >
            {{ $t('apply') }}
          </Btn>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-trip-modification {
  .modal__header {
    padding: 0;
  }

  &__btn-container {
    display: flex;
    width: 50%;

    button {
      width: 50%;
    }
  }

  &__cancel-all {
    width: 100%;
    padding: 10px 15px;
    border-top: solid 1px $border-variant;
  }

  &__delay-input {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__disabled-trip {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgb(235 87 87 / 80%);
    color: white;
    backdrop-filter: blur(5px);

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: 36px;
      font-family: $font-poppins;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }

  &__map {
    position: relative;
    width: 50%;
    min-width: 500px;

    // 96vh because 2vh margin top&bottom, 68px = header, 40px = modifyService title, 49px = delay input, 42px = cancel trip, 25px = next days,  98px = footer btn, -20px bonus
    height: calc(96vh - 68px - 40px - 49px - 42px - 25px - 98px - 20px);
  }

  &__stops-container {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    border: solid 1px $border-variant;
    border-radius: 5px;
  }

  &__part-title {
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
  }

  &__period-select {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  // utilites
  .margin-auto {
    margin: auto;
  }

  .orange-icon {
    color: $warn;
  }

  .btn-display-on-hover {
    opacity: 0;
  }

  .modal__body {
    overflow-y: auto;
    margin-bottom: 0;
  }
}
</style>

<i18n locale="fr">
{
  "apply": "Appliquer les modifications",
  "tripModificationTitle": "Modification de la course",
  "minutes": "minutes",
  "advanceMinutes": "minute d'avance | minutes d'avance",
  "delayMinutes": "minute de retard | minutes de retard",
  "addDelay": "Indiquer un retard",
  "cancelTrip": "Annuler la course",
  "modifyServicing": "Modifier la desserte",
  "restore": "Restaurer",
  "canceledTrip": "Course annulée",
  "restoreTrip": "Restaurer la course",
  "neutralized": "Neutralisé",
  "from": "Du",
  "to": "au"
}
</i18n>

<i18n locale="en">
{
  "apply": "Apply changes",
  "tripModificationTitle": "Trip modification",
  "minutes": "minutes",
  "advanceMinutes": "minute early | minutes early",
  "delayMinutes": "minute late | minutes late",
  "addDelay": "Apply a delay",
  "cancelTrip": "Cancel trip",
  "modifyServicing": "Modify service",
  "restore": "Restore",
  "canceledTrip": "canceled trip",
  "restoreTrip": "Restore trip",
  "neutralized": "Neutralized",
  "from": "From",
  "to": "to"
}
</i18n>
