<template>
  <Modal modal-class="reports-modal-period" :show="true" @close="$emit('close')">
    <template #title>
      {{ $t('editPeriod') }}
    </template>

    <template #body>
      <div v-if="isMetricPunctuality" class="reports-modal-period__limit-info">
        {{ $t('limitReportingPeriodInfo') }}
      </div>
      <div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ $t('from') }}</span>
          </div>

          <div class="form-control">
            <HeaderDatepicker
              v-model:value="startDateValue"
              class="datepicker"
              :disabled="{
                maxDate: new Date(),
              }"
            />
          </div>

          <div class="input-group-prepend">
            <span class="input-group-text">{{ $t('to') }}</span>
          </div>

          <div class="form-control">
            <HeaderDatepicker
              v-model:value="endDateValue"
              class="datepicker"
              :disabled="{
                maxDate: endDateMax,
                minDate: startDateValue,
              }"
            />
          </div>
        </div>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" @click="$emit('submit', { startDateValue, endDateValue })">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import { dateGtfsFormatToObj, recalculateMaxDateTo31days } from '@/libs/helpers/dates';
import { GroupRoute as RouteName } from '@/libs/routing';

export default {
  name: 'ReportsModalPeriod',

  components: {
    Btn,
    HeaderDatepicker,
    Modal,
  },

  props: {
    endDate: {
      type: String,
      required: true,
    },

    startDate: {
      type: String,
      required: true,
    },
    metric: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['close', 'submit'],

  data: () => ({
    /** @type {Date} */
    endDateValue: new Date(),

    /** @type {Date} */
    startDateValue: new Date(),
  }),
  computed: {
    endDateMax() {
      const currentDate = new Date();
      // If punctuality mode, block to 1 month max the interval periode
      if (this.isMetricPunctuality) {
        return recalculateMaxDateTo31days(this.startDateValue, currentDate);
      }
      // else block to current date
      return currentDate;
    },
    isMetricPunctuality() {
      return this.metric === RouteName.REPORTING_OLD_PUNCTUALITY;
    },
  },

  watch: {
    endDate: {
      immediate: true,
      handler() {
        this.endDateValue = dateGtfsFormatToObj(this.endDate);
      },
    },

    startDate: {
      immediate: true,
      handler() {
        this.startDateValue = dateGtfsFormatToObj(this.startDate);
      },
    },

    startDateValue() {
      if (this.startDateValue > this.endDateValue) {
        this.endDateValue = this.startDateValue;
      }
      if (this.isMetricPunctuality && this.endDateValue > this.endDateMax) {
        this.endDateValue = this.endDateMax;
      }
    },
  },
};
</script>

<style lang="scss">
.reports-modal-period {
  .input-group {
    display: flex;
    align-items: center;
  }

  .input-group-text {
    border: 0;
  }

  .modal__body {
    overflow-y: initial;
  }

  &__limit-info {
    padding: 10px;
    font-size: 12px;
  }
}
</style>

<i18n locale="fr">
{
  "editPeriod": "Choisir une période",
  "from": "Du",
  "to": "au",
}
</i18n>

<i18n locale="en">
{
  "editPeriod": "Choose a period",
  "from": "From",
  "to": "To ",
}
</i18n>

<i18n locale="cz">
{
  "from": "Od",
  "to": "Do",
  "editPeriod": "Vybrat období"
}
</i18n>

<i18n locale="de">
{
  "from": "Ab",
  "to": "Bis",
  "editPeriod": "Zeitspanne auswählen"
}
</i18n>

<i18n locale="es">
{
  "from": "De",
  "to": "Para",
  "editPeriod": "Elige un periodo"
}
</i18n>

<i18n locale="it">
{
  "from": "Dal",
  "to": "Al",
  "editPeriod": "Scegli un periodo"
}
</i18n>

<i18n locale="pl">
{
  "from": "Od",
  "to": "Do",
  "editPeriod": "Wybierz okres"
}
</i18n>
