<template>
  <div class="mapbox-history"></div>
</template>

<script>
import { MapboxHelper } from '@/components/map/mapboxHelper';

const DEVICE_TRAIL_SOURCE_ID = 'deviceTrailSource';

const DEVICE_TRAIL_LAYER_ID = 'pointsLayer';

export default {
  name: 'MapboxHistoryLayer',
  props: {
    /** @type {import('vue').Prop<mapboxgl.Map>} */
    map: {
      type: Object,
      required: true,
    },
    /** @type {import('vue').Prop<GeoJSON.Feature<GeoJSON.LineString>>} */
    deviceTrailSource: {
      type: Object,
      required: true,
    },
  },
  emits: ['isLoaded'],
  data: () => ({
    loaded: false,
  }),
  watch: {
    deviceTrailSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, DEVICE_TRAIL_SOURCE_ID, [this.deviceTrailSource]);
      },
    },
  },
  created() {
    this.map.once('idle', () => {
      this.loaded = true;
    });
    this.initSourceAndLayer();
  },
  unmounted() {
    MapboxHelper.cleanLayersAndSources(this.map, [DEVICE_TRAIL_LAYER_ID], [DEVICE_TRAIL_SOURCE_ID]);
  },
  methods: {
    initSourceAndLayer() {
      MapboxHelper.createEmptySource(this.map, DEVICE_TRAIL_SOURCE_ID);
      this.addTrailLayer();

      this.$emit('isLoaded', true);
    },
    addTrailLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: DEVICE_TRAIL_LAYER_ID,
          type: 'line',
          source: DEVICE_TRAIL_SOURCE_ID,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },

          paint: {
            'line-width': 4,
            'line-color': '#00b871', // $primary-light
          },
        }),
      );
    },
  },
};
</script>
